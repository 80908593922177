<template>
    <span></span>
</template>

<script>

import $ from 'jquery';
import MainAppMixin from "../MainAppMixin";
import EditorMixin from './EditorMixin';
import ScNotification from "../../shared/common/ScNotification.vue";
import ScCommonUtil from "../../shared/common/ScCommonUtil";

export default {
  name: "EditorConcurrencyChecker",
  mixins: [MainAppMixin, EditorMixin],
  props: {
    hsHotspot: {type: Object},
    width: {type:Number},
    height: {type:Number}
  },
  data: function() {
    return {
      hereTimeMs: 0,
      userConcurrencyUuid: ScCommonUtil.guid()
    }
  },

  mounted: function() {
    //console.log('EditorConcurrencyChecker mounted')
    document.addEventListener('sc_event:concurrency_user_is_here', this.concurrencyUserIsHere);
    this.concurrencyTellServerIAmHere(null);
  },
  beforeUnmount() {
    //console.log('EditorConcurrencyChecker mounted')
    document.removeEventListener('sc_event:concurrency_user_is_here', this.concurrencyUserIsHere);
  },

  methods: {
    concurrencyTellServerIAmHere(originalConcurrencyUuid) {
      //console.log('concurrencyTellServerIAmHere', originalConcurrencyUuid);
      setTimeout(() => {
        $.ajax({ method: 'POST', url: '/main/presentations/concurrency_i_am_here_v2', data: {
            workspace_id: this.mainMxCurrentWorkshopId,
            presentation_id: this.edMxStateShowcase.presentation.id,
            concurrency_uuid: this.userConcurrencyUuid,
            original_concurrency_uuid: originalConcurrencyUuid }
        }).done((data) => {
          if (data && data.here_time_ms) {
            this.hereTimeMs = data.here_time_ms;
          }
        });  // fire and forget, server gives to pubnub
      }, originalConcurrencyUuid ? 0 : 2000);
    },

    concurrencyUserIsHere(e) {
      let data = e.detail;
      if ( !data || !data.presentation_id || !data.concurrency_uuid || !data.user_display_name || !data.user_id) return;
      let presentationId = this.edMxStateShowcase.presentation.id;
      // console.log('concurrencyUserIsHere', data.presentation_id, '=', this.edMxStateShowcase.presentation.id,
      //   data.concurrency_uuid, '=', this.userConcurrencyUuid,
      //   data.here_time_ms, '<', this.hereTimeMs);
      if (data.presentation_id !== presentationId) {
        // console.log('diff pres')
        return;  // different presentation
      }
      if (data.concurrency_uuid === this.userConcurrencyUuid) {
        // console.log('same edit session')
        return;  // same as this editing session
      }
      if (data.here_time_ms < this.hereTimeMs) {
        // console.log('from before')
        return;  // msg is from before we were loaded, ignore
      }

      // either tell server who we are OR show a conflict

      // console.log('process', e, this.userConcurrencyUuid, e.concurrency_uuid === this.userConcurrencyUuid, data);
      if (!data.original_concurrency_uuid) {  // we are replying to another user
        // console.log('reply other user');
        this.concurrencyTellServerIAmHere(data.concurrency_uuid);
      }  else {
        // console.log('warn');
        let message = data.user_display_name + ' is also editing this presentation. This could cause conflicts! ' +
          'Please ensure only one person edits at a time.';
        if (data.user_id === this.$store.state.user.user_id) {
          message = 'You are editing this presentation in another tab, window, or computer. This could cause ' +
            'conflicts, please close other tabs to avoid any trouble.';
        }
        ScNotification.growlErrMsg(message);
      }
    },
  },

}

</script>
