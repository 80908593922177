<template>
  <div v-if="edMxSelectedHotspots.length > 0 && edMxSelectedHotspotsBranding.length > 0" class="d-inline-block" >
    <div class="dropdown">
      <button type="button" class="btn px-md-3 sc-highlight-expanded" data-bs-toggle="dropdown" :disabled="edMxLoneSelectedHotspotLocked"
              role="button" aria-expanded="false" v-sc-tooltip="'Alignment and Order'">
        <ScIcon v-if="textAlign === 'left' || !textAlign" name="alignLeftFW"/>
        <ScIcon v-if="textAlign === 'center'" name="alignCenterFW"/>
        <ScIcon v-if="textAlign === 'right'" name="alignRightFW"/>
      </button>
      <div class="dropdown-menu dropdown-menu-right pb-3" @click.prevent.stop style="width: 300px;">

        <span class="dropdown-header border-0 mb-0">Text Horizontal Align</span>
        <div class="btn-group align-items-center w-100 px-3 mb-2">
          <button class="btn" title="Left"
                  :class="[(textAlign === 'left') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="textAlignUpdate('left')"><ScIcon name="alignLeftFW"/></button>
          <button class="btn" title="Center"
                  :class="[(textAlign === 'center') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="textAlignUpdate('center')"><ScIcon name="alignCenterFW"/></button>
          <button class="btn" title="Right"
                  :class="[(textAlign === 'right') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="textAlignUpdate('right')"><ScIcon name="alignRightFW"/></button>
        </div>

        <hr class="my-2 mx-4" />

        <span class="dropdown-header border-0 mb-0">Text Vertical Align</span>
        <div class="btn-group align-items-center w-100 px-3 mb-2">
          <button class="btn" title="Top"
                  :class="[(verticalAlign === 'top') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="verticalAlignUpdate('top')"><ScIcon name="caretSquareUpFW"/></button>
          <button class="btn" title="Middle"
                  :class="[(verticalAlign === 'middle') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="verticalAlignUpdate('middle')"><ScIcon name="caretSquareRightFW"/></button>
          <button class="btn" title="Bottom"
                  :class="[(verticalAlign === 'bottom') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="verticalAlignUpdate('bottom')"><ScIcon name="caretSquareDownFW"/></button>
        </div>

        <hr class="my-2 mx-4" />

        <span :class="[!isLoneHsSelected ? 'text-muted' : '']"
              class="dropdown-header border-0 mb-0">Arrange
          <template v-if="isLoneHsSelected">({{currentHsIdx+1}} of {{totalHotspotsOnPage}})</template></span>
        <div class="btn-group align-items-center w-100 px-3">
          <button title="Move the selected hotspot forward one layer" class="btn btn-outline-secondary"
                  :disabled="!isLoneHsSelected || currentHsIdx+1 >= totalHotspotsOnPage"
                  @click.prevent.stop="orderForward()" style="width: 90px;">
            Forward</button>
          <button title="Move the selected hotspot back one layer" class="btn btn-outline-secondary"
                  :disabled="!isLoneHsSelected || currentHsIdx < 1"
                  @click.prevent.stop="orderBackward()" style="width: 90px;">
            Back</button>
        </div>

      </div>
    </div>
  </div>
</template>


<script>

import EditorMixin from './../EditorMixin';
import ScIcon from '../../../shared/common/ScIcon.vue';


export default {
    name: "EditorToolbarTextAlign",
    mixins: [EditorMixin],
    components: {ScIcon},
    methods: {
      textAlignUpdate (align) {
        //console.log('textAlignUpdate', align)
        this.edMxUpdateSelectedHotspotsBranding('text_alignment', align);
      },
      verticalAlignUpdate(align) {
        this.edMxUpdateSelectedHotspotsBranding('vertical_alignment', align);
      },

      orderForward() {
        if (!this.isLoneHsSelected || !this.edMxLoneSelectedHotspot) return;
        let hotspots = this.$store.getters.presHotspotsForPageId(this.edMxStateEd.currentPageId);
        //console.log('orderForward', this.currentHsIdx, hotspots.length);
        if (this.currentHsIdx+1 < hotspots.length) {
          this.swapZindex(this.edMxLoneSelectedHotspot, hotspots[this.currentHsIdx+1])
        }
      },
      orderBackward() {
        if (!this.isLoneHsSelected || !this.edMxLoneSelectedHotspot) return;
        let hotspots = this.$store.getters.presHotspotsForPageId(this.edMxStateEd.currentPageId);
        //console.log('orderBackward', this.currentHsIdx, hotspots.length);
        if (this.currentHsIdx > 0) {
          this.swapZindex(this.edMxLoneSelectedHotspot, hotspots[this.currentHsIdx-1])
        }
      },
      swapZindex(hotspot, swapHotspot) {
        //console.log('swapZindex', hotspot.zindex, swapHotspot.zindex);
        let hs = Object.assign({}, hotspot);
        let swapHs = Object.assign({}, swapHotspot);
        let origHsZindex = hs.zindex;
        if (swapHs.zindex === origHsZindex) origHsZindex = swapHs.zindex+1;  // fix duplicate zindex
        hs.zindex = swapHs.zindex;
        swapHs.zindex = origHsZindex;
        this.$store.dispatch('presUndoableSaveHotspotBatch', [hs, swapHs]);
      },
    },
    computed: {

      currentHsIdx() {
        if (!this.isLoneHsSelected || !this.edMxLoneSelectedHotspot.id) return 0;
        let hotspots = this.$store.getters.presHotspotsForPageId(this.edMxStateEd.currentPageId);
        return hotspots.findIndex((hs) => hs.id === this.edMxLoneSelectedHotspot.id);
      },

      totalHotspotsOnPage () {
        if (!this.isLoneHsSelected || !this.edMxLoneSelectedHotspot.id) return 0;
        let hotspots = this.$store.getters.presHotspotsForPageId(this.edMxStateEd.currentPageId);
        return hotspots.length;
      },

      isLoneHsSelected () {
        return !!this.edMxLoneSelectedHotspot;
      },

      textAlign () {
        return this.edMxSelectedMultipleHotspotsBrandingDisplayValueForAttribute('text_alignment');
      },

      verticalAlign () {
        return this.edMxSelectedMultipleHotspotsBrandingDisplayValueForAttribute('vertical_alignment');
      },

    },

}


</script>
