<template>
  <div v-if="edMxSelectedHotspots.length > 0 && edMxSelectedHotspotsBranding.length > 0" class="d-inline-block" >
    <div class="dropdown">
      <button type="button" class="btn px-md-3 sc-highlight-expanded" data-bs-toggle="dropdown"
              :disabled="edMxLoneSelectedHotspotLocked" role="button" aria-expanded="false" v-sc-tooltip="'Effects'">
        <ScIcon name="sparklesFW"/>
      </button>

      <div class="dropdown-menu dropdown-menu-right pb-3" @click.prevent.stop style="width: 345px;">

        <span class="dropdown-header border-0 mb-0">Text Shadow</span>

        <div class="btn-group align-items-center w-100 mb-2 px-3">
          <button class="btn"
                  :class="[(hsbTextShadow === 'null') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingTextShadow(null)">Off</button>
          <button class="btn"
                  :class="[(hsbTextShadow === 'sm') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingTextShadow('sm')">S</button>
          <button class="btn"
                  :class="[(hsbTextShadow === 'md') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingTextShadow('md')">M</button>
          <button class="btn"
                  :class="[(hsbTextShadow === 'lg') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingTextShadow('lg')">L</button>
        </div>

        <hr class="my-2 mx-4" />

        <span class="dropdown-header border-0 mb-0">Text Hollow</span>

        <div class="btn-group align-items-center w-100 mb-2 px-3">
          <button class="btn"
                  :class="[(hsbTextHollow === 'null') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingTextHollow(null)">Off</button>
          <button class="btn"
                  :class="[(hsbTextHollow === 'sm') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingTextHollow('sm')">S</button>
          <button class="btn"
                  :class="[(hsbTextHollow === 'md') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingTextHollow('md')">M</button>
          <button class="btn"
                  :class="[(hsbTextHollow === 'lg') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingTextHollow('lg')">L</button>
        </div>

        <hr class="my-2 mx-4" />

        <div class="row justify-content-between mx-0">

          <span class="col-5 dropdown-header border-0 mt-2">Uppercase</span>

          <div class="col mt-2 text-end">
            <div class="btn-group align-items-center w-100 mb-2">
              <button class="btn"
                      :class="[(hsbUpperCase === 'null')? 'active btn-outline-primary' : 'btn-outline-secondary']"
                      @click.prevent.stop="updateHotspotBrandingUppercase(null)">Off</button>
              <button class="btn"
                      :class="[(hsbUpperCase === 'on') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                      @click.prevent.stop="updateHotspotBrandingUppercase('on')">On</button>
            </div>
          </div>

        </div>

        <hr class="my-2 mx-4" />

        <EditorToolbarHotspotEffect/>

      </div>
    </div>
  </div>
</template>


<script>

import EditorMixin from './../EditorMixin';
import ScIcon from '../../../shared/common/ScIcon.vue';
import EditorToolbarHotspotEffect from './EditorToolbarHotspotEffect.vue';



export default {
    name: "EditorToolbarTextEffect",
    mixins: [EditorMixin],
    components: { ScIcon, EditorToolbarHotspotEffect },
    methods: {
      updateHotspotBrandingTextShadow (value){
        this.edMxUpdateSelectedHotspotsBrandingAdditionalStyles('text_shadow', value);
      },
      updateHotspotBrandingTextHollow (value){
        this.edMxUpdateSelectedHotspotsBrandingAdditionalStyles('text_hollow', value);
      },
      updateHotspotBrandingUppercase (value) {
        this.edMxUpdateSelectedHotspotsBrandingAdditionalStyles('uppercase', value);
      },

    },
    computed: {

      hsbTextShadow () {
        return this.edMxSelectedMultipleHotspotsBrandingAdditionalStylesDisplayValueForAttribute('text_shadow');
      },
      hsbTextHollow () {
        return this.edMxSelectedMultipleHotspotsBrandingAdditionalStylesDisplayValueForAttribute('text_hollow');
      },
      hsbUpperCase () {
        return this.edMxSelectedMultipleHotspotsBrandingAdditionalStylesDisplayValueForAttribute('uppercase');
      }


    },

}


</script>
