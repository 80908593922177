<template>
    <div class="container-fluid">

        <div class="row">

            <div class="col-1 px-0 pt-2">
                <ScToggleOnOff :active="isEnabled"
                               :loading="showLoading"
                               @sc-toggle="toggleEnabled()"></ScToggleOnOff>
            </div>
            <div class="col-11">
                <h4>Intro Video</h4>
                <small class="d-block">Plays before the first slide when the presentation is opened. Short videos like logo
                    animations are best here. Users can toggle whether it plays.

                  <br/><br/>Requires a republish to take effect.
                </small>

                <template v-if="isEnabled">
                    <div v-if="!introVideoResource" class="my-3">
                        <button type="button" title="Set background" class="btn btn-outline-secondary "
                                @click.prevent="chooseIntroVideo()">
                            <ScIcon name="filmFW"/> Choose video</button>
                    </div>
                    <div v-if="introVideoResource" class="my-3">
                        <EditorResourceThumb v-if="introVideoResource" :resourceId="introVideoResource.id">
                            <div class="col my-auto ms-auto text-end">
                                <button type="button" class="btn btn-outline-secondary"
                                        @click.prevent="chooseIntroVideo()"><ScIcon name="randomFW" class="me-1"/>Replace</button>
                                <button type="button" class="btn btn-outline-secondary ms-2" v-sc-tooltip="'Remove'"
                                        @click.prevent="assignIntroVideoResource(null)"><ScIcon name="minusCircleFW"/></button>
                            </div>
                        </EditorResourceThumb>
                    </div>
                </template>

            </div>

        </div>

        <hr class="my-4"/>

        <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>

    </div>
</template>



<script>

    import ScIcon from '../../../shared/common/ScIcon.vue';
    import LibraryMainModal from '../../library/LibraryMainModal.vue';
    import EditorMixin from './../EditorMixin';
    import EditorResourceThumb from './../EditorResourceThumb.vue';
    import ScToggleOnOff from '../../../shared/common/ScToggleOnOff.vue';


    export default {
        name: "EditorToolbarIntroVideo",
        components: {EditorResourceThumb, ScIcon, ScToggleOnOff, LibraryMainModal},
        mixins: [EditorMixin],
        data () {
            return {
                isShown: false,
                isEnabled: true,
                showLoading: false
            }
        },
        mounted () {
            if (!this.edMxStateShowcase.presentationmetadata.opening_scene_resource_id)
                this.isEnabled = false;
        },
        methods: {
            chooseIntroVideo() {
                this.$refs.libraryMainModal.toggleBsModal({
                    selectionMode: 'single', dialogType: 'modal',
                    filterType: 'movie'
                }, this.assignIntroVideoResource);
            },

            assignIntroVideoResource(selectedResources) {
                //console.log('assignIntroVideoResource', selectedResources);
                let selectedResource = null;
                if (selectedResources && selectedResources.length > 0) {
                    selectedResource = selectedResources[0];
                    this.$store.dispatch('presResourceAddedFromLibrary', selectedResource);
                }
                this.$store.dispatch('presSetIntroVideo', selectedResource ? selectedResource.id : null);
            },

            toggleEnabled() {
                this.isEnabled = !this.isEnabled;
                this.assignIntroVideoResource(null);
            },

        },

        computed: {
            introVideoResource () {
                let res = null;
                if (this.edMxStateShowcase.presentationmetadata.opening_scene_resource_id) {
                    res = this.$store.getters.presResource(
                        this.edMxStateShowcase.presentationmetadata.opening_scene_resource_id);
                }
                return res;
            },
        }
    }

</script>
