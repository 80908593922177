<template>
  <button v-if="color !== 'transparent'" type="button" class="btn p-0" :disabled="isDisabled"
          @click.prevent="openCpModal">
    <span :style="{ backgroundColor: color, height: '42px', width: '42px' }"
          class="d-inline-block btn btn-outline-secondary"></span>
  </button>

  <button v-else type="button" class="btn p-0" :disabled="isDisabled"
          v-sc-tooltip="'Transparent'" @click.prevent="openCpModal">
    <span style="width: 42px; height: 42px" class="d-inline-block btn btn-outline-secondary p-0 py-2">
      <ScIcon name="tintSlash" class="small mx-1 text-muted" style="opacity: 0.5;"/>
    </span>
  </button>
</template>


<script>

  import ScIcon from '../../shared/common/ScIcon.vue';
  import ScColorPickerModal from './ScColorPickerModal.vue';

  export default {
    name: 'ScColorPicker',
    emits: ['change'],
    components: {ScIcon},
    props: {
      color: {type: String, default: '#FFFFFF'},
      hasTransparent: {type: Boolean, default: true},
      isDisabled: {type: Boolean, default: false},
      showPresentationColors: {type: Boolean, default: true}
    },
    methods: {
      openCpModal() {
        ScColorPickerModal.modal({
          color: this.currentColorUpperNoHash, hasTransparent: this.hasTransparent,
          isDisabled: this.isDisabled, scColors: this.scDataColors
        }, (color) => {
          this.$emit('change', color);
        });
      },
    },
    computed: {
      currentColorUpperNoHash() {
        return _cleanColorDefn(this.color);
      },
      scDataColors() {
        let scCols = [];
        if (this.showPresentationColors) {
          let scData = this.$store.state.pres.showcase;
          if (scData) {
            Object.values(scData.page).forEach((pg) => {
              _addScColor(scCols, pg.page_bg_color, []);
            });
            Object.values(scData.hotspot_branding).forEach((hsb) => {
              _addScColor(scCols, hsb.fill_colour, []);
              _addScColor(scCols, hsb.background_colour, []);
              _addScColor(scCols, hsb.font_colour, []);
              if (hsb.additional_style_obj) {
                _addScColor(scCols, hsb.additional_style_obj.border_color, []);
              }
            });
          }
          _sortHexColors(scCols);
        }
        return scCols;
      }
    },
  };

  let _cleanColorDefn = function(color) {
    if (!color) return null;
    let col = color.toUpperCase();
    if (col === 'TRANSPARENT') return 'transparent';
    return col.indexOf('#') === 0 ? col.substring(1) : col;
  };

  let _addScColor = (cols, c, excludeList) => {
    if (c) {
      let cleanColor = _cleanColorDefn(c);
      if (!cols.includes(cleanColor) && !excludeList.includes(cleanColor) && cleanColor !== 'transparent') {
        cols.push(cleanColor);
      }
    }
  };

  let _sortHexColors = function(colors) {
    colors.sort((firstEl, secondEl) => {
      let firstHsl = _hexToHsl(firstEl), secondHsl = _hexToHsl(secondEl);
      //console.log('_hexToHsl', firstHsl, secondHsl);
      if (firstHsl[0] < secondHsl[0]) return -1;
      if (firstHsl[0] > secondHsl[0]) return 1;
      return 0;
    });
  };

  let _hexToHsl = function(hex) {
    let rgb = _hexToRgb(hex);
    return _rgbToHsl(rgb[0], rgb[1], rgb[2]);
  };

  let _hexToRgb = function(color) {
    let r = parseInt(color.substr(1,2), 16); // Grab the hex representation of red (chars 1-2) and convert to decimal (base 10).
    let g = parseInt(color.substr(3,2), 16);
    let b = parseInt(color.substr(5,2), 16);
    return [r, g, b];
  };

  let _rgbToHsl = function (r, g, b){
    r /= 255;
    g /= 255;
    b /= 255;
    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;
    if (max === min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch(max){
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }
    return [h, s, l];
  };


</script>



