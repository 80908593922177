<template>
    <div v-if="edMxCurrentPage && edMxLoneSelectedHotspot" class="d-inline-block">
        <hr class="mt-1 mb-3 mx-5" />

        <form class="row px-5 pb-3 justify-content-center" @submit.prevent>
            <div class="form-check col-auto my-auto">
                <input class="form-check-input" type="checkbox" id="enable-pin" v-model="pinCodeEnabled"
                       @click.stop="setPinCodeEnabled(!pinCodeEnabled)" :disabled="!allowToggle">
                <label class="form-check-label me-3" @click="setPinCodeEnabled(!pinCodeEnabled)"
                       for="enable-pin"> Enable PIN</label>
            </div>

            <input type="text" ref="pinCode" :class="{'form-control': true, 'is-invalid': !pinCodeValid}"
                   style="width: 100px;" maxlength="4" :disabled="!pinCodeEnabled || !allowToggle"
                   v-model="pinCode"
                   @keyup="updatePinCode()"
                   @blur="updatePinCode()"
                   @focus="$event.target.select()">
            <small class="text-muted mt-3"><ScIcon name="questionCircle"/>
                With <strong>PIN protection</strong> you can create
                restricted areas in your presentation. Clicking a hotspot with PIN protection enabled will
                prompt the user for a 4 digit PIN code.
            </small>
        </form>
    </div>
</template>



<script>

import EditorMixin from './../EditorMixin';
import ScIcon from '../../../shared/common/ScIcon.vue';

export default {
    name: "EditorToolbarHotspotPin",
    mixins: [EditorMixin],
    components: {ScIcon},
    props: {
        currentHotspotHasTarget: {type: Boolean, default: false},
        allowToggle: {type: Boolean, default: false}
    },
    data: function() {
        return {
            pinCode: null,
            pinCodeEnabled: false
        }
    },
    mounted: function() {
        this.updateData();
    },
    methods: {

        updatePinCode() {
            //console.log('updatePinCode', this.edMxLoneSelectedHotspot, this.pinCodeValid, this.pinCode);
            if (this.edMxLoneSelectedHotspot && this.pinCodeValid
                    && this.pinCode !== this.edMxLoneSelectedHotspot.passcode) {
                let hs = Object.assign({}, this.edMxLoneSelectedHotspot);
                hs.passcode = this.pinCode;
                this.$store.dispatch('presUndoableSaveHotspotBatch', [hs]);
            }
        },
        setPinCodeEnabled(isEnabled) {
            if (!this.currentHotspotHasTarget) return;
            //console.log('setPinCodeEnabled', isEnabled);
            this.pinCodeEnabled = isEnabled;
            this.pinCode = isEnabled ? '0000' : null;
            this.updatePinCode();
        },
        updateData: function() {
            //console.log('change hotspot', this.edMxLoneSelectedHotspot);
            this.pinCode = this.edMxLoneSelectedHotspot ? this.edMxLoneSelectedHotspot.passcode : null;
            this.pinCodeEnabled = !!this.pinCode;
        },

    },
    computed: {
        pinCodeValid: function() {
            //console.log('pinCodeValid', this.pinCode);
            let pin = this.pinCode;
            return !pin || pin.length === 4 && pin.match('[0-9]{4}');
        },
    },
    watch: {
        edMxLoneSelectedHotspot: function() {
            this.updateData();
        }
    }

}


</script>

