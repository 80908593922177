<template>
    <li class="list-group-item">
        <span class="float-end" v-if="!action_pending"><a href="#" @click="deleteShareableItem"><ScIcon name="times" class="text-muted"/></a></span>
        <span class="float-end" v-if="action_pending"><ScIcon name="spinnerFW" class="text-muted"/></span>

        <img :src="resourceIconUrl(res)" alt="Share Icon" class="me-2" style="width: 30px;height: 30px;" />
        {{ res.name }}

        <a class="resource-info-popover-link" v-if="!action_pending" v-sc-trackevent="'manage_shareable:resource_info_popover'" href="#"
            @click.prevent="mainMxShowResInfo(resource_id)"><ScIcon name="infoCircle"/></a>
    </li>
</template>


<script>

import MainMixin from '../MainAppMixin';
import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';
import ScIcon from '../../shared/common/ScIcon.vue';
import ScNativeExts from 'ScVueAliasScNativeExts';


export default {
    name: 'PresentationShareableItem',
    emits: ['remove-shared-file'],
    mixins: [MainMixin],
    components: {ScIcon},
    props: {
        page_id: Number,
        resource_id: Number,
        res: Object,
    },
    data() {
        return {
            action_pending: false
        }
    },
    methods: {
        deleteShareableItem(e) {
            e.preventDefault();
            ScConfirmModal2.modal('Are you sure you want to remove this file from sharing?').then(() => {
                //_store.toggleShareableItem(this, false);
                this.$emit('remove-shared-file');
                this.action_pending = true;
                this.$store.dispatch('presToggleShareableResource', {
                    resource_id: this.resource_id, page_id: this.page_id, enabled: false
                });
            }, () => {});
        },

        resourceIconUrl (item) {
            if (!this.displayGenericThumbnails && item.thumb_url) return item.thumb_url;
            if (!item) return null;
            let custom_suffix_map = {
                'docx': 'doc',
                'xlsx': 'xls',
                'rar': 'zip',
                'pptx': 'ppt',
                'html.zip': 'zip',
                'showcase': 'scw'
            };
            let shareIconImg = 'all';
            if (!item.suffix)
                shareIconImg = 'all';
            else if (item.suffix.toLowerCase() in custom_suffix_map)
                shareIconImg = custom_suffix_map[item.suffix.toLowerCase()];
            else if (['mov', 'mp4', 'm4v', 'avi', 'wmv', 'flv', 'mpg', 'mpeg', 'webm', 'f4v'].includes(item.suffix.toLowerCase()))
                shareIconImg = 'video';
            else if (!['pdf', 'doc', 'xls', 'zip', 'ppt'].includes(item.suffix.toLowerCase()))
                shareIconImg = 'all';
            return ScNativeExts.getStaticRootUrl() + '/showcase-icons/7.1.1/200x200_' + shareIconImg + '.png';
        }
    },
    computed: {
        displayGenericThumbnails() {
            return !navigator.onLine;
        }
    }

};

</script>