<template>
    <div class="row mx-1">
        <div class="result-card d-flex align-items-center shadow-sm rounded p-2 bg-white col">

            <img :src="resource.thumb_url" class=" me-2" style="width: 50px; height: 50px;"
                 alt="Thumbnail"/>

            <span class="text-truncate text-start px-md-0 px-2 py-0" style="color: #333; max-width: 70%">
                <span class="my-auto">{{resource.name}}</span>

            </span>
            <a href="#" v-sc-trackevent="'editor:resource_info_popover'"
                    @click.prevent="mainMxShowResInfo(resource.id)"><ScIcon name="infoCircle" class="ms-2"/></a>
        </div>

        <slot></slot>

    </div>
</template>


<script>

    import MainAppMixin from '../MainAppMixin';
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
        name: "EditorResourceThumb",
        mixins: [MainAppMixin],
        components: {ScIcon},
        props: {
            resourceId: {type: Number, default: null}
        },
        computed: {
            resource: function() {
                return this.$store.getters.presResource(this.resourceId);
            }
        }
    }

</script>