<template>
    <div class="container-fluid h-100 toolbar-inner">
        <div class="row">
            <div class="col-3 px-1 px-xl-3">
                <div class="row g-0">
                <div class="col-auto d-flex justify-content-center align-items-center" style="height: 79px;">
                    <router-link class="navbar-brand ms-0 me-0"
                                 :to="{ name: 'workshop-home', params: { workshopId: mainMxCurrentWorkshopId }}">
                        <img alt="Logo" :src="mainMxCdn('/showcase-icons/7.1.0/showcase-logo.svg')"
                             style="width: 50px; height: 50px" /></router-link>
                </div>
                <div class="col">
                    <div class="row g-0">
                        <div class="col">
                            <div class="py-2 px-1 fw-light">
                                <router-link class="text-white"
                                    :to="{ name: 'workshop-home', params: { workshopId: mainMxCurrentWorkshopId }}"
                                    v-sc-tooltip="'Back to home'"><ScIcon name="angleLeftFW" class="h5 mb-0 me-1 text-white"/>Back</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="row g-0 mt-2">
                        <div class="col col-xl-10" v-if="edMxCurrentPage">
                            <ul class="nav nav-pills nav-fill">
                                <li class="nav-item">
                                    <button class="nav-link p-0" type="button" v-sc-tooltip="'Show or hide device image'"
                                       v-sc-trackevent="'editor:toggledevicebg'"
                                       v-on:click.prevent="changeDeviceImg()">
                                        <ScIcon name="tabletAltFW" class="h5 mb-0 text-white"/></button>
                                </li>
                                <li class="nav-item">
                                    <button class="nav-link p-0" type="button" :disabled="zoomLevel >= (maxZoomLevel-1)"
                                       v-sc-tooltip="'Zoom In (currently ' + (edMxStateEd.scaleRatio*100)+ '%)'" v-sc-trackevent="'editor:zoomin'"
                                       @click.prevent="zoom(+1)"
                                       ><ScIcon name="searchPlusFW" class="h5 mb-0 text-white"/></button>
                                </li>
                                <li class="nav-item">
                                    <button class="nav-link p-0" type="button" :disabled="zoomLevel <= 0"
                                       @click.prevent="zoom(-1)"
                                       v-sc-trackevent="'editor:zoomout'"
                                       v-sc-tooltip="'Zoom Out (currently ' + (edMxStateEd.scaleRatio*100)+ '%)'">
                                        <ScIcon name="searchMinusFW" class="h5 mb-0 text-white"/></button>
                                </li>
                                <li class="nav-item">
                                    <button type="button" class="nav-link p-0" v-if="$store.getters.undoCanUndo" @click.prevent="undo()"
                                       v-sc-trackevent="'editor:undo'"
                                       v-sc-tooltip="'Undo (Ctrl+Z)'">
                                        <ScIcon name="undoFW" class="h5 mb-0 text-white"/>
                                    </button>
                                    <button type="button" class="nav-link p-0" v-if="!$store.getters.undoCanUndo" :disabled="true"
                                       v-sc-tooltip="'Undo (Ctrl+Z)'">
                                        <ScIcon name="undoFW" class="h5 mb-0 text-white"/>
                                    </button>
                                </li>
                                <li class="nav-item">
                                    <button type="button" class="nav-link p-0" v-if="$store.getters.undoCanRedo" @click.prevent="redo()"
                                       v-sc-trackevent="'editor:redo'"
                                       v-sc-tooltip="'Redo'">
                                        <ScIcon name="redoFW" class="h5 mb-0 text-white"/>
                                    </button>
                                    <button type="button" class="nav-link p-0" v-if="!$store.getters.undoCanRedo"
                                       v-sc-tooltip="'Redo'" :disabled="true">
                                        <ScIcon name="redoFW" class="h5 mb-0 text-white"/>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>

            </div>

            <div class="col-6">
                <div class="row g-0">
                    <div class="col d-flex justify-content-center align-items-center" v-if="edMxStateShowcase.presentation.title">
                        <strong class="navbar-brand ps-2 text-white my-1"
                                style="max-width: 500px; text-overflow: ellipsis;overflow: hidden; white-space: nowrap;"
                                @dblclick.prevent="showRenameModal()"
                                :title="edMxStateShowcase.presentation.title">{{edMxStateShowcase.presentation.title}}</strong>
                        <button type="button" @click.prevent="showRenameModal()" class="btn btn-link p-0"
                           v-sc-tooltip="'Rename'"><ScIcon name="pencil" class="text-white h5 mb-0" style="opacity: 0.6;"/></button>
                        <button type="button" @click.prevent="showManageLabels()" class="btn btn-link p-0"
                           v-sc-tooltip="'Manage labels'"><ScIcon name="tag" class="ms-2 text-white h5 mb-0" style="opacity: 0.6;"/></button>
                        <span v-if="edMxStateShowcase.presentation.template"
                              v-sc-tooltip="'Not visible to Viewers. Editors with access and Admins can use for new presentations.'"
                              class="badge rounded-pill bg-secondary ms-2 py-1 px-2">Template</span>
                    </div>
                </div>
                <div class="row g-0 justify-content-center  mt-2">
                    <div class="col col-xl-10">
                        <ul class="nav nav-pills nav-fill">
                            <li class="nav-item">
                                <button type="button" class="nav-link text-white p-0" @click.prevent="presSettingsModalActive=true"
                                                    v-sc-trackevent="'editor:settingsmodal'"
                                                    v-sc-tooltip="'Presentation Settings'">
                                <span><ScIcon name="slidersHFW" class="h5 mb-0 text-white"/></span>
                                <span class="ps-1">Settings</span>
                            </button></li>
                             <li class="nav-item">
                                 <button type="button" class="nav-link text-white p-0" @click.prevent="showFilesModal()"
                                                    v-sc-trackevent="'editor:filelib'"
                                                    v-sc-tooltip="'Workshop File Library'">
                                <span><ScIcon name="fileAltFW" class="h5 mb-0 text-white"/></span>
                                <span class="ps-1">Files</span>
                            </button></li>
                            <li class="nav-item"><button type="button" class="nav-link text-white p-0" @click.prevent="showManageAccess()"
                                                    v-sc-trackevent="'editor:manageaccess'"
                                                    v-sc-tooltip="'Manage Access'">
                                <span><ScIcon name="lockFW" class="h5 mb-0 text-white"/></span>
                                <span class="ps-1">Access</span>
                            </button></li>
                            <li class="nav-item"><button type="button" class="nav-link text-white p-0" @click.prevent="showPresSharing"
                                                    v-sc-trackevent="'editor:managesharing'"
                                                    v-sc-tooltip="'Manage Sharing'">
                                <span><ScIcon name="shareSquareFW" class="h5 mb-0 text-white"/></span>
                                <span class="ps-1">Sharing</span>
                            </button></li>
                            <li class="nav-item dropdown navbar-light">
                                <button type="button" class="nav-link text-white p-0 dropdown-toggle" data-bs-toggle="dropdown"
                                   role="button" aria-expanded="false">More</button>
                                <div class="dropdown-menu">
                                    <router-link class="dropdown-item" :to="{ name: 'outline', params: {
                                        workshopId: mainMxCurrentWorkshopId,
                                        showcaseId: edMxStateShowcase.presentation.id
                                        }, query: { back: 'editor' }}">Outline</router-link>

                                    <router-link class="dropdown-item" :to="{ name: 'reporting-activity', params: {
                                        workshopId: mainMxCurrentWorkshopId },
                                        query: {scid: edMxStateShowcase.presentation.id }}">Activity log</router-link>


                                    <router-link v-if="edMxStateShowcase.presentation.publish_status === 'published'"
                                        class="dropdown-item" :to="{ name: 'reporting-analytics', params: {
                                        workshopId: mainMxCurrentWorkshopId }, query: {
                                        scid: edMxStateShowcase.presentation.id }}">Analytics</router-link>

                                    <router-link v-if="edMxStateShowcase.presentation.publish_status === 'published'"
                                        class="dropdown-item" :to="{ name: 'email-viewers', params: {
                                            workshopId: mainMxCurrentWorkshopId,
                                            showcaseId: edMxStateShowcase.presentation.id
                                            }}">Email users</router-link>
                                    <div v-if="edMxStateShowcase.presentation.publish_status === 'published'"
                                         class="dropdown-divider"></div>
                                    <button v-if="edMxStateShowcase.presentation.publish_status === 'published'"
                                        class="dropdown-item" type="button" @click.prevent="showPresHistory()">History</button>
                                    <button v-if="edMxStateShowcase.presentation.publish_status === 'published'"
                                        class="dropdown-item" type="button" @click.prevent="showPresCopy()">Copy</button>
                                    <button v-if="edMxStateShowcase.presentation.publish_status === 'published'"
                                        class="dropdown-item" type="button" @click.prevent="showPresChangeStatus('unpublished')">Unpublish</button>
                                    <button
                                        class="dropdown-item" type="button" @click.prevent="showPresChangeStatus('archived')">Archive</button>
                                    <div class="dropdown-divider"></div>
                                    <button class="dropdown-item" type="button" @click.prevent="showPresFileUsages()">Replace Files</button>
                                    <button class="dropdown-item" type="button" @click.prevent="showPresDelete()">Delete presentation</button>
                                    <button class="dropdown-item" type="button" @click.prevent="showPresExport()">Export</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
            <div class="col-3 ps-0 d-flex align-items-center justify-content-center">

                <button class="btn btn-link text-white btn-preview ps-2"
                        type="button" @click.prevent="showPreview()"
                        :disabled="!$store.getters.presCanPublish"
                        v-sc-tooltip="!$store.getters.presCanPublish ? 'Saving...' : 'View before publishing'">
                    <ScIcon name="playCircleFW" class="h5 mb-0 text-white"/>
                    <strong class="ps-1">Preview</strong>
                </button>

                <div v-if="edMxStateShowcase.presentation.publish_status === 'published'">
                    <div v-if="edMxStateShowcase.presentationmetadata.cid === 0">
                        <button type="button"
                                class="btn btn-info btn-publish"
                                :disabled="!$store.getters.presCanPublish"
                                v-sc-trackevent="'editor:publish_popover'"
                                v-sc-tooltip="!$store.getters.presCanPublish ? 'Saving...' : 'Publish changes to users'"
                                @click.prevent="showPresPublish()">

                            <span class="d-none d-xl-inline me-3">
                                <ScIcon name="cloudFasEditor" class="align-middle me-1 text-orange"/>
                                <ScIcon name="longArrowRight" class="mx-2 align-middle"/>
                                <ScIcon name="cloudCheckLayeredEditor" class="align-middle text-success"/>
                            </span>Publish
                        </button>
                    </div>
                    <div v-else class="rounded py-2 px-3 text-white" style="background-color: rgba(255, 255, 255, 0.2); opacity: 0.8;">
                        <span style="white-space: nowrap;">
                            <ScIcon name="cloudCheckLayeredEditor" class="align-middle me-2 text-success"/>
                            <span class="ms-2">Published</span>
                        </span>
                    </div>
                </div>
                <div v-else>
                    <button type="button" class="btn btn-info btn-publish"
                            :disabled="!$store.getters.presCanPublish"
                            v-sc-trackevent="'editor:publish_popover'"
                            v-sc-tooltip="!$store.getters.presCanPublish ? 'Saving...' : 'Publish this presentation to users.'"
                            @click.prevent="showPresPublish()">
                        <ScIcon name="cloudTimesLayeredEditor" class="align-middle me-2 text-danger"/>
                        <span class="ms-2">Publish</span>
                    </button>
                </div>

            </div>
        </div>

        <PresentationSettingsModal v-if="presSettingsModalActive"
                                   @modal-was-hidden="presSettingsModalActive=false"></PresentationSettingsModal>

        <AssignLabelsModal></AssignLabelsModal>

        <ManageAccessModal></ManageAccessModal>
        <PresentationCopy></PresentationCopy>
        <PresentationExport></PresentationExport>
        <PresentationShareable></PresentationShareable>
        <PresentationDelete></PresentationDelete>
        <PresentationHistory></PresentationHistory>
        <PresentationPublish></PresentationPublish>
        <PresentationChangeStatus></PresentationChangeStatus>
        <PresentationFileUsages></PresentationFileUsages>
        <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>
    </div>
</template>

<script>

    import MainAppMixin from "../MainAppMixin";
    import EditorMixin from "./EditorMixin";
    import constants from "../../constants";

    import PresentationHistory from "../presentation/PresentationHistory.vue";
    import PresentationPublish from "../presentation/PresentationPublish.vue";
    import PresentationChangeStatus from "../presentation/PresentationChangeStatus.vue";
    import PresentationDelete from "../presentation/PresentationDelete.vue";
    import PresentationExport from "../presentation/PresentationExport.vue";
    import PresentationCopy from "../presentation/PresentationCopy.vue";
    import PresentationShareable from "../presentation/PresentationShareable.vue";
    import PresentationFileUsages from "../presentation/PresentationFileUsages.vue";
    import ManageAccessModal from "../presentation/manage-access/ManageAccessModal.vue";
    import AssignLabelsModal from "../presentation/labels/AssignLabelsModal.vue";
    import PresentationSettingsModal from "./pres-settings/PresentationSettingsModal.vue";
    import LibraryMainModal from "../library/LibraryMainModal.vue";
    import ScRenameModal2 from "../../shared/common/ScRenameModal2.vue";
    import ScNotification from "../../shared/common/ScNotification.vue";
    import ScIcon from "../../shared/common/ScIcon.vue";

    import store from "../../store";
    import PresentationListStore from "../../store/PresentationListStore";

    if (!store.state.plist) store.registerModule('plist', PresentationListStore);

    export default {
        name: "EditorNavbar",
        mixins: [MainAppMixin, EditorMixin],
        components: { ScIcon, PresentationSettingsModal, AssignLabelsModal, ManageAccessModal, PresentationCopy,
            PresentationExport, PresentationShareable, PresentationDelete, PresentationHistory, PresentationPublish,
            PresentationChangeStatus, PresentationFileUsages, LibraryMainModal },
        data () {
            return {
                presSettingsModalActive: false
            }
        },
        mounted() {
            this.$store.dispatch('plistLabelsLoad', {workspaceId: this.mainMxCurrentWorkshopId})
        },
        methods: {
            showRenameModal() {
                ScRenameModal2.modal({name: this.edMxStateShowcase.presentation.title, maxLength: 128}, (newName) => {
                    return this.$store.dispatch('presSetTitle', newName);
                });
            },
            showManageLabels() {
                AssignLabelsModal.modal(this.edMxStateShowcase.presentation.id);
            },
            zoom(amt) {
                if (this.zoomLevel <= 0 && amt < 0) return;
                if (this.zoomLevel+1 >= this.maxZoomLevel && amt > 0) return;
                this.$store.commit('edSetScaleRatio', constants.CANVAS_ZOOM_LEVELS[this.zoomLevel + amt]);
            },
            undo() {
                this.$store.commit('edSelectedHotspotIdsReset');
                this.$store.dispatch('undoUndoAction');
            },
            redo() {
                this.$store.commit('edSelectedHotspotIdsReset');
                this.$store.dispatch('undoRedoAction');
            },
            changeDeviceImg() {
                this.$store.commit('edSetDeviceImage', null);
            },
            showPresHistory() {
                PresentationHistory.modal(this.mainMxCurrentWorkshopId, this.edMxStateShowcase.presentation.id);
            },
            showPresPublish() {
                if (!this.$store.getters.presCanPublish) return;
                let confirmFirst = this.edMxStateShowcase.presentation.publish_status !== "published";
                let permissions = this.edMxStateShowcase.presentation.permissions ? this.edMxStateShowcase.presentation.permissions : '';
                PresentationPublish.modal(this.mainMxCurrentWorkshopId,
                    this.edMxStateShowcase.presentation.id, permissions, confirmFirst, true,
                    this.edMxStateShowcase.presentation.template);
            },
            showPresChangeStatus(newStatus) {
                PresentationChangeStatus.modal(this.edMxStateShowcase.presentation.id,
                    this.mainMxCurrentWorkshopId, true, this.edMxStateShowcase.presentation.publish_status, newStatus);
            },
            showPresCopy() {
                PresentationCopy.modal(this.edMxStateShowcase.presentation.id, this.mainMxCurrentWorkshopId);
            },
            showPresDelete() {
                PresentationDelete.modal(this.edMxStateShowcase.presentation.id, this.mainMxCurrentWorkshopId, true);
            },
            showPresExport() {
                PresentationExport.modal(this.edMxStateShowcase.presentation.id);
            },
            showPresFileUsages() {
                PresentationFileUsages.modal(this.edMxStateShowcase.presentation.id, this.edMxNavToPage,
                    this.presFileUsageReplacementPerformed);
            },
            presFileUsageReplacementPerformed() {
                ScNotification.growlSuccessMsg('Replacements occured, reloading editor...');  // reload editor so it can see changes
                this.$store.dispatch('presReload');
            },
            showPresSharing() {
                let page = this.$store.getters.presPage(this.edMxStateEd.currentPageId);
                PresentationShareable.modal(this.edMxStateShowcase.presentation.id,
                    page.id, page.alpha_num_name, null);
            },
            showFilesModal() {
                this.$refs.libraryMainModal.toggleBsModal({
                    selectionMode: 'single', dialogType: 'modal',
                    filterType: 'all'
                });
            },
            showManageAccess() {
                ManageAccessModal.modal({presentation_id: this.edMxStateShowcase.presentation.id,
                    presentation_read_only: false});
            },
            showPreview() {
                let currentWs = this.$store.getters.userCurrentWorkspaceObj;
                if (currentWs) {
                    if (!this.$store.getters.presCanPublish) return;
                    this.$router.push({
                        name: 'editor-preview',
                        params: {workshopId: this.mainMxCurrentWorkshopId,
                            showcaseId: this.edMxStateShowcase.presentation.id},
                        query: {pid: this.edMxStateEd.currentPageId}
                    });
                }
            }
        },

        computed: {
            zoomLevel() {
                return constants.CANVAS_ZOOM_LEVELS.indexOf(this.edMxStateEd.scaleRatio);
            },
            maxZoomLevel() {
                return constants.CANVAS_ZOOM_LEVELS.length;
            },
        },

        watch: {

        }

    }

</script>

<style>

    .toolbar-inner {
        background: #008ab0 !important;
    }

    .toolbar-inner .nav-pills .nav-link.active, .toolbar-inner .nav-pills .show>.nav-link {
        background-color: inherit;
    }

    .toolbar-inner button {
        opacity: 1;
    }
    .toolbar-inner button:hover {
        opacity: 0.7;
    }

    .toolbar-inner button:disabled, .toolbar-inner button:disabled:hover {
        opacity: 0.3;
    }

    .btn.btn-publish, .btn-preview {
        white-space: nowrap
    }

    .btn.btn-publish[disabled] {
        color: white;
        background-color: #17a2b8;
    }

    .toolbar-inner .dropdown-menu {
        z-index: 6000; /* see README.md webapp z-index values */
    }
</style>