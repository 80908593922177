<template>
    <div class="container-fluid" v-if="isShown">

        <div class="row">
            <div class="col-1 px-0 pt-2">
                <ScToggleOnOff :active="isEnabled"
                               :loading="showLoading"
                               @sc-toggle="toggleEnableArrows()"></ScToggleOnOff>
            </div>
            <div class="col-11">
                <h4>Show navigational arrows for multiple image slides</h4>
                <small class="d-block">
                    When enabled, any sequence of multiple image slides added to this presentation will display left
                    and right 'navigational arrows' on the apps or Present tab.
                    <br/>
                    If disabled, users can still click and drag to advance the slide.
                  <br/><br/>Requires a republish to take effect.

                </small>
            </div>
        </div>

        <hr class="my-4"/>

    </div>
</template>


<script>

import ScNotification from '../../../shared/common/ScNotification.vue';
import ScToggleOnOff from '../../../shared/common/ScToggleOnOff.vue';
import $ from 'jquery';


let DataManager = {
    loadData (vm) {
        vm.showLoading = true;
        vm.isEnabled = true;
        $.ajax({
            type: 'GET', url: "/main/presentations/ajax_load_arrows_enabled",
            data: { workspace_id: vm.workspaceId, presentation_id: vm.presentationId }

        }).done(function(data){
            if (data.viewer_arrows_enabled !== null) {
                vm.isShown = true;
                vm.isEnabled = !!data.viewer_arrows_enabled;
            }   else {
                vm.isShown = false;
            }

        }).fail(function(jqXhr) {
            ScNotification.growlXhrError(jqXhr, "loading data");

        }).always(function() {
            vm.showLoading = false;
        });

    },
    updateData (vm) {
        vm.showLoading = true;
        $.ajax({
            type: 'POST', url: "/main/presentations/ajax_save_arrows_enabled",
            data: {
                workspace_id: vm.workspaceId,
                presentation_id: vm.presentationId,
                viewer_arrows_enabled: !vm.isEnabled
            }
        }).done(function(data){
            if(data.status === 'error') {
                ScNotification.growlErrMsg('Change NOT saved. Please reload this page and try again. ' +
                    'If problem continues please contact support.');
            } else {
                ScNotification.growlSuccessMsg('Saved.');
                vm.isEnabled = !vm.isEnabled;
                vm.markPresHasChanges();
            }

        }).fail(function() {
            ScNotification.growlErrMsg('Change NOT saved. Please reload this page and try again. ' +
                'If problem continues please contact support.');

        }).always(function() {
            vm.showLoading = false;
        });

    }
};


export default {
    name: 'ManageViewerArrows',
    components: { ScToggleOnOff },
    props: {
        workspaceId: {type: Number, default: null},
    },
    data () {
        return {
            isShown: false,
            isEnabled: true,
            showLoading: false
        };
    },
    mounted () {
        DataManager.loadData(this);
    },

    methods: {
        toggleEnableArrows() {
            DataManager.updateData(this);
        },
        markPresHasChanges() {
            if (this.$store.state.pres && this.$store.state.pres.showcase.presentation.id === this.presentationId) {
                this.$store.dispatch('presDraftCompileStarted');
                this.$store.commit('presViewerArrowsEnabled', this.isEnabled);
            }
            if (this.$store.state.plist) {
                this.$store.commit('plistPresHasChanges', this.presentationId);
            }
        },
    },
    computed: {
        presentationId () {
            return (this.$store.state.pres) ? this.$store.state.pres.showcase.presentation.id: null;
        }
    }
};


</script>