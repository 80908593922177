<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal" @vb-hidden-bs-modal="isModalShown=false"
            class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="btn-close" data-bs-dismiss="modal"></button>
                    <h4 class="modal-title w-100 text-center">URL</h4>
                </div>
                <div class="modal-body clearfix">
                    <form action="#" @submit.prevent>
                        <input type="text" :class="['form-control', webUrlValidationError ? 'is-invalid' : '']"
                               maxlength="8096" placeholder="https://www.example.com"
                               @keyup="webUrlValidationError=null"
                               @keyup.enter="saveWeburl()"
                               @blur="validateWeburl()" ref="webUrlInput"
                               v-model.trim="weburlValue" />

                        <div v-if="webUrlValidationError" class="invalid-feedback">{{webUrlValidationError}}</div>

                        <div class="my-3 mx-2">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="hs-target-url-external"
                                       v-model="weburlOpenExternally" :disabled="isProtocolShowcaseForm">
                                <label class="form-check-label ms-1" for="hs-target-url-external">Opened externally in the apps</label>
                            </div>

                            <div class="form-text">
                                <ScIcon name="questionCircle"/>
                                When this presentation is viewed via the Showcase app(s), this URL will open outside
                                Showcase, in the most appropriate app installed on the device. This is useful for
                                opening other apps directly, like Google Maps, Facebook, or Spotify.</div>

                        </div>

                        <div class="my-3 mx-2">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="hs-target-url-browser-inline"
                                       v-model="weburlBrowserOpensExternally" :disabled="isProtocolShowcaseForm || weburlOpenExternally">
                                <label class="form-check-label ms-1" for="hs-target-url-browser-inline">Opened externally from the browser</label>
                            </div>

                            <div class="form-text">
                                <ScIcon name="questionCircle"/>
                                When this presentation is viewed via a web browser, open this URL in a new tab. Unticking
                                will force URLs to open within the presentation, in an <code>iframe</code> (most websites
                                don't work in an <code>iframe</code>).
                            </div>
                        </div>
                    </form>

                </div>
                <div class="confirm-buttons modal-footer">
                    <button v-on:click.prevent="saveWeburl()" :disabled="!!webUrlValidationError || !weburlValue || weburlValue.length === 0"
                            class="btn btn-unpublish btn-primary-orange fw-bold">Save</button>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import _ from 'underscore'; // isArray
    import ScIcon from '../../shared/common/ScIcon.vue';

    let _ins = null;

    export default {
        name: 'EditorWeburlModal',
        components: {ScIcon},
        data: function() {
            return {
                isModalShown: false,
                weburlValue: null,
                weburlOpenExternally: false,
                weburlBrowserOpensExternally: false,
                webUrlValidationError: null,
                successCallback: null
            }
        },
        mounted() {
            _ins = this;
        },
        beforeUnmount() {
            _ins = null;
        },
        methods: {
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            saveWeburl: function() {
                if (!this.webUrlValidationError && this.weburlValue) {
                    if (this.isProtocolShowcaseForm) this.weburlOpenExternally = false;
                    // if no protocol we force https://
                    if (this.weburlValue && !/^[a-zA-Z]+:/.test(this.weburlValue)) {
                        this.weburlValue = 'https://' + this.weburlValue;
                    }
                    this.successCallback({
                        weburl: this.weburlValue, openExternally: this.weburlOpenExternally,
                        browserOpensInline: !this.weburlOpenExternally && !this.weburlBrowserOpensExternally
                    });
                    this.hideModal();
                }
            },
            validateWeburl: function() {
                let error = null;
                if (this.isProtocolShowcaseForm) {
                    let jsonData = this.weburlValue.substring(15);
                    let scForm = {};
                    try {
                        scForm = JSON.parse(jsonData);
                    } catch(e) {
                        error = 'Invalid form JSON provided.';
                    }
                    if (!error) {
                        if (!scForm['name'] || scForm['name'].match(/^[a-z0-9]+$/i) === null) {
                            error = 'Form name is missing (or not a-zA-Z0-9).';
                        } else if (!scForm['fields'] || !_.isArray(scForm['fields'])) {
                            error = 'Form fields are missing.';
                        } else {
                            scForm['fields'].forEach((field) => {
                                if (!field['name'] || field['name'].match(/^[a-z0-9]+$/i) === null) {
                                    error = 'A field name is missing (or not a-zA-Z0-9).'
                                }
                            });
                        }
                    }
                }
                this.webUrlValidationError = error;
            },
            toggleBsModal(targetWeburlObj, successCallback) {
                this.weburlValue = targetWeburlObj ? targetWeburlObj.weburl : null;
                this.weburlOpenExternally = targetWeburlObj ? targetWeburlObj.openExternally : false;
                this.weburlBrowserOpensExternally = !(targetWeburlObj ? targetWeburlObj.browserOpensInline : false);
                this.successCallback = successCallback;
                this.isModalShown = true;
            }
        },
        computed: {
            isProtocolShowcaseForm: function() {
                return this.weburlValue && this.weburlValue.indexOf('showcaseform://') === 0;
            },
        },

        //
        // public functions
        //

        modal: function(targetWeburlObj, successCallback) {
            _ins.toggleBsModal(targetWeburlObj, successCallback);
        }

    }

</script>