<template>
  <div class="row g-0" style="height: 60px;">

    <div v-if="edMxCurrentPage && edMxSelectedHotspots.length === 0"
         class="col-12 d-flex justify-content-center align-items-center">

      <button class="btn" @click.prevent="showFindPage()"
              v-sc-trackevent="'editor:findslides'"
              v-sc-tooltip="'Slides in this presentation'">
        <ScIcon name="thLargeFW" /><span class="ms-1">Slides</span>
      </button>

      <EditorToolbarSlideBg></EditorToolbarSlideBg>

      <EditorToolbarAddHotspot/>
    </div>

    <div v-if="edMxCurrentPage && edMxSelectedHotspots.length > 0"
         class="col-12 d-flex justify-content-center align-items-center my-auto">

      <!-- if hs selected show these: -->
      <EditorToolbarHotspotMain/>

      <EditorToolbarHotspotTarget :hideTargetName="hideTargetName"/>

      <EditorToolbarTextFont :shortenFontName="shortenFontName"/>

      <EditorToolbarTextColor/>

      <EditorToolbarTextAlign/>

      <EditorToolbarTextSpacing/>

      <EditorToolbarHotspotImage/>

      <EditorToolbarHotspotAlign/>

      <EditorToolbarHotspotBorder/>

      <EditorToolbarTextEffect/>

    </div>
  </div>
</template>



<script>

    import EditorMixin from './../EditorMixin';
    import EditorToolbarTextFont from './EditorToolbarTextFont.vue';
    import EditorToolbarTextAlign from './EditorToolbarTextAlign.vue';
    import EditorToolbarTextColor from './EditorToolbarTextColor.vue';
    import EditorToolbarAddHotspot from './EditorToolbarAddHotspot.vue';
    import EditorToolbarHotspotMain from './EditorToolbarHotspotMain.vue';
    import EditorToolbarHotspotTarget from './EditorToolbarHotspotTarget.vue';
    import EditorToolbarHotspotImage from './EditorToolbarHotspotImage.vue';
    import EditorToolbarSlideBg from './EditorToolbarSlideBg.vue';
    import EditorToolbarTextSpacing from './EditorToolbarTextSpacing.vue';
    import EditorToolbarTextEffect from './EditorToolbarTextEffect.vue';
    import EditorToolbarHotspotBorder from './EditorToolbarHotspotBorder.vue';
    import EditorToolbarHotspotAlign from "./EditorToolbarHotspotAlign.vue";
    import EditorFindPageModal from '../EditorFindPageModal.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';

    export default {
      name: "EditorToolbar",
      mixins: [EditorMixin],
      components: {
        EditorToolbarHotspotAlign, EditorToolbarHotspotImage, EditorToolbarSlideBg, EditorToolbarHotspotMain, EditorToolbarTextAlign,
        EditorToolbarHotspotTarget, EditorToolbarAddHotspot, EditorToolbarTextFont, EditorToolbarTextColor,
        EditorToolbarTextSpacing, EditorToolbarTextEffect, EditorToolbarHotspotBorder, ScIcon
      },
      data () {
        return {
          hideTargetName: false,
          shortenFontName: false,
        }
      },
      mounted () {
        window.addEventListener('resize', this.shortenButtonText, false);
        this.shortenButtonText()
      },
      beforeUnmount () {
        window.removeEventListener('resize', this.shortenButtonText, false);
      },
      methods: {
        shortenButtonText() {
          this.hideTargetName = document.documentElement.clientWidth < 1203;
          this.shortenFontName = document.documentElement.clientWidth < 1150;
        },
        showFindPage() {
          EditorFindPageModal.modal({
            pageChooseMode: false,
            success: (pageId) => {
              if (pageId) this.edMxNavToPage(pageId);
            }
          });
        },
      }
    }


</script>


<style>

  .editor-toolbar .dropdown-menu {
    z-index: 6000; /* see README.md webapp z-index values */
  }

</style>