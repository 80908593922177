<template>
    <div class="container-fluid">

        <div class="row">

            <div class="col-1 px-0 pt-2">
                <ScToggleOnOff :active="isEnabled"
                               :loading="showLoading"
                               @sc-toggle="toggleEnabled()"></ScToggleOnOff>
            </div>
            <div class="col-11">
                <h4>Screensaver Video</h4>
                <small class="d-block">This video will play if the screen is left
                        unattended for the specified period of time.

                  <br/><br/>Requires a republish to take effect.
                </small>

                <template v-if="isEnabled">
                    <div v-if="!screensaverVideoResource" class="my-3">
                        <button type="button" title="Set background" class="btn btn-outline-secondary"
                                @click.prevent="chooseScreensaverVideo()">
                            <ScIcon name="filmFW"/> Choose video</button>
                    </div>
                    <div v-if="screensaverVideoResource" class="my-3">
                        <EditorResourceThumb v-if="screensaverVideoResource" :resourceId="screensaverVideoResource.id">
                            <div class="col my-auto ms-auto text-end">
                                <button type="button" class="btn btn-outline-secondary"
                                        @click.prevent="chooseScreensaverVideo()"><ScIcon name="randomFW" class="me-1"/>Replace</button>
                                <button type="button" class="btn btn-outline-secondary ms-2" v-sc-tooltip="'Remove'"
                                        @click.prevent="assignScreensaverVideoResource(null)"><ScIcon name="minusCircleFW"/></button>
                            </div>
                        </EditorResourceThumb>
                    </div>

                    <div class="my-3 d-flex">
                        <label class="small my-auto me-3">Start after</label>
                        <div class="customSelectArrow">
                            <select class="form-control bg-white" v-model="timeoutValue" @change="changeScreensaverTimeout"
                                    :disabled="screensaverVideoResource === null">
                                <option value="1">1 minute</option>
                                <option value="2">2 minutes</option>
                                <option value="5">5 minutes</option>
                                <option value="10">10 minutes</option>
                                <option value="15">15 minutes</option>
                            </select>
                        </div>
                    </div>
                </template>
            </div>

        </div>

        <hr class="my-4"/>

        <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>

    </div>
</template>



<script>

import ScIcon from '../../../shared/common/ScIcon.vue';
import LibraryMainModal from '../../library/LibraryMainModal.vue';
import EditorMixin from './../EditorMixin';
import EditorResourceThumb from './../EditorResourceThumb.vue';
import ScToggleOnOff from '../../../shared/common/ScToggleOnOff.vue';


export default {
    name: "EditorToolbarScreensaver",
    components: {EditorResourceThumb, ScIcon, ScToggleOnOff, LibraryMainModal},
    mixins: [EditorMixin],
    data() {
        return {
            timeoutValue: 1,
            isShown: false,
            isEnabled: true,
            showLoading: false
        }
    },
    mounted () {
        //console.log('mounted', this.edMxStateShowcase.presentationmetadata.screensaver_video_resource_id)
        if (this.edMxStateShowcase.presentationmetadata.screensaver_timeout)
            this.timeoutValue = this.edMxStateShowcase.presentationmetadata.screensaver_timeout;
        if (!this.edMxStateShowcase.presentationmetadata.screensaver_video_resource_id)
                this.isEnabled = false;

    },
    methods: {
        chooseScreensaverVideo() {
            this.$refs.libraryMainModal.toggleBsModal({
                selectionMode: 'single', dialogType: 'modal',
                filterType: 'movie'
            }, this.assignScreensaverVideoResource);
        },

        changeScreensaverTimeout() {
            this.$store.dispatch('presSetScreensaverTimeout', this.timeoutValue);
        },

        assignScreensaverVideoResource(selectedResources) {
            //console.log('assignScreensaverVideoResource', selectedResources);
            let selectedResource = null;
            if (selectedResources && selectedResources.length > 0) {
                selectedResource = selectedResources[0];
                this.$store.dispatch('presResourceAddedFromLibrary', selectedResource);
            }
            this.$store.dispatch('presSetScreensaverVideo', selectedResource ? selectedResource.id : null);
        },

        toggleEnabled() {
            this.isEnabled = !this.isEnabled;
            this.assignScreensaverVideoResource(null);
        },
    },

    computed: {
        screensaverVideoResource () {
            let res = null;
            if (this.edMxStateShowcase.presentationmetadata.screensaver_video_resource_id) {
                res = this.$store.getters.presResource(
                        this.edMxStateShowcase.presentationmetadata.screensaver_video_resource_id);
            }
            return res;
        },

    }
}

</script>