
let EditorHotspotStyleHelper = {

    addHotspotStyles(cssObj, hsbObj, scaleRatio) {
        if (!hsbObj) return;
        let scalePx = (d) => (d * scaleRatio) + 'px';
        this._handleFontColor(cssObj, hsbObj);
        this._handleHighlightColor(cssObj, hsbObj);
        this._handleTextAlignment(cssObj, hsbObj);
        this._handleFontSize(cssObj, hsbObj, scalePx);

    },

    addHotspotBgColor(cssObj, hsbObj) {
        if (!hsbObj.fill_colour) return;
        cssObj.backgroundColor = hsbObj.fill_colour;
    },

    addHotspotVerticalAlignment(cssObj, hsbObj) {
        this._handleVerticalAlignment(cssObj, hsbObj)
    },


    _handleFontSize(cssObj, hsbObj, scalePx){
        let fontSize = (!hsbObj.font_size) ? 24 : this.fontsNormalizeFontSize(hsbObj.font_size);
        cssObj.fontSize = scalePx(fontSize);
    },
    _handleFontColor(cssObj, hsbObj){
        if (!hsbObj.font_colour) return;
        cssObj.color = hsbObj.font_colour;
    },
    _handleHighlightColor(cssObj, hsbObj){
        if (!hsbObj.background_colour) return;
        cssObj.backgroundColor = hsbObj.background_colour;
    },
    _handleTextAlignment(cssObj, hsbObj){
        if (!hsbObj.text_alignment) return;
        cssObj.textAlign = hsbObj.text_alignment;
    },
    _handleVerticalAlignment(cssObj, hsbObj){
        if (!hsbObj.vertical_alignment) return;
        cssObj.verticalAlign = hsbObj.vertical_alignment
        if (hsbObj.vertical_alignment === 'top') {
            cssObj.alignSelf = 'flex-start';
        } else if (hsbObj.vertical_alignment === 'middle') {
            cssObj.alignSelf = 'center';
        } else if (hsbObj.vertical_alignment === 'bottom') {
            cssObj.alignSelf = 'flex-end';
        }
    },


    // same as in FontStore.js
    fontsNormalizeFontSize (fontSize)  {
        //console.log('normalizeFontSize', fontSize);
        if (!fontSize) {
            return 24;
        }
        let legacyFontSizes = {'small': 18, 'medium': 24, 'large': 36};
        if (legacyFontSizes[fontSize]) {
            return legacyFontSizes[fontSize];
        }
        if (/^\+?(0|[1-9]\d*)$/.test(fontSize)) {
            let fontSizeInt = Number(fontSize);
            if (fontSizeInt < 1) fontSizeInt = 1;
            if (fontSizeInt > 999) fontSizeInt = 999;
            return fontSizeInt;
        }
        return 24;
    },


}

export default EditorHotspotStyleHelper;