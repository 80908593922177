<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
            @vb-hide-bs-modal="canModalHide" @vb-hidden-bs-modal="modalWasHidden"
            class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">

    <div v-if="!delUnusedPagelistsLoading" class="modal-header">
        <div class="row g-0 justify-content-between w-100">
            <div class="col">
                <button type="button" class="btn-close" @click.prevent="hideModal" aria-hidden="true"></button>
            </div>
            <div class="col">
                <h4 class="modal-title w-100 text-center">
                    <span v-if="searchForText">Results for "{{ searchForText }}"</span>
                    <span v-else-if="pageChooseMode">Choose Slide</span>
                    <span v-else>Slides</span>
                </h4>
            </div>
            <div class="col-auto text-end">
                <div v-if="displayResults" class="ms-2 d-flex">
                    <button class="btn btn-outline-secondary dropdown-toggle sc-highlight-expanded me-2"
                            data-bs-toggle="dropdown" role="button" aria-expanded="false">
                        <ScIcon v-if="pageFilter === 'all'" name="asterisk" />
                        <ScIcon v-else-if="pageFilter === 'current'" name="clone" />
                        <ScIcon v-else-if="pageFilter === 'unused'" name="lowVision" />
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a href="#" @click.prevent="pageFilter='all'"
                           :class="['dropdown-item text-start', pageFilter === 'all' ? 'active' : '']">
                            <ScIcon name="asteriskFW" class="me-2"/> All Slides
                        </a>
                        <a href="#" @click.prevent="pageFilter='current'"
                           :class="['dropdown-item text-start', pageFilter === 'current' ? 'active' : '']">
                            <ScIcon name="cloneFW" class="me-2"/> Current Slideshow
                        </a>
                        <a href="#" @click.prevent="pageFilter='unused'"
                           :class="['dropdown-item text-start', pageFilter === 'unused' ? 'active' : '']">
                            <ScIcon name="lowVisionFW" class="me-2"/> Unused Slides
                        </a>
                    </div>
                    <input type="search" placeholder="Search" class="form-control search-query"
                           maxlength="128" v-model.trim="searchForText" @keyup.enter.prevent />
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body sc-modal-touch-scroll text-center" style="min-height: 50vh;">
        <div v-if="delUnusedPagelistsLoading" style="padding-top: 15vh;">
            <ScIcon name="spinnerFW" class="text-muted"/>
            <br/> Deleting unused slides...
        </div>
        <div v-else-if="!displayResults" style="padding-top: 15vh;">
            <ScIcon name="spinnerFW" class="text-muted"/>
        </div>
        <EditorPageListing v-else ref="editorPageListingRef"
                           :searchText="searchForText"
                           :selectedPageId="selectedPageId"
                           :pageFilter="pageFilter"
                           :displayPagesReadOnly="pageChooseMode"
                           @page-selected="selectClick"
                           @search-results-length="searchResultsLength">
        </EditorPageListing>
    </div>
    <div class="modal-footer">
        <div class="row w-100" v-if="!delUnusedPagelistsLoading && displayResults">
            <div class="col-6 d-flex align-items-center">
                <span class="text-muted me-3">
                    {{ totalPagesLength }}
                    <span v-if="totalPagesLength === 1">slide</span>
                    <span v-else>slides</span>
                </span>
                <button v-if="linkToHotspotId" class="btn btn-link copy-pagelists"
                   @click.prevent="copyPagelists()"
                   v-sc-trackevent="'editor:copy_pagelists'"
                   v-sc-tooltip="'Copy slide from another presentation'">Copy slide(s)</button>
                <button v-if="showPurgeUnusedPagesLink && !delUnusedPagelistsLoading"
                   @click.prevent="delUnusedPagelists()" :disabled="edMnUnusedPageIds.length === 0"
                   class="btn btn-link">Delete all unused slides</button>
            </div>
            <div class="col-6 text-end">
                <button @click.prevent="chooseClick" class="btn btn-primary-orange fw-bold"
                        :disabled="!selectedPageId">
                    <span v-if="pageChooseMode">Choose selected</span>
                    <span v-else>Go to Slide</span>
                </button>
            </div>
        </div>
    </div>

            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

import _ from 'underscore'; // clone
import EditorCopyPagelists from './EditorCopyPagelists.vue';
import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';
import EditorMixin from './EditorMixin';
import ScIcon from '../../shared/common/ScIcon.vue';
import EditorPageListing from "./EditorPageListing.vue";

let _ins = null;

export default {
    name: "EditorFindPageModal",
    mixins: [EditorMixin],
    inject: ['edMnUnusedPageIds', 'edMnUnusedPagelistIds'],
    components: {EditorPageListing, ScIcon},
    data() {
        return {
            isModalShown: false,
            showCopyPagesOnHidden: false,
            selectedPageId: null,
            searchForText: null,
            searchInputText: null,
            delUnusedPagelistsLoading: false,
            linkToHotspotId: null,
            pageFilter: 'all',
            displayResults: false,
            pageChooseMode: true,
            pagesLength: null // emitted from search in EditorPageListing, see searchResultsLength
        };
    },
    mounted() {
        _ins = this;
    },
    beforeUnmount() {
        _ins = null;
    },
    methods: {
        modalWasHidden() {
            this.isModalShown = false;
            if (this.showCopyPagesOnHidden) {
                EditorCopyPagelists.modal({
                    workspaceId: this.workspaceId,
                    currentPresentationId: this.currentPresentationId,
                    currentLayoutCode: this.edMxStateShowcase.primary_layout.code,
                    linkToHotspotId: this.linkToHotspotId
                });
                this.showCopyPagesOnHidden = false;
            }
        },
        canModalHide(e) {
            if (this.delUnusedPagelistsLoading) e.preventDefault();
        },
        hideModal() {
            this.$refs.scModal.$vb.modal.hide();
        },
        delUnusedPagelists() {
            let numToDelete = this.edMnUnusedPageIds.length;
            ScConfirmModal2.modal(
                    'This will PERMANENTLY delete ' + numToDelete +
                    ' slides marked as unused.  Are you sure?').then(() => {
                this.delUnusedPagelistsLoading = true;
                if (this.edMnUnusedPageIds.indexOf(this.currentPageId) > -1) {  // if user on a page that will be removed
                    this.edMxNavToPage(this.$store.getters.presRootPage.id);
                }
                // find all the pagelists to delete
                let unusedPageListIds = _.clone(this.edMnUnusedPagelistIds);
                let deleteNextPagelist = () => {
                    let unusedPagelistId = unusedPageListIds.pop();
                    if (unusedPagelistId) {
                        this.$store.dispatch('presDeletePagelist', unusedPagelistId).then(() => {
                            deleteNextPagelist();
                        });
                    } else {
                        this.delUnusedPagelistsLoading = false;
                    }
                };
                deleteNextPagelist();
            }, () => {});
        },

        copyPagelists() {
            this.showCopyPagesOnHidden = true;
            this.hideModal();
        },

        chooseClick() {
            if (this.onSuccessCallback) this.onSuccessCallback(this.selectedPageId);
            this.hideModal();
        },

        selectClick(page, andChoose) {
            if (page && page.id) {
                if (this.pageChooseMode && this.currentPageId === page.id) {
                    // ignore, don't let select current in page choose mode
                } else if (this.selectedPageId && this.selectedPageId === page.id) {
                    this.selectedPageId = null;  // de-select if already selected
                }   else {
                    this.selectedPageId = page.id;
                }
                if (andChoose) this.chooseClick();
            }
        },
        searchResultsLength(length) {
            this.pagesLength = length;
        },
        toggleBsModal(options) {
            this.isModalShown = true;
            this.selectedPageId = null;
            this.searchForText = null;
            this.searchInputText = null;
            this.delUnusedPagelistsLoading = false;
            this.linkToHotspotId = options.linkToHotspotId;
            this.pageFilter = 'all';
            this.displayResults = false;
            this.pageChooseMode = options.pageChooseMode;
            this.onSuccessCallback = options.success;

            this.displayResults = false;
            setTimeout(() => {
                this.displayResults = true;
            }, 150);  // delay slightly to give modal time to render
        }
    },

    computed: {
        presentation() {
            return this.$store.state.pres.showcase.presentation;
        },
        pagelists() {
            return this.$store.state.pres.showcase.pagelist;
        },
        currentPresentationId() {
            return this.edMxStateShowcase.presentation.id;
        },
        currentPageId() {
            return this.edMxStateEd.currentPageId;
        },
        showPurgeUnusedPagesLink() {
            return !this.pageChooseMode && !this.searchForText && this.pageFilter === 'unused';
        },
        totalPagesLength() {
            if (this.pagesLength >= 0) return this.pagesLength;
            return Object.values(this.$store.state.pres.showcase.page).length;
        }
    },

    //
    // public functions
    //
    modal(options) {
        _ins.toggleBsModal(options);
    }
}



</script>

