<template>
    <div class="editable-container">
        <div class="text-edit-toolbar shadow-lg rounded bg-light p-0" v-if="edTextActive">
            <div id="editor-editable-text-toolbar" class="btn-group rounded" role="group" aria-label="Text format" style="width:14rem">
                <button :class="{'btn btn-edit-cmd': 1, 'active btn-outline-primary': edMxStateEd.textToolbar.boldActive, 'btn-light': !edMxStateEd.textToolbar.boldActive}"
                        @pointerdown.prevent="edTextCmd('bold')" title="Bold">
                    <ScIcon name="boldFW"/></button>
                <button :class="{'btn btn-edit-cmd': 1, 'active btn-outline-primary': edMxStateEd.textToolbar.italicActive, 'btn-light': !edMxStateEd.textToolbar.italicActive}"
                        @pointerdown.prevent="edTextCmd('italic')" title="Italic">
                    <ScIcon name="italicFW"/></button>
                <button :class="{'btn btn-edit-cmd': 1, 'active btn-outline-primary': edMxStateEd.textToolbar.underlineActive, 'btn-light': !edMxStateEd.textToolbar.underlineActive}"
                        @pointerdown.prevent="edTextCmd('underline')" title="Underline">
                    <ScIcon name="underlineFW"/></button>
                <button :class="{'btn btn-edit-cmd': 1, 'active btn-outline-primary': edMxStateEd.textToolbar.strikethroughActive, 'btn-light': !edMxStateEd.textToolbar.strikethroughActive}"
                        @pointerdown.prevent="edTextCmd('strikethrough')" title="Strikethrough">
                    <ScIcon name="strikethroughFW"/></button>
                <button :class="{'btn btn-edit-cmd': 1, 'active btn-outline-primary': edMxStateEd.textToolbar.superscriptActive, 'btn-light': !edMxStateEd.textToolbar.superscriptActive}"
                        @pointerdown.prevent="edTextCmd('superscript')" title="Superscript">
                    <ScIcon name="superscriptFW"/></button>
                <button :class="{'btn btn-edit-cmd': 1, 'active btn-outline-primary': edMxStateEd.textToolbar.subscriptActive, 'btn-light': !edMxStateEd.textToolbar.subscriptActive}"
                        @pointerdown.prevent="edTextCmd('subscript')" title="Subscript">
                    <ScIcon name="subscriptFW"/></button>
            </div>
        </div>

        <div :class="{'editable-text': 1, 'ed-text-active': edTextActive}"
                :style="textStyleObject">

            <div :contenteditable="edTextActive" :style="editableTextStyleObject" ref="contentEditableEle"
                 @keydown="editableKeydown($event)"
                 @blur="editableBlur()"
                 @paste.prevent="editablePaste($event)"
                 @keyup="updateFormatButtons()"
                 @pointerup="updateFormatButtons()"
                 class="editable-html-content"
                 v-html="hsHotspot.html_content"></div>

        </div>
    </div>

</template>

<script>

    import _ from 'underscore'; // debounce
    import $ from 'jquery';
    import EditorMixin from './EditorMixin';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import EditorHotspotAdditionalStyleHelper from './EditorHotspotAdditionalStyleHelper';
    import EditorHotspotStyleHelper from './EditorHotspotStyleHelper';


    let placeCaretAtEnd = function(el) {
        el.focus();
        if (typeof window.getSelection !== "undefined"
            && typeof document.createRange !== "undefined") {
            let range = document.createRange();
            range.selectNodeContents(el);
            range.collapse(false);
            let sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (typeof document.body.createTextRange !== "undefined") {
            let textRange = document.body.createTextRange();
            textRange.moveToElementText(el);
            textRange.collapse(false);
            textRange.select();
        }
    };


    /**
     * Simple WYSIWYG Editor modelled from Pell (https://github.com/jaredreich/pell)
     */
    export default {
        name: "EditorEditableText",
        mixins: [EditorMixin],
        components: {ScIcon},
        props: {
            hsHotspot: {type: Object},
            width: {type:Number},
            height: {type:Number}
        },
        data: function() {
            return {}
        },

        mounted: function() {
            this.focusOrBlur();
            document.addEventListener('sc_event:hotspot_created_select_text', this.selectText);
        },

        beforeUnmount: function () {
            document.removeEventListener('sc_event:hotspot_created_select_text', this.selectText);
        },

        methods: {
            edTextCmd(cmdName) {
                //console.log('edTextCmd', cmdName);
                if (!this.edMxStateEd.textEditHotspotId && this.edMxLoneSelectedHotspot
                      && !this.edMxLoneSelectedHotspotLocked) {
                    this.$store.commit('edSetTextEditHotspotId', this.edMxLoneSelectedHotspot.id);
                }   else if (this.edMxStateEd.textEditHotspotId){
                    this.formatSelection(cmdName);
                }
            },

            focusOrBlur: function() {
                //console.log('focusOrBlur', this.edTextActive);
                if (this.edTextActive) {
                    //console.log('this.$refs.contentEditableEle', this.$refs.contentEditableEle);
                    setTimeout(() => {
                        if (this.$refs.contentEditableEle) {
                            this.$refs.contentEditableEle.focus();
                            placeCaretAtEnd(this.$refs.contentEditableEle);
                            this.updateFormatButtons();
                        }
                    });  // we have to wait until vue has updated the dom
                } else {
                    window.getSelection().removeAllRanges();
                    if (this.$refs.contentEditableEle) {
                        this.$refs.contentEditableEle.blur();
                    }
                }
            },

            selectText: function() {
                if (!this.edTextActive) return;
                let range = document.createRange();
                range.selectNodeContents(this.$refs.contentEditableEle);
                let sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            },

            edTextScrollToTop: function() {  // test editing can cause hs to scroll, reset on blur
                if (!this.$refs.contentEditableEle) return;
                this.$refs.contentEditableEle.scrollTop = 0;
                this.$refs.contentEditableEle.scrollLeft = 0;
            },
            htmlValueUpdate: function(htmlValue) {
                if (this.hsHotspot.html_content === htmlValue) return;  // ignore when no changes
                if (htmlValue && htmlValue === '<br>') htmlValue = null;
                if (htmlValue && htmlValue === '<br>\u200C') htmlValue = null;
                if (htmlValue && htmlValue === '\u200C') htmlValue = null;

                //console.log('htmlValueUpdate', htmlValue, this.edMxLoneSelectedHotspot);
                let hs = Object.assign({}, this.hsHotspot);
                hs.html_content = htmlValue;
                this.$store.dispatch('presUndoableSaveHotspotBatch', [hs]);
            },

            editableKeydown: function(e) {
                //console.log('key', e.key);
                if (e.key === 'Enter') {
                    let docFragment = document.createDocumentFragment();

                    // add the br (don't allow browser to add <p
                    // note, document.execCommand('insertHTML' ..) does not work in IE11
                    let newEle = document.createElement('br');
                    docFragment.appendChild(newEle);
                    let newEleNl = document.createTextNode('\u200C');
                    // note \u200C is a non visual space we need it to trick chrome into adding new lines in an empty div
                    docFragment.appendChild(newEleNl);

                    //make the br replace selection
                    let range = window.getSelection().getRangeAt(0);
                    range.deleteContents();
                    range.insertNode(docFragment);

                    // create a new range
                    let range2 = document.createRange();
                    range2.setStartAfter(newEleNl);
                    range2.collapse(true);

                    // make the cursor there
                    let sel = window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range2);

                    e.preventDefault();
                }
                if (e.key === 'Escape') {
                    $(this.$el).find('.editable-html-content').blur();
                    e.preventDefault();
                }
            },

            updateFormatButtons: _.debounce(function() {  // debounced so we don't hammer the dom
                //console.log('updateFormatButtons');
                this.$store.commit('edSetTextToolbarFormatState', {
                    boldActive: document.queryCommandState('bold'),
                    italicActive: document.queryCommandState('italic'),
                    underlineActive: document.queryCommandState('underline'),
                    strikethroughActive: document.queryCommandState('strikethrough'),
                    superscriptActive: document.queryCommandState('superscript'),
                    subscriptActive: document.queryCommandState('subscript')
                });
            }, 200),

            formatSelection(cmd) {
                //console.log('formatSelection', cmd);
                document.execCommand(cmd, false, null);
                //console.log('html now', $(this.$el).find('.editable-html-content').html());
                if (this.$refs.contentEditableEle) this.$refs.contentEditableEle.focus();
                this.updateFormatButtons();
            },

            editableBlur: function() {
                //console.log('editableBlur');
                if (this.$refs.contentEditableEle) this.htmlValueUpdate(this.$refs.contentEditableEle.innerHTML);
                this.edTextScrollToTop();
                this.$store.commit('edSetTextEditHotspotId', null);
            },

            editablePaste: function(e) {
                // Get pasted data via clipboard API, to make sure we strip formatting
                let clipboardData = e.clipboardData || window.clipboardData;
                let pastedData = clipboardData.getData('Text');
                document.execCommand('insertHTML', false, pastedData);
            }
        },

        computed: {
            textStyleObject() {
                if (!this.currentHotspotBranding) return {};
                // note this should match as closely as possible to ~/sc/webapp/main/modules/commands/presentation/createbuttonpreviewv2.py
                let cssObj =  {
                    'overflow': 'hidden',
                    'display': 'flex'
                };
                let additionalBorderWidth = EditorHotspotAdditionalStyleHelper.getBorderWidthValue(
                        this.currentHotspotBrandingAdditionalStyles, this.fontScaleRatio * this.scaleRatio);

                let calculatedWidth = this.width + (additionalBorderWidth *2); // *2 = add left and right border width
                let calculatedHeight = this.height + (additionalBorderWidth *2); // *2 = add top and bottom border width

                //console.log('textStyleObject', this.width, calculatedWidth, additionalBorderWidth);
                cssObj.width = calculatedWidth + 'px';
                cssObj.height = calculatedHeight + 'px';
                cssObj.maxWidth = calculatedWidth + 'px';
                cssObj.maxHeight = calculatedHeight + 'px';

                EditorHotspotAdditionalStyleHelper.addAdditionalHotspotStylesEditorHighlightBorder(cssObj,
                    this.currentHotspotBrandingAdditionalStyles, this.fontScaleRatio * this.scaleRatio);

                return cssObj;
            },
            htmlContentEmpty() {
                return !(this.hsHotspot.html_content && this.hsHotspot.html_content.length > 0);
            },
            editableTextStyleObject() {
                if (!this.currentHotspotBranding || (this.htmlContentEmpty && !this.edTextActive)) return {};
                let fontObj = this.$store.getters.fontsFontForId(this.currentHotspotBranding.font_resource_id);

                // note this should match as closely as possible to
                // ~/sc/deployment/cloudformation-templates/headless-browser2/function/slide-to-png-v2/HotspotCompSsr.js
                let cssObj = {
                    'line-height': '1.2em',
                    'word-break': 'break-word',
                    'letter-spacing': '0.0016em',
                    'max-height': this.height + 'px',
                    'width': this.width + 'px',
                    'font-family': fontObj.cssFamily,
                    'overflow': 'hidden',
                    '-webkit-font-smoothing': 'antialiased',
                    'text-rendering': 'optimizeLegibility',
                    'align-self': 'center',
                };

                EditorHotspotStyleHelper.addHotspotStyles(cssObj, this.currentHotspotBranding, this.fontScaleRatio * this.scaleRatio);

                EditorHotspotStyleHelper.addHotspotVerticalAlignment(cssObj, this.currentHotspotBranding)

                EditorHotspotAdditionalStyleHelper.addAdditionalHotspotTextStyles(cssObj,
                    this.currentHotspotBrandingAdditionalStyles, this.fontScaleRatio * this.scaleRatio,
                    this.currentHotspotBranding);

                return cssObj;
            },

            currentHotspotBranding() {
                return this.$store.getters.presHotspotBrandingByHsId(this.hsHotspot.id);
            },
            currentHotspotBrandingAdditionalStyles() {
                return this.$store.getters.presHotspotBrandingAdditionalStylesObjByHsId(this.hsHotspot.id);
            },
            fontScaleRatio() {
                return this.$store.getters.presFontScaleRatio;
            },
            scaleRatio() {
                return this.edState.scaleRatio;
            },
            edTextActive() {
                return this.edMxLoneSelectedHotspot && !this.edMxLoneSelectedHotspotLocked
                    && this.edState.textEditHotspotId === this.hsHotspot.id;
            },
            edState() {
                return this.$store.state.ed;
            },
        },

        watch: {
            edTextActive() {
                //console.log('edTextActive change');
                this.focusOrBlur();
            },
            'hsHotspot.html_content': function() { // probably an undo or something
                //console.log('EditorEditableText/watch/hsHotspot.html_content');
                if (this.$refs.contentEditableEle) {
                    this.$refs.contentEditableEle.innerHTML = this.hsHotspot.html_content ? this.hsHotspot.html_content : '';
                }
            }
        }
    }

</script>

<style>

    .editable-container {
        width: 100%; height: 100%; overflow: hidden;
    }
    .editable-text.ed-text-active {
        cursor: text;
    }
    .editable-text.ed-text-active .editable-html-content {
      z-index: 140;  /* see main/ui/README.md for all z-index values */
    }
    .editable-html-content:focus {
        outline: none;
    }

    .text-edit-toolbar {
        z-index: 140;  /* see main/ui/README.md for all z-index values */
        border: 2px solid #c0c0c0;
        position: absolute;
        top: -55px;
        left: 0;
        width: fit-content;
    }

    .editable-text sub {
        vertical-align: sub;
    }
    .editable-text sup {
        vertical-align: super;
    }
    /* sometimes hotspots can get weird html from browser plugins, make sure we ignore sizing */
    .editable-text font {
        font-size: 100%
    }

    .btn-edit-cmd {
        padding: .5rem;
    }
    .btn-edit-cmd.active svg {
        color: #fff !important;
    }

</style>


