<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
            @vb-hide-bs-modal="canModalHide" @vb-hidden-bs-modal="modalWasHidden"
            class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">

                <div class="modal-header text-center">
                    <button v-if="!publishingLoading" type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title w-100">Publish</h4>
                </div>
                <div class="modal-body clearfix" style="min-height: 200px;">
                    <div v-if="confirmFirst" class="access-message mb-4">
                        <p v-if="isTemplate && permissions === 'group-read'">
                            This presentation template will be visible to all Editor and Admin users in your workshop.
                            This can be changed in
                            <a href="#" @click.prevent="showManageAccess()">Manage Access</a>.</p>

                        <p v-if="isTemplate && permissions !== 'group-read'">
                            This presentation template will be visible to the users specified in
                            <a href="#" @click.prevent="showManageAccess()">Manage Access</a>.</p>

                        <p v-if="!isTemplate && permissions === 'group-read'">
                            This presentation will be visible to <strong><em>all</em> users in your workshop</strong>.
                            This can be changed in
                            <a href="#" @click.prevent="showManageAccess()">Manage Access</a>.</p>

                        <p v-if="!isTemplate && permissions !== 'group-read'">
                            This presentation will be visible to users listed in
                            <a href="#" @click.prevent="showManageAccess()">Manage Access</a>.</p>
                    </div>

                    <div v-if="sizeCheckLoading">
                        <ScIcon name="spinnerFW" class="me-1 text-muted"/>
                        Checking size...
                    </div>

                    <div v-if="!sizeCheckLoading" class="publish-stats">
                        <span v-if="totalNumPages && totalNumPages < PAGE_COUNT_WARN_LIMIT">
                            <ScIcon name="check" class="text-success me-1" />
                            <span class="ms-1">{{ fmtNum(totalNumPages) }}</span>
                            slide<span v-if="totalNumPages > 1">s</span>
                            <span class="text-muted ms-1" v-sc-tooltip="'Should have less than ' + fmtNum(PAGE_COUNT_WARN_LIMIT) + ' slides'">
                                <ScIcon name="questionCircle"/>
                            </span>
                        </span>
                        <span v-if="totalNumPages && totalNumPages >= PAGE_COUNT_WARN_LIMIT && totalNumPages < PAGE_COUNT_ERROR_LIMIT" class="text-warning">
                            <ScIcon name="exclamationTriangleFW" class="me-1"/>
                            To ensure good performance across different devices, presentations should
                            have less than {{ fmtNum(PAGE_COUNT_WARN_LIMIT) }} slides. Max {{ fmtNum(PAGE_COUNT_ERROR_LIMIT) }}. Currently <span>{{ fmtNum(totalNumPages) }}</span>
                            slides.
                        </span>
                        <span v-if="totalNumPages && totalNumPages >= PAGE_COUNT_ERROR_LIMIT" class="text-danger">
                            <ScIcon name="timesFW" class="me-1 text-danger"/>
                            Presentations must have less than {{ fmtNum(PAGE_COUNT_ERROR_LIMIT) }} slides.
                            Currently <span>{{ fmtNum(totalNumPages) }}</span> slides.
                        </span>

                        <span v-if="totalNumHotspots && totalNumHotspots < HOTSPOT_COUNT_WARN_LIMIT">
                            <br/><ScIcon name="check" class="text-success me-1" />
                            <span class="ms-1">{{ fmtNum(totalNumHotspots) }}</span>
                            hotspot<span v-if="totalNumHotspots > 1">s</span>
                            <span class="text-muted ms-1" v-sc-tooltip="'Should have less than ' + fmtNum(HOTSPOT_COUNT_WARN_LIMIT) + ' hotspots'">
                                <ScIcon name="questionCircle"/>
                            </span>
                        </span>
                        <span v-if="totalNumHotspots && totalNumHotspots >= HOTSPOT_COUNT_WARN_LIMIT && totalNumHotspots < HOTSPOT_COUNT_ERROR_LIMIT" class="text-warning">
                            <br/><ScIcon name="exclamationTriangleFW" class="me-1"/>
                            To ensure good performance across different devices, presentations should
                            have less than {{ fmtNum(HOTSPOT_COUNT_WARN_LIMIT) }} hotspots. Max {{ fmtNum(HOTSPOT_COUNT_ERROR_LIMIT) }}. Currently <span>{{ fmtNum(totalNumHotspots) }}</span>
                            hotspots.
                        </span>
                        <span v-if="totalNumHotspots && totalNumHotspots >= HOTSPOT_COUNT_ERROR_LIMIT" class="text-danger">
                            <br/><ScIcon name="timesFW" class="me-1 text-danger"/>
                            Presentations must have less than {{ fmtNum(HOTSPOT_COUNT_ERROR_LIMIT) }} hotspots.
                            Currently <span>{{ fmtNum(totalNumHotspots) }}</span> hotspots.
                        </span>

                        <span v-if="totalNumTargetRes && totalNumTargetRes < TARGET_RES_COUNT_WARN_LIMIT">
                            <br/><ScIcon name="check" class="me-1 text-success"/>
                            <span class="ms-1">{{ fmtNum(totalNumTargetRes) }}</span>
                            file<span v-if="totalNumTargetRes > 1">s</span> linked from hotspots
                            <span class="text-muted ms-1" v-sc-tooltip="'Should have less than ' + fmtNum(TARGET_RES_COUNT_WARN_LIMIT) + ' files linked from hotspots'">
                                <ScIcon name="questionCircle"/>
                            </span>
                        </span>
                        <span v-if="totalNumTargetRes && totalNumTargetRes >= TARGET_RES_COUNT_WARN_LIMIT" class="text-warning">
                            <br/><ScIcon name="exclamationTriangleFW" class="me-1" />
                            To ensure good performance across different devices, presentations should
                            have less than {{ fmtNum(TARGET_RES_COUNT_WARN_LIMIT) }} files linked from hotspots. Currently <span>{{ fmtNum(totalNumTargetRes) }}</span>
                            files.
                        </span>

                        <span v-if="totalByteSizeHd && totalByteSizeHd < WARN_BYTES_LIMIT">
                            <br/><ScIcon name="check" class="me-1 text-success"/>
                            Size <span class="byte-size-num">{{ fmtFileSizeBytes(totalByteSizeHd) }}</span>
                            <span class="ms-1 text-muted" v-sc-tooltip="'Should be less than ' + fmtFileSizeBytes(WARN_BYTES_LIMIT) + ' in size'">
                                <ScIcon name="questionCircle"/>
                            </span>
                        </span>
                        <span v-if="totalByteSizeHd && totalByteSizeHd >= WARN_BYTES_LIMIT" class="text-warning">
                            <br/><ScIcon name="exclamationTriangleFW" class="me-1"/>
                            Presentations should be less than {{ fmtFileSizeBytes(WARN_BYTES_LIMIT) }} in size.  This will ensure they work on poor quality devices.
                            Currently <span>{{ fmtFileSizeBytes(totalByteSizeHd) }}</span>.</span>
                    </div>

                    <div v-if="!resourcesPublishable && resourcesCheckLoading">
                        <ScIcon name="spinnerFW" class="text-muted me-1"/>
                        Working...
                            <span v-if="presProblems && presProblems.length > 0">{{ presProblems.length }} task<span v-if="presProblems.length > 1" >s</span> remaining...</span>
                        <span v-if="resourcesChecksNum > 2" class="text-muted next-check">(checking in {{ resourcesCheckCountdownSecs }}s)</span>
                    </div>
                    <div v-if="!resourcesCheckLoading && resourcesChecksNum > MAX_RESOURCE_CHECKS">
                        <div class="text-danger">
                            <ScIcon name="timesFW" class="text-danger me-1"/>
                            Tasks are taking too long.  Check the following and try again or contact support.</div>
                        <div v-if="presProblems">
                            <ul>
                                <li v-for="prob in presProblems" :key="prob.prob_guid">
                                    Slide {{ prob.alpha_num_name }} {{ prob.res_name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="resourcesPublishable && !resourcesCheckLoading">
                        <ScIcon name="check" class="text-success me-1"/> Mobile versions generated
                    </div>

                    <div v-if="resourcesPublishable && publishingLoading">
                        <ScIcon name="spinnerFW" class="text-muted me-1"/>
                        Publishing...
                    </div>
                    <div v-if="!publishingLoading && publishedOk">
                        <ScIcon name="check" class="text-success me-1"/> Published
                    </div>

                    <br/>
                    <br/>

                </div>
                <div class="modal-footer" v-if="!autoPublish && !publishingLoading && !publishedOk">
                    <button :disabled="totalNumPages >= PAGE_COUNT_ERROR_LIMIT || !resourcesPublishable || publishCancelled"
                            @click.prevent="publishNow" type="button" class="btn btn-primary-orange fw-bold">Publish</button>
                </div>

            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import $ from 'jquery';
    import ScCommonUtil from '../../shared/common/ScCommonUtil';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ManageAccessModal from './manage-access/ManageAccessModal.vue';
    import MainAppMixin from '../MainAppMixin';

    let _resCheckTimer = null;
    let _ins = null;

    export default {
        name: 'PresentationPublish',
        mixins: [MainAppMixin],
        components: {ScIcon},
        data: function() {
            return {
                isModalShown: false,
                showManageAccessOnHidden: false,
                workspaceId: null,
                presentationId: null,
                isTemplate: null,
                permissions: null,
                confirmFirst: null,

                MAX_RESOURCE_CHECKS: 13,
                HOTSPOT_COUNT_WARN_LIMIT: 2500,
                PAGE_COUNT_WARN_LIMIT: 500,
                TARGET_RES_COUNT_WARN_LIMIT: 500,
                PAGE_COUNT_ERROR_LIMIT: 2000,
                HOTSPOT_COUNT_ERROR_LIMIT: 3600,
                WARN_BYTES_LIMIT: (1024*1024*1024*1.5),  // 1.5gb

                sizeCheckLoading: false,
                totalNumHotspots: null,
                totalNumPages: null,
                totalNumTargetRes: null,
                totalByteSizeHd: null,
                autoPublish: true,

                resourcesChecksNum: 1,
                resourcesCheckLoading: false,
                resourcesPublishable: false,
                resourcesCheckTimeout: 2000,
                resourcesCheckCountdownSecs: 0,
                presProblems: [],

                publishingLoading: false,
                publishedOk: false,
                publishCancelled: false
            }
        },
        mounted() {
            _ins = this;

            if (_resCheckTimer) clearInterval(_resCheckTimer);
            _resCheckTimer = setInterval(() => {
                if (this.resourcesCheckCountdownSecs < 1) return;
                this.resourcesCheckCountdownSecs = this.resourcesCheckCountdownSecs - 1;
            }, 1000);
        },
        beforeUnmount() {
            _ins = null;
        },
        methods: {
            fmtNum(n) {
              return n && typeof n.toLocaleString === 'function' ? n.toLocaleString() : n;
            },
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            modalWasHidden() {
                this.isModalShown = false;
                if (this.showManageAccessOnHidden) {
                    ManageAccessModal.modal({ presentation_id: this.presentationId,
                        presentation_read_only: false});
                    this.showManageAccessOnHidden = false
                }
                if (this.publishedOk && this.reloadEditorOnPublish) {
                    this.$store.dispatch('presReload');
                }
                if (this.publishedOk && this.$store.state.plist) {
                    this.$store.commit('plistPresPublishStatusChange', {
                        presentation_id: this.presentationId, publish_status: 'published'});
                }
            },
            canModalHide(e) {
                if (this.publishingLoading) {
                    e.preventDefault();
                } else {
                    this.cancelPublish();
                    if (_resCheckTimer) clearInterval(_resCheckTimer);
                }
            },
            showManageAccess() {
                this.showManageAccessOnHidden = true;
                this.hideModal();
            },
            fmtFileSizeBytes(bytes) {
                return ScCommonUtil.filesizeWithBytes(bytes);
            },
            cancelPublish() {
                this.publishCancelled = true;
            },
            presSizeCheck() {
                return new Promise((resolve) => {
                    this.sizeCheckLoading = true;
                    this.totalNumHotspots = null;
                    this.totalNumPages = null;
                    this.totalByteSizeHd = null;
                    $.ajax({
                        method: 'GET', url: '/main/presentations/ajax_showcase_stats',
                        data: {workspace_id: this.workspaceId, presentation_id: this.presentationId}
                    }).done((data) => {
                        //console.log('stats', data);
                        this.totalNumHotspots = data.stats.total_num_hotspots || 0;
                        this.totalNumPages = data.stats.total_num_pages;
                        this.totalNumTargetRes = data.stats.total_num_target_res;
                        this.totalByteSizeHd = Number(data.stats.total_bytesize_hd);  // double check we have a num

                        if (this.totalNumHotspots > this.HOTSPOT_COUNT_WARN_LIMIT) this.autoPublish = false;
                        if (this.totalNumPages > this.PAGE_COUNT_WARN_LIMIT) this.autoPublish = false;
                        if (this.totalNumTargetRes > this.TARGET_RES_COUNT_WARN_LIMIT) this.autoPublish = false;
                        if (this.totalByteSizeHd > this.WARN_BYTES_LIMIT) this.autoPublish = false;
                        resolve();

                    }).always(() => {
                        this.sizeCheckLoading = false;
                    }).fail((jqXhr) => {
                        ScNotification.growlXhrError(jqXhr, 'loading statistics');
                        this.publishCancelled = true;
                        resolve();
                    });
                });
            },

            resourcesCheck() {
                return new Promise((resolve) => {
                    this.resourcesCheckLoading = true;
                    this.resourcesPublishable = false;
                    $.ajax({
                        type: 'POST',
                        url: "/main/presentations/ajax_check_resources_publishable_v2",
                        data: {presentation_id: this.presentationId, workspace_id: this.workspaceId,
                            attempt: this.resourcesChecksNum}
                    }).then((data) => {
                        this.resourcesPublishable = data.resources_publishable;
                        this.presProblems.splice(0);
                        if (data.pres_problems) {
                            data.pres_problems.forEach((prob) => {
                                this.presProblems.push(prob);
                            })
                        }
                        if ( this.resourcesPublishable ) {
                            this.resourcesCheckLoading = false;
                            resolve();

                        }   else {
                            this.resourcesCheckTimeout = this.resourcesChecksNum * 10 * 1000;  // back off each time  eg, 10s, 20s, 30s
                            if ( this.resourcesCheckTimeout > (1000 * 30)) this.resourcesCheckTimeout = 1000 * 30; // max 30s
                            this.resourcesChecksNum += 1;
                            if (this.resourcesChecksNum > this.MAX_RESOURCE_CHECKS) {
                                this.resourcesCheckLoading = false;
                                resolve();

                            }   else if (!this.publishCancelled) {
                                this.resourcesCheckCountdownSecs = this.resourcesCheckTimeout/1000;
                                setTimeout(() => {
                                    this.resourcesCheck().then(() => {
                                        resolve();
                                    });
                                }, this.resourcesCheckTimeout);
                            }
                        }
                        if (this.publishCancelled) {
                            this.publishingLoading = false;
                            resolve();
                        }

                    }).fail((jqXhr) => {
                        this.resourcesCheckLoading = false;
                        resolve();
                        ScNotification.growlXhrError(jqXhr, 'checking files');
                    });
                });
            },

            autoPublishCheck() {
                if ( !this.publishCancelled && !this.confirmFirst
                        && this.autoPublish ) {  // not confirm and modal shown
                    this.publishingLoading = true;
                    setTimeout(this.publishNow, 1000);  // auto publish, delay slightly so the user can sorta follow along
                }
            },

            publishNow() {
                this.publishingLoading = true;
                this.publishedOk = false;

                $.ajax({
                    type: 'POST', url: "/main/presentations/ajax_publish_v2",
                    data: {workspace_id: this.workspaceId, presentation_id: this.presentationId}
                }).done(() => {
                    this.publishedOk = true;
                    this.publishingLoading = false;
                    this.hideModal();

                }).fail((xhr) => {
                    this.publishCancelled = true;
                    ScNotification.growlErrMsg('Error during publishing (' + xhr.status + ').  Please retry in 5 minutes.');

                }).always(() => {
                    this.publishingLoading = false;
                });
            },

            toggleBsModal(workspaceId, presentationId, permissions, confirmFirst, reloadEditorOnPublish, isTemplate) {
                this.workspaceId = workspaceId;
                this.presentationId = presentationId;
                this.permissions = permissions;
                this.isTemplate = isTemplate;
                this.confirmFirst = confirmFirst;
                this.reloadEditorOnPublish = reloadEditorOnPublish;

                this.sizeCheckLoading = false;
                this.totalNumHotspots = 0;
                this.totalNumPages = 0;
                this.totalNumTargetRes = 0;
                this.totalByteSizeHd = 0;
                this.autoPublish = !confirmFirst;

                this.resourcesChecksNum = 1;
                this.resourcesCheckLoading = false;
                this.resourcesCheckTimeout = 2000;
                this.resourcesCheckCountdownSecs = 0;
                this.presProblems.splice(0);

                this.publishingLoading = false;
                this.publishedOk = false;
                this.publishCancelled = false;

                ScNotification.growlClear();  // hide any pending toasts

                this.isModalShown = true;
                Promise.all([
                    this.presSizeCheck(),
                    this.resourcesCheck()
                ]).then(() => {
                    this.autoPublishCheck();
                });
            }
        },
        watch: {
            resourcesCheckTimeout: function() {
                $(this.$el).find(".next-check").animate({opacity: .5}).animate({opacity: 1});
            },
        },

        //
        // public functions
        //
        modal: function(workspaceId, presentationId, permissions, confirmFirst, reloadEditorOnPublish, isTemplate) {
            _ins.toggleBsModal(workspaceId, presentationId, permissions, confirmFirst, reloadEditorOnPublish,
              isTemplate);
        }


    }

</script>