<template>
  <transition name="sc-transition-fade">
    <div v-if="showEditorInfoPanel" class="p-2 editor-info-panel rounded-end text-muted">
      <small class="mx-1"> W: {{ Math.floor(selectionDimsW) }}px </small>
      <small class="mx-1"> H: {{ Math.floor(selectionDimsH) }}px </small>
      <small class="mx-1"> X: {{ Math.floor(selectionDimsX) }}px </small>
      <small class="mx-1"> Y: {{ Math.floor(selectionDimsY) }}px </small>
    </div>
  </transition>
</template>

<script>

  let _clearSelectionTimer = null;

  export default {
    name: "EditorInfoPanel",
    props: {
      infoPanelShownKey: {type: String, default: ''},
      selectionDimsX: { type: Number, default: 0 },
      selectionDimsY: { type: Number, default: 0 },
      selectionDimsW: { type: Number, default: 0 },
      selectionDimsH: { type: Number, default: 0 },
    },
    data () {
      return {
        showEditorInfoPanel: false,
      }
    },
    mounted () {
      //console.log('mounted', this.selectionDimsX, this.selectionDimsY, this.selectionDimsW, this.selectionDimsH);

    },
    beforeUnmount () {
      if (_clearSelectionTimer) clearTimeout(_clearSelectionTimer);
    },
    methods: {
      selectionActive () {
        if (_clearSelectionTimer) clearTimeout(_clearSelectionTimer);
        if (this.selectionDimsX === 0 && this.selectionDimsY === 0 && this.selectionDimsW === 0 && this.selectionDimsH === 0) {
          this.showEditorInfoPanel = false;
          return;
        }
        this.showEditorInfoPanel = true;
        _clearSelectionTimer = setTimeout(() => {
          this.showEditorInfoPanel = false;
        }, 5000);  // 5 seconds
      }
    },

    watch: {
      infoPanelShownKey() {
        this.selectionActive();
      }
    }

  }
</script>

<style scoped>

  .editor-info-panel {
    z-index: 170;  /* see main/ui/README.md for all z-index values */
    position: fixed;
    bottom: 0;
    left: 250px; /* account for width of EditorSidePanel */
    background-color: #f5f5f5;
    border-top: 2px solid #e9e9f2;
  }

</style>