<template>
    <teleport to=".sc-modals-anchor">
        <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
             @vb-hidden-bs-modal="modalWasHidden" @vb-hide-bs-modal="canModalHide"
             class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">

                    <div class="modal-header text-center">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        <h4 class="modal-title w-100">Add slide</h4>
                    </div>

                    <div class="modal-body pb-3" style="min-height: 150px">
                        <div v-if="slideAddLoading" class="text-center pt-5">
                            <ScIcon name="spinnerFW" class="text-muted"/>
                        </div>
                        <div v-else>
                            <button class="btn btn-outline-secondary w-100 mb-2" @click.prevent.stop="newImageSlides()"
                                    v-sc-tooltip="'Add image slide(s) to slideshow'">
                                <ScIcon name="fileImageFW"/> New image slide(s)</button>
                            <button class="btn btn-outline-secondary w-100 mb-2" @click.prevent.stop="newBlankSlide()"
                                    v-sc-tooltip="'Add blank slide to slideshow'">
                                <ScIcon name="fileFW"/> New blank slide</button>
                        </div>
                    </div>

                </div>
            </div>
            <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>
        </div>
    </teleport>
</template>


<script>

import ScIcon from '../../shared/common/ScIcon.vue';
import EdtorMixin from './EditorMixin';
import PresentationUtils from '../../store/PresentationUtils';
import LibraryMainModal from '../library/LibraryMainModal.vue';

let _ins = null;

export default {
  name: 'EditorNewSlideModal',
  mixins: [ EdtorMixin],
  components: {ScIcon, LibraryMainModal},
  data () {
    return {
      isModalShown: false,
      selectedPagelistId: null,
      slideAddLoading: false,
      pageIdToLoadWhenDone: null
    };
  },
  mounted() {
    _ins = this;
  },
  beforeUnmount() {
    _ins = null;
  },
  methods: {
    canModalHide(e) {
      if (this.slideAddLoading) e.preventDefault();
    },
    hideModal() {
      this.$refs.scModal.$vb.modal.hide();
    },
    modalWasHidden() {
      if (this.pageIdToLoadWhenDone) this.edMxNavToPage(this.pageIdToLoadWhenDone);
      this.isModalShown = false
    },
    newBlankSlide() {
      this.slideAddLoading = true;
      this.pageIdToLoadWhenDone = null;
      let newSortOrder = this.edMxCurrentPage.sort_order + 1; // add slide AFTER selected slide
      let pagelistId = this.selectedPagelistId ? this.selectedPagelistId : this.edMxCurrentPage.pagelist_id;
      let pl = this.$store.getters.presGetPagelistById(this.edMxCurrentPage.pagelist_id);
      let newAlphaNumName = PresentationUtils.getAlphaNumName(pl.sequence_num, newSortOrder);
      this.$store.dispatch('presNewPage', {
        pagelist_id: pagelistId,
        background_src_resource_id: null, title: null, page_bg_color: this.edMxCurrentPage.page_bg_color,
        sort_order: newSortOrder, alpha_num_name: newAlphaNumName
      }).then((newPage) => {
        setTimeout(() => {
          this.slideAddLoading = false;
          this.pageIdToLoadWhenDone = newPage.id;
          this.hideModal();
        }, 500);  // make it take a little longer to prevent repeated clicking
      });
    },

    newImageSlidesResourcesPicked(selectedResources) {
      //console.log('newImageSlidesResourcesPicked', selectedResources);
      if (selectedResources && selectedResources.length > 0) {
        this.slideAddLoading = true;
        this.pageIdToLoadWhenDone = null;
        let newSortOrder = this.edMxCurrentPage.sort_order;
        let pagelistId = this.selectedPagelistId ? this.selectedPagelistId : this.edMxCurrentPage.pagelist_id;
        let pl = this.$store.getters.presGetPagelistById(this.edMxCurrentPage.pagelist_id);
        let addNextPageWithBg = (resIdx) => {
          newSortOrder = newSortOrder + 1; // add slide AFTER selected slide
          let newAlphaNumName = PresentationUtils.getAlphaNumName(pl.sequence_num, newSortOrder);
          let selectedResource = selectedResources[resIdx];
          if (!selectedResource) {
            this.slideAddLoading = false;
            this.hideModal();
            return;
          }
          this.$store.dispatch('presResourceAddedFromLibrary', selectedResource);
          this.$store.dispatch('presNewPage', {
            pagelist_id: pagelistId,
            background_src_resource_id: selectedResource.id, title: selectedResource.name,
            page_bg_color: this.edMxCurrentPage.page_bg_color, sort_order: newSortOrder, alpha_num_name: newAlphaNumName
          }).then((newPage) => {
            if (resIdx === 0) {  // navigate to the first page added
              this.pageIdToLoadWhenDone = newPage.id;
            }
            addNextPageWithBg(resIdx+1);
          });
        };
        addNextPageWithBg(0);
      }
    },
    newImageSlides() {
      this.$refs.libraryMainModal.toggleBsModal({
        selectionMode: 'multiple', dialogType: 'modal',
        filterType: 'image'
      }, this.newImageSlidesResourcesPicked);
    },
    toggleBsModal(selectedPagelistId) {
      this.selectedPagelistId = selectedPagelistId;
      this.isModalShown = true;
    }
  },

  //
  // public functions
  //
  modal(selectedPagelistId) {
    _ins.toggleBsModal(selectedPagelistId);
  }

}

</script>