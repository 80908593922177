

let _deviceChassisImages = {  // effectively a constant but we might want to load remotely

    'hd-landscape': {  // Landscape 4:3 HD
        imgPath: '/showcase-chassis/7.1.1/4x3-full-landscape.png', imgWidth: 2608, imgHeight: 1856,
        imgMarginTopForTitle: 106,
        thumbPath: '/showcase-chassis/7.1.2/thumb-4x3-landscape.png', thumbOffsetTop: 36, thumbOffsetLeft: 43
    }, 'hd-portrait': {  // Portrait 4:3 HD
        imgPath: '/showcase-chassis/7.1.1/4x3-full-portrait.png', imgWidth: 1856, imgHeight: 2608,
        imgMarginTopForTitle: 214,
        thumbPath: '/showcase-chassis/7.1.2/thumb-4x3-portrait.png', thumbOffsetTop: 43, thumbOffsetLeft: 36

    }, 'ipad-landscape': {  // Landscape 4:3 SD
        imgPath: '/showcase-chassis/7.1.1/4x3-full-landscape.png', imgWidth: 2608 * .5, imgHeight: 1856 * .5,
        imgMarginTopForTitle: 52,
        thumbPath: '/showcase-chassis/7.1.2/thumb-4x3-landscape.png', thumbOffsetTop: 36, thumbOffsetLeft: 43
    }, 'ipad-portrait': {  // Portrait 4:3 SD
        imgPath: '/showcase-chassis/7.1.1/4x3-full-portrait.png', imgWidth: 1856 * .5, imgHeight: 2608 * .5,
        imgMarginTopForTitle: 108,
        thumbPath: '/showcase-chassis/7.1.2/thumb-4x3-portrait.png', thumbOffsetTop: 43, thumbOffsetLeft: 36

    }, 'android-tablet-landscape': {  // Legacy: Landscape 16:9 (minus android navbar) SD
        imgPath: '/showcase-chassis/7.1.1/16x9-full-landscape.png', imgWidth: 1680, imgHeight: 1152,
        imgMarginTopForTitle: 60,
        thumbPath: '/showcase-chassis/7.1.2/thumb-16x9-landscape.png', thumbOffsetTop: 36, thumbOffsetLeft: 43

    }, 'sd-16by9-landscape': {  // Landscape 16:9 SD
        imgPath: '/showcase-chassis/7.1.3/16x9-fullscreen-landscape.png', imgWidth: 1680, imgHeight: 1152,
        imgMarginTopForTitle: 60,
        thumbPath: '/showcase-chassis/7.1.3/thumb-16x9-fullscreen-landscape.png', thumbOffsetTop: 41, thumbOffsetLeft: 37
    }, 'sd-16by9-portrait': {  // Legacy: Portrait 16:9 SD
        imgPath: '/showcase-chassis/7.1.3/16x9-fullscreen-portrait.png', imgWidth: 1152, imgHeight: 1680,
        imgMarginTopForTitle: 88,
        thumbPath: '/showcase-chassis/7.1.3/thumb-16x9-fullscreen-portrait.png', thumbOffsetTop: 37, thumbOffsetLeft: 41

    }, 'hd-16by9-landscape': {  // Landscape 16:9 HD
        imgPath: '/showcase-chassis/7.1.3/16x9-fullscreen-landscape.png', imgWidth: 1680 * 1.59, imgHeight: 1152 * 1.59,
        imgMarginTopForTitle: 100,
        thumbPath: '/showcase-chassis/7.1.3/thumb-16x9-fullscreen-landscape.png', thumbOffsetTop: 41, thumbOffsetLeft: 37
    }, 'hd-16by9-portrait': {  // Portrait 16:9 HD
        imgPath: '/showcase-chassis/7.1.3/16x9-fullscreen-portrait.png', imgWidth: 1152 * 1.59, imgHeight: 1680 * 1.59,
        imgMarginTopForTitle: 134,
        thumbPath: '/showcase-chassis/7.1.3/thumb-16x9-fullscreen-portrait.png', thumbOffsetTop: 37, thumbOffsetLeft: 41

    }, 'surface-pro3-landscape': {
        imgPath: '/showcase-chassis/7.1.1/3x2-full-landscape.png', imgWidth: 1680, imgHeight: 1152,
        imgMarginTopForTitle: 60,
        thumbPath: '/showcase-chassis/7.1.2/thumb-3x2-landscape.png', thumbOffsetTop: 36, thumbOffsetLeft: 43
    }, 'sd-3by2-portrait': {
        imgPath: '/showcase-chassis/7.1.1/3x2-full-portrait.png', imgWidth: 1152, imgHeight: 1680,
        imgMarginTopForTitle: 118,
        thumbPath: '/showcase-chassis/7.1.2/thumb-3x2-portrait.png', thumbOffsetTop: 43, thumbOffsetLeft: 36

    }, 'hd-3by2-landscape': {
        imgPath: '/showcase-chassis/7.1.1/3x2-full-landscape.png', imgWidth: 1680 * 1.9, imgHeight: 1152 * 1.9,
        imgMarginTopForTitle: 126,
        thumbPath: '/showcase-chassis/7.1.2/thumb-3x2-landscape.png', thumbOffsetTop: 36, thumbOffsetLeft: 43
    }, 'hd-3by2-portrait': {
        imgPath: '/showcase-chassis/7.1.1/3x2-full-portrait.png', imgWidth: 1152 * 1.9, imgHeight: 1680 * 1.9,
        imgMarginTopForTitle: 150,
        thumbPath: '/showcase-chassis/7.1.2/thumb-3x2-portrait.png', thumbOffsetTop: 43, thumbOffsetLeft: 36
    }
};


let PresentationUtils = {

    getDeviceChassisImage: (layoutCode) => {
        let img = null;
        if (Object.keys(_deviceChassisImages).indexOf(layoutCode) > -1) {
            img = _deviceChassisImages[layoutCode];
        }
        return img;
    },

    pageAlphaNumSortComparator (a ,b) {
        let reA = /[^a-zA-Z]/g;
        let reN = /[^0-9]/g;

        let aA = a.alpha_num_name.replace(reA, "");
        let bA = b.alpha_num_name.replace(reA, "");

        if (aA === bA) {
            let aN = Number(a.alpha_num_name.replace(reN, ""));
            let bN = Number(b.alpha_num_name.replace(reN, ""));
            return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
            if (aA.length > 1) {
                aA = "Z".repeat(aA.length - 1) + aA;
            }
            if (bA.length > 1) {
                bA = "Z".repeat(bA.length - 1) + bA;
            }
            return aA > bA ? 1 : -1;
        }
    },

    letterFromNumber: function(num) {
        let letter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26];
        let num2 = Math.floor(Number(num / 26));
        if (num2 > 0) {
            return this.letterFromNumber(num2 - 1) + letter;
        }
        return letter
    },

    getAlphaNumName: function(sequenceNum, sortOrder) {
        return this.letterFromNumber(sequenceNum) + (sortOrder + 1);
    },

    resequencePages: function(sequenceNum, pages, changedPages) {
        let sortOrder = 0;
        pages.forEach((page) => {  // update sort order on other pages
            if (page.sort_order !== sortOrder) {
                let changedPage = Object.assign({}, page);
                changedPage.sort_order = sortOrder;
                changedPage.alpha_num_name = PresentationUtils.getAlphaNumName(sequenceNum, sortOrder);
                changedPages.push(changedPage);
            }
            sortOrder++;
        });
    },

    scDataPage: (scData, id) => {
        //console.log('scDataPage', id);
        if (!id) return null
        return scData.page[String(id)]
    },

    scDataHotspot: (scData, id) => {
        //console.log('scDataPage', id);
        if (!id) return null
        return scData.hotspot[String(id)]
    },

    scDataHotspotsForPageId: (scData, pageId) => {
        let hotspots = Object.values(scData.hotspot).filter((hs) => {
            return hs && hs.parent_page_id === pageId && !hs.deleted
        });
        return PresentationUtils.scDataHotspotsSort(hotspots);
    },

    scDataHotspotsSort: (hotspots) => {
        // sort by z-index then id (in case we have multiple with same z-index)
        return [...hotspots].sort((a, b) => a.zindex - b.zindex || a.id - b.id);
    },

    scDataHotspotBrandingByHsId: (scData, hsId) => {
        if (!hsId) return null;
        return scData.hotspot_branding[String(hsId)];
    },

    scDataPagesForPagelist: (scData, pagelistId) => {
        let pages = Object.values(scData.page).filter((pg) => {
            return pg.pagelist_id === pagelistId && !pg.deleted
        })
        pages.sort((a, b) => a.sort_order - b.sort_order || a.id - b.id);
        return pages;
    },

    scDataUnusedPagelistIds: (scData) => {
        //console.log('scDataUnusedPagelistIds')

        // pre calculate list of all active pagelist ids
        let activePagelistIds = [];
        Object.values(scData.pagelist).forEach((pl) => {
            if (pl && pl.id && !pl.deleted) {
                activePagelistIds.push(pl.id);
            }
        });

        // pre calculate a mapping between page and parent pagelist
        let pagelistIdForPageId = {}
        Object.values(scData.page).forEach((page) => {
            if (page && page.pagelist_id && !page.deleted && activePagelistIds.includes(page.pagelist_id)) {
                pagelistIdForPageId[String(page.id)] = page.pagelist_id;
            }
        });

        // pre calculate all the hotspot target page lists and store by source pagelist id
        let targetPagelistIdsForPagelistId = {}
        Object.values(scData.hotspot).forEach((hs) => {
            if (hs && hs.parent_page_id && !hs.deleted) {
                let srcPlId = pagelistIdForPageId[String(hs.parent_page_id)];
                let targetPlId = hs.target_page_id ? pagelistIdForPageId[String(hs.target_page_id)] : null;
                if (srcPlId && targetPlId) {
                    if (!targetPagelistIdsForPagelistId[String(srcPlId)]) {
                        targetPagelistIdsForPagelistId[String(srcPlId)] = []
                    }
                    targetPagelistIdsForPagelistId[String(srcPlId)].push(targetPlId);
                }
            }
        });

        // in effect here we are navigate to all possible pages via hotspots calculated above
        let walkTree = function (pagelistId) {
            if (walkedPagelistIds.indexOf(pagelistId) > -1) return;  // we already did this one
            walkedPagelistIds.push(pagelistId);
            let targetPagelistIds = targetPagelistIdsForPagelistId[String(pagelistId)];
            if (targetPagelistIds) {
                targetPagelistIds.forEach((targetPagelistId) => {
                    if (targetPagelistId) walkTree(targetPagelistId);
                })
            }
        };
        let walkedPagelistIds = [];
        walkTree(scData.presentationmetadata.root_pagelist_id);

        // now find what pagelists can be navigated to
        let unusedPagelistIds = [];
        Object.values(scData.pagelist).forEach((pl) => {
            if (!walkedPagelistIds.includes(pl.id)) unusedPagelistIds.push(pl.id);
        });
        return unusedPagelistIds;
    },

    scDataUnusedPageIds: (scData) => {
        //console.log('scDataUnusedPageIds');
        let unusedPageIds = [];
        let unusedPagelistIds = PresentationUtils.scDataUnusedPagelistIds(scData);
        Object.values(scData.page).forEach((page) => {
            if (unusedPagelistIds.includes(page.pagelist_id)) unusedPageIds.push(page.id);
        });
        return unusedPageIds;
    }


}

export default PresentationUtils;