<template>
    <div class="four-corners" v-if="edMxCurrentPage">
        <a v-if="edMxCurrentPage.id === edMxRootPage.id"
           href="#" @click.prevent="edMxNavToPage(null)"><img alt="Home"
            :src="mainMxCdn('/showcase-icons/5.0.0/home_button.png')"
            class="corner corner-tl"
            :style="cornerTLStyleObject"></a>
        <a v-if="edMxCurrentPage.id !== edMxRootPage.id"
           href="#" @click.prevent="goBack()"><img alt="Back"
            :src="mainMxCdn('/showcase-icons/5.0.0/back_button.png')"
            class="corner corner-tl"
            :style="cornerTLStyleObject"></a>
        <a href="#" @click.prevent="cornerTRClick()"><img alt="Share"
            :src="mainMxCdn('/showcase-icons/5.0.0/share_button.png')"
            class="corner corner-tr"
            :style="cornerTRStyleObject"></a>
        <a href="#" @click.prevent="cornerBLClick()"><img alt="Drawer"
            :src="mainMxCdn('/showcase-icons/5.0.0/menu_button.png')"
            class="corner corner-bl"
            :style="cornerBLStyleObject"></a>

        <a v-if="showNextPrev && prevPage" href="#" @click.prevent="edMxNavToPage(prevPage.id)">
            <img alt="Previous"
                 :src="mainMxCdn('/showcase-icons/7.1.1/slideimages_left.png')"
                 class="corner" :style="prevStyleObject" /></a>

        <a v-if="showNextPrev && nextPage" href="#" @click.prevent="edMxNavToPage(nextPage.id)">
            <img alt="Next"
                 :src="mainMxCdn('/showcase-icons/7.1.1/slideimages_right.png')"
                 class="corner" :style="nextStyleObject" /></a>


    </div>
</template>

<script>

    import MainAppMixin from "../MainAppMixin";
    import PresentationShareable from "../presentation/PresentationShareable.vue";
    import EditorMixin from "./EditorMixin";


    export default {
        name: "EditorFourCorners",
        mixins: [MainAppMixin, EditorMixin],
        props: [],
        data() {
            return {

            }
        },
        methods: {
            goBack() {
                this.$store.commit('edSetPage', null);
            },
            cornerTRClick() {
                if (this.edMxCurrentPage)
                    PresentationShareable.modal(this.edMxStateShowcase.presentation.id,
                        this.edMxCurrentPage.id, this.edMxCurrentPage.alpha_num_name, null);
            },
            cornerBLClick() {
                // should we do something here?
            },
        },

        computed: {
            cornerImgScale() {
                let scale = 1;
                if (this.edMxStateShowcase && this.edMxStateShowcase.presentation) {
                    let maxDim = Math.max(this.edMxStateShowcase.presentation.width,
                        this.edMxStateShowcase.presentation.height);
                    // We want corner img to be 100x100 when device is 2048x1024 (or 1024x2048)
                    // We want corner img to be 50x50 when device is 1024x768 (or 786x1024)
                    // all dimensions in this file are stated as if canvas was at 2048
                    scale = maxDim/2048;
                    //console.log('deviceScaleRatio', maxDim, scale);
                }
                return scale;
            },
            cornerTLStyleObject() {
                return {
                    margin: (20 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    width: (100 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    height: (100 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px'
                }
            },
            cornerTRStyleObject() {
                return {
                    margin: (20 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    width: (100 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    height: (100 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    right: '0px'
                }
            },
            cornerBLStyleObject() {
                return {
                    margin: (20 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    width: (100 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    height: (100 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    top: ((this.edMxStateShowcase.presentation.height - ((20 + 100 + 20) * this.cornerImgScale))
                        * this.edMxStateEd.scaleRatio) + 'px'
                }
            },
            prevPage() {
                let prev = null;
                if (this.edMxCurrentPage) {
                    let pages = this.$store.getters.presPagesForPagelist(this.edMxCurrentPage.pagelist_id);
                    let currentIdx = pages.indexOf(this.edMxCurrentPage);
                    if (currentIdx > 0) prev = pages[currentIdx-1];
                }
                return prev;
            },
            nextPage() {
                let next = null;
                if (this.edMxCurrentPage) {
                    let pages = this.$store.getters.presPagesForPagelist(this.edMxCurrentPage.pagelist_id);
                    let currentIdx = pages.indexOf(this.edMxCurrentPage);
                    if (currentIdx+1 < pages.length) {
                        next = pages[currentIdx+1];
                    }
                }
                return next;
            },
            showNextPrev() {
                // note if viewer_arrows_enabled is null we should not show (matching ViewerEmbedHandler.shouldDisplayNextPrevArrowsWhenSettingUndefined)
                return this.edMxStateShowcase.presentation.viewer_arrows_enabled === true;
            },
            prevStyleObject() {
                return {
                    margin: (20 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    width: (36 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    height: (100 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    top: (((this.edMxStateShowcase.presentation.height/2) - (((20 + 36 + 100 + 20)/2) * this.cornerImgScale))
                        * this.edMxStateEd.scaleRatio) + 'px'
                }
            },
            nextStyleObject() {
                return {
                    margin: (20 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    width: (36 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    height: (100 * this.cornerImgScale * this.edMxStateEd.scaleRatio) + 'px',
                    top: (((this.edMxStateShowcase.presentation.height/2) - (((20 + 36 + 100 + 20)/2) * this.cornerImgScale))
                        * this.edMxStateEd.scaleRatio) + 'px',
                    right: '0px'
                }
            },
        },

        watch: {

        }

    }

</script>

<style>

    .four-corners {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .corner {
        z-index: 100;  /* see main/ui/README.md for all z-index values */
        opacity: 0.2;
        position: absolute;
        border: none;
    }

    .corner:hover {
        opacity: 0.5;
    }

</style>