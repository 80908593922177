
import _ from 'underscore'; // findWhere, contains
import $ from 'jquery';
import ScNotification from '../shared/common/ScNotification.vue';

let _state = {
    loadedFonts: [],
    loadingFonts: false,
    disableFontsWeight: [],
    sysFonts: [],
    gwfFonts: []
};

let injectIntoPage = function(state) {
    // user defined fonts
    let styleLines = [];  // custom ttf fonts
    for (let font of state.loadedFonts) {
        if (!_.contains(state.gwfFonts, font.name) && !_.contains(state.sysFonts, font.name)) {
            styleLines.push('@font-face { /* ' + font.name + ' */' +
                ' font-family: "sc-font-' + font.id + '"; font-weight:normal; font-style:normal;' +
                ' src: url(\'' + font.url + '\') format(\'truetype\'); font-display: swap; }');
        }
    }
    $('#injectedFontCss').remove();
    if (styleLines.length > 0) {
        $('head').append($('<style>', { id: 'injectedFontCss', type:"text/css" }).text(styleLines.join('\n')));
    }

    // now load fonts css
    $('#injectedFontLink').remove();
    $('head').append($('<link>', {
        id: 'injectedFontLink', href: "https://cdn.showcaseworkshop.com/showcase-fonts/1.0.3/fonts-woff.css", rel: "stylesheet", type: "text/css"
    }));
};

let _getters = {
    // should always start with "fonts" to avoid collisions with other modules

    fontsFontForId: (state) => (id) => {
        if (!state.loadedFonts || state.loadedFonts.length === 0 ) return 'Arial, sans-serif';
        let font = _.findWhere(state.loadedFonts, {id: id});
        if (!font) font = _.findWhere(state.loadedFonts, {name: 'Arial'});
        return font;
    },

    fontsDefaultFont: (state) => {
        return _.findWhere(state.loadedFonts, {name: 'Arial'});
    },

    fontsNormalizeFontSize: () => (fontSize) => {
        //console.log('normalizeFontSize', fontSize);
        if (!fontSize) {
            return 24;
        }
        let legacyFontSizes = {'small': 18, 'medium': 24, 'large': 36};
        if (legacyFontSizes[fontSize]) {
            return legacyFontSizes[fontSize];
        }
        if (/^\+?(0|[1-9]\d*)$/.test(fontSize)) {
            let fontSizeInt = Number(fontSize);
            if (fontSizeInt < 1) fontSizeInt = 1;
            if (fontSizeInt > 999) fontSizeInt = 999;
            return fontSizeInt;
        }
        return 24;
    },

    fontsSelectable: (state) => {
        return state.loadedFonts.filter((font) => {
            return font.name !== 'Museo Slab'; // skip buggy font Museo Slab
        })
    }

};


let _actions = {

    fontsFetch(context, workshopId) {
        return new Promise((resolve) => {
            $.ajax({
                url: '/main/editor/ajax_get_font_resources',
                data: {workspace_id: workshopId}
            }).done(function (data) {
                context.commit('fontsSetFonts', data);
                resolve();
            }).fail(function (jqXhr) {
                context.commit('fontsSetFonts', null);
                resolve();
                ScNotification.growlXhrError(jqXhr, 'loading fonts');
            });
        });
    }

};


let _mutations = {

    fontsSetFonts: function(state, data) {
        state.loadedFonts.splice(0);
        state.disableFontsWeight.splice(0);
        if (data && data.disable_fonts_weight) {
            data.disable_fonts_weight.forEach((font) => {
                state.disableFontsWeight.push(font);
            });
        }
        state.sysFonts.splice(0);
        if (data && data.sys_fonts) {
            data.sys_fonts.forEach((font) => {
                state.sysFonts.push(font);
            });
        }
        state.gwfFonts.splice(0);
        if (data && data.gwf_fonts) {
            data.gwf_fonts.forEach((font) => {
                state.gwfFonts.push(font);
            });
        }
        if (data && data.font_resources) {
            data.font_resources.forEach(function (font) {
                font.extraCssClass = '';
                let isCustomFont = font.name.toLowerCase().indexOf('.ttf') > -1;
                if (isCustomFont) {
                    font.extraCssClass += ' sc-font-disable-style';
                }
                if (_.contains(state.disableFontsWeight, font.name) || isCustomFont) {
                    font.extraCssClass += ' sc-font-disable-weight';
                }
                font.cssFamily = '"' + font.name + '", sans-serif';
                if (!_.contains(state.gwfFonts, font.name) && !_.contains(state.sysFonts, font.name)) {
                    font.cssFamily = 'sc-font-' + font.id + ', sans-serif';
                }
                state.loadedFonts.push(font);
            });
        }
        injectIntoPage(state);
    },


};

export default {
    state: _state,
    getters: _getters,
    actions: _actions,
    mutations: _mutations
};