
import constants from '../constants'
import PresentationUtils from './PresentationUtils'


let _state = {

    workshopId: null,

    selectedHotspotIds: [],

    currentPageId: null,
    navHistoryPages: [],
    scaleRatio: constants.DEFAULT_SCALE_RATIO,
    currentDeviceImageCode: 'best',

    textEditHotspotId: null,
    loadPageOnSaveGuid: null,

    textToolbar: {
        boldActive: false,
        italicActive: false,
        underlineActive: false,
        strikethroughActive: false,
        superscriptActive: false,
        subscriptActive: false
    },

    hotspotImageToolbar: {
        zoomSliderVal: 100,
        cropXSliderVal: 0,
        cropYSliderVal: 0
    },

    deviceChassisImages: {  // effectively a constant but we might want to load remotely
        'hd-landscape': {
            screenWidth: 2048, screenHeight: 1532,
            imgPath: '/showcase-chassis/7.1.1/4x3-full-landscape.png', imgWidth: 2608, imgHeight: 1856
        }, 'hd-portrait': {
            screenWidth: 1532, screenHeight: 2048,
            imgPath: '/showcase-chassis/7.1.1/4x3-full-portrait.png', imgWidth: 1856, imgHeight: 2608
        }, 'ipad-landscape': {
            screenWidth: 1024, screenHeight: 768,
            imgPath: '/showcase-chassis/7.1.1/4x3-full-landscape.png', imgWidth: 2608*.5, imgHeight: 1856*.5
        }, 'ipad-portrait': {
            screenWidth: 768, screenHeight: 1024,
            imgPath: '/showcase-chassis/7.1.1/4x3-full-portrait.png', imgWidth: 1856*.5, imgHeight: 2608*.5
        }, 'android-tablet-landscape': {
            screenWidth: 2048, screenHeight: 1532,
            imgPath: '/showcase-chassis/7.1.1/16x9-full-landscape.png', imgWidth: 1680, imgHeight: 1152
        }, 'android-tablet-portrait': {
            screenWidth: 2048, screenHeight: 1532,
            imgPath: '/showcase-chassis/7.1.1/16x9-full-portrait.png', imgWidth: 1152, imgHeight: 1680
        }, 'hd-16by9-landscape': {
            screenWidth: 2048, screenHeight: 1532,
            imgPath: '/showcase-chassis/7.1.1/4x3-full-landscape.png', imgWidth: 2608, imgHeight: 1856
        }, 'hd-16by9-portrait': {
            screenWidth: 2048, screenHeight: 1532,
            imgPath: '/showcase-chassis/7.1.1/4x3-full-portrait.png', imgWidth: 1856, imgHeight: 2608
        }, 'surface-pro3-landscape': {
            screenWidth: 2048, screenHeight: 1532,
            imgPath: '/showcase-chassis/7.1.1/3x2-full-landscape.png', imgWidth: 1680, imgHeight: 1152
        }, 'surface-pro3-portrait': {
            screenWidth: 2048, screenHeight: 1532,
            imgPath: '/showcase-chassis/7.1.1/3x2-full-portrait.png', imgWidth: 1152, imgHeight: 1680
        }
    }
};



let _getters = {
    // should always start with "ed" to avoid collisions with other modules

    edGetDeviceChassisImage: (state) => (layoutCode) => {
        let img = null;
        if (state.currentDeviceImageCode === 'best') {
            img = PresentationUtils.getDeviceChassisImage(layoutCode);
        }
        return img;
    },
};


let _actions = {
    // for editor related stuff we can just skip actions and use mutations (eg, no ajax needed)
};


let _mutations = {
    edSetPage(state, page) {
        //console.log('$store/ed/mutations/edSetPage', pageId);
        state.selectedHotspotIds.splice(0);
        state.textEditHotspotId = null;
        if (page === null) {  // go back
            page = state.navHistoryPages[0];
            if (state.navHistoryPages.length > 1) {
                state.navHistoryPages.pop(); // remove current page
                while(state.navHistoryPages[state.navHistoryPages.length -1].deleted) {  // remove deleted pages
                    state.navHistoryPages.pop(); // remove deleted page
                }
                page = state.navHistoryPages[state.navHistoryPages.length -1];  // grab last
            }
        }   else {
            let lastVisitedPage = null;
            if (state.navHistoryPages.length > 0) {
                lastVisitedPage = state.navHistoryPages[state.navHistoryPages.length-1];  // last is current page
            }
            if (!lastVisitedPage || page.pagelist_id !== lastVisitedPage.pagelist_id) {
                state.navHistoryPages.push(page);
            }
        }
        state.currentPageId = page.id;
        // note, undo buffer is cleared on slide change in pres.js
    },
    edSelectedHotspotIdsReset(state) {
        state.selectedHotspotIds.splice(0);
    },
    edSelectedHotspotIdsRemove(state, id) {
        let hsIdx = state.selectedHotspotIds.indexOf(id);
        if (hsIdx !== -1) state.selectedHotspotIds.splice(hsIdx, 1);
    },
    edSelectedHotspotIdsAdd(state, ids) {
        state.selectedHotspotIds.push(...ids);
    },
    edSetTextEditHotspotId(state, hotspotId) {
        //console.log('edSetTextEditHotspotId');
        state.textEditHotspotId = hotspotId;
    },
    edSetTextToolbarFormatState(state, formatState) {
        //console.log('edSetTextToolbarFormatState', formatState);
        let tt = state.textToolbar;
        tt.boldActive = formatState.boldActive;
        tt.italicActive = formatState.italicActive;
        tt.underlineActive = formatState.underlineActive;
        tt.strikethroughActive = formatState.strikethroughActive;
        tt.superscriptActive = formatState.superscriptActive;
        tt.subscriptActive = formatState.subscriptActive;
    },
    edSetScaleRatio(state, scaleRatio) {
        //console.log('setSetScaleRatio', state, scaleRatio);
        state.scaleRatio = scaleRatio;
    },
    edSetDeviceImage(state) {
        state.currentDeviceImageCode = state.currentDeviceImageCode ? null : 'best';
    },
    edReset(state) {
        state.selectedHotspotIds.splice(0);
        state.currentPageId = null;
        state.scaleRatio = constants.DEFAULT_SCALE_RATIO;
        state.currentDeviceImageCode = 'best';
        state.textEditHotspotId = null;
        state.loadPageOnSaveGuid = null;
        state.navHistoryPages.splice(0);
    },
    edSetHotspotImageToolbarZoomSliderVal(state, zoomSliderVal) {
        state.hotspotImageToolbar.zoomSliderVal = zoomSliderVal;
    },
    edSetHotspotImageToolbarCropXSliderVal(state, cropXSliderVal) {
        if (isNaN(cropXSliderVal)) throw Error('cropXSliderVal is NaN, invalid');
        state.hotspotImageToolbar.cropXSliderVal = cropXSliderVal;
    },
    edSetHotspotImageToolbarCropYSliderVal(state, cropYSliderVal) {
        state.hotspotImageToolbar.cropYSliderVal = cropYSliderVal;
    },
    edSetHotspotImageToolbarSliders(state, sliderVals) {
        if (isNaN(sliderVals.cropXSliderVal)) throw Error('sliderVals.cropXSliderVal is NaN, invalid');
        state.hotspotImageToolbar.zoomSliderVal = sliderVals.zoomSliderVal;
        state.hotspotImageToolbar.cropXSliderVal = sliderVals.cropXSliderVal;
        state.hotspotImageToolbar.cropYSliderVal = sliderVals.cropYSliderVal;
    }

};


export default {
    state: _state,
    getters: _getters,
    actions: _actions,
    mutations: _mutations
};
