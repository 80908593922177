<template>
    <div></div>
</template>

<script>

    import _ from 'underscore' // debounce
    import $ from 'jquery'
    import EditorMixin from './EditorMixin'
    import HotspotUtils from '../../store/HotspotUtils'


    export default {
        name: "EditorKeyboardShortcuts",
        inject: ['edMnNewHotspotThatTargetsDocument'],
        mixins: [EditorMixin],
        data: function() {
            return {
                lastCopiedHotspotIds: []
            }
        },
        mounted: function() {
            //console.log('EditorKeyboardShortcuts mounted');
            window.addEventListener('keydown', this.handleKeyDown, false);
        },
        beforeUnmount: function () {
            //console.log('EditorKeyboardShortcuts beforeUnmount');
            window.removeEventListener('keydown', this.handleKeyDown, false);
        },
        methods: {
            handleKeyDown: function(e) {
                //console.log('EditorKeyboardShortcuts', e.target.tagName, e.target);
                if ($('.dropdown-menu.show').length > 0  // dropdown is shown
                        || $('.scPresDiv').length > 0  // showcase preview is shown
                        || $('.modal.show').length > 0 || $('.sc-modal-mask').length > 0  // modal is shown
                        || $('input:focus').length > 0 || $('textarea:focus').length > 0) {  //input/textarea focused
                    return;  // ignore the event
                }
                let edMainEl = document.getElementById('editorMain');
                if (!e.target || !edMainEl.contains) return;
                if (!(e.target.tagName === 'BODY' || edMainEl.contains(e.target))) {
                    return;  // ignore if target is not body or child of editor
                }

                //console.log('EditorKeyboardShortcuts2', e.key, e.code, e.target, e.ctrlKey, e.shiftKey);
                if (!this.edMxCurrentPage) return;
                if (this.edMxStateEd.textEditHotspotId) return;

                let ctrlOrCmd = (e.ctrlKey||e.metaKey);
                let altKey = (e.altKey);
                if ((e.code === 'KeyZ' || e.key === 'z') && !e.shiftKey && ctrlOrCmd) {  // ctrl/cmd Z
                    this.handleUndo(e);
                }
                if ((e.code === 'KeyZ' || e.key === 'z') && e.shiftKey && ctrlOrCmd) {  // ctrl/cmd shift Z
                    this.handleRedo(e);
                }

                if ((e.code === 'KeyV' || e.key === 'v') && ctrlOrCmd) this.handlePaste(); // ctrl/cmd V

                if ((e.code === 'KeyB' || e.key === 'b') && altKey) this.handleNewBlankHotspot(e); // alt/opt B

                if ((e.code === 'KeyW' || e.key === 'w') && altKey) this.handleNewTextHotspot(e); // alt/opt W

                if ((e.code === 'KeyP' || e.key === 'p') && altKey) this.handleNewDocumentHotspot(e); // alt/opt P

                if (this.edMxStateEd.selectedHotspotIds.length > 0) {
                    if ((e.code === 'KeyC' || e.key === 'c') && ctrlOrCmd) this.handleCopy();  // ctrl/cmd C
                    if (e.key === 'Escape') this.handleDeselectHotspots();
                    if (e.key === 'Backspace' || e.key === 'Delete') this.handleDeleteHotspots(e);
                    if (e.key === 'ArrowUp' || e.key === 'Up') this.handleNudgeHotspot('up', e);
                    if (e.key === 'ArrowDown' || e.key === 'Down') this.handleNudgeHotspot('down', e);
                    if (e.key === 'ArrowLeft' || e.key === 'Left') this.handleNudgeHotspot('left', e);
                    if (e.key === 'ArrowRight' || e.key === 'Right') this.handleNudgeHotspot('right', e);

                    if (!this.edMxStateEd.textEditHotspotId && !e.metaKey && !ctrlOrCmd && !altKey) {
                        let charTyped = e.key;
                        //console.log('charTyped', charTyped);
                        if (charTyped && charTyped.length === 1) {
                            this.handleChangeToTextMode();
                        }
                    }
                }
            },

            handleCopy: function() {
                if (this.edMxStateEd.selectedHotspotIds.length > 0 && !this.edMxLoneSelectedHotspotLocked) {
                    this.lastCopiedHotspotIds.splice(0);
                    this.lastCopiedHotspotIds.push(...this.edMxStateEd.selectedHotspotIds);
                }
            },

            handlePaste: function() {
                if (this.lastCopiedHotspotIds.length > 0 && this.edMxStateEd.currentPageId) {
                    this.edMxDuplicateHostposts(this.lastCopiedHotspotIds);
                }
            },

            handleUndo: function(e) {
                e.preventDefault();
                e.stopPropagation();
                if (this.$store.getters.undoCanUndo) {
                    this.$store.commit('edSelectedHotspotIdsReset');
                    this.$store.dispatch('undoUndoAction');
                }
            },

            handleRedo: function(e) {
                e.preventDefault();
                e.stopPropagation();
                if (this.$store.getters.undoCanRedo) {
                    this.$store.commit('edSelectedHotspotIdsReset');
                    this.$store.dispatch('undoRedoAction');
                }
            },

            handleDeselectHotspots: function() {
                if (this.edMxStateEd.textEditHotspotId) {
                    this.$store.commit('edSetTextEditHotspotId', null);
                }   else {
                    this.$store.commit('edSelectedHotspotIdsReset');
                }
            },

            handleNewBlankHotspot: function(e) {
                e.preventDefault();
                e.stopPropagation();
                if (!this.edMxCurrentPage) return;
                this.edMxNewHotspot(null, null, null, false);
            },

            handleNewTextHotspot: function(e) {
                if (!this.edMxCurrentPage) return;
                e.preventDefault();
                e.stopPropagation();
                this.edMxNewHotspot(null, null, null, true);
            },

            handleNewDocumentHotspot: function(e) {
                if (!this.edMxCurrentPage) return;
                e.preventDefault();
                e.stopPropagation();
                this.edMnNewHotspotThatTargetsDocument();
            },

            handleChangeToTextMode: function() {
                if (this.edMxStateEd.selectedHotspotIds.length === 1) {
                  this.$store.commit('edSetTextEditHotspotId', this.edMxStateEd.selectedHotspotIds[0]);
                }
            },

            handleDeleteHotspots: _.debounce(function(e) {
                //console.log('EditorKeyboardShortcuts/methods/handleDeleteHotspots', this.edMxSelectedHotspots);
                if (this.edMxSelectedHotspots.length > 0 && !this.edMxLoneSelectedHotspotLocked) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.edMxDeleteSelectedHotspots();
                }
            }, 500, true),

            handleNudgeHotspot: function(direction, e) {
                let bigNudge = !e.shiftKey;  // we want the undo buffer to have big jumps in it (not full of 1px adjustments
                //console.log('EditorKeyboardShortcuts/methods/handleNudgeHotspot', direction, bigNudge);
                if (this.edMxSelectedHotspots.length > 0 && !this.edMxLoneSelectedHotspotLocked) {
                    e.preventDefault();
                    e.stopPropagation();

                    let boundsAdjAmount = 1;
                    if (bigNudge) boundsAdjAmount = 10;

                    let hsBatch = [];
                    this.edMxSelectedHotspots.forEach((hotspot) => {
                      let hs = Object.assign({}, hotspot);
                      if (hs.is_locked) return;
                      let boundsObj = HotspotUtils.boundsStrToObj(hs.bounds);
                      if (direction === 'left') boundsObj.hsLeft = boundsObj.hsLeft - boundsAdjAmount;
                      if (direction === 'right') boundsObj.hsLeft = boundsObj.hsLeft + boundsAdjAmount;
                      if (direction === 'up') boundsObj.hsTop = boundsObj.hsTop - boundsAdjAmount;
                      if (direction === 'down') boundsObj.hsTop = boundsObj.hsTop + boundsAdjAmount;
                      //console.log('boundsObj', boundsObj);
                      hs.bounds = HotspotUtils.boundsObjToStr(boundsObj);
                      hsBatch.push(hs);
                    });
                    this.$store.dispatch('presUndoableSaveHotspotBatch', hsBatch);
                }
            }
        }

    }

</script>
