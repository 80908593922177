
const UNDO_BUFFER_MAX = 100;

let _dataManager = null;


let _state = {
    undoBufferIdx: 0,
    undoBuffer: []
};


let _getters = {

    // should always start with undo

    undoCanUndo: (state) => {
        //console.log('ret', undoState);
        return state.undoBuffer.length > 0 && state.undoBufferIdx > 0;
    },
    undoCanRedo: (state) => {
        return state.undoBuffer.length > 0 && state.undoBufferIdx < state.undoBuffer.length
    },
};


let _actions = {

    // should always start with undo

    undoExecStep: function(context, opts) {
        _dataManager = opts.dataManager;
        if (opts.addStep) {
            context.commit('undoAddUndoStep', opts.undoStep);
        }
        //console.log('$store/pres/actions/execStep', undoStep, values);
        context.dispatch('presSavePending');
        opts.undoStep.operations.forEach((operation) => {
            let value = operation[opts.valueKey];
            //console.log('$store/undo/actions/execStep value', value);
            if (operation.dataManagerCmd) opts.dataManager[operation.dataManagerCmd](value, context);
            context.commit(operation.mutationCmd, value, { root: true });
        });
    },

    undoUndoAction: function(context) {
        let undoState = context.state;
        //console.log('$store/pres/actions/presUndoAction idx', undoState.undoBufferIdx, undoState.undoBuffer);
        if (undoState.undoBufferIdx === 0) return;
        context.commit('undoSetUndoBufferIdx', undoState.undoBufferIdx - 1);
        let undoStep = undoState.undoBuffer[undoState.undoBufferIdx];
        context.dispatch('undoExecStep', {
            undoStep: undoStep, valueKey: 'oldValue', dataManager: _dataManager, addStep: false});
    },

    undoRedoAction: function(context) {
        let undoState = context.state;
        //console.log('store/pres/actions/presRedoAction idx', undoState.undoBufferIdx, undoState.undoBuffer);
        if (undoState.undoBufferIdx > undoState.undoBuffer.length) return;
        let undoStep = undoState.undoBuffer[undoState.undoBufferIdx];
        context.dispatch('undoExecStep', {
            undoStep: undoStep, valueKey: 'newValue', dataManager: _dataManager, addStep: false});
        context.commit('undoSetUndoBufferIdx', undoState.undoBufferIdx + 1);
    },

};


let _mutations = {

    undoAddUndoStep(state, undoStep) {
        let undoState = state;
        //console.log('$store/pres/mutations/presAddUndoStep', undoState.undoBufferIdx);
        if (undoState.undoBufferIdx < undoState.undoBuffer.length) {
            //console.log('adding but items after this, trim out', undoState.undoBuffer.length, 'idx', undoState.undoBufferIdx, undoState.undoBuffer.length-state.undoBufferIdx);
            undoState.undoBuffer.splice(undoState.undoBufferIdx, undoState.undoBuffer.length - undoState.undoBufferIdx);
        }
        undoState.undoBuffer[undoState.undoBufferIdx] = undoStep;
        undoState.undoBufferIdx = undoState.undoBufferIdx + 1;

        if (undoState.undoBuffer.length > UNDO_BUFFER_MAX) {
            //console.log('too long drop one');
            undoState.undoBufferIdx = undoState.undoBufferIdx - 1;
            undoState.undoBuffer.splice(0, 1);
        }
    },

    undoSetUndoBufferIdx(state, undoBufferIdx) {
        //console.log('$store/pres/mutations/presSetUndoBufferIdx', undoBufferIdx);
        state.undoBufferIdx = undoBufferIdx;
    },




// mutations from ed.js

    edSetPage(state) {
        state.undoBufferIdx = 0;
        state.undoBuffer.splice(0);
    },

    updatePres(state) {
        state.undoBufferIdx = 0;
        state.undoBuffer.splice(0);
    }


};


export default {
    state: _state,
    getters: _getters,
    actions: _actions,
    mutations: _mutations
};
