<template>
  <div class="container-fluid">

    <div class="row">
      <div class="col-1 px-0 pt-2">
        <!-- -->
      </div>
      <div class="col-11">
        <h4>Resize Presentation</h4>
        <div>

          <p>Choose different screen dimensions for this presentation.</p>

          <p><ScIcon name="questionCircle" /> Background images may scroll or be letterboxed once resized.
            Hotspots stay in the same fixed position relative to the top left corner of the presentation. You can move
            hotspots and replace slide backgrounds after resizing.</p>

          <div v-if="currentLayout">
            <div class="row mb-3">
              <label for="rz-proposed-code" class="col-3 col-form-label">Resize to</label>
              <div class="col-8">
                <select id="rz-proposed-code" class="form-select" v-model="proposedCode"
                        :disabled="resizeLoading || resourceCheckLoading || reloadingEditor">
                  <option v-for="pl in availableLayouts" :key="pl.code"
                          :value="pl.code" :selected="pl.code === proposedCode">
                    {{pl.title}} ({{ pl.width }} x {{ pl.height }})
                    <span v-if="pl.code === currentLayout.code"> (current)</span>
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-3"></div>
              <div class="col-8">
                <button type="button" class="btn btn-outline-secondary me-1 mb-2"
                  :disabled="resizeLoading || resourceCheckLoading || reloadingEditor"
                  @click.prevent="resizeNow"><ScIcon name="resizeFW"/> Resize</button>
                <div class="mb-2"><span class="text-danger">Warning: This cannot be undone! Make a copy if you're unsure.</span></div>

                <div v-if="resizeLoading">
                  <ScIcon name="spinnerFW" class="text-muted me-1"/> Updating metadata</div>
                <div v-if="resizeDone">
                  <ScIcon name="check" class="text-success me-1"/> Metadata updated</div>
                <div v-if="resourceCheckLoading">
                  <ScIcon name="spinnerFW" class="text-muted me-1"/> Rebuilding mobile versions and thumbnails</div>
                <div v-if="resourceCheckDone">
                  <ScIcon name="check" class="text-success me-1"/> Mobile versions and thumbnails generated</div>
                <div v-if="rebuildDraftLoading">
                  <ScIcon name="spinnerFW" class="text-muted me-1"/> Rebuilding cache</div>
                <div v-if="rebuildDraftDone">
                  <ScIcon name="check" class="text-success me-1"/> Rebuilt cache</div>
                <div v-if="reloadingEditor">
                  <ScIcon name="spinnerFW" class="text-muted me-1"/> Reloading editor</div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</template>


<script>

import ScNotification from '../../../shared/common/ScNotification.vue';
import $ from 'jquery';
import constants from "../../../constants";
import MainAppMixin from "../../MainAppMixin";
import ScIcon from "../../../shared/common/ScIcon.vue";


export default {
  name: 'PresResize',
  mixins: [MainAppMixin],
  components: { ScIcon },
  props: { },
  data () {
    return {
      proposedCode: null,

      resizeLoading: false,
      resizeDone: false,
      resourcesChecks: 0,
      resourceCheckLoading: false,
      resourceCheckDone: false,
      rebuildDraftLoading: false,
      rebuildDraftDone: false,
      reloadingEditor: false,
      layouts: constants.ALL_PRIMARY_LAYOUTS,
    };
  },
  mounted () {
    if (this.$store.state.pres) {
      this.isShown = true;
    }
  },

  methods: {
    resizeNow() {
      if (this.proposedCode === this.currentLayout.code) {
        ScNotification.growlErrMsg('Current size. Please choose a different size.');
        return;
      }
      this.resizeDone = false;
      this.resourceCheckDone = false;
      this.rebuildDraftDone = false;
      this.resizeLoading = true;
      $.ajax({
        type: 'POST', url: "/main/editor/ajax_pres_resize",
        data: { workspace_id: this.mainMxCurrentWorkshopId, presentation_id: this.scData.presentation.id,
          code: this.proposedCode }
      }).done(() => {
        this.resizeLoading = false;
        this.resizeDone = true;
        this.resourcesCheck();
      }).fail((jqXhr) => {
        ScNotification.growlXhrError(jqXhr, 'trying to resize');
        this.resizeLoading = false;
      });
    },
    resourcesCheck: function() {
      this.resourceCheckDone = false;
      this.resourceCheckLoading = true;
      $.ajax({
        type: 'POST',
        url: "/main/presentations/ajax_check_resources_publishable_v2",
        data: {attempt: this.resourcesChecks+1, workspace_id: this.mainMxCurrentWorkshopId,
          presentation_id: this.scData.presentation.id}
      }).then((data) => {
        if ( data && data.resources_publishable ) {
          this.resourceCheckDone = true;
          this.resourceCheckLoading = false;
          this.draftRecompile();
        } else if (this.resourcesChecks > 10) {
          ScNotification.growlErrMsg('File checking taking too long.  Any issues should be resolved on publish.  Reloading editor.');
          this.resourceCheckLoading = false;
        }   else {
          this.resourcesChecks = this.resourcesChecks +1;
          setTimeout(() => {
            this.resourcesCheck()
          },  this.resourcesChecks * 5 * 1000);  // back off each time
        }
      }).fail((jqXhr) => {
        ScNotification.growlXhrError(jqXhr, 'when checking files');
        this.resourceCheckLoading = false;
      });
    },
  draftRecompile() {
      this.rebuildDraftDone = false;
      this.rebuildDraftLoading = true;
      $.ajax({
        type: 'POST',
        url: "/main/editor/ajax_pres_draft_recompile",
        data: {workspace_id: this.mainMxCurrentWorkshopId, presentation_id: this.scData.presentation.id}
      }).then(() => {
        this.rebuildDraftDone = true;
        this.rebuildDraftLoading = false;
        this.reloadingEditor = true;
        setTimeout(() => {
          this.$store.dispatch('presReload');
        }, 5000);

      }).fail((jqXhr) => {
        ScNotification.growlXhrError(jqXhr, 'when checking files');
        this.rebuildDraftLoading = false;
      });
    },
  },
  computed: {
    scData() {
      return (this.$store.state.pres) ? this.$store.state.pres.showcase : null;
    },
    currentLayout() {
      if (!this.scData) return null;
      return this.layouts.find((pl) => pl.code === this.scData.primary_layout.code);
    },
    availableLayouts() {
      if (!this.currentLayout) return [];
      return this.layouts.filter((pl) => pl.size_type === this.currentLayout.size_type)
    }
  },
  watch: {
    currentLayout: {
      immediate: true, handler() {
        this.proposedCode =  this.currentLayout ? this.currentLayout.code : null;
      },
    }
  }

};


</script>