
let AdditionalStyleObjHelper = {

    addAdditionalHotspotStyles(cssObj, addStylesObj, scaleRatio) {
        if (!addStylesObj) return;
        let scalePx = (d) => (d * scaleRatio) + 'px';
        this._handleBorder(cssObj, addStylesObj, scaleRatio, scalePx);
        this._handleBorderRadius(cssObj, addStylesObj, scalePx);
        this._handleHotspotShadow(cssObj, addStylesObj, scalePx);
    },

    addAdditionalHotspotTextStyles(cssObj, addStylesObj, scaleRatio, hsbObj) {
        if (!addStylesObj) return;
        let scalePx = (d) => (d * scaleRatio) + 'px';
        this._handleUpperCase(cssObj, addStylesObj);
        this._handleLetterSpacing(cssObj, addStylesObj);
        this._handleLineHeight(cssObj, addStylesObj);
        this._handleTextShadow(cssObj, addStylesObj, scalePx);
        this._handleTextHollow(cssObj, addStylesObj, scalePx);
        this._handleHotspotBgOpacity(cssObj, addStylesObj, hsbObj);
        this._handleHotspotPadding(cssObj, addStylesObj, scalePx, hsbObj);
    },

    addAdditionalHotspotBgImgStyles(cssObj, addStylesObj) {
        if (!addStylesObj) return;
        this._handleHotspotOpacity(cssObj, addStylesObj);
    },

    addAdditionalHotspotStylesEditorHighlightBorder(cssObj, addStylesObj, scaleRatio) {
        // to fix highlight color not respecting rounded borders in editor
        if (!addStylesObj) return;
        let scalePx = (d) => (d * scaleRatio) + 'px';
        this._handleBorderRadius(cssObj, addStylesObj, scalePx);
        this._handleBorder(cssObj, addStylesObj, scaleRatio, scalePx);
    },

    _handleHotspotPadding(cssObj, addStylesObj, scalePx, hsbObj) {
        // default is closeish to legacy logic "hsPadding = this.hsb.font_size > 36 ? ((6.5 * this.fontScaleRatio) * this.scaleRatio) + 'px' : '0.7em';"
        let defaultHsPadding = hsbObj.font_size && hsbObj.font_size > 36 ? 'sm' : 'md';
        let hsPadding = addStylesObj.hotspot_padding ? addStylesObj.hotspot_padding : defaultHsPadding;
        if (hsPadding === 'off') {
            cssObj.padding = '0px';
        } else if (hsPadding === 'sm') {
            cssObj.padding = scalePx(4);  // bootstrap p-1 = 4px
        } else if (hsPadding === 'md') {
            cssObj.padding = scalePx(16);  // bootstrap p-3 = 16px
        } else if (hsPadding === 'lg') {
            cssObj.padding = scalePx(36);  // half way between bootstrap p-4 = 24px and p-5 = 48px
        }
    },

    _handleHotspotOpacity(cssObj, addStylesObj) {
        if (!addStylesObj.hotspot_opacity) return;
        if (addStylesObj.hotspot_opacity === '0') {
            cssObj.opacity = '0 !important';
        } else if (addStylesObj.hotspot_opacity === '25') {
            cssObj.opacity = '.25 !important';
        } else if (addStylesObj.hotspot_opacity === '50') {
            cssObj.opacity = '.5 !important';
        } else if (addStylesObj.hotspot_opacity === '75') {
            cssObj.opacity = '.75 !important';
        }
    },

    _handleHotspotBgOpacity(cssObj, addStylesObj, hsbObj) {
        // update background color if opacity is set
        if (!addStylesObj.hotspot_opacity) return;
        if (hsbObj.background_colour === 'transparent') {
            cssObj.backgroundColor = hsbObj.background_colour;
            return;
        }
        let opacityHex = this.getHexOpacityValue(addStylesObj);
        cssObj.backgroundColor = hsbObj.background_colour + opacityHex;
    },

    _handleBorder(cssObj, addStylesObj, scaleRatio){
        let opacityHex = this.getHexOpacityValue(addStylesObj);
        let borderColor = (addStylesObj.border_color) ? addStylesObj.border_color + opacityHex : '#000000' + opacityHex;

        if (!addStylesObj.border_width) return;
        let borderWidthNum = AdditionalStyleObjHelper.getBorderWidthValue(addStylesObj, scaleRatio);
        if (borderWidthNum > 0) {
            cssObj.border = borderWidthNum + 'px solid ' + borderColor + ' !important';
        }
    },

    _handleBorderRadius(cssObj, addStylesObj, scalePx) {
        if (!addStylesObj.border_radius) return;
        if (addStylesObj.border_radius === 'sm') {
            cssObj.borderRadius = scalePx(8) + ' !important';
        } else if (addStylesObj.border_radius === 'md') {
            cssObj.borderRadius = scalePx(14) + ' !important';
        } else if (addStylesObj.border_radius === 'lg') {
            cssObj.borderRadius = scalePx(18) + ' !important';
        }
    },

    _handleHotspotShadow(cssObj, addStylesObj, scalePx) {
        if (!addStylesObj.hotspot_shadow) return;
        // h-offset v-offset blur spread
        if (addStylesObj.hotspot_shadow === 'sm') {
            // bootstrap orig style: rgba(0, 0, 0, 0.075) 0px 2px 4px 0px
            cssObj.boxShadow = 'rgba(0, 0, 0, 0.2) 0px ' + scalePx(2) + ' ' + scalePx(4) + ' 0px !important';
        } else if (addStylesObj.hotspot_shadow === 'md') {
            // bootstrap orig style: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px
            cssObj.boxShadow = 'rgba(0, 0, 0, 0.2) 0px ' + scalePx(6) + ' ' + scalePx(12) + ' 0px !important';
        } else if (addStylesObj.hotspot_shadow === 'lg') {
            // bootstrap orig style: rgba(0, 0, 0, 0.176) 0px 16px 48px 0px
            cssObj.boxShadow = 'rgba(0, 0, 0, 0.2) 0px ' + scalePx(12) + ' ' + scalePx(24) + ' 0px !important';
        }
    },

    _handleUpperCase(cssObj, addStylesObj) {
        if (!addStylesObj.uppercase) return;
        if (addStylesObj.uppercase === 'on') {
            cssObj.textTransform = 'uppercase';
        }
    },

    _handleLetterSpacing(cssObj, addStylesObj) {
        if (!addStylesObj.letter_spacing) return;
        // copied from tailwind ??? bootstrap???
        if (addStylesObj.letter_spacing === 'xs') {
            cssObj.letterSpacing = '-.1em !important';
        } else if (addStylesObj.letter_spacing === 'sm') {
            cssObj.letterSpacing = '-.05em !important';
        } else if (addStylesObj.letter_spacing === 'md') {
            cssObj.letterSpacing = '0 !important';
        } else if (addStylesObj.letter_spacing === 'lg') {
            cssObj.letterSpacing = '.05em !important';
        } else if (addStylesObj.letter_spacing === 'xl') {
            cssObj.letterSpacing = '.1em !important';
        }
    },

    _handleLineHeight(cssObj, addStylesObj) {
        if (!addStylesObj.line_height) return;
        if (addStylesObj.line_height === 'xs') {
            cssObj.lineHeight = '1 !important';
        } else if (addStylesObj.line_height === 'sm') {
            cssObj.lineHeight = '1.25 !important';
        } else if (addStylesObj.line_height === 'md') {
            cssObj.lineHeight = '1.5 !important';
        } else if (addStylesObj.line_height === 'lg') {
            cssObj.lineHeight = '1.75 !important';
        } else if (addStylesObj.line_height === 'xl') {
            cssObj.lineHeight = '2 !important';
        }
    },

    _handleTextShadow(cssObj, addStylesObj, scalePx) {
        if (!addStylesObj.text_shadow) return;
        if (addStylesObj.text_shadow === 'sm') {
            cssObj.textShadow = scalePx(3) + ' ' + scalePx(3) + ' 0px rgba(0,0,0,0.2) !important';
        } else if (addStylesObj.text_shadow === 'md') {
            cssObj.textShadow = scalePx(5) + ' ' + scalePx(5) + ' ' + scalePx(2) + ' rgba(0,0,0,0.2) !important';
        } else if (addStylesObj.text_shadow === 'lg') {
            cssObj.textShadow = scalePx(8) + ' ' + scalePx(8) + ' ' + scalePx(4) + ' rgba(0,0,0,0.2) !important';
        }
    },

    _handleTextHollow(cssObj, addStylesObj, scalePx) {
        if (!addStylesObj.text_hollow) return;
        if (addStylesObj.text_hollow === 'sm') {
            cssObj['-webkit-text-stroke-width'] = scalePx(1); //+ ' !important';
            cssObj['-webkit-text-stroke-color'] = cssObj.color;
            cssObj['caret-color'] = cssObj.color;
            cssObj.color = 'transparent';
        } else if (addStylesObj.text_hollow === 'md') {
            cssObj['-webkit-text-stroke-width'] = scalePx(2);// + ' !important';
            cssObj['-webkit-text-stroke-color'] = cssObj.color;
            cssObj['caret-color'] = cssObj.color;
            cssObj.color = 'transparent';
        } else if (addStylesObj.text_hollow === 'lg') {
            cssObj['-webkit-text-stroke-width'] = scalePx(3);// + ' !important';
            cssObj['-webkit-text-stroke-color'] = cssObj.color;
            cssObj['caret-color'] = cssObj.color;
            cssObj.color = 'transparent';
        }
    },

    getBorderWidthValue(addStylesObj, scaleRatio) {
        let borderWidth = 0;
        if (addStylesObj && addStylesObj.border_width) {
            if (addStylesObj.border_width === 'sm') {
                borderWidth = 2 * scaleRatio;
            } else if (addStylesObj.border_width === 'md') {
                borderWidth = 4 * scaleRatio;
            } else if (addStylesObj.border_width === 'lg') {
                borderWidth = 8 * scaleRatio;
            }
        }
        // we round here to work-around browser bug with borders and decimals
        // see here for reproduction: ~/sc/labs/2021/20210528-border-decimal.html
        return Math.round(borderWidth);
    },

    getHexOpacityValue(addStylesObj){
        // we're using hex codes and need to get opacity right according to this table:
        // https://css-tricks.com/8-digit-hex-codes/
        if (!addStylesObj.hotspot_opacity || addStylesObj.hotspot_opacity === '100') return '';
        let opacityHexValues = { '75' : 'BF', '50': '80', '25': '40'};
        return opacityHexValues[addStylesObj.hotspot_opacity];
    }

};

export default AdditionalStyleObjHelper;