<template>
  <div v-if="edMxCurrentPage && edMxSelectedHotspots.length > 0" class="d-inline-block">
    <div class="dropdown">
      <button type="button" class="btn px-md-3 sc-highlight-expanded" data-bs-toggle="dropdown" role="button"
              aria-expanded="false" ref="hotspotImageToggle" style="min-width: 50px"
              :disabled="edMxLoneSelectedHotspotLocked" v-sc-tooltip="'Hotspot Image'">
        <ScIcon name="imageFW" />
      </button>
      <div class="dropdown-menu dropdown-menu-right pb-3 mt-1" ref="ddMenuHsColor"
           @click.prevent.stop style="width: 320px;">

        <div class="row justify-content-between mx-0">
          <span class="col dropdown-header border-0 mt-2 mb-0">Hotspot Fill Color</span>
          <div class="col mt-1 text-end">
            <ScColorPicker id="colPickerFill"
                           :color="fillColor"
                           :hasTransparent="true"
                           :isDisabled="edMxLoneSelectedHotspotLocked"
                           @change="updateHotspotBrandingFillColor($event)" />
          </div>
        </div>

        <hr class="my-2 mx-3" />

        <span class="dropdown-header border-0 mb-0">Hotspot Image</span>
        <div class="my-3 px-3" v-if="loneSelectedHotspotImageResource">

          <EditorResourceThumb :resourceId="loneSelectedHotspotImageResource.id">

            <div class="col-12 my-auto ms-auto text-center px-0">
              <div class="mt-3">
                <button type="button" class="btn btn-outline-secondary"
                        @click.prevent="chooseHotspotImageResource()">
                  <ScIcon name="randomFW" class="me-1"/>Replace</button>
                <button type="button" class="btn btn-outline-secondary ms-2" v-sc-tooltip="'Remove'"
                        @click.prevent="assignHotspotImageResource(null)">
                  <ScIcon name="minusCircleFW"/></button>
              </div>
            </div>

          </EditorResourceThumb>
        </div>

        <div class="px-3">
          <button v-if="!loneSelectedHotspotImageResource"
                  :disabled="!loneSelectedHotspotBounds"
                  class="btn btn-outline-secondary w-100 my-2"
                  @click.prevent="chooseHotspotImageResource()">
            <ScIcon name="imageFW"/>
            Choose image</button>

          <div v-if="loneSelectedHotspotBounds" class="text-muted">
            <ScIcon name="questionCircle"/>
            Use images with a resolution of
            {{loneSelectedHotspotBounds.hsWidth}}x{{loneSelectedHotspotBounds.hsHeight}}px
            for best results.
          </div>

          <div class="row mt-4">
            <div class="col-4"><label for="hsImgZoom">Zoom</label></div>
            <div class="col-8">

              <input id="hsImgZoom" class="form-control-range w-100" type="range" name="points"
                     :disabled="!loneSelectedHotspotImageResource"
                     :value="zoomSliderValRounded"
                     @input="hsImgZoomSlider($event.target.value)"
                     @change="hsImgZoomSlider($event.target.value)"
                     min="0" max="200" step="2" list="steplist-zoom">
              <datalist id="steplist-zoom">
                <option>0</option>
                <option>100</option>
                <option>200</option>
              </datalist>
            </div>
          </div>
          <div class="row">
            <div class="col-4"><label for="hsImgLr">Left/right</label></div>
            <div class="col-8">
              <input id="hsImgLr" class="form-control-range w-100" type="range" name="points"
                     :disabled="!loneSelectedHotspotImageResource"
                     :value="cropXSliderValRounded"
                     @input="hsImgCropXSlider($event.target.value)"
                     @change="hsImgCropXSlider($event.target.value)"
                     min="-100" max="100" step="2" list="steplist-crop-x">
              <datalist id="steplist-crop-x">
                <option>-100</option>
                <option>0</option>
                <option>100</option>
              </datalist>
            </div>
          </div>
          <div class="row">
            <div class="col-4"><label for="hsImgUd">Up/down</label></div>
            <div class="col-8">
              <input id="hsImgUd" class="form-control-range w-100" type="range" name="points"
                     :disabled="!loneSelectedHotspotImageResource"
                     :value="cropYSliderValRounded"
                     @input="hsImgCropYSlider($event.target.value)"
                     @change="hsImgCropYSlider($event.target.value)"
                     min="-100" max="100" step="2" list="steplist-crop-y">
              <datalist id="steplist-crop-y">
                <option>-100</option>
                <option>0</option>
                <option>100</option>
              </datalist>
            </div>
          </div>
        </div>

      </div>

      <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>

    </div>
  </div>
</template>


<script>

    import MainMixin from '../../MainAppMixin';
    import LibraryMainModal from '../../library/LibraryMainModal.vue';
    import EditorMixin from './../EditorMixin';
    import EditorResourceThumb from './../EditorResourceThumb.vue';
    import HotspotUtils from '../../../store/HotspotUtils';
    import ScBootstrapUtils from '../../global/ScBootstrapUtils';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import ScColorPicker from '../../global/ScColorPicker.vue';

    export default {
      name: "EditorToolbarHotspotImage",
      mixins: [MainMixin, EditorMixin],
      components: {EditorResourceThumb, ScIcon, ScColorPicker, LibraryMainModal},
      data () {
        return {
          openingCP: false // flag to indicate if cp is triggered to open for closeCP function
        }
      },
      methods: {

        hsImgZoomSlider(value) {
          let hsImgZoomSliderVal = Number(value);
          this.$store.commit('edSetHotspotImageToolbarZoomSliderVal', hsImgZoomSliderVal);
        },
        hsImgCropXSlider(value) {
          let hsImgCropXSliderVal = Number(value);
          this.$store.commit('edSetHotspotImageToolbarCropXSliderVal', hsImgCropXSliderVal);
        },
        hsImgCropYSlider(value) {
          let hsImgCropYSliderVal = Number(value);
          this.$store.commit('edSetHotspotImageToolbarCropYSliderVal', hsImgCropYSliderVal);
        },

        assignHotspotImageResource(selectedResources) {
          //console.log('assignPageBackgroundResource', selectedResources);
          let selectedResource = null;
          if (selectedResources && selectedResources.length > 0) {
            selectedResource = selectedResources[0];
            this.$store.dispatch('presResourceAddedFromLibrary', selectedResource);
          }
          let hs = Object.assign({}, this.edMxLoneSelectedHotspot);
          hs.original_resource_id = selectedResource ? selectedResource.id : null;
          if (selectedResource) {
            let minRatio = HotspotUtils.defaultHotspotImgZoomRatio(
                this.edMxLoneSelectedHotspot, selectedResource.width,
                selectedResource.height);
            hs.crop_x = 0;
            hs.crop_y = 0;
            hs.zoom_width = Math.floor(selectedResource.width * minRatio);
            hs.zoom_height = Math.floor(selectedResource.height * minRatio);
          }   else {
            hs.crop_x = null;
            hs.crop_y = null;
            hs.zoom_width = null;
            hs.zoom_height = null;
          }
          this.$store.dispatch('presUndoableSaveHotspotBatch', [hs]);
          ScBootstrapUtils.ensureDropdownShown(this.$refs.hotspotImageToggle);
        },

        chooseHotspotImageResource() {
          this.$refs.libraryMainModal.toggleBsModal({
            selectionMode: 'single', dialogType: 'modal',
            filterType: 'image'
          }, this.assignHotspotImageResource);
        },

        updateHotspotBrandingFillColor (color){
          this.edMxUpdateSelectedHotspotsBranding('fill_colour', color);
          ScBootstrapUtils.ensureDropdownShown(this.$refs.hotspotImageToggle);
        },
      },
      computed: {
        loneSelectedHotspotBounds() {
          let bounds = null;
          if (this.edMxLoneSelectedHotspot) {
            bounds = HotspotUtils.boundsStrToObj(this.edMxLoneSelectedHotspot.bounds);
          }
          return bounds;
        },
        hotspotImageToolbarState() {
          return this.$store.state.ed.hotspotImageToolbar;
        },
        loneSelectedHotspotImageResource() {
          let res = null;
          if (this.edMxLoneSelectedHotspot) {
            res = this.$store.getters.presHotspotImageResourceByHsId(this.edMxLoneSelectedHotspot.id);
          }
          return res;
        },
        cropXSliderValRounded() {
          return Math.round(this.hotspotImageToolbarState.cropXSliderVal/2) * 2;  // in steps of 2
        },
        cropYSliderValRounded() {
          return Math.round(this.hotspotImageToolbarState.cropYSliderVal/2) * 2;  // in steps of 2
        },
        zoomSliderValRounded() {
          return Math.round(this.hotspotImageToolbarState.zoomSliderVal/2) * 2;  // in steps of 2
        },

        fillColor () {
          return this.edMxSelectedMultipleHotspotsBrandingDisplayValueForAttribute('fill_colour');
        }
      }
    }


</script>
