<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
         @vb-hide-bs-modal="canModalHide" @vb-hidden-bs-modal="modalWasHidden"
         class="modal fade" tabindex="-1" >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title w-100 text-center">Replace Files</h4>
                </div>
                <div class="modal-body pb-0 clearfix" style="min-height: 150px;">

                    <div v-if="usagesLoading" class="file-usages-loading text-center m-4">
                        <ScIcon name="spinnerFW" class="text-muted"/>
                    </div>

                    <div v-if="!usagesLoading" class="file-usages-list">
                        <form action="#" @submit.prevent>
                            <div class="input-group">
                                <input type="search" placeholder="Search files" class="form-control" maxlength="128"
                                       v-model.trim="searchForText" @keyup.enter.prevent />
                                <a href="#" @click.prevent class="btn btn-outline-secondary input-group-text">
                                    <ScIcon name="search"/></a>
                            </div>
                        </form>

                        <div v-if="isSearchedUsagesByResEmpty" class="text-center mt-4">
                            <em class="text-muted">No matching files</em>
                        </div>

                        <div>
                            <div v-for="usageByRes in searchedUsagesByRes" :key="usageByRes.resource.id">
                                <hr class="my-4">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="d-flex">
                                        <img :src="usageByRes.resource.thumb_url" alt="Thumbnail"
                                             class="img-thumbnail" />
                                            </div>
                                    </div>
                                    <div class="col-6 text-break">
                                        <strong>{{ usageByRes.resource.name }}</strong>
                                        <a v-sc-trackevent="'editor:resource_info_popover'" href="#"
                                           @click.prevent="mainMxShowResInfo(usageByRes.resource.id)"><ScIcon name="infoCircle" class="ms-2"/></a>

                                        <div v-for="(usage, idx) in usageByRes.usages" :key="'ivu'+idx">
                                            <div v-if="usage.type === 'intro_video'">
                                                <a href="#" @click="navToPage($event, null)">Intro video</a>
                                            </div>
                                        </div>

                                        <div v-for="(usage, idx) in usageByRes.usages" :key="'svu'+idx">
                                            <div v-if="usage.type === 'screensaver_video'">
                                                <a href="#" @click="navToPage($event, null)">Screensaver video</a>
                                            </div>
                                        </div>

                                        <div v-if="usageByRes.pageShareableUsages.length > 0 || usageByRes.shareableAllSlides">
                                            Shareable on:
                                            &nbsp; <a v-if="usageByRes.shareableAllSlides"
                                               href="#" @click.prevent="openManageSharing">All slides</a>

                                            <span v-for="(page, idx) in usageByRes.pageShareableUsages" :key="'psu'+idx">
                                                &nbsp; <a href="#" @click="navToPage($event, page.id)">{{ page.alpha_num_name }}</a>
                                            </span>
                                        </div>

                                        <div v-if="usageByRes.pageBgUsages.length > 0">
                                            Slide background on:
                                            <span v-for="(page, idx) in usageByRes.pageBgUsages" :key="'pbu'+idx">
                                                &nbsp; <a href="#" @click="navToPage($event, page.id)">{{ page.alpha_num_name }}</a>
                                            </span>
                                        </div>


                                        <div v-if="usageByRes.hsTargetUsages.length > 0">
                                            Hotspot target<span v-if="usageByRes.hsTargetUsages.length > 1">s</span>
                                            on:
                                            <span v-for="(page, idx) in usageByRes.hsTargetUsages" :key="'htu'+idx">
                                                &nbsp; <a href="#" @click="navToPage($event, page.id)">{{ page.alpha_num_name }}</a>
                                            </span>
                                        </div>

                                    </div>
                                    <div class="col-3">
                                        <a href="#" class="btn btn-outline-secondary float-end"
                                           @click.prevent="replaceFile(usageByRes.resource.id)">Replace</a>
                                    </div>
                                </div>
                            </div>

                            <br/>
                            <p v-if="fileUsages.length === 0 && !usagesLoading"><em class="text-muted">No files in this presentation</em></p>

                            <p class="text-muted"><ScIcon name="questionCircle"/> Fonts &amp; usages in hotspot images are not shown.</p>

                            <p v-if="replacementPerformedCallback" class="text-muted">
                                <ScIcon name="questionCircle"/> After replacing a file, the editor needs to be reloaded.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>
    </div>
    </teleport>
</template>

<script>

import $ from 'jquery';
import _ from 'underscore'; // sortBy
import ScNotification from '../../shared/common/ScNotification.vue';
import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';
import ScIcon from '../../shared/common/ScIcon.vue';
import LibraryMainModal from '../library/LibraryMainModal.vue';
import MainAppMixin from '../MainAppMixin';
import PresentationShareable from '../presentation/PresentationShareable.vue';

let _ins = null;

export default {

    name: 'PresentationFileUsages',
    mixins: [MainAppMixin],
    components: {ScIcon, LibraryMainModal},
    data: function() {
        return {
            isModalShown: false,
            showPresShareableOnHidden: false,
            presId: null,
            usagesLoading: false,
            fileUsages: [],
            replacementPerformedCallback: null,
            replacementPerformed: false,
            navigateToPageCallback: null,
            searchForText: null
        }
    },
    mounted: function() {
        _ins = this;
    },
    beforeUnmount() {
        _ins = null;
    },
    methods: {
        hideModal() {
            this.$refs.scModal.$vb.modal.hide();
        },
        modalWasHidden() {
            if (this.replacementPerformed && this.replacementPerformedCallback) {
                ScNotification.growlClear();
                this.replacementPerformedCallback();
            } else {
                // loaded on a page without the editor, nothing to do
            }
            if (this.showPresShareableOnHidden) {
                PresentationShareable.modal(this.presId, null, null, null);
            }
            this.isModalShown = false;
        },
        canModalHide(e) {
            if (this.usagesLoading) e.preventDefault();
        },
        loadUsages: function () {
            this.usagesLoading = true;
            $.ajax({
                method: 'GET', url: '/main/library/ajax_find_resources_in_presentation_details',
                data: {workspace_id: this.mainMxCurrentWorkshopId, presentation_id: this.presId}
            }).done((data) => {
                this.usagesLoading = false;
                this.fileUsages.splice(0);
                this.fileUsages = data.usages;

            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'loading usages');
            });
        },

        replaceUsagesWithResource: function (srcRes, destRes) {
            if (!srcRes) return;
            if (srcRes.id === destRes.id) {
                ScNotification.growlErrMsg('Please choose a different file.');
                return;
            }

            let msg = 'This will replace ' + this.usagesCountText(srcRes.id) + ' of "' +
                        srcRes.name + '" with "' + destRes.name + '", are you sure?';

            ScConfirmModal2.modal(msg).then(() => {
                this.usagesLoading = true;
                $.ajax({
                    method: 'POST', url: '/main/library/ajax_replace_resource_usages',
                    data: {workspace_id: this.mainMxCurrentWorkshopId, presentation_id: this.presId,
                        src_resource_id: srcRes.id, dest_resource_id: destRes.id}
                }).done(() => {
                    ScNotification.growlSuccessMsg('Replace usages done');
                    this.replacementPerformed = true;
                    this.loadUsages();

                }).always(() => {
                    this.usagesLoading = false;

                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'replacing');
                });
            }, () => {});
        },

        replaceFile: function(resId) {
            let srcRes = this.getResource(resId);
            if (!srcRes) return;
            let filterType = srcRes.content_type;
            if (srcRes.content_type === 'image' && srcRes.suffix === 'gif') filterType = 'image:gif';
            this.$refs.libraryMainModal.toggleBsModal({
                selectionMode: 'single', dialogType: 'modal',
                filterType: filterType
            }, (destRes) => {
                this.replaceUsagesWithResource(srcRes, destRes[0])
            });
        },

        navToPage: function(e, pageId) {
            if (this.navigateToPageCallback) {
                e.preventDefault();
                this.hideModal();
                this.navigateToPageCallback(pageId);
            }
        },

        openManageSharing () {
            this.showPresShareableOnHidden = true;
            this.hideModal();
        },

        getResource: function(resId) {
            let res = null;
            this.fileUsages.forEach(function (usage) {
                if (usage.resource && usage.resource.id && usage.resource.id === resId) {
                    res = usage.resource;
                }
            });
            return res;
        },
        usagesCountText (resId) {
            let count = 0;
            for (let f of this.fileUsages) {
                if (f.resource_id === resId)
                    count++;
            }
            if (count > 1)
                return count + ' usages';
            return count + ' usage';
        },
        toggleBsModal(presentationId, navigateToPageCallback, replacementPerformedCallback) {
            this.isModalShown = true;
            this.showPresShareableOnHidden = false;
            this.presId = presentationId;
            this.usagesLoading = false;
            this.fileUsages.splice(0);
            this.replacementPerformedCallback = replacementPerformedCallback;
            this.replacementPerformed = false;
            this.navigateToPageCallback = navigateToPageCallback;
            this.searchForText = null;
            this.loadUsages();
        }

    },
    computed: {
        searchedUsagesByRes () {
            if (this.searchForText) {
                let elements = [];
                for (let u of this.usagesByRes) {
                    if (u.resource.name.toLowerCase().includes(this.searchForText))
                        elements.push(u);
                }
                return elements;
            }
            return this.usagesByRes;
        },
        isSearchedUsagesByResEmpty () {
            return this.fileUsages.length > 0 && this.searchedUsagesByRes.length === 0;
        },
        usagesByRes: function() {
            let usagesByRes = {};
            if (this.fileUsages) {
                this.fileUsages.forEach(function (usage) {
                    if (!usage.resource || !usage.resource.id) return;
                    if (!usagesByRes[usage.resource.id]) {
                        usagesByRes[usage.resource.id] = {
                            resource: usage.resource, usages: [], pageBgUsages: [], hsTargetUsages: [],
                            pageShareableUsages: [], shareableAllSlides: false
                        };
                    }
                    if (usage.type === 'hotspot_target') {
                        usagesByRes[usage.resource.id].hsTargetUsages.push(usage.page);
                    } else if (usage.type === 'page_background') {
                        usagesByRes[usage.resource.id].pageBgUsages.push(usage.page);
                    } else if (usage.type === 'page_shareable') {
                        usagesByRes[usage.resource.id].pageShareableUsages.push(usage.page);
                    } else if (usage.type === 'shared_content') {
                        usagesByRes[usage.resource.id].shareableAllSlides = true;
                    }
                    usagesByRes[usage.resource.id].usages.push(usage);
                });
            }
            usagesByRes = _.sortBy(usagesByRes, function (usageByResItem) {
                return usageByResItem.resource.name.toLowerCase();
            });

            return usagesByRes;
        }
    },

    //
    // public functions
    //
    modal: function (presentationId, navigateToPageCallback, replacementPerformedCallback) {
        _ins.toggleBsModal(presentationId, navigateToPageCallback, replacementPerformedCallback)
    }
};

</script>