<template>
    <div :class="['scsd',
            acceptableTargetOfDrag && showDragOverLineOnSide && dropActiveBefore === 'before' ? 'scsd-dropzone-left' : '',
            acceptableTargetOfDrag && showDragOverLineOnSide && dropActiveBefore === 'after' ? 'scsd-dropzone-right' : '',
            acceptableTargetOfDrag && !showDragOverLineOnSide && dropActiveBefore === 'before' ? 'scsd-dropzone-top' : '',
            acceptableTargetOfDrag && !showDragOverLineOnSide && dropActiveBefore === 'after' ? 'scsd-dropzone-bottom' : '',
            hasDragStarted?'scsd-started':'']"
         :draggable="isDraggable"
         @dragstart="dragStart"
         @dragend="dragEnd"
         @drop="onDrop" @dragover.prevent="dragOver"
         @dragenter.prevent @dragleave.prevent="dragLeave">
        <!-- debugging: {{listIdx}} before {{dropActiveBefore}} to {{globalSharedState.toIdx}}-->
        <slot></slot>
    </div>
</template>

<script>

let _globalSharedState = {
    toIdx: null,
    fromIdx: null,
    currentlyDraggingContentType: null
}


export default {
    name: "ScSimpleDraggable",
    emits: ['has-dropped'],
    props: {
        isDraggable: {type: Boolean, default: false},
        showDragOverLineOnSide: {type: Boolean, default: true},
        listIdx: {type: Number, default: 0}, // idx of item in parents list context
        draggableContentType: {type: String, default: 'text/sc-draggable'}, // can be user to limit where item can be dropped
    },
    data() {
        return {
            hasDragStarted: false,
            dropActiveBefore: null,
            globalSharedState: _globalSharedState
        }
    },
    methods: {

        dragStart (e) {  // fires on the instance being dragged
            //console.log('dragStart on idx', this.listIdx);
            this.hasDragStarted = true;
            this.globalSharedState.fromIdx = this.listIdx;
            this.globalSharedState.currentlyDraggingContentType = this.draggableContentType;
            e.dataTransfer.effectAllowed = 'move';
            try {
                // reset drag image to current element (if img was dragged it will be replaced)
                e.dataTransfer.setDragImage(this.$el,0, 0);
            } catch(e) {
                // this is dodgy in some browsers
            }
        },

        dragEnd () {  // fires on the instance being dragged
            //console.log('dragEnd on idx', this.listIdx);
            this.hasDragStarted = false;
            if (this.globalSharedState.toIdx !== null && this.listIdx !== this.globalSharedState.toIdx) {
                this.$emit('has-dropped', {fromIdx: this.listIdx, toIdx: this.globalSharedState.toIdx});
            }
            this.globalSharedState.fromIdx = null;
            this.globalSharedState.toIdx = null;
            this.globalSharedState.currentlyDraggingContentType = null;
        },

        onDrop () {  // fires on the instance not being dragged
            //console.log('onDrop on idx', this.listIdx);
            this.dropActiveBefore = null;
        },

        dragOver (e) {  // fires on the instance not being dragged
            // console.log('dragOver on idx', this.globalSharedState.currentlyDraggingContentType,
            //     this.draggableContentType, this.listIdx, this.globalSharedState.fromIdx);
            if (this.acceptableTargetOfDrag) {
                // what side do we put the target on
                let rect = e.target.getBoundingClientRect();
                if (this.showDragOverLineOnSide) {
                    this.dropActiveBefore = (e.clientX - rect.left) < (rect.width/2) ? 'before' : 'after';
                }   else {
                    this.dropActiveBefore = (e.clientY - rect.top) < (rect.height/2) ? 'before' : 'after';
                }
                let toIdx = this.listIdx;
                if (this.dropActiveBefore !== 'before') toIdx += 1
                if (this.globalSharedState.fromIdx < this.listIdx) toIdx -= 1
                this.globalSharedState.toIdx = toIdx;
                e.dataTransfer.dropEffect = 'move';
            }   else {
                this.dropActiveBefore = null;
                this.globalSharedState.toIdx = null;
                e.dataTransfer.dropEffect = 'none';
            }
        },

        dragLeave () {  // fires on the instance not being dragged
            this.dropActiveBefore = null;
        }
    },
    computed: {
        acceptableTargetOfDrag() {
            return this.globalSharedState.currentlyDraggingContentType === this.draggableContentType &&
                this.listIdx !== this.globalSharedState.fromIdx;
        },
    }
}
</script>

<style scoped>

.scsd {
    border: 3px solid transparent;
}
.scsd-started {
    opacity: 0.6;
}
/** active **/
.scsd-dropzone-left {
    border-left: 3px dashed #008ab0;
}
.scsd-dropzone-right {
    border-right: 3px dashed #008ab0;
}
.scsd-dropzone-top {
    border-top: 3px dashed #008ab0;
}
.scsd-dropzone-bottom {
    border-bottom: 3px dashed #008ab0;
}

</style>