<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
         @vb-hide-bs-modal="canModalHide" @vb-hidden-bs-modal="isModalShown=false"
         class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title w-100 text-center">
                        <span v-if="oldStatus === 'archived' && newStatus === 'unpublished'">Unarchive</span>
                        <span v-else-if="newStatus === 'unpublished'">Unpublish</span>
                        <span v-else-if="newStatus === 'archived'">Archive</span>
                    </h4>
                </div>
                <div class="modal-body clearfix">
                    <div v-if="oldStatus === 'archived' && newStatus === 'unpublished'">
                        <p>Unarchiving this presentation will set its status back to unpublished.</p>
                        <p>You'll then be able to edit it again if necessary, and/or publish to get it visible to
                            Viewer users again.</p>
                    </div>
                    <div v-else-if="newStatus === 'unpublished'">
                        <p>Unpublishing this presentation will stop it being available on devices.</p>
                        <p>Users who have already downloaded this presentation will no longer be able to view it.</p>
                        <p>If the whole presentation has been shared, sharing recipients will lose access too.</p>
                    </div>
                    <div v-else-if="newStatus === 'archived'">
                        <p>Archiving this presentation will stop it being available on devices.</p>
                        <p>Users who have already downloaded this presentation will no longer be able to view it.</p>
                        <p>If the whole presentation has been shared, sharing recipients will permanently lose access too.</p>
                    </div>
                </div>
                <div class="confirm-buttons modal-footer">
                    <button v-on:click.prevent="unPublishNow"
                            class="btn btn-unpublish btn-primary-orange fw-bold"
                        :disabled="statusChangeLoading">
                        <ScIcon v-if="statusChangeLoading" name="spinnerFW" class="mx-3"/>
                        <span v-else-if="oldStatus === 'archived' && newStatus === 'unpublished'">Unarchive</span>
                        <span v-else-if="newStatus === 'unpublished'">Unpublish</span>
                        <span v-else-if="newStatus === 'archived'">Archive</span>
                    </button>
                </div>
           </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';

    let _ins = null;

    export default {
        name: 'PresentationChangeStatus',
        components: {ScIcon},
        data: function() {
            return {
                isModalShown: false,
                workspaceId: null,
                presentationId: null,
                statusChangeLoading: false,
                editorActive: false,
                oldStatus: null,
                newStatus: null
            }
        },
        mounted() {
            _ins = this;
        },
        beforeUnmount() {
            _ins = null;
        },
        methods: {
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            canModalHide(e) {
                if (this.statusChangeLoading) e.preventDefault();
            },
            unPublishNow() {
                this.statusChangeLoading = true;
                $.ajax({
                    method: 'POST', url: '/main/presentations/ajax_set_pres_status',
                    data: {workspace_id: this.workspaceId, presentation_id: this.presentationId,
                      new_status: this.newStatus}
                }).done((data) => {
                    if (this.editorActive && data.publish_status === 'archived') {
                        setTimeout(() => {
                            this.$router.push({name: 'workshop-home', params: {workshopId: this.workspaceId}});
                        }, 50);
                    } else if (this.editorActive) {
                        this.$store.dispatch('presReload');
                    }   else {
                        this.$store.commit('plistPresPublishStatusChange', {
                            presentation_id: this.presentationId, publish_status: data.publish_status});
                    }
                    this.statusChangeLoading = false;
                    this.hideModal();
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'changing status');
                    this.statusChangeLoading = false;
                });
            },
            toggleBsModal(presentationId, workspaceId, editorActive, oldStatus, newStatus) {
                this.workspaceId = workspaceId;
                this.presentationId = presentationId;
                this.statusChangeLoading = false;
                this.editorActive = editorActive;
                this.oldStatus = oldStatus;
                this.newStatus = newStatus;
                this.isModalShown = true;
            }
        },

        //
        // public functions
        //
        modal: function(presentationId, workspaceId, editorActive, oldStatus, newStatus) {
            _ins.toggleBsModal(presentationId, workspaceId, editorActive, oldStatus, newStatus);
        }

    }

</script>