<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
         @vb-hidden-bs-modal="isModalShown=false" @vb-hide-bs-modal="canModalHide"
         class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title w-100 text-center">Delete</h4>
                </div>
                <div class="modal-body clearfix">
                    <p>Deleting this presentation will remove it from the workshop and it will no longer be available on devices.</p>
                    <p><b>Deleting cannot be undone</b>. Are you sure you want to delete this presentation?</p>
                </div>
                <div class="confirm-buttons modal-footer">
                    <button class="btn btn-delete btn-primary-orange fw-bold" :disabled="deleteLoading" @click.prevent="deleteNow()">
                        <ScIcon v-if="deleteLoading" name="spinnerFW" class="mx-3"/>
                        <span v-else>Delete</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import $ from 'jquery'
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';

    let _ins = null;

    export default {
        name: 'PresentationDelete',
        components: {ScIcon},
        data: function() {
            return {
                isModalShown: false,
                presId: null,
                workspaceId: null,
                deleteLoading: false,
                loadHomeOnDone: false
            }
        },
        mounted: function() {
            _ins = this;
        },
        beforeUnmount() {
            _ins = null;
        },
        methods: {
            hideModal() {
                if (this.$refs.scModal) this.$refs.scModal.$vb.modal.hide();
            },
            canModalHide(e) {
                if (this.deleteLoading) e.preventDefault();
            },
            deleteNow: function() {
                ScConfirmModal2.modal('Are you sure?').then(() => {
                    this.deleteLoading = true;
                    $.ajax({
                        method: 'POST', url: '/main/presentations/ajax_delete_v2',
                        data: {presentation_id: this.presId, workspace_id: this.workspaceId}
                    }).done(() => {
                        this.deleteLoading = false;
                        this.hideModal();
                        ScNotification.growlSuccessMsg('Presentation has been deleted.');
                        if (this.loadHomeOnDone) {
                            window.location.assign('/workshop/' + this.workspaceId);
                        }   else {
                            this.$store.commit('plistPresDeleted', this.presId);
                        }
                    }).fail((jqXhr) => {
                        ScNotification.growlXhrError(jqXhr, 'deleting');
                        this.deleteLoading = false;
                    });
                }, () => {});
            },
            toggleBsModal(presentationId, workspaceId, loadHomeOnDone) {
                this.presId = presentationId;
                this.workspaceId = workspaceId;
                this.loadHomeOnDone = loadHomeOnDone;
                this.isModalShown = true;
            }
        },

        //
        // public functions
        //
        modal: function (presentationId, workspaceId, loadHomeOnDone) {
            _ins.toggleBsModal(presentationId, workspaceId, loadHomeOnDone)
        }

    };



</script>

