<template>
  <form class="row mx-auto" @submit.prevent>

    <ScColorPicker :disabled="tooManyLabels || addingLabel"
                   :color="newLabelBgColor" class="py-auto col-auto px-0 me-1"
                   :hasTransparent="false"
                   :showPresentationColors="false"
                   @change="updateNewLabelBgColor" />

    <div class="col px-0">
      <input type="text" class="form-control me-1" placeholder="Name" maxlength="32"
             v-model.trim="newLabelName" ref="newLabelInput"
             @keyup.enter="saveLabel" :disabled="tooManyLabels || addingLabel">
    </div>
    <div class="col-auto ps-1 pe-0">
      <button class="btn btn-outline-secondary" :disabled="tooManyLabels || addingLabel"
              type="submit" @click.prevent="saveLabel">
        <template v-if="addingLabel"><ScIcon name="spinnerFW" class="mx-2 ps-1 text-muted" /></template>
        <template v-else >{{ btnTxt }}</template>
      </button>

      <button v-if="labelName && labelBgColor" class="btn add-label-delete pe-2"
              v-sc-tooltip="'Delete Label'" :disabled="addingLabel" type="button"
              @click.prevent="$emit('label-delete')"><ScIcon name="trashAltFW"/></button>
      <button @click.prevent="$emit('label-edit-close')" :disabled="addingLabel" v-sc-tooltip="'Cancel'"
              type="button" class="btn add-label-close px-2"><span class="h4 align-top">&times;</span></button>
    </div>
    <div v-if="tooManyLabels" class="ms-3 mt-2">
      <ScIcon name="exclamationTriangleFW" class="text-danger"/> You can only add up to {{ maxNumLabels }} labels</div>
  </form>
</template>

<script>

    import ScIcon from '../../../shared/common/ScIcon.vue';
    import ScColorPicker from '../../global/ScColorPicker.vue';
    import constants from '../../../constants';

    const DEFAULT_LABEL_COLOR = '#3D77B6';


    export default {
      name: "ManageLabelsEdit",
      emits: ['label-delete', 'label-edit-close', 'save-label'],
      components: { ScIcon, ScColorPicker },
      props: {
        labelBgColor: { type: String, default: DEFAULT_LABEL_COLOR},
        labelName: { type:String, default: null },
        btnTxt: {type: String, default: 'Add'},
        tooManyLabels: {type: Boolean, default: false},
        maxNumLabels: {type: Number, default: constants.MAX_NUM_LABELS},
        addingLabel: {type: Boolean, default: false}
      },
      data () {
        return {
          workspaceId: null,
          newLabelBgColor: DEFAULT_LABEL_COLOR,
          newLabelName: null,
        };
      },

      mounted () {
        //console.log('mounted', this.labelBgColor, this.labelName )
        this.newLabelBgColor = this.labelBgColor;
        this.newLabelName = this.labelName;

        this.$nextTick(() => {
          this.$refs.newLabelInput.focus()
        });
      },

      methods: {
        updateNewLabelBgColor (bgColor) {
          this.newLabelBgColor = bgColor;
        },
        saveLabel () {
          //console.log('save label', this.newLabelName, this.newLabelBgColor);
          if (this.newLabelName === this.labelName && this.newLabelBgColor === this.labelBgColor)
            this.$emit('label-edit-close');
          else
            this.$emit('save-label', {'bg_color': this.newLabelBgColor, 'name': this.newLabelName})
        }
      }
    }
</script>

<style scoped>

    .add-label-close:hover {
        opacity: 1;
    }
    .add-label-close {
        height: 38px;
        color: #333;
        font-weight: 700;
        opacity: .5;
    }
    .add-label-close span {
        line-height: 0.75
    }
    .add-label-delete {
        color: #333;
    }
    .color-option {
        width: 45px;
        height: 45px;
    }

</style>