<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
            @vb-hidden-bs-modal="isModalShown=false"
            class="modal fade" tabindex="-1" >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title w-100 text-center">Export</h4>
                </div>
                <div class="modal-body clearfix">
                    <p>Create a <em>.showcase</em> export file that can be imported into a different workshop within
                        7 days of export.</p>
                    <p>Exporting may take a few minutes for very large presentations.</p>
                </div>
                <div class="confirm-buttons modal-footer">
                    <a :href="'/main/presentations/export_pres/'+presId + '?workspace_id=' + mainMxCurrentWorkshopId" target="_blank"
                       @click="hideModal"
                       class="btn btn-primary-orange fw-bold btn-sc-export">Export</a>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>

<script>

    import MainAppMixin from "../MainAppMixin";

    let _ins = null;

    export default {
        name: 'PresentationExport',
        mixins: [MainAppMixin],
        data() {
            return {
                isModalShown: false,
                presId: null
            }
        },
        mounted() {
            _ins = this;
        },
        beforeUnmount() {
            _ins = null;
        },
        methods: {
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            toggleBsModal(presentationId) {
                this.presId = presentationId;
                this.isModalShown = true;
            }
        },

        modal: function (presentationId) {
            _ins.toggleBsModal(presentationId)
        }
    };

</script>
