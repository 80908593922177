<template>
  <div v-if="edMxSelectedHotspots.length > 0 && edMxSelectedHotspotsBranding.length > 0" class="d-inline-block" >
    <div class="dropdown">
      <button type="button" class="btn px-md-3 sc-highlight-expanded" data-bs-toggle="dropdown" ref="toolbarHsBorderDdBtn"
              :disabled="edMxLoneSelectedHotspotLocked" role="button" aria-expanded="false"
              v-sc-tooltip="'Border Style'">
        <ScIcon name="borderOuterFW" />
      </button>
      <div class="dropdown-menu dropdown-menu-right pb-3" ref="ddMenuBorderColor"
           @click.prevent.stop style="width: 330px;">
        <span class="dropdown-header border-0 mb-0">Border Width</span>

        <div class="btn-group align-items-center w-100 mb-2 px-3">
          <button class="btn"
                  :class="[(hsbBorderWidth === 'null') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingBorderWidth(null)">Off</button>
          <button class="btn"
                  :class="[(hsbBorderWidth === 'sm') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingBorderWidth('sm')">S</button>
          <button class="btn"
                  :class="[(hsbBorderWidth === 'md') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingBorderWidth('md')">M</button>
          <button class="btn"
                  :class="[(hsbBorderWidth === 'lg') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingBorderWidth('lg')">L</button>
        </div>

        <hr class="my-2 mx-4" />

        <div class="row justify-content-between mx-0">
          <span class="col dropdown-header border-0 mt-2 mb-0">Border Color</span>
          <div class="col mt-1 text-end">
            <ScColorPicker id="colPickerBorder"
                           :color="hsbBorderColor"
                           :hasTransparent="false"
                           :isDisabled="edMxLoneSelectedHotspotLocked"
                           @change="updateHotspotBrandingBorderColor($event)" />
          </div>
        </div>

        <hr class="my-2 mx-4" />

        <span class="dropdown-header border-0 mb-0">Rounded Corners</span>

        <div class="btn-group align-items-center w-100 mb-2 px-3">
          <button class="btn"
                  :class="[(hsbBorderRadius === 'null') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingBorderRadius(null)">Off</button>
          <button class="btn"
                  :class="[(hsbBorderRadius === 'sm') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingBorderRadius('sm')">S</button>
          <button class="btn"
                  :class="[(hsbBorderRadius === 'md') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingBorderRadius('md')">M</button>
          <button class="btn"
                  :class="[(hsbBorderRadius === 'lg') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingBorderRadius('lg')">L</button>
        </div>

      </div>
    </div>
  </div>
</template>


<script>

import EditorMixin from './../EditorMixin';
import ScIcon from '../../../shared/common/ScIcon.vue';
import ScColorPicker from '../../global/ScColorPicker.vue';
import ScBootstrapUtils from "../../global/ScBootstrapUtils";


export default {
    name: "EditorToolbarHotspotBorder",
    mixins: [EditorMixin],
    components: {ScIcon, ScColorPicker},
    methods: {

      updateHotspotBrandingBorderWidth (value) {
        // console.log('border width:', value);
        this.edMxUpdateSelectedHotspotsBrandingAdditionalStyles('border_width', value);
      },
      updateHotspotBrandingBorderRadius (value) {
        // console.log('border radius:', value);
        this.edMxUpdateSelectedHotspotsBrandingAdditionalStyles('border_radius', value);
      },
      updateHotspotBrandingBorderColor (color) {
        //console.log('border color:', color);
        this.edMxUpdateSelectedHotspotsBrandingAdditionalStyles('border_color', color);
        ScBootstrapUtils.ensureDropdownShown(this.$refs.toolbarHsBorderDdBtn);
      },

    },
    computed: {
      hsbBorderColor () {
        return this.edMxSelectedMultipleHotspotsBrandingAdditionalStylesDisplayValueForAttribute('border_color', '#000000');
      },
      hsbBorderWidth () {
        return this.edMxSelectedMultipleHotspotsBrandingAdditionalStylesDisplayValueForAttribute('border_width');
      },
      hsbBorderRadius () {
        return this.edMxSelectedMultipleHotspotsBrandingAdditionalStylesDisplayValueForAttribute('border_radius');
      },

    },

}


</script>
