<template>

  <span class="dropdown-header border-0 mb-0">Hotspot Opacity</span>

  <div class="btn-group align-items-center w-100 mb-2 px-3">
    <button class="btn"
            :class="[(hsbHotspotOpacity === '25') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
            @click.prevent.stop="updateHotspotBrandingHsOpacity('25')">25%</button>
    <button class="btn"
            :class="[(hsbHotspotOpacity === '50') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
            @click.prevent.stop="updateHotspotBrandingHsOpacity('50')">50%</button>
    <button class="btn"
            :class="[(hsbHotspotOpacity === '75') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
            @click.prevent.stop="updateHotspotBrandingHsOpacity('75')">75%</button>
    <button class="btn"
            :class="[(hsbHotspotOpacity === '100') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
            @click.prevent.stop="updateHotspotBrandingHsOpacity('100')">100%</button>
  </div>

  <hr class="my-2 mx-4" />

  <span class="dropdown-header border-0 mb-0">Hotspot Shadow</span>

  <div class="btn-group align-items-center w-100 mb-2 px-3">
    <button class="btn"
            :class="[(hsbHotspotShadow === 'null') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
            @click.prevent.stop="updateHotspotBrandingHsShadow(null)">Off</button>
    <button class="btn"
            :class="[(hsbHotspotShadow === 'sm') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
            @click.prevent.stop="updateHotspotBrandingHsShadow('sm')">S</button>
    <button class="btn"
            :class="[(hsbHotspotShadow === 'md') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
            @click.prevent.stop="updateHotspotBrandingHsShadow('md')">M</button>
    <button class="btn"
            :class="[(hsbHotspotShadow === 'lg') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
            @click.prevent.stop="updateHotspotBrandingHsShadow('lg')">L</button>
  </div>

</template>


<script>

import EditorMixin from './../EditorMixin';


export default {
    name: "EditorToolbarHotspotEffect",
    mixins: [EditorMixin],
    methods: {
      updateHotspotBrandingHsShadow (value) {
        this.edMxUpdateSelectedHotspotsBrandingAdditionalStyles('hotspot_shadow', value);
      },
      updateHotspotBrandingHsOpacity (value) {
        this.edMxUpdateSelectedHotspotsBrandingAdditionalStyles('hotspot_opacity', value);
      },
    },
    computed: {

      hsbHotspotShadow () {
        return this.edMxSelectedMultipleHotspotsBrandingAdditionalStylesDisplayValueForAttribute('hotspot_shadow');
      },
      hsbHotspotOpacity () {
        return this.edMxSelectedMultipleHotspotsBrandingAdditionalStylesDisplayValueForAttribute('hotspot_opacity', '100');
      },
    },

}


</script>
