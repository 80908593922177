<template>
    <div v-if="edMxCurrentPage && edMxSelectedHotspots.length > 0" class="d-inline-block">
        <div class="d-inline-block">
          <button v-if="edMxLoneSelectedHotspotLocked" type="button" class="btn px-md-3"
                  v-sc-tooltip="'Unlock hotspot'" :disabled="!edMxLoneSelectedHotspot"
                  @click="toggleLockHotspot(false)"><ScIcon name="lockFW" /></button>
          <button v-else type="button" class="btn px-md-3"
                  v-sc-tooltip="'Lock hotspot'"
                  @click="toggleLockHotspot(true)"><ScIcon name="unlockAltFW" /></button>
        </div>
        <div class="d-inline-block">
            <button type="button" class="btn px-md-3" :disabled="edMxLoneSelectedHotspotLocked"
                    v-sc-tooltip="'Delete hotspot (Bksp/Del)'"
                    @click.prevent="deleteHotspots()">
                <ScIcon name="trashAltFW"/></button>
        </div>
        <div class="d-inline-block">
            <button type="button" class="btn px-md-3"
                    v-sc-tooltip="'Make a copy of selected Hotspot (Ctrl+C / Ctrl+V)'"
                    :disabled="edMxSelectedHotspots.length === 0 || duplicateHotspotLoading || edMxLoneSelectedHotspotLocked"
                    @click.prevent="duplicateHotspot()">
                <ScIcon name="copyFW"/></button>
        </div>
    </div>
</template>


<script>

import EditorMixin from './../EditorMixin';
import ScIcon from '../../../shared/common/ScIcon.vue';

export default {
    name: "EditorToolbarHotspotMain",
    mixins: [EditorMixin],
    components: {ScIcon},
    data: function() {
        return {
            duplicateHotspotLoading: false,
        }
    },
    methods: {
        deleteHotspots() {
            this.edMxDeleteSelectedHotspots();
        },
        duplicateHotspot() {
          this.duplicateHotspotLoading = true;
            this.edMxDuplicateHostposts(this.edMxStateEd.selectedHotspotIds).then(() => {
              this.duplicateHotspotLoading = false;
            });
        },
        toggleLockHotspot (isLocked) {
            let hsBatch = [];
            this.edMxSelectedHotspots.forEach((hotspot) => {
              let hs = Object.assign({}, hotspot);
              hs.is_locked = isLocked;
              hsBatch.push(hs);
            });
            if (hsBatch.length > 0) this.$store.dispatch('presUndoableSaveHotspotBatch', hsBatch);
            if (!this.edMxLoneSelectedHotspot) this.$store.commit('edSelectedHotspotIdsReset');
        },
    },
}


</script>

