<template>
  <div class="dropdown">
    <a href="#" :id="id" :class="['sc-select-toggle', (disabled ? 'disabled' : '')]"
       :data-bs-toggle="(disabled ?'': 'dropdown')" aria-expanded="false"
       @click.prevent="searchFocus()" ref="dropdownToggle">
      <span v-if="!searching && selectedItem" class="scPreview item rounded bg-white d-block">
        <span @click="deselectItem" class="float-end me-2"><ScIcon name="timesFW" class="text-muted"/></span>
        <b class="select-arrow"></b>

        <span style="width: 64px;" class="text-center">
          <img alt="Thumbnail" :src="selectedItem.thumbnail" :style="thumbDimsStyleObject(selectedItem.thumbnail)"/>
        </span>
        <span class="d-inline-block align-middle"
              style="width: calc(100% - 95px)">{{ itemName(selectedItem)  }}</span>
      </span>
      <span v-if="!searching && !selectedItem" class="scPreview item rounded bg-white d-block">
        <b class="select-arrow"></b>
        <span class="">{{placeholder}}</span>
      </span>
      <!-- to fix page jumping on opening dropdown -->
      <span v-if="searching" class="item float-start w-100" :style="{height: (selectedItem) ? '68px' : '44px'}"></span>
    </a>

    <div class="dropdown-menu p-0 w-100" :class="['sc-dropdown-menu rounded', searching ? 'd-block' : '']" :aria-labelledby="id" ref="dropdownMenu">
      <input v-if="searching" ref="searchInput" class="form-control sc-search-input" placeholder="Search..." type="search"
             role="textbox"
             autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
             @blur="blurSearchInput"
             @keyup.enter.prevent="selectHighlightedItem"
             @keyup.up.prevent="highlightPrevItem"
             @keyup.down.prevent="highlightNextItem"
             v-model="searchValue" />
      <ul class="sc-ul rounded bg-white mt-1">
        <li v-if="searchedItems.length === 0" class="text-muted p-2"><em>No results found</em></li>
        <li v-for="(item, idx) in searchedItems" :key="'swt'+idx" :ref="'swt'+idx"
            @click="selectItem(item)" @mouseover="mouseOver(idx)"
            :class="['item',
                        (idx === highlightedItemIdx ? 'highlighted':''),
                        (idx === selectedItemId ? 'selected':''),
                    ]">
          <span v-if="item.thumbnail" style="width: 64px;" class="text-center">
            <img alt="Thumbnail" :src="item.thumbnail" loading="lazy" class="d-inline-block mx-auto"
                 :style="thumbDimsStyleObject(item.thumbnail)"/>
          </span>
          <span class="d-inline-block my-auto align-middle"
                :style="{width: (item.thumbnail) ? 'calc(100% - 64px)' : ''}">{{ itemName(item) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>

    import _ from 'underscore'; // filter, findWhere
    import {Dropdown} from 'bootstrap';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScCommonUtil from '../../shared/common/ScCommonUtil';

    export default {
      name: 'ScSelectWithThumbnailAndSearch',
      emits: ['sc-select-item', 'deselect-item'],
      components: {ScIcon},
      props: {
        id: {type: String, default: 'scSelect'},
        items: {type: Array, default: () => []},
        placeholder: {type: String, default: 'Select an item...'},
        disabled: {type: Boolean, default: false},
        defaultSelectedItemId: {type: Number, default: null},
      },
      data () {
        return {
          searchValue: null,
          selectedItemId: null,
          searching: false,
          highlightedItemIdx: 0,
        }
      },
      mounted () {
        //console.log('mounted', this.items)
        if (this.defaultSelectedItemId) this.selectedItemId = this.defaultSelectedItemId;
      },
      methods: {
        blurSearchInput () {
          setTimeout(() => { this.searching = false; }, 150);
        },
        selectItem (item) {
          //console.log('selectItem', item.id);
          this.selectedItemId = item.id;
          this.highlightedItemIdx = 0;
          this.searchValue = null;
          this.searching = false;
          this.adjustTopMarginDd();
          this.$emit('sc-select-item', item.id);
        },
        deselectItem () {
          //console.log('deselectItem', this.selectedItemId);
          this.selectedItemId = null;
          this.searchFocus();
          this.$emit('deselect-item');
        },
        searchFocus () {
          //console.log('searchFocus');
          if (this.disabled) return;
          this.highlightedItemIdx = 0;
          this.searchValue = null;
          this.searching = true;
          this.adjustTopMarginDd();
          this.$nextTick(() => {
            if (this.$refs.searchInput) {
              this.$refs.searchInput.focus();
              this.$refs.searchInput.select();
            }
          });
        },
        adjustTopMarginDd () {
          this.$nextTick(() =>  {
            let dd = Dropdown.getInstance(this.$refs.dropdownToggle);
            if (dd && dd._element.ariaExpanded === 'false') dd.toggle();
          });
        },
        scrollInView () {
          let container = this.$refs.dropdownMenu;
          let cHeight = container.clientHeight;
          let cTop = container.scrollTop;

          let element = this.$refs['swt' + this.highlightedItemIdx];
          //if (element) element = element[0];
          if (!element) return;

          let eTop = element.offsetTop - container.offsetTop;
          let eBottom = eTop + element.clientHeight;

          if ((eTop < 0 && eBottom > 0 ) || (eTop > 0 && eTop <= cHeight) || (eTop > 0 && eTop >= cHeight) || (eTop === cTop))
            element.scrollIntoView({block: 'nearest'});
        },
        selectHighlightedItem () {
          //console.log('selectHighlightedItem', this.highlightedItemIdx, this.searchedItems);
          if (this.highlightedItemIdx === 0) return;
          if (!this.searchedItems[this.highlightedItemIdx]) return;
          this.selectItem(this.searchedItems[this.highlightedItemIdx]);
          this.$nextTick(() => {
            if (this.$refs.searchInput) {
              this.$refs.searchInput.blur();
            } else {
              let dd = Dropdown.getInstance(this.$refs.dropdownToggle);
              if (dd && dd._element.ariaExpanded === "true") dd.hide();
            }
          });
        },
        highlightPrevItem () {
          this.highlightedItemIdx = this.highlightedItemIdx - 1;
          if (this.highlightedItemIdx < 0) this.highlightedItemIdx = 0;
          this.scrollInView();
        },
        highlightNextItem () {
          this.highlightedItemIdx = this.highlightedItemIdx + 1;
          if (this.highlightedItemIdx >= this.searchedItems.length) this.highlightedItemIdx = this.searchedItems.length-1;
          this.scrollInView();
        },
        mouseOver (idx) {
          this.highlightedItemIdx = idx;
        },
        itemName(item) {
          return item.name ? item.name : item.title;
        },
        thumbDimsStyleObject (thumbUrl) {
          let maxWidth = 50; // define max-width for thumbs
          let thumb = ScCommonUtil.getThumbDimensionsFromUrl(thumbUrl, maxWidth, 50);
          let scaleFactor = (maxWidth / thumb.w);

          // landscape = scale thumb height && portrait = original thumb height
          let calcHeight = (thumb.w < maxWidth) ? thumb.h : thumb.h * scaleFactor;
          let calcWidth = (maxWidth < thumb.w) ? maxWidth : thumb.w ;

          return {
            maxHeight: calcHeight + 'px',
            maxWidth: calcWidth + 'px'
          }
        }
      },
      computed: {
        searchedItems () {
          return _.filter(this.items, (item) => {
            if (!this.searchValue) return true;
            if (!item.name && !item.title) return false;
            let name = item.name ? item.name : item.title;
            return name.toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) !== -1;
          });
        },
        selectedItem () {
          if (!this.selectedItemId) return null;
          return _.findWhere(this.items, {id: this.selectedItemId});
        }
      },
      watch: {
        searchValue () {
          this.highlightedItemIdx = 0;
        },
      }
    }
</script>


<style scoped>

  .disabled {
    opacity: 0.5;
  }
  .sc-select-toggle:hover {
    text-decoration: none;
  }
  .sc-select-toggle .scPreview {
    border: 1px solid #dfdfeb; /* same style as <select> */
    cursor: pointer;
    position: relative;
  }
  .sc-select-toggle .scPreview span {
    color: #212529; /* same color as .form-control */
  }
  .sc-select-toggle .scPreview img {
    max-height: 100px;
    margin: 5px;
  }
  .sc-select-toggle b.select-arrow {
    border-color: #000 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    width: 0;
    right: 15px;
    margin-top: 12px;
    position: absolute;
  }
  .sc-select-toggle[aria-expanded=true] b.select-arrow {
    border-color: transparent transparent #000 transparent;
    border-width: 0 4px 5px 4px;
  }
  .sc-dropdown-menu {
    width: 100%;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    transform: none !important;
  }
  .sc-ul {
    cursor: pointer;
    padding: 0;
    max-height: 200px;
    overflow-y: scroll;
    display: block;
    box-shadow: -0.0625rem 0 0.625rem 0 rgba(0, 0, 0, 0.07), 0.3125rem 1.25rem 2.5rem 0 rgba(0, 0, 0, 0.04);
  }
  .sc-ul li {
    background-color: #fff;
  }
  .sc-ul li.highlighted {
    background-color: #eee;
  }
  .sc-search-input {
    box-shadow: -0.0625rem 0 0.625rem 0 rgba(0, 0, 0, 0.07), 0.3125rem 1.25rem 2.5rem 0 rgba(0, 0, 0, 0.04);
  }
  .item {
    padding: 0.5625rem 1.125rem;
  }
  .item span {
    display: inline-block;
  }
  .item span img {
    margin: 5px;
  }

</style>