<template>
    <div v-if="viewportWidth" id="editorMain">
        <div v-if="viewportTooSmall" style="height: 100vh;">
            <div class="text-center d-flex align-items-center h-100 justify-content-center">
                <div class="text-muted">
                    <h3>Minimum screen width 1024px</h3>
                    <p>(yours is {{viewportWidth}}px)</p>
                    <p><router-link :to="{ name: 'workshop-home', params: { workshopId: mainMxCurrentWorkshopId }}">Home</router-link></p>
                </div>
            </div>
        </div>
        <div v-if="!viewportTooSmall && showcaseOrEditorLoading" style="height: 100vh;">
            <div class="text-center d-flex align-items-center h-100 justify-content-center">
                <ScIcon name="spinnerMedium" class="text-muted"/>
            </div>
        </div>
        <div v-if="!viewportTooSmall && !showcaseOrEditorLoading && !showcaseLoaded" style="height: 100vh;">
            <div class="text-center d-flex align-items-center h-100 justify-content-center">
                <div class="text-muted">
                    <em class="text-muted">No presentation loaded</em>
                    <p><router-link :to="{ name: 'workshop-home', params: { workshopId: mainMxCurrentWorkshopId }}">Home</router-link></p>
                </div>
            </div>
        </div>
        <div v-if="!viewportTooSmall && !showcaseOrEditorLoading && showcaseLoaded" class="wrap">

            <EditorConcurrencyChecker></EditorConcurrencyChecker>
            <EditorKeyboardShortcuts v-if="edMxCurrentPage"/>

            <div class="canvas" :style="canvasStyleObject">
                <div class="canvas-scroller">

                    <div v-if="!edMxCurrentPage" class="text-center d-flex align-items-center h-100 justify-content-center">
                        <div class="text-muted"><em>No slide selected </em><br>
                            <a href="#" @click.prevent="edMxNavToPage(null)">Select A1</a>
                        </div>
                    </div>

                    <div v-if="edMxCurrentPage">

                        <EditorSelection :presOffsetPosX="presOffsetPosX"
                                         :presOffsetPosY="presOffsetPosY"
                                         :style="editorSelectionStyleObject"></EditorSelection>

                        <div class="device-chassis" :style="deviceChassisStyleObject"></div>

                        <div class="device-frame" :style="deviceFrameStyleObject" ref="deviceFrameRef">

                            <div v-if="animatedBgUrl" class="animated-container" :style="animatedContainerStyleObj">
                                <img v-if="isAnimatedBgOfType('image')"
                                     :src="animatedBgUrl" style="width: 100%;height: auto;"/>

                                <video v-if="isAnimatedBgOfType('movie')"
                                       :key="animatedBgUrl" loop autoplay muted
                                       style="width: 100%;height: auto;">
                                    <source type="video/mp4" :src="animatedBgUrl" />
                                </video>
                            </div>

                            <div class="four-corners-wrap">
                                <EditorFourCorners></EditorFourCorners>
                            </div>

                            <div class="hotspots">
                                <EditorHotspot
                                    v-for="hotspot in currentPageHotspots"
                                    :key="hotspot.id"
                                    :hsHotspot="hotspot"/>
                            </div>

                        </div>

                        <EditorTopCanvasToolbar :style="topCanvasBarStyleObject"
                                                :page="edMxCurrentPage" />

                    </div>

                </div>
            </div>

            <div class="editor-navbar">
                <EditorNavbar/>
            </div>

            <div class="editor-toolbar">
                <EditorToolbar/>
            </div>

            <div class="editor-side-panel">
                <EditorSidePanel/>
            </div>

            <EditorFindPageModal></EditorFindPageModal>
            <EditorCopyPagelists></EditorCopyPagelists>

            <EditorWeburlModal></EditorWeburlModal>
            <EditorNewSlideModal></EditorNewSlideModal>

            <ScColorPickerModal></ScColorPickerModal>

            <ScHelpWidget />

            <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>

            <!-- for child routes -->
            <router-view></router-view>

        </div>
    </div>
</template>

<script>

    import { computed } from 'vue';
    import _ from 'underscore'; // debounce, findWhere
    import ScColorPickerModal from '../global/ScColorPickerModal.vue';
    import EditorHotspot from './EditorHotspot.vue';
    import EditorToolbar from './toolbar/EditorToolbar.vue';
    import EditorFourCorners from './EditorFourCorners.vue';
    import EditorSidePanel from './EditorSidePanel.vue';
    import MainAppMixin from '../MainAppMixin';
    import EditorKeyboardShortcuts from './EditorKeyboardShortcuts.vue';
    import EditorFindPageModal from './EditorFindPageModal.vue';
    import EditorNewSlideModal from './EditorNewSlideModal.vue';
    import EditorNavbar from './EditorNavbar.vue';
    import EditorTopCanvasToolbar from './EditorTopCanvasToolbar.vue';
    import EditorConcurrencyChecker from './EditorConcurrencyChecker.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import constants from '../../constants';
    import EditorMixin from './EditorMixin';
    import store from '../../store';
    import PresentationUtils from '../../store/PresentationUtils';
    import EditorUndoStore from '../../store/EditorUndoStore';
    import PresentationStore from '../../store/PresentationStore';
    import EditorStore from '../../store/EditorStore';
    import FontsStore from '../../store/FontsStore';
    import ScHelpWidget from '../global/ScHelpWidget.vue';
    import EditorWeburlModal from "./EditorWeburlModal.vue";
    import EditorCopyPagelists from "./EditorCopyPagelists.vue";
    import LibraryMainModal from "../library/LibraryMainModal.vue";
    import EditorSelection from "./EditorSelection.vue";

    if (!store.state.undo) store.registerModule('undo', EditorUndoStore);
    if (!store.state.pres) store.registerModule('pres', PresentationStore);
    if (!store.state.ed) store.registerModule('ed', EditorStore);
    if (!store.state.fonts) store.registerModule('fonts', FontsStore);

    export default {
        name: 'EditorMain',
        mixins: [MainAppMixin, EditorMixin],
        components: {
            EditorSelection,
            EditorWeburlModal, EditorFourCorners, EditorSidePanel, EditorNavbar, EditorKeyboardShortcuts, EditorToolbar,
            EditorHotspot, ScHelpWidget, ScIcon, EditorFindPageModal, EditorNewSlideModal, EditorTopCanvasToolbar,
            ScColorPickerModal, EditorCopyPagelists, LibraryMainModal, EditorConcurrencyChecker },
        data() {
            return {
                viewportWidth: null,
                viewportHeight: null,
                editorLoading: false
            };
        },

        provide() {
            // these are cpu intensive tasks on large showcases so calculate it once and make it available to children
            return {
                edMnUnusedPageIds: computed(() => {
                    return PresentationUtils.scDataUnusedPageIds(this.edMxStateShowcase);
                }),
                edMnUnusedPagelistIds: computed(() => {
                    return PresentationUtils.scDataUnusedPagelistIds(this.edMxStateShowcase);
                }),
                edMnNewHotspotThatTargetsDocument: () => {
                    this.$refs.libraryMainModal.toggleBsModal({
                        selectionMode: 'single', dialogType: 'modal',
                        filterType: 'document'
                    }, (resources) => {
                        if (!resources || resources.length === 0) return;
                        this.$store.dispatch('presResourceAddedFromLibrary', resources[0]);
                        this.edMxNewHotspot(null, resources[0].id, null, false);
                        // add to shareable on this slide
                        if (this.$store.getters.userCurrentWorkspaceObj.shareable_on_slide_by_default) {
                            this.$store.dispatch('presToggleShareableResource', {
                                resource_id: resources[0].id, page_id: this.edMxCurrentPage.id, enabled: true
                            });
                        }
                    });
                }
            }
        },

        mounted() {
            //console.log('mounted', this.$store);
            document.addEventListener('gesturestart', this.preventGestureStart, false);
            window.addEventListener('resize', this.windowResize, false);
            window.addEventListener('beforeunload', this.confirmExit);
            document.addEventListener('sc_event:refresh_resources_thumb', this.windowResourceThumbReady);
            document.addEventListener('sc_event:page_thumbnail_ready', this.windowPageThumbReady);
            document.addEventListener('sc_event:pres_draft_compiled', this.presDraftCompiled);

            this.windowResize();
            this.setShowcaseFromRoute(true);
        },

        beforeUnmount() {
            document.removeEventListener('gesturestart', this.preventGestureStart, false);
            window.removeEventListener('resize', this.windowResize, false);
            window.removeEventListener('beforeunload', this.confirmExit);
            document.removeEventListener('sc_event:refresh_resources_thumb', this.windowResourceThumbReady);
            document.removeEventListener('sc_event:page_thumbnail_ready', this.windowPageThumbReady);
            document.removeEventListener('sc_event:pres_draft_compiled', this.presDraftCompiled);
        },

        methods: {
            resetEditor() {
                //console.log('resetEditor');
                this.$store.commit('edReset');
                let pageId = this.$route.query.pid;
                this.edMxNavToPage(pageId ? Number(pageId) : null);
                this.zoomToFit();

            },
            confirmExit(e) {
                if (!this.$store.getters.presCanPublish) {
                    e.returnValue = "You have unsaved changes. Leaving the page will dismiss your current changes.";
                }
            },


            presDraftCompiled(e) {
                let data = e.detail;
                //console.log('presDraftCompiled', data);
                if (data && data.draft_compile_token) this.$store.commit('presDraftCompiled', data.draft_compile_token);
            },

            windowPageThumbReady(e) {
                //console.log('windowPageThumbReady', data);
                this.$store.commit('presPageThumbReady', e.detail);
            },

            windowResourceThumbReady(e) {
                this.$store.commit('presResourceThumbReady', e.detail);
            },

            windowResize: _.debounce(function() {
                this.viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                this.viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            }, 250),

            preventGestureStart(event) {
                // disallow ios two finger ping to zoom as it breaks editor resizing
                // ideally we would translate the gesture into scaleRatio here
                event.preventDefault();
            },

            setShowcaseFromRoute(forceReload) {
                //console.log('setShowcaseFromRoute', this.$route, this.edMxStateEd.currentPageId);
                this.editorLoading = true;

                // load/reload fonts each time we have a change
                this.$store.dispatch('fontsFetch', this.mainMxCurrentWorkshopId).then(() => {

                    //console.log('this.$store.getters.fontsDefaultFont', this.$store.getters.fontsDefaultFont);
                    if (this.$store.getters.fontsDefaultFont) {
                        this.$store.commit('presSetDefaultFont', this.$store.getters.fontsDefaultFont.id);
                    }   else {
                        console.warn("Unable to load fonts listing.")
                    }

                    this.editorLoading = false;

                    // load the presentation
                    if (forceReload || this.edMxStateShowcase.presentation.id !== Number(this.$route.params.showcaseId)) {
                        //console.log('fetch now');
                        this.$store.dispatch('presFetch', {
                            workspace_id: this.mainMxCurrentWorkshopId,
                            presentation_id: Number(this.$route.params.showcaseId)
                        });
                    }

                }).catch(() => {
                    this.editorLoading = false;
                });

            },
            zoomToFit() {
                //console.log('zoomToFit', this.viewportWidth, this.edMxStateShowcase.presentation.width);
                let perfectDeviceFrameWidth = (this.edMxStateShowcase.presentation.width + 60);  // 60px padding
                let perfectDeviceFrameHeight = (this.edMxStateShowcase.presentation.height + 60);
                if (this.deviceChassisImage) {
                    //console.log('deviceChassisImage', this.deviceChassisImage);
                    perfectDeviceFrameWidth = (this.deviceChassisImage.imgWidth);
                    perfectDeviceFrameHeight = (this.deviceChassisImage.imgHeight);
                }
                //console.log('perfect size', perfectDeviceFrameWidth, perfectDeviceFrameHeight);
                let perfectFitRatioWidth = this.viewportWidth / (perfectDeviceFrameWidth + constants.SIDE_PANEL_WIDTH);
                let perfectFitRatioHeight = this.viewportHeight / (perfectDeviceFrameHeight + constants.TOOLBAR_HEIGHT + constants.NAVBAR_HEIGHT);
                //console.log('perfect ratio', perfectFitRatioWidth, perfectFitRatioHeight);
                let lessThanZoomLevels = constants.CANVAS_ZOOM_LEVELS.filter((val) => {
                    return val < perfectFitRatioWidth && val < perfectFitRatioHeight
                });
                let closest = constants.CANVAS_ZOOM_LEVELS[0];
                if (lessThanZoomLevels.length > 1) closest = lessThanZoomLevels[lessThanZoomLevels.length-1];
                //console.log('closest', closest);
                this.$store.commit('edSetScaleRatio', closest);
            },

            isAnimatedBgOfType(contentType) {
                let isType= false;
                if (this.edMxCurrentPage.page_animated_bg_id) {
                    let bgRes = this.$store.getters.presResource(this.edMxCurrentPage.page_animated_bg_id);
                    isType = (bgRes.content_type === contentType)
                }
                return isType;
            },

        },

        computed: {
            showcaseLoaded() {
                return this.$store.state.pres.showcaseLoaded;
            },
            showcaseOrEditorLoading() {
                return this.$store.state.pres.showcaseLoading || this.editorLoading;
            },
            viewportTooSmall() {
                return this.viewportWidth && this.viewportWidth < 1024;
            },
            canvasWidth() {
                return this.viewportWidth - constants.SIDE_PANEL_WIDTH;
            },
            canvasHeight() {
                return this.viewportHeight - constants.TOOLBAR_HEIGHT - constants.NAVBAR_HEIGHT;
            },
            slideHeightWithBg() {
                let slideHeight = this.showcase.presentation.height;
                let bgDims = this.slideBackgroundDims;
                if ( bgDims && bgDims.height > slideHeight ) slideHeight = bgDims.height;
                return slideHeight;
            },
            canvasStyleObject() {
                return { bottom: 0 + 'px', left: constants.SIDE_PANEL_WIDTH + 'px'}
            },
            deviceChassisImage() {
                return this.$store.getters.edGetDeviceChassisImage(this.edMxStateShowcase.primary_layout.code);
            },
            deviceChassisSizing() {
              let width = (constants.CANVAS_BLEED_SIZE * 2) + this.showcase.presentation.width;
              let height = (constants.CANVAS_BLEED_SIZE * 2) + this.slideHeightWithBg;
              let scaledWidth = (width * this.scaleRatio);
              let scaledHeight = (height * this.scaleRatio);
              let posLeft = scaledWidth < this.canvasWidth ? (this.canvasWidth - scaledWidth)/2 : 0;
              let posTop = scaledHeight < this.canvasHeight ? (this.canvasHeight - scaledHeight)/2 : 0;

              // to fix errors/#6148 chassis top canvas bar overlapping in 4:3 portrait
              // same calculation as for top canvas bar position margin top
              // when marginTop goes negative increase top margin on chassis
              let topCanvasBarPadding = (constants.CANVAS_BLEED_SIZE * this.scaleRatio);
              let topCanvasBarHeight = constants.TOP_CANVAS_BAR_HEIGHT;
              let deviceImgTopMargin = (this.deviceChassisImage) ? this.deviceChassisImage.imgMarginTopForTitle * this.scaleRatio : 0;
              let topCanvasBarMargin = (this.deviceChassisImage) ? (topCanvasBarPadding - deviceImgTopMargin - topCanvasBarHeight) : 0;
              // update marginTop if topCanvasBarMargin is negative
              posTop = (topCanvasBarMargin < 0 ) ? posTop + (topCanvasBarMargin * -1) : posTop;
              return { w: scaledWidth, h: scaledHeight, x: posLeft, y: posTop };
            },
            editorSelectionStyleObject() {
                return {
                  position: 'absolute',
                  width: (this.deviceChassisSizing.w + (2*this.deviceChassisSizing.x)) + 'px',
                  height: (this.deviceChassisSizing.h + (2*this.deviceChassisSizing.y)) + 'px'
                };
            },
            deviceChassisStyleObject() {
                // looks similar to canvasBleedStyleObject, we might want to control the device chassis image separately
                // so don't reuse those styles
                let devImg = null;
                let devSize = null;
                let devPosY = null;
                if (this.deviceChassisImage) {
                    let deviceScaledWidth = this.deviceChassisImage.imgWidth * this.scaleRatio;
                    let deviceScaledHeight = this.deviceChassisImage.imgHeight * this.scaleRatio;
                    devImg = 'url(' + this.mainMxCdn(this.deviceChassisImage.imgPath) + ')';
                    devSize = deviceScaledWidth + 'px ' + deviceScaledHeight + 'px';
                    devPosY = (constants.CANVAS_BLEED_SIZE
                        - ((this.deviceChassisImage.imgHeight - this.showcase.presentation.height) / 2))
                        * this.scaleRatio + 'px';
                }
                return {
                    backgroundImage: devImg ? devImg : 'none',
                    backgroundSize: devSize ? devSize : '',
                    backgroundPositionX: 'center',
                    backgroundPositionY: devPosY ? devPosY : 'center',
                    width: this.deviceChassisSizing.w + 'px', height: this.deviceChassisSizing.h + 'px',
                    left: this.deviceChassisSizing.x + 'px', top: this.deviceChassisSizing.y + 'px',
                }
            },

            deviceFrameStyleObject() {
                //console.log('EditorMain/computed/deviceFrameStyleObject', this.showcase.presentation.height * this.scaleRatio);
                if (!this.edMxCurrentPage) return {};

                let bgImg = null;
                let scaleRatio = this.scaleRatio;
                let presWidth = this.showcase.presentation.width;
                let presHeight = this.showcase.presentation.height;
                let bgDims = this.slideBackgroundDims;
                let pageBgColor = this.edMxCurrentPage.page_bg_color;

                if (this.edMxCurrentPage.background_src_resource_id) {
                    let bgRes = this.$store.getters.presResource(this.edMxCurrentPage.background_src_resource_id);
                    bgImg = 'url(' + bgRes.url + ')';
                }

                return {
                    top: this.presOffsetPosY + 'px',
                    left: this.presOffsetPosX + 'px',
                    backgroundColor: pageBgColor ? pageBgColor : '#000000',
                    backgroundImage: bgImg ? bgImg : 'none',
                    backgroundPosition: bgDims ? (bgDims.height > presHeight ? 'center 0' : 'center center') : '',
                    backgroundSize: bgDims ? (bgDims.width <= presWidth ?
                        (bgDims.width * scaleRatio) + 'px ' + (bgDims.height * scaleRatio) + 'px' : '100%') : '',
                    width: (this.showcase.presentation.width * scaleRatio) + 'px',
                    height: (this.slideHeightWithBg * scaleRatio) + 'px'
                }
            },

            presOffsetPosX() {
              let width = (constants.CANVAS_BLEED_SIZE * 2) + this.showcase.presentation.width;
              let scaledWidth = (width * this.scaleRatio);
              return (scaledWidth < this.canvasWidth ? (this.canvasWidth - scaledWidth)/2 : 0)
                     + (constants.CANVAS_BLEED_SIZE * this.scaleRatio);
            },

            presOffsetPosY() {
              let height = (constants.CANVAS_BLEED_SIZE * 2) + this.slideHeightWithBg;
              let scaledHeight = (height * this.scaleRatio);
              return (scaledHeight < this.canvasHeight ? (this.canvasHeight - scaledHeight)/2 : 0)
                     + (constants.CANVAS_BLEED_SIZE * this.scaleRatio);
            },

            topCanvasBarStyleObject() {
                let scaleRatio = this.scaleRatio;
                let deviceImgTopMargin = (this.deviceChassisImage) ? this.deviceChassisImage.imgMarginTopForTitle * scaleRatio : 0;
                let paddingTop = deviceImgTopMargin + constants.TOP_CANVAS_BAR_HEIGHT;
                const topCanvasBarPaddingLeft = 8;
                return {
                    top: (this.presOffsetPosY - paddingTop) + 'px',
                    left: this.presOffsetPosX - topCanvasBarPaddingLeft + 'px'
                }
            },

            slideBackgroundDims() {
                let bgDims = null;
                if (this.edMxCurrentPage.background_src_resource_id) {
                    let bgRes = this.$store.getters.presResource(this.edMxCurrentPage.background_src_resource_id);
                    let presWidth = this.showcase.presentation.width;
                    let ratio = Math.min(presWidth / bgRes.width, 1);  // we 'fit to width' background images
                    bgDims = {width: bgRes.width * ratio, height: bgRes.height * ratio};
                }
                return bgDims;
            },

            animatedBgUrl() {
                let bgUrl = null;
                if (this.edMxCurrentPage && this.edMxCurrentPage.page_animated_bg_id) {
                    let bgRes = this.$store.getters.presResource(this.edMxCurrentPage.page_animated_bg_id);
                    bgUrl = (bgRes) ? bgRes.url : null;
                    if (bgRes) {
                        let rmd = _.findWhere(this.showcase.resourcemetadata, {
                            resource_id: bgRes.id, name: 'video_1024_url' });
                        if (rmd) {
                            bgUrl = rmd.value;
                        }
                        rmd = _.findWhere(this.showcase.resourcemetadata, {
                            resource_id: bgRes.id, name: 'video_2048_url'});
                        if (rmd) {
                            bgUrl = rmd.value;
                        }
                    }
                }
                return bgUrl;
            },
            animatedContainerStyleObj() {
                //console.log('animatedContainerStyleObj');
                return {
                    minWidth: '100%',
                    minHeight: '100%',
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }
            },

            currentPageHotspots() {
                return this.$store.getters.presHotspotsForPageId(this.edMxStateEd.currentPageId);
            },
            showcase() {
                return this.$store.state.pres.showcase;
            },
            showcaseLastLoadedDate() {
                return this.$store.state.pres.showcaseLastLoadedDate;
            },
            scaleRatio() {
                return this.edMxStateEd.scaleRatio;
            },
        },
        watch: {
            '$route': function() {
                this.setShowcaseFromRoute(false);
            },
            showcaseLastLoadedDate() {
                //console.log('EditorMain/watch/showcaseLastLoadedDate', this.showcase);
                this.resetEditor();
            },
            scaleRatio() {
              // when zooming scroll to the top left of the device frame, this is not perfect but pretty good
              if (this.$refs.deviceFrameRef) {
                this.$refs.deviceFrameRef.scrollIntoView({behavior: 'auto', block: 'start', inline: 'start'});
              }
            }
        }

    }
</script>



<style>

    /* main layout */

    .wrap {
        background-color: #dddddd;
    }

    .editor-navbar {
        left: 0;
        top: 0;
        height: 80px;
        width: 100%;
    }

    .editor-toolbar {
        position: absolute;
        left: 250px;
        top: 80px;
        height: 60px;
        right: 0;
        background-color: white;
        border-bottom: 2px solid #e9e9f2;
    }

    .canvas {
        position: absolute;
        top: calc(80px + 60px);
        right: 0;

        user-select: none;
        background: #f5f5f5;
    }

    .canvas-scroller {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: auto;
        z-index: 50;  /* see main/ui/README.md for all z-index values */
    }
    .canvas-bleed {
        position: absolute;
        display: inline-block;
    }

    .device-chassis {
        position: absolute;
        background: no-repeat center center;
        filter: drop-shadow(0px 5px 5px #222);
    }

    .device-frame {
        background-repeat: no-repeat;
        position: absolute;
    }

    .four-corners-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .editor-side-panel {
        position: absolute;
        top: 80px;
        left: 0;
        bottom: 0;
        width: 250px;
        background-color: #f5f5f5;
        border-right: 2px solid #c0c0c0;
        overflow-y: auto;
    }

</style>

