<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
            @vb-hide-bs-modal="canModalHide" @vb-hidden-bs-modal="isModalShown=false"
            class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header text-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title w-100 text-center">History</h4>
                </div>
                <div class="modal-body pb-0 clearfix" style="min-height: 150px;">
                    <div v-if="restoreLoading || historyLoading" class="text-center pt-5">
                        <ScIcon name="spinnerFW" class="text-muted"/>
                    </div>

                    <div v-else>
                        <p>If you restore a previous version it will be created as a new presentation.</p><br/>
                        <table class="table table-hover table-pres-history">
                            <thead>
                                <tr>
                                    <th>Version</th><th>Published</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="presVer in versions" :key="presVer.version">
                                    <td class="align-middle">{{ presVer.version }}</td>
                                    <td class="align-middle">{{ mainMxScDateFmt(presVer.created) }}</td>
                                    <td class="align-middle"><button class="restore_pres btn btn-link"
                                            @click.prevent="restoreHistoryItem(presVer.version)"
                                            >Restore</button></td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="text-muted" v-if="versions.length >= 50">
                            <em>Only the last 50 versions are shown.</em>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

import $ from 'jquery';
import MainAppMixin from '../MainAppMixin';
import ScNotification from '../../shared/common/ScNotification.vue';
import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';
import ScIcon from '../../shared/common/ScIcon.vue';

let _ins = null;

export default {
    name: 'PresentationHistory',
    mixins: [ MainAppMixin ],
    components: {ScIcon},
    data: function() {
        return {
            isModalShown: false,
            presId: null,
            workspaceId: null,
            historyLoading: false,
            restoreLoading: false,
            versions: []
        }
    },
    mounted: function() {
        _ins = this;
    },
    beforeUnmount() {
        _ins = null;
    },
    methods: {
        hideModal() {
            this.$refs.scModal.$vb.modal.hide();
        },
        canModalHide(e) {
            if (this.restoreLoading) e.preventDefault();
        },
        loadHistory: function() {
            this.historyLoading = true;
            this.versions.splice(0);
            $.ajax({
                method: 'GET', url: '/main/presentations/ajax_get_presentation_history',
                data: {workspace_id: this.workspaceId, presentation_id: this.presId}
            }).then((data) => {
                this.versions = data.versions;
                this.historyLoading = false;
            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'fetching history');
                this.historyLoading = false;
            });
        },
        restoreHistoryItem: function(version) {
            ScConfirmModal2.modal('Restore this version to a new presentation?').then(() => {
                this.restoreLoading = true;
                $.ajax({
                    method: 'POST', url: '/main/presentations/ajax_restore_presentation',
                    data: {
                        workspace_id: this.workspaceId,
                        presentation_id: this.presId, version: version}
                }).then((data) => {
                    this.restoreLoading = false;
                    this.hideModal();
                    this.$store.commit('plistPresAdded', data);
                    ScNotification.growlSuccessMsg('Restore to new presentation process started.  ' +
                        'This may take up to 10 minutes.');
                    setTimeout(() => {
                        document.dispatchEvent(new CustomEvent('sc_event:workshop_home_show_pres', {detail: data}));
                    }, 250);

                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'when restoring');
                    this.restoreLoading = false;
                });
            }, () => {});
        },
        toggleBsModal(workspaceId, presentationId) {
            this.presId = presentationId;
            this.workspaceId = workspaceId;
            this.isModalShown = true;
            this.loadHistory();
        }
    },

    //
    // public functions
    //
    modal: function (workspaceId, presentationId) {
        _ins.toggleBsModal(workspaceId, presentationId)
    }
}

</script>

