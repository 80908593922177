<template>
    <div v-if="edMxCurrentPage && edMxSelectedHotspots.length === 0">
        <div class="dropdown">
            <button type="button" class="btn w-100 dropdown-toggle sc-highlight-expanded" data-bs-toggle="dropdown"
                    ref="slideBackgroundToggle" aria-expanded="false">
                <ScIcon name="imageFW"/> Background
            </button>
            <div class="dropdown-menu" @click.stop >
                <div class="my-2 mx-3" style="width: 320px;">
                    <h4 class="text-center mb-3">Slide Background</h4>


                    <div class="row justify-content-between mx-0" >
                        <span class="col h5 border-0 mt-2 ps-0">Background Color</span>
                        <div class="col-auto px-0 text-end">
                            <ScColorPicker id="pageBgColorPicker" class="d-inline-block ms-3"
                                           :color="edMxCurrentPage.page_bg_color ? edMxCurrentPage.page_bg_color : '#000000'"
                                           :hasTransparent="false" @change="updatePageBgColor" />
                        </div>
                    </div>

                    <hr class="my-2"/>

                    <label class="mt-2 mb-0">
                        <h5 class="d-inline-block">Background File</h5>
                    </label>
                    <div v-if="!currentPageBackgroundResource && !currentPageAnimatedBackgroundResource" class="row mx-0 mt-2">
                        <button type="radio" name="slide-bg-type" value="image" @click.prevent="chooseSlideBg()"
                                class="col-5 me-1 btn btn-outline-secondary w-100 mb-2">
                            <ScIcon name="imageFW"/> Image</button>

                        <button type="radio" name="slide-bg-type" value="video" @click.prevent="chooseSlideBgVideo()"
                                class="col ms-0 mt-0 btn btn-outline-secondary w-100 mb-2">
                            <ScIcon name="fileVideoFW"/>Cinemagraph<i class="h5 align-top">&#42;</i></button>

                        <div class="mt-2">
                            <p class="text-muted my-2" style="line-height: 1.35">
                                <ScIcon name="questionCircle" /> Background color is supported in app versions 9+.</p>
                            <p class="text-muted my-2" style="line-height: 1.35">Use background images with a resolution of
                                {{ edMxStateShowcase.presentation.width }}x{{ edMxStateShowcase.presentation.height}}px
                                for best results.</p>
                            <p class="text-muted my-1" style="line-height: 1.35">
                                <i class="h5 text-muted">&#42;</i> Gifs or videos can be set as slide backgrounds. Supported in app versions 9+.</p>
                        </div>
                    </div>

                    <div v-if="currentPageBackgroundResource" class="my-3">

                        <EditorResourceThumb :resourceId="currentPageBackgroundResource.id">
                            <div class="col-12 my-auto ms-auto text-center px-0">
                                <div class="my-3">
                                    <button class="btn btn-outline-secondary" @click.prevent="openSharing">
                                        <ScIcon name="shareSquareFW"/> Sharing for this file
                                    </button>
                                </div>

                                <button type="button" class="btn btn-outline-secondary"
                                        @click.prevent="chooseSlideBg()"><ScIcon name="randomFW" class="me-1"/>Replace</button>
                                <button type="button" class="btn btn-outline-secondary ms-2" v-sc-tooltip="'Remove'"
                                        @click.prevent="assignPageBackgroundResource(null)"><ScIcon name="minusCircleFW"/></button>
                            </div>
                        </EditorResourceThumb>

                        <div class="mt-3">
                            <p class="text-muted my-1">
                                <ScIcon name="questionCircle" /> Background color is supported in app versions 9+.</p>
                        </div>
                    </div>


                    <div v-else-if="currentPageAnimatedBackgroundResource" class="my-3">

                        <EditorResourceThumb :resourceId="currentPageAnimatedBackgroundResource.id">
                            <div class="col-12 my-auto ms-auto text-center px-0">
                                <div class="my-3">
                                    <button type="button" class="btn btn-outline-secondary"
                                            @click.prevent="chooseSlideBgVideo()"><ScIcon name="randomFW" class="me-1"/>Replace</button>
                                    <button type="button" class="btn btn-outline-secondary ms-2" v-sc-tooltip="'Remove'"
                                            @click.prevent="assignPageAnimatedBackgroundResource(null)"><ScIcon name="minusCircleFW"/></button>
                                </div>
                            </div>
                        </EditorResourceThumb>

                        <div class="mt-3">
                            <p class="text-muted my-1">
                                <ScIcon name="questionCircle" /> Background color is supported in app versions 9+.</p>
                        </div>
                    </div>
                </div>
                <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>
            </div>
        </div>
    </div>
</template>



<script>

import ScBootstrapUtils from '../../global/ScBootstrapUtils';
import ScIcon from '../../../shared/common/ScIcon.vue';
import PresentationShareable from '../../presentation/PresentationShareable.vue';
import LibraryMainModal from '../../library/LibraryMainModal.vue';
import EditorMixin from './../EditorMixin';
import EditorResourceThumb from './../EditorResourceThumb.vue';
import ScColorPicker from '../../global/ScColorPicker.vue';

export default {
    name: "EditorToolbarSlideBg",
    components: {LibraryMainModal, EditorResourceThumb, ScColorPicker, ScIcon},
    mixins: [EditorMixin],
    data () {
        return {
            openingCP: false // flag to indicate if cp is triggered to open for closeCP function
        }
    },
    methods: {
        openSharing() {
            PresentationShareable.modal(this.edMxStateShowcase.presentation.id, this.edMxCurrentPage.id,
                    this.edMxCurrentPage.alpha_num_name, this.currentPageBackgroundResource.id);
        },

        chooseSlideBg() {
            this.$refs.libraryMainModal.toggleBsModal({
                selectionMode: 'single', dialogType: 'modal',
                filterType: 'image'
            }, this.assignPageBackgroundResource);
        },
        chooseSlideBgVideo() {
            this.$refs.libraryMainModal.toggleBsModal({
                selectionMode: 'single', dialogType: 'modal',
                filterType: 'cinemagraph', specialValidateMode: 'bg-video-ok'
            }, this.assignPageAnimatedBackgroundResource);
        },
        assignPageBackgroundResource(selectedResources) {
            //console.log('assignPageBackgroundResource', selectedResources);
            // remove current file from sharing if enabled
            let hadBgBefore = false;
            let pg = Object.assign({}, this.edMxCurrentPage);
            if (this.currentPageBackgroundResource &&
                    this.currentPageBackgroundResource.name === pg.title) pg.title = 'None';

            if (this.currentPageBackgroundResource) {
                hadBgBefore = true;
                this.edMxRemoveFromSharingForCurrentPage(this.currentPageBackgroundResource.id);
            }
            let selectedResource = null;
            if (selectedResources && selectedResources.length > 0) {
                selectedResource = selectedResources[0];
                this.$store.dispatch('presResourceAddedFromLibrary', selectedResource);
            }
            if (selectedResource && pg.title === 'None') pg.title = selectedResource.name;
            pg.background_src_resource_id = selectedResource ? selectedResource.id : null;
            pg.page_animated_bg_id = selectedResource ? null : pg.page_animated_bg_id;
            this.$store.dispatch('presUndoableSavePage', pg);

            if (hadBgBefore) ScBootstrapUtils.ensureDropdownShown(this.$refs.slideBackgroundToggle);
            else ScBootstrapUtils.ensureDropdownHidden(this.$refs.slideBackgroundToggle);
        },
        assignPageAnimatedBackgroundResource(selectedResources) {
            //console.log('assignPageAnimatedBackgroundResource', selectedResources);
            let pg = Object.assign({}, this.edMxCurrentPage);
            if (this.currentPageAnimatedBackgroundResource &&
                    this.currentPageAnimatedBackgroundResource.name === pg.title) pg.title = 'None';

            let selectedResource = null;
            if (selectedResources && selectedResources.length > 0) {
                selectedResource = selectedResources[0];
                this.$store.dispatch('presResourceAddedFromLibrary', selectedResource);
            }
            if (selectedResource && pg.title === 'None') pg.title = selectedResource.name;
            pg.page_animated_bg_id = selectedResource ? selectedResource.id : null;
            pg.background_src_resource_id = selectedResource ? null : pg.background_src_resource_id;
            this.$store.dispatch('presUndoableSavePage', pg);
        },
        updatePageBgColor (color) {
            let pg = Object.assign({}, this.edMxCurrentPage);
            pg.page_bg_color = color ? color : '#000000';
            this.$store.dispatch('presUndoableSavePage', pg);
            ScBootstrapUtils.ensureDropdownShown(this.$refs.slideBackgroundToggle);
        }
    },

    computed: {
        currentPageBackgroundResource () {
            let res = null;
            if (this.edMxCurrentPage.background_src_resource_id) {
                res = this.$store.getters.presResource(this.edMxCurrentPage.background_src_resource_id);
            }
            return res;
        },
        currentPageAnimatedBackgroundResource () {
            let res = null;
            if (this.edMxCurrentPage.page_animated_bg_id) {
                res = this.$store.getters.presResource(this.edMxCurrentPage.page_animated_bg_id);
            }
            return res;
        }
    }
}

</script>
