

import ScNotification from '../shared/common/ScNotification.vue';
import $ from 'jquery';


let presTemplate = {
    id: 0,
    title: '',
    code: null,
    orientation: null,
    permissions: '',
    template: false,
    has_changes: true,
    publish_status: '',  // unpublished, published
    is_read_only: false,
    owner_workshop_name: null,
    thumbnail: null,
    total_tasks: 100,
    tasks_done: 0,
    percent_tasks_done_decimal: 0,
    version: 0,
    width: 0,
    height: 0,
    thumb_width: 0,
    thumb_height: 0
};

let labelTemplate = {
    id: null,
    name: '',
    bg_color: '#008ab0',
    presentation_ids: null,
    recently_added: false,
    sort_key: null
};

let _state = {
    presentationsLoading: false,
    presentations: [],
    labels: [],
    selectedLabelIds: [],
    plistPresListFilter: 'show-default',
};



let _getters = {
    // should always start with "plist" to avoid collisions with other modules


};


let _actions = {
    // should always start with "plist" to avoid collisions with other modules
    plistPresentationsLoad: (context, opts) => {
        // sc.workshopId, sc.showcaseId)
        context.commit('plistPresentationListLoading', true);
        $.ajax({
            type: 'GET', url: '/main/presentations/ajax_list',
            data: {workspace_id: opts.workspaceId}
        }).done(function(data) {
            context.commit('plistPresentationsLoaded', data);
            context.commit('plistPresentationListLoading', false);

        }).catch(function(jqXhr){
            context.commit('plistPresentationsLoaded', {presentations: {}});
            context.commit('plistPresentationListLoading', false);
            ScNotification.growlXhrError(jqXhr, 'fetching presentations');
        });
    },
    plistLabelsLoad: (context, opts) => {
        $.ajax({
            type: 'GET', url: "/main/presentations/ajax_labels_list",
            data: { workspace_id: opts.workspaceId }
        }).done(function(data) {
            context.commit('plistLabelsLoaded', data);

        }).catch(function(jqXhr){
            context.commit('plistLabelsLoaded', {labels: {}});
            ScNotification.growlXhrError(jqXhr, 'fetching labels');
        });
    },
    plistCancelImport: (context, opts) => {
        $.ajax({
            url: "/main/presentations/ajax_cancel_import",
            type: 'POST',
            data: { presentation_id: opts.presentationId, workspace_id: opts.workspaceId },
            success: () => {
                ScNotification.growlSuccessMsg('Import has been cancelled.');
                context.commit('plistPresDeleted', opts.presentationId);
            },
            error: () => {
                ScNotification.growlErrMsg('Import cancellation failed.');
            }
        });
    },
    plistSetTitle: (context, opts) => {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST', url: '/main/editor/ajax_change_title',
                data: { workspace_id: opts.workspaceId, presentation_id: opts.presId, title: opts.newTitle}
            }).done((response) => {
                if (response.status === 'ok') {
                    context.commit('plistComTitle', opts);
                    resolve();
                }
                if (response.status === 'error' && response.code === 'title-taken') {
                    ScNotification.growlErrMsg('Dang! That title\'s taken. Try another?');
                }
                if (response.status === 'error' && response.msg){
                    ScNotification.growlErrMsg(response.msg);
                }
                reject();

            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'when saving title');
                reject();
            });
        });
    },
};


let _mutations = {
    // should always start with "plist" to avoid collisions with other modules

    plistPresentationListLoading: (state, isLoading) => {
        state.presentationsLoading = isLoading;
    },

    plistPresentationsLoaded: (state, listing) => {
        state.presentations.splice(0);
        Object.values(listing.presentations).forEach((pres) => {
            let p = Object.assign({}, presTemplate, pres);
            state.presentations.push(p);
        });
    },

    plistLabelsLoaded: (state, listing) => {
        state.labels.splice(0);
        Object.values(listing.labels).forEach((label) => {
            let l = Object.assign({}, labelTemplate, label);
            state.labels.push(l);
        });
    },

    plistLabelsUpdated: (state, listing) => {
        let label = state.labels.find(t => t.id === listing.label_id);
        if (label){
            label.name = listing.label_name;
            label.bg_color = listing.label_color;
        }
    },
    plistLabelsSortingUpdated: (state, listing) => {
        let label = state.labels.find(t => t.id === listing.label.id);
        let l_key = label.sort_key;
        let sort_with_label = state.labels.find(t => t.id === listing.sort_with_label.id);
        let s_l_key = sort_with_label.sort_key;

        if (label && sort_with_label) {
            label.sort_key = s_l_key;
            sort_with_label.sort_key = l_key;
            // apply new sorting to state.labels
            state.labels.sort((a,b) => a.sort_key - b.sort_key)
        }
    },

    plistLabelDeleted: (state, labelId) => {
        let labelIdx = state.labels.findIndex((l) => { return l.id === labelId; });
        if (labelIdx !== -1) state.labels.splice(labelIdx, 1);
    },

    plistPresDeleted: (state, presId) => {
        let presIdx = state.presentations.findIndex((p) => {
             return p.id === presId;
        });
        if (presIdx !== -1) state.presentations.splice(presIdx, 1);
    },

    plistPresAdded: (state, pres) => {
        // console.log('plistPresAdded', pres);
        let p = Object.assign({}, presTemplate, {
            code: pres.code,
            orientation: pres.orientation,
            id: pres.presentation_id,
            // scroll_into_view: false,
            title: pres.title,
            publish_status: 'importing',
            permissions: pres.permissions,
            total_tasks: 100,
            tasks_done: 0
        });
        state.presentations.push(p);
    },

    plistPresImportProgress: (state, presArgs) => {
        // console.log('plistPresImportProgress', presArgs);
        let pres = state.presentations.find((p) => { return p.id === presArgs.presentation_id});
        if (pres) {
            pres.total_tasks = presArgs.total_tasks;
            pres.tasks_done = presArgs.tasks_done;
        }
    },

    plistPresImportPageDone: (state, presArgs) => {
        // console.log('plistPresImportPageDone', presArgs);
        let pres = state.presentations.find((p) => { return p.id === presArgs.presentation_id});
        if (pres) {
            pres.tasks_done = pres.tasks_done +1;
        }
    },

    plistPresImportComplete: (state, presArgs) => {
        // console.log('plistPresImportComplete', presArgs);
        let pres = state.presentations.find((p) => { return p.id === presArgs.presentation_id});
        if (pres) {
            pres.title = presArgs.title;
            pres.code = presArgs.code;
            pres.is_read_only = presArgs.is_read_only;
            pres.orientation = presArgs.orientation;
            pres.publish_status = presArgs.published ? 'published' : 'unpublished';
            pres.thumbnail = presArgs.thumbnail;
            pres.width = presArgs.width;
            pres.height = presArgs.height;
            pres.thumb_width = presArgs.thumb_width;
            pres.thumb_height = presArgs.thumb_height;
            pres.version = presArgs.version;
        }
    },

    plistPresPublishStatusChange: (state, presArgs) => {
        // console.log('plistPresPuslighStatusChange', presArgs);
        let pres = state.presentations.find((p) => { return p.id === presArgs.presentation_id});
        if (pres) {
            pres.publish_status = presArgs.publish_status;
            pres.has_changes = false;
        }
    },

    plistNewLabel: (state, labelArgs) => {
        let existingLabel = state.labels.find((l) => { return l.id === labelArgs.id });
        if (!existingLabel) {
            let l = Object.assign({}, labelTemplate, labelArgs);
            state.labels.push(l);
        }
    },

    plistLabelsAssigned: (state, payload) => {
        // this.$store.commit('plistLabelsAssigned', payload);
        state.labels.forEach((label) => {
            let labelSelected = payload.labelIds.indexOf(label.id) !== -1;
            let currentIdx = label.presentation_ids.indexOf(payload.presentationId);
            //console.log('label', label.name, labelSelected, currentIdx);
            if (!labelSelected && currentIdx !== -1) label.presentation_ids.splice(currentIdx, 1);
            if (labelSelected && currentIdx === -1) label.presentation_ids.push(payload.presentationId);

            if (label.presentation_ids.length === 0) {  // no presentations, de-select is selected
                let selectedLabelIdx = state.selectedLabelIds.indexOf(payload.presentationId) !== -1;
                if (selectedLabelIdx !== -1) state.selectedLabelIds.splice(selectedLabelIdx, 1);
            }
        });
    },

    pListLabelToggleSelected: (state, labelId) => {
        let labelIdx = state.selectedLabelIds.indexOf(labelId);
        if (labelIdx !== -1) state.selectedLabelIds.splice(labelIdx, 1);
        else state.selectedLabelIds.push(labelId);
    },

    pListLabelReset: (state) => {
        state.selectedLabelIds.splice(0);
        state.plistPresListFilter = 'show-default';
    },

    pListSetPresListFilter: (state, filter) => {
        state.plistPresListFilter = filter;
    },

    plistComTitle: (state, payload) => {
        let pres = state.presentations.find((p) => { return p.id === payload.presId});
        if (pres) {
            pres.title = payload.newTitle;
            pres.has_changes = true;
        }
    },

    plistPresHasChanges: (state, presId) => {
        let pres = state.presentations.find((p) => { return p.id === presId});
        if (pres) {
            pres.has_changes = true;
        }
    },

    plistPresChangePermissionsType: (state, presArgs) => {
        let pres = state.presentations.find((p) => { return p.id === presArgs.presId });
        if (pres) {
            pres.permissions = presArgs.permissions;
        }
    }

};


export default {
    state: _state,
    getters: _getters,
    actions: _actions,
    mutations: _mutations
};
