<template>
  <div :style="{left: flyoutPosLeft, top: flyoutPosTop}"
       class="hotspot-flyout p-1 rounded shadow-lg btn-outline-secondary">

    <button v-if="targetPage"
       type="button" class="btn-sc-flyout"
       v-sc-tooltip="'Edit Link to ' + targetPage.alpha_num_name"
       @pointerdown.prevent.stop="showHotspotLink()">
      <ScIcon name="shareFW" class="text-dark"/></button>

    <button v-if="targetPage"
       type="button" class="btn-sc-flyout fw-bold mx-1" style="color: #008ab0;"
       v-sc-tooltip="'Go to ' + targetPage.alpha_num_name"
       @pointerdown.prevent.stop="edMxNavToPage(targetPage.id)">
      {{ targetPage.alpha_num_name }}</button>

    <button v-if="targetResource && targetResource.content_type !== 'weburl'"
       type="button" class="btn-sc-flyout"
       v-sc-tooltip="'Edit link to file: '+ targetResource.name"
       @pointerdown.prevent.stop="showHotspotLink()">
      <ScIcon :name="edMxResourceIconCode(targetResource)" class="text-dark"/></button>

    <button v-if="targetResource && targetResource.content_type === 'weburl'"
       type="button" class="btn-sc-flyout"
       v-sc-tooltip="'Edit URL: '+ targetResource.name"
       @pointerdown.prevent.stop="showHotspotLink()">
      <ScIcon :name="edMxResourceIconCode(targetResource)" class="text-dark"/></button>

    <span v-if="hsHotspotIsLocked"
          v-sc-tooltip="'Hotspot locked'">
      <ScIcon name="lockFW" class="text-muted"/></span>

    <button v-if="(!targetPage && !targetResource)"
       type="button" class="btn-sc-flyout"
       v-sc-tooltip="'Add Content'"
       @pointerdown.prevent.stop="showHotspotLink()">
      <ScIcon name="plusFW" class="text-dark"/></button>

  </div>
</template>

<script>

  import EditorMixin from "./EditorMixin";
  import ScIcon from "../../shared/common/ScIcon.vue";

  export default {
    name: "EditorHotspotFlyout",
    mixins: [EditorMixin],
    components: { ScIcon },
    props: {
      hsHotspot: {type: Object, default: null},
      hotspotIsSelected: {type: Boolean, default: false},
      scaledPosX: {type: Number, default: 0},
      scaledPosY: {type: Number, default: 0},
      scaledWidth: {type: Number, default: 0}
    },
    data () {
      return {
        flyoutPosLeft: 0,
        flyoutPosTop: 0
      }
    },
    mounted () {
      //console.log('mounted EditorHotspotFlyout');
      this.calcSize();
    },

    methods: {
      showHotspotLink () {
        //console.log('showHotspotLink');
        this.$store.commit('edSelectedHotspotIdsReset');
        this.$store.commit('edSelectedHotspotIdsAdd', [this.hsHotspot.id]);
        this.$store.commit('edSetTextEditHotspotId', null);
        setTimeout(() => {
          document.dispatchEvent(new CustomEvent('sc_event:hotspot_link_show_details', {detail: {}}));
        }, 250);
      },
      calcSize () {
        if (!this.scaledWidth) return;
        this.$nextTick(() => {
          let hsMarginLeft = (this.scaledWidth - this.$el.offsetWidth) / 2;
          this.flyoutPosLeft = Math.floor(this.scaledPosX + hsMarginLeft + 2.5) + 'px';
          // if hotspot is small and selected we nudge it up a little so it doesn't get in the way of resize handles
          let topPosNudge = this.scaledWidth < (2 * this.$el.offsetWidth) && this.hotspotIsSelected ? 3 : 0;
          this.flyoutPosTop = Math.floor(this.scaledPosY - this.$el.offsetHeight - topPosNudge) + 'px';
        })
      }
    },
    computed: {
      targetResource () {
        return (this.hsHotspot.target_resource_id) ? this.$store.getters.presResource(this.hsHotspot.target_resource_id) : null;
      },
      targetPage () {
        return (this.hsHotspot.target_page_id) ? this.$store.getters.presPage(this.hsHotspot.target_page_id) : null;
      },
      hsHotspotIsLocked () {
        return this.hsHotspot.is_locked;
      },
      currentHotspotBrandingAdditionalStyles() {
        return this.$store.getters.presHotspotBrandingAdditionalStylesObjByHsId(this.hsHotspot.id);
      },
    },
    watch: {
      scaledPosX() {
        this.calcSize();
      },
      scaledPosY() {
        this.calcSize();
      },
      scaledWidth() {
        this.calcSize();
      },
      hotspotIsSelected() {
        this.calcSize();
      },
    }

  }
</script>

<style scoped>

  .hotspot-flyout {
    position: absolute;
    border: 2px solid #c0c0c0;
    background-color: white;
    z-index: 130; /* see README.md webapp z-index values */
    min-width: 25px;
  }
  .btn-sc-flyout {
      border: 0;
      padding: 0;
      margin: 0;
      background-color: transparent;
  }

</style>