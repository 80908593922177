<template>
  <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
         @vb-hidden-bs-modal="isModalShown=false"
         class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content shadow-lg">
          <div class="modal-body">
            <button type="button" class="btn-close" @click.prevent="hideModal"
                    style="margin: -0.5rem;color: black;opacity: 0.6;float:right;" aria-hidden="true"></button>

            <div class="pb-1 fw-bold">Apply Color</div>
            <form class="d-flex" @submit.prevent>
              <div class="d-flex align-items-center">
                <div class="input-group">
                  <ScIcon name="hashtag" class="input-group-text px-2"
                          style="border-top-left-radius: .5rem; border-bottom-left-radius: .5rem"/>
                  <input :class="['form-control form-control-sm text-center', {'is-invalid': hasError }]"
                         v-model="manualInputColor" size="10" maxlength="6"
                         @change="selectManualInputColor"
                         placeholder="Hex"/>
                </div>
                <input type="color" id="browserColorPicker" name="color"
                       v-model="browserPickerColor" @change="selectBrowserPickerVal"
                       style="min-width: 36px; height: 36px; background-color: white;" class="border mx-1"/>
                <button :disabled="selectedColor === origColor"
                        @click.prevent="hideModal()"
                        class="btn btn-sm btn-outline-secondary">Apply</button>
              </div>
            </form>
            <div class="mt-3" v-if="(scColors && scColors.length > 0)">
              <div class="pb-1 fw-bold">Presentation Colors
                <small class="text-muted"><ScIcon name="questionCircle"
                          v-sc-tooltip="'Colors currently used in this presentation'"></ScIcon>
                </small></div>
              <div class="d-flex flex-wrap">
                <template v-for="(loopColor, idx) in scColors" :key="'sc' + idx">
                  <button type="button" @click.prevent="selectColor(loopColor)"
                          :class="['cp-modal-color',
                                    loopColor === 'FFFFFF' ? 'cp-modal-color-white' : '',
                                    loopColor === selectedColor ? 'cp-modal-color-selected' : '']"
                          :title="loopColor" :style="{ backgroundColor: '#' + loopColor }"></button>
                </template>
              </div>
            </div>
            <div class="mt-3">
              <div class="pb-1 fw-bold">System Colors</div>
              <div v-for="(paletteLine, lineIdx) in systemPalette" class="d-flex" :key="lineIdx">
                <template v-for="(loopColor, idx) in paletteLine">
                  <button type="button" v-if="loopColor === 'transparent' && hasTransparent"
                          :key="'syt'+idx" @click.prevent="selectTransparent" v-sc-tooltip="'Transparent'"
                          :class="['cp-modal-color cp-modal-transparent-link', 'transparent' === selectedColor ? 'cp-modal-color-selected' : '']">
                    <ScIcon name="tintSlashFW"/>
                  </button>
                  <button type="button" v-else-if="loopColor !== 'transparent'" :key="'syc'+idx"
                          @click.prevent="selectColor(loopColor)"
                          :class="['cp-modal-color',
                                    loopColor === 'FFFFFF' ? 'cp-modal-color-white' : '',
                                    loopColor === selectedColor ? 'cp-modal-color-selected' : '']"
                          :title="loopColor" :style="{ backgroundColor: '#' + loopColor }"></button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>


<script>

import ScIcon from '../../shared/common/ScIcon.vue';

let _ins = null;

export default {
  name: 'ScColorPickerModal',
  components: {ScIcon},
  data () {
    return {
      systemPalette: [
        ['000000', '434343', '666666', 'CCCCCC', 'D9D9D9', 'FFFFFF', 'transparent'],
        ['E6B8AF', 'F4CCCC', 'FCE5CD', 'FFF2CC', 'D9EAD3', 'D0E0E3', 'C9DAF8', 'CFE2F3', 'D9D2E9', 'EAD1DC'],
        ['DD7E6B', 'EA9999', 'F9CB9C', 'FFE599', 'B6D7A8', 'A2C4C9', 'A4C2F4', '9FC5E8', 'B4A7D6', 'D5A6BD'],
        ['CC4125', 'E06666', 'F6B26B', 'FFD966', '93C47D', '76A5AF', '6D9EEB', '6FA8DC', '8E7CC3', 'C27BA0'],
        ['A61C00', 'CC0000', 'E69138', 'F1C232', '6AA84F', '45818E', '3C78D8', '3D85C6', '674EA7', 'A64D79'],
        ['980000', 'FF0000', 'FF9900', 'FFFF00', '00FF00', '00FFFF', '4A86E8', '0000FF', '9900FF', 'FF00FF'],
        ['5B0F00', '660000', '783F04', '7F6000', '274E13', '0C343D', '1C4587', '737630', '20124D', '4C1130']
      ],
      hasError: false,
      isModalShown: false,
      successFn: null,
      scColors: null,

      origColor: 'FFFFFF',
      selectedColor: 'FFFFFF',
      manualInputColor: 'FFFFFF',
      browserPickerColor: '#FFFFFF',
      hasTransparent: true,
      isDisabled: false,
    };
  },
  mounted() {
    _ins = this;
  },
  beforeUnmount() {
    document.body.classList.remove('cp-modal-active'); // just in case, see in watch also
    _ins = null;
  },
  methods: {
    hideModal() {
      if (this.selectedColor !== this.origColor) {
        if (this.selectedColor === 'transparent') this.successFn('transparent');
        else this.successFn('#' + this.selectedColor);
      }
      this.$refs.scModal.$vb.modal.hide();
    },
    selectTransparent() {
      this.selectedColor = 'transparent';
      this.hideModal();
    },
    selectColor(color) {
      this.selectedColor = color;
      this.hideModal();
    },
    selectBrowserPickerVal() {
      //console.log('selectBrowserPickerVal', this.browserPickerColor);
      if (this.browserPickerColor && /^#[0-9A-F]{6}$/i.test(this.browserPickerColor)) {
        this.selectedColor = this.browserPickerColor.substring(1, 7).toUpperCase();
        this.manualInputColor = this.selectedColor;
      }
    },
    selectManualInputColor() {
      this.hasError = false;
      if (!this.manualInputColor) {
        this.hasError = true;
      } else if (this.manualInputColor.toLowerCase() === "transparent") {
        this.selectedColor = 'transparent';
      } else {
        let col = this.manualInputColor.toUpperCase();
        if (/^[0-9A-F]{6}$/i.test(col)) {
          this.selectedColor = col;
          this.browserPickerColor = '#' + col;
        } else {
          this.hasError = true;
        }
      }
    },
    toggleBsModal(options, successCallback) {
      //console.log('open modal', options);
      this.origColor = options.color;
      this.selectedColor = options.color;
      this.manualInputColor = options.color;
      this.browserPickerColor = options.color === 'transparent' ? '#FFFFFF' : '#' + options.color;

      this.scColors = options.scColors;
      this.hasTransparent = options.hasTransparent;
      this.isDisabled = options.isDisabled;
      this.hasError = false;
      this.successFn = successCallback;
      this.isModalShown = true;
    }
  },
  watch: {
    isModalShown() {
      if (this.isModalShown) {
        document.body.classList.add('cp-modal-active');
      } else {
        document.body.classList.remove('cp-modal-active');
      }
    }
  },


  //
  // public functions
  //

  modal (options, successCallback) {
    _ins.toggleBsModal(options, successCallback)
  }


}

</script>

<style>

.cp-modal-transparent-link {
    font-size: 12px;
    padding: 0;
    margin: 0;
    background-color: #eeeeee;
    color: #000000;
}
.cp-modal-color {
    width: 25px;
    height: 25px;
    display: inline-block;
    border: 1px solid transparent;
}
.cp-modal-color:hover {
    text-decoration: none;
    border: 1px solid #008ab0;
}
.cp-modal-color-white {
    border: 1px solid #D9D9D9;
}
.cp-modal-color-selected {
    border: 1px solid #000000;
}
body.cp-modal-active .modal-backdrop.show {
  opacity: 0;
}

</style>
