<template>
  <div class="dropdown">
    <button type="button" class="btn px-md-3 text-start dropdown-toggle sc-highlight-expanded"
            :class="[hasBorder ? 'btn-outline-secondary' : '']"
            data-bs-toggle="dropdown"
            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
            :style="{width: shortenFontName ? '80px' : (hasBorder ? '250px' : '150px')}"
            :disabled="isDisabled">
      <span v-if="currentFontId" :style="getFontStyles(selectedFont)">{{ selectedFont.name }}</span>
      <span v-if="!currentFontId">Multiple Fonts</span>
    </button>

    <div class="dropdown-menu menu-fonts" role="menu">

      <template v-if="showShowcaseFonts">
        <div class="dropdown-header border-bottom-0">Presentation Fonts
          <small class="text-muted me-3">
            <ScIcon name="questionCircle" v-sc-tooltip="'Fonts currently used in this presentation'"/>
          </small>
        </div>

        <a :key="'sc-font-' + font.id" v-for="font in showcaseFonts"
           :class="{'dropdown-item': 1, active: selectedFont && selectedFont.id === font.id}"
           href="#" @click.prevent="returnSelectedFont(font)"
           :style="getFontStyles(font)">
          {{ font.name }}</a>
        <div class="dropdown-header border-top pt-3 mt-2 border-bottom-0">All Fonts</div>
      </template>

      <a v-for="font in selectableFonts" :key="'font-' + font.id"
         :class="{'dropdown-item': 1, active: selectedFont && selectedFont.id === font.id}"
         href="#" @click.prevent="returnSelectedFont(font)"
         :style="getFontStyles(font)">{{ font.name }}</a>
    </div>

  </div>
</template>


<script>

    import ScIcon from '../../shared/common/ScIcon.vue';


    export default {
      name: 'ScFonts',
      emits: ['selected-font-changed'],
      components: {ScIcon},
      data () {
        return {};
      },
      props: {
        currentFontId: {type: Number, default: null},
        isDisabled: {type: Boolean, default: false},
        showShowcaseFonts: {type: Boolean, default: false},
        shortenFontName: {type: Boolean, default: false},
        hasBorder: {type: Boolean, default: false}
      },
      methods: {
        returnSelectedFont (font) {
          this.$emit('selected-font-changed', font);
        },
        getFontStyles (font) {
          if (!font) return {};
          return {
            'font-weight': 'normal',
            'font-style': 'normal',
            'font-family': font.cssFamily,
            'font-size': '1rem'
          };
        },

      },
      computed: {
        loadedFonts () {
          //console.log(this.$store.state.fonts.loadedFonts)
          return this.$store.state.fonts.loadedFonts;
        },
        selectedFont () {
          if (!this.currentFontId) return null;
          return this.$store.getters.fontsFontForId(Number(this.currentFontId));
        },
        selectableFonts () {
          return this.$store.getters.fontsSelectable;
        },
        showcaseFonts () {
          let usedFonts = [];
          if (this.showShowcaseFonts) {
            let scData = this.$store.state.pres.showcase;
            if (scData) {
              Object.values(scData.hotspot_branding).forEach((hsb) => {
                this.loadedFonts.filter((font) => {
                  if (font.id === hsb.font_resource_id && font.name !== 'Museo Slab' && !usedFonts.includes(font)) {
                    usedFonts.push(font)
                  }
                });
              });
            }
          }
          usedFonts = usedFonts.sort((a, b) => a.name.localeCompare(b.name));
          return usedFonts;
        }

      }
    }

</script>

<style scoped>

    .menu-fonts {
      height: auto;
      max-height: 60vh;
      overflow-x: hidden;
    }

    .dropdown-toggle::after {  /* make sure toggle is ALWAYS positioned on right edge */
      right: 10px;
      top: 21px;
      position: absolute;
    }
    .dropdown-toggle:hover {
      cursor: pointer;
    }

</style>

