<template>
    <div v-if="edMxCurrentPage && edMxSelectedHotspots.length > 0" class="d-inline-block">
        <div class="dropdown navbar-light mx-1 my-auto">
            <button class="btn btn-outline-secondary dropdown-toggle sc-highlight-expanded text-truncate active fw-bold"
                    data-bs-toggle="dropdown" role="button" aria-expanded="false"
                    style="max-width: 180px; min-width: 72px"
                    ref="hotspotLinkToggle"
                    v-sc-tooltip="currentHotspotTargetWeburl ? 'Hotspot URL' :
                                  currentHotspotTargetResource ? 'Hotspot File' :
                                  currentHotspotTargetPage ? 'Hotspot Link' : 'Add Hotspot Content'"
                    :disabled="!edMxLoneSelectedHotspot || (edMxLoneSelectedHotspot && edMxLoneSelectedHotspotLocked && !currentHotspotHasTarget)">
                <span v-if="currentHotspotTargetWeburl">
                    <ScIcon name="linkFW"/>
                    <span v-if="!hideTargetName" class="ms-1">{{currentHotspotTargetWeburl.weburl}}</span>
                </span>
                <span v-else-if="currentHotspotTargetResource">
                    <ScIcon :name="edMxResourceIconCode(currentHotspotTargetResource)"/>
                    <span v-if="!hideTargetName" class="ms-1">{{currentHotspotTargetResource.name}}</span>
                </span>
                <span v-else-if="currentHotspotTargetPage">
                    <ScIcon name="shareFW"/>
                    <span v-if="!hideTargetName" class="ms-1">Slide {{currentHotspotTargetPage.alpha_num_name}}</span>
                </span>
                <span v-else>
                    <ScIcon name="plusFW"/>
                    <span v-if="!hideTargetName" class="ms-1">Add Content</span>
                </span>
            </button>
            <div class="dropdown-menu" @click.prevent.stop>

                <div class="my-2 mx-3" style="width: 320px;">

                    <h4 class="text-center">
                        <span v-if="currentHotspotTargetWeburl">Hotspot URL</span>
                        <span v-else-if="currentHotspotTargetResource">Hotspot File</span>
                        <span v-else-if="currentHotspotTargetPage">Hotspot Link</span>
                        <span v-else>Add Hotspot Content</span>
                    </h4>

                    <div v-if="currentHotspotTargetWeburl" class="my-3">

                        <div class="my-2 text-break" style="max-height: 100px; overflow-y: auto;">
                            <samp>{{ currentHotspotTargetWeburl.weburl }}</samp>
                        </div>

                        <div  class="my-2">
                            Opened
                            <i v-if="currentHotspotTargetWeburl.openExternally">externally</i>
                            <i v-else>internally</i>
                            in the Showcase app(s),

                            <i v-if="!currentHotspotTargetWeburl.browserOpensInline">externally</i>
                            <i v-else>internally</i>
                            when viewed in browser.
                        </div>

                        <div class="mt-3 mb-1">
                            <button type="button" class="btn btn-outline-secondary" v-sc-tooltip="'Replace'"
                                    :disabled="!!edMxLoneSelectedHotspotLocked"
                                    @click.prevent="chooseHotspotWeburl(currentHotspotTargetWeburl)"
                            ><ScIcon name="editFW"/> Edit</button>
                            <button type="button" class="btn btn-outline-secondary ms-2 float-end"
                                    :disabled="!!edMxLoneSelectedHotspotLocked"
                                    @click.prevent="assignHotspotTargetResource(null)">Remove</button>
                        </div>

                    </div>
                    <div v-else-if="currentHotspotTargetResource" class="my-3">
                        <EditorResourceThumb :resourceId="currentHotspotTargetResource.id">
                            <div class="col-12 my-auto ms-auto text-center px-0">
                                <div class="my-3">
                                    <button type="button" class="btn btn-outline-secondary"
                                            :disabled="!!edMxLoneSelectedHotspotLocked"
                                            @click.prevent="replaceHotspotTargetResource"
                                    ><ScIcon name="randomFW" class="me-1"/>Replace</button>
                                    <button type="button" class="btn btn-outline-secondary ms-2" v-sc-tooltip="'Remove'"
                                            :disabled="!!edMxLoneSelectedHotspotLocked"
                                            @click.prevent="assignHotspotTargetResource(null)"
                                    ><ScIcon name="minusCircleFW"/></button>
                                </div>

                                <div>
                                    <button class="btn btn-outline-secondary" @click.prevent="openSharing"
                                            :disabled="!!edMxLoneSelectedHotspotLocked"
                                            v-sc-trackevent="'editor:managesharingfile'">
                                        <ScIcon name="shareSquareFW"/> Sharing for this file
                                    </button>
                                </div>

                                <div v-if="currentHotspotTargetResource.content_type === 'movie'"
                                     class="form-check my-3 d-flex justify-content-center">
                                    <input class="form-check-input me-2" type="checkbox" id="hs-target-video-loops"
                                           :checked="currentHotspotTargetVideoLoops"
                                           :disabled="!!edMxLoneSelectedHotspotLocked"
                                           @click.stop="toggleCurrentHotspotVideoLoops(!currentHotspotTargetVideoLoops)">
                                    <label for="hs-target-video-loops" class="form-check-label"
                                           @click="toggleCurrentHotspotVideoLoops(!currentHotspotTargetVideoLoops)"> Video loops until closed
                                    </label>
                                </div>
                            </div>
                        </EditorResourceThumb>
                    </div>

                    <div v-else-if="currentHotspotTargetPage" class="my-3">
                        <EditorPageThumb :pageId="currentHotspotTargetPage.id"
                                         @select-page="pageSelected(currentHotspotTargetPage)"></EditorPageThumb>
                        <div class="mt-3 text-center">
                            <button type="button" class="btn btn-outline-secondary"
                                    :disabled="!!edMxLoneSelectedHotspotLocked"
                                    @click.prevent="chooseHotspotTargetPage()"
                            ><ScIcon name="randomFW" class="me-1"/>Replace</button>
                            <button class="btn btn-outline-secondary ms-2" v-sc-tooltip="'Remove'"
                                    :disabled="!!edMxLoneSelectedHotspotLocked"
                                    @click.prevent="assignHotspotTargetPage(null)"
                            ><ScIcon name="minusCircleFW"/></button>
                        </div>
                    </div>

                    <div v-else class="my-3 row g-0">
                        <div class="col-6 pe-1">
                            <button class="btn btn-outline-secondary w-100 mb-2 px-0" role="button"
                                    @click.prevent="newPagelistWithImagesForHotspot()">New Image Slide(s)</button>
                            <button class="btn btn-outline-secondary w-100 mb-2" role="button"
                                    @click.prevent="chooseHotspotTargetPage()">Existing Slide</button>
                            <button class="btn btn-outline-secondary w-100 mb-2" role="button"
                                    @click.prevent="chooseHotspotWeburl(null)">URL</button>
                        </div>
                        <div class="col-6 ps-1">
                            <button class="btn btn-outline-secondary w-100 mb-2" role="button"
                                    @click.prevent="newPagelistForHotspot()">New Blank Slide</button>
                            <button class="btn btn-outline-secondary w-100 mb-2" role="button"
                                    @click.prevent="chooseHotspotTargetResource('movie')">Video</button>
                            <button class="btn btn-outline-secondary w-100 mb-2" role="button"
                                    @click.prevent="chooseHotspotTargetResource('document')">Document</button>
                        </div>
                    </div>
                </div>

                <EditorToolbarHotspotPin :currentHotspotHasTarget="currentHotspotHasTarget"
                                         :allowToggle="!edMxLoneSelectedHotspotLocked"/>
            </div>
        </div>
        <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>
    </div>
</template>



<script>

import LibraryMainModal from '../../library/LibraryMainModal.vue';
import EditorMixin from './../EditorMixin';
import EditorWeburlModal from './../EditorWeburlModal.vue';
import EditorFindPageModal from './../EditorFindPageModal.vue';
import EditorPageThumb from './../EditorPageThumb.vue';
import EditorToolbarHotspotPin from './EditorToolbarHotspotPin.vue';
import EditorResourceThumb from './../EditorResourceThumb.vue';
import ScBootstrapUtils from '../../global/ScBootstrapUtils';
import PresentationShareable from '../../presentation/PresentationShareable.vue';
import ScIcon from '../../../shared/common/ScIcon.vue';

export default {
    name: "EditorToolbarHotspotTarget",
    mixins: [EditorMixin],
    components: {EditorPageThumb, EditorResourceThumb, EditorToolbarHotspotPin, ScIcon, LibraryMainModal},
    props: {
        hideTargetName: {type: Boolean, default: false} // needed for toolbar responsive behavior
    },
    mounted: function() {
        document.addEventListener('sc_event:hotspot_link_show_details', this.hotspotLinkShowDetails);
    },
    beforeUnmount: function () {
        document.removeEventListener('sc_event:hotspot_link_show_details', this.hotspotLinkShowDetails);
    },
    methods: {

        hotspotLinkShowDetails() {
            if (!this.edMxLoneSelectedHotspot) return;
            ScBootstrapUtils.ensureDropdownShown(this.$refs.hotspotLinkToggle)
        },

        chooseHotspotTargetResource(type) {
            this.$refs.libraryMainModal.toggleBsModal({
                selectionMode: 'single', dialogType: 'modal',
                filterType: type
            },  this.assignHotspotTargetResource);
        },

        chooseHotspotWeburl(targetWeburlObj) {
            EditorWeburlModal.modal(targetWeburlObj, (updatedTargetWeburlObj) => {
                this.$store.dispatch('presNewWeburlResource', updatedTargetWeburlObj).then((resId) => {
                    let hs = Object.assign({}, this.edMxLoneSelectedHotspot);
                    hs.target_resource_id = resId;
                    this.$store.dispatch('presUndoableSaveHotspotBatch', [hs]);
                }, () => {});
                if (!targetWeburlObj) ScBootstrapUtils.ensureDropdownHidden(this.$refs.hotspotLinkToggle);
            });
        },

        replaceHotspotTargetResource () {
            if (this.currentHotspotTargetResource) {
                this.chooseHotspotTargetResource(this.currentHotspotTargetResource.content_type);
            }
        },

        assignHotspotTargetResource(resources) {
            // remove current file from sharing if enabled
            if (this.currentHotspotTargetResource) {
                this.edMxRemoveFromSharingForCurrentPage(this.currentHotspotTargetResource.id);
                this.edMxRemoveFromSharingForShowcase(this.currentHotspotTargetResource.id);
            }

            let resId = null;
            if (resources && resources.length > 0) {
                this.$store.dispatch('presResourceAddedFromLibrary', resources[0]);
                resId = resources[0].id;

                if (this.$store.getters.userCurrentWorkspaceObj.shareable_on_slide_by_default) {
                    // add to shareable on this slide
                    this.$store.dispatch('presToggleShareableResource', {
                        resource_id: resId, page_id: this.edMxCurrentPage.id, enabled: true
                    });
                }
            }
            let hs = Object.assign({}, this.edMxLoneSelectedHotspot);
            hs.target_resource_id = resId;
            this.$store.dispatch('presUndoableSaveHotspotBatch', [hs]);
            if (resources && resources.length > 0) this.hotspotLinkShowDetails();
        },

        openSharing() {
            PresentationShareable.modal(this.edMxStateShowcase.presentation.id, this.edMxCurrentPage.id,
                    this.edMxCurrentPage.alpha_num_name, this.currentHotspotTargetResource.id);
        },

        toggleCurrentHotspotVideoLoops (on) {
            if (this.edMxLoneSelectedHotspotLocked) return;
            let hs = Object.assign({}, this.edMxLoneSelectedHotspot);
            hs.is_movie_looping = on;
            this.$store.dispatch('presUndoableSaveHotspotBatch', [hs]);
        },
        assignHotspotTargetPage(pageId) {
            let hs = Object.assign({}, this.edMxLoneSelectedHotspot);
            hs.target_page_id = pageId;
            this.$store.dispatch('presUndoableSaveHotspotBatch', [hs]);
            if (pageId) this.hotspotLinkShowDetails();
        },

        chooseHotspotTargetPage() {
            EditorFindPageModal.modal({  // modal will close itself automatically
                pageChooseMode: true, linkToHotspotId: this.edMxLoneSelectedHotspot.id,
                success: this.assignHotspotTargetPage
            });
        },




        newPagelistForHotspot() {
            this.$store.dispatch('presNewPagelist', {
                existing_hotspot_id: this.edMxLoneSelectedHotspot.id,
                new_hotspot_page_id: null,
                page_background_res_ids: null
            }).then(() => {
                this.hotspotLinkShowDetails();
            });
        },

        assignBgResourcesToNewPagelistForHotspot(slideBgResources) {
            let bgResIds = [];
            slideBgResources.forEach((res) => {
                this.$store.dispatch('presResourceAddedFromLibrary', res);
                bgResIds.push(res.id);
            });
            this.$store.dispatch('presNewPagelist', {
                existing_hotspot_id: this.edMxLoneSelectedHotspot.id,
                new_hotspot_page_id: null,
                page_background_res_ids: bgResIds
            }).then(() => {
                this.hotspotLinkShowDetails();
            });
        },

        newPagelistWithImagesForHotspot() {
            this.$refs.libraryMainModal.toggleBsModal({
                selectionMode: 'multiple', dialogType: 'modal',
                filterType: 'image'
            },  (resources) => {
                if (!resources || resources.length === 0) return;
                this.assignBgResourcesToNewPagelistForHotspot(resources);
            });
        },



        assignBgResourcesToNewPagelist(slideBgResources) {
            let bgResIds = [];
            slideBgResources.forEach((res) => {
                this.$store.dispatch('presResourceAddedFromLibrary', res);
                bgResIds.push(res.id);
            });
            this.$store.dispatch('presNewPagelist', {
                existing_hotspot_id: null,
                new_hotspot_page_id: this.edMxStateEd.currentPageId,
                page_background_res_ids: bgResIds
            }).then((n) => { this.edMxSelectHotspot(n, false) });
        },

        pageSelected (page) {
            if ("activeElement" in document) document.activeElement.blur();
            this.edMxNavToPage(page.id)
        },
    },
    computed: {

        currentHotspotTargetWeburl: function() {
            let targetWeburl = null;
            if (this.currentHotspotTargetResource && this.currentHotspotTargetResource.content_type === 'weburl') {
                targetWeburl = {weburl: null, openExternally: null};
                let urlRmd = this.$store.getters.presResourcemetadataFirstWithName(
                        this.currentHotspotTargetResource.id, 'weburl');
                if (urlRmd) targetWeburl.weburl = urlRmd.value;
                let openRmd = this.$store.getters.presResourcemetadataFirstWithName(
                        this.currentHotspotTargetResource.id, 'open_externally');
                if (openRmd) targetWeburl.openExternally = openRmd.value;
                let brwIntRmd = this.$store.getters.presResourcemetadataFirstWithName(
                        this.currentHotspotTargetResource.id, 'browser_opens_inline');
                if (brwIntRmd) targetWeburl.browserOpensInline = brwIntRmd.value;
            }
            return targetWeburl;
        },
        currentHotspotTargetResource: function() {
            let res = null;
            if (this.edMxLoneSelectedHotspot && this.edMxLoneSelectedHotspot.target_resource_id) {
                res = this.$store.getters.presResource(this.edMxLoneSelectedHotspot.target_resource_id);
            }
            return res;
        },
        currentHotspotTargetVideoLoops: function() {
            return this.edMxLoneSelectedHotspot && this.edMxLoneSelectedHotspot.is_movie_looping;
        },
        currentHotspotTargetPage: function() {
            let res = null;
            if (this.edMxLoneSelectedHotspot && this.edMxLoneSelectedHotspot.target_page_id) {
                res = this.$store.getters.presPage(this.edMxLoneSelectedHotspot.target_page_id);
            }
            return res;
        },
        currentHotspotHasTarget () {
            // to disable when no target is set
            return !!this.currentHotspotTargetWeburl || !!this.currentHotspotTargetResource || !!this.currentHotspotTargetPage;
        }
    },


}


</script>
