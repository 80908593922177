<template>
    <div class="editor-selection">
    <div :class="['selection-backdrop',
            resizeAction.scaleX || moveAction.amountX ? 'selection-backdrop-rz-move': '',
            [ct.PAN_STATUS.POSSIBLE, ct.PAN_STATUS.ACTIVE].includes(panAction.status) ? 'pan-active': '']"
         @pointerdown.prevent="backdropPointerDown"
         @pointermove.prevent="backdropPointerMove"
         @pointercancel.prevent="backdropPointerCancel"
         @pointerleave.prevent="backdropPointerLeave"
         @contextmenu="backdropContextMenu"></div>

      <div :class="['selection-wrap']">

      <div v-if="selectionOuterBoxDims.x"
           :class="['selection-outer-box', resizeAction.scaleX ? '' : 'selection-outer-active']"
           :style="{
                left: adjPosX(selectionOuterBoxDims.x + moveAction.amountX) + 'px ',
                top: adjPosY(selectionOuterBoxDims.y + moveAction.amountY) + 'px',
                width: selectionOuterBoxDims.w + 'px', height: selectionOuterBoxDims.h + 'px'}">
        <div v-if="!edMxStateEd.textEditHotspotId && !moveAction.amountX && !edMxLoneSelectedHotspotLocked" class="rz-handles">
          <div v-for="handleName in ['tr','br','bl','tl']" :key="'h'+handleName"
               :class="['rz-handle', 'rz-handle-'+handleName, 'rz-cursor-'+handleName]"
               @pointerover="resizeAction.hoverHandle=handleName" @pointerout="resizeAction.hoverHandle=null"
               @pointerdown.stop.prevent="resizeHandlePointerDown(handleName, $event)"
               @pointermove.prevent="backdropPointerMove">
            <div class="rz-handle-box shadow"></div>
          </div>
        </div>
      </div>

      <!-- every hotspot gets a div to show if it selected or not -->
        <div class="hs-dims-wrap">
            <div v-for="hsDims in calculatedHotspotDims" :key="'hd-' + hsDims.hotspotId"
                 @pointerdown.stop.prevent="hotspotPointerDown($event, hsDims.hotspotId)"
                 @dblclick="hotspotDblClick($event, hsDims.hotspotId)"
                 @pointermove.prevent="backdropPointerMove"
                 :class="['hs-dims',
                    selectedHsIds.includes(hsDims.hotspotId) && resizeAction.scaleX ? 'hs-dims-resizing' : '',
                    selectedHsIds.includes(hsDims.hotspotId) && moveAction.amountX ? 'hs-dims-moving' : '',
                    selectedHsIds.includes(hsDims.hotspotId) && !resizeAction.scaleX ? 'hs-dims-sel' : '',
                    preSelectAction.hotspotIds.includes(hsDims.hotspotId) && !resizeAction.scaleX ? 'hs-dims-pre-sel' : '',
                 ]"
                 :style="{
                    left: adjPosX(hsDims.x + (selectedHsIds.includes(hsDims.hotspotId) ? moveAction.amountX : 0)) + 'px',
                    top: adjPosY(hsDims.y + (selectedHsIds.includes(hsDims.hotspotId) ? moveAction.amountY : 0)) + 'px',
                    width: hsDims.w + 'px', height: hsDims.h + 'px',
                 }"></div>
        </div>

        <div v-if="resizeAction.scaleX" :class="['resizing-selection-wrap', 'rz-cursor-'+resizeAction.downHandle]">
            <div v-for="hsDims in calculatedHotspotDims" :key="'hr-' + hsDims.hotspotId" class="ed-hs-resizing">
                <div v-if="selectedHsIds.includes(hsDims.hotspotId) && resizeAction.scaleX"
                     class="resizing-selection-hs"
                     :style="{
                        transformOrigin:
                                (resizeScaleOriginPoint.x - hsDims.x) + 'px ' + (resizeScaleOriginPoint.y - hsDims.y) + 'px',
                        transform: 'scaleX(' + resizeAction.scaleX + ') scaleY(' + resizeAction.scaleY + ')',
                        left: adjPosX(hsDims.x) + 'px', top: adjPosY(hsDims.y) + 'px',
                        width: hsDims.w + 'px', height: hsDims.h + 'px'}"
                     @vnode-mounted="hotspotResizeSaveStartPos(hsDims.hotspotId, $event)"
                     @vnode-updated="hotspotResizeSavePos(hsDims.hotspotId, $event)"></div>
            </div>
        </div>
        <div v-if="resizeAction.scaleX"
             :class="['resizing-selection-outer', 'rz-origin-'+resizeAction.downHandle]"
             :style="{
                transform: 'scaleX(' + resizeAction.scaleX + ') scaleY(' + resizeAction.scaleY + ')',
                left: adjPosX(selectionOuterBoxDims.x) + 'px ', top: adjPosY(selectionOuterBoxDims.y) + 'px',
                width: selectionOuterBoxDims.w + 'px', height: selectionOuterBoxDims.h + 'px'}">
        </div>

        <div class="pre-select-box" v-if="preSelectAction.boxX"
             :style="{left: adjPosX(preSelectAction.boxX) + 'px', top: adjPosY(preSelectAction.boxY) + 'px',
                      width: preSelectAction.boxW + 'px', height: preSelectAction.boxH + 'px'}"></div>

        <EditorInfoPanel
            :infoPanelShownKey="[...selectedHsIds,
              selectionOuterBoxDims.x, selectionOuterBoxDims.y, selectionOuterBoxDims.w, selectionOuterBoxDims.h,
              moveAction.amountX, moveAction.amountY, scaleRatio].join('-')"
            :selectionDimsX="(selectionOuterBoxDims.x+moveAction.amountX)/scaleRatio"
            :selectionDimsY="(selectionOuterBoxDims.y+moveAction.amountY)/scaleRatio"
            :selectionDimsW="selectionOuterBoxDims.w/scaleRatio" :selectionDimsH="selectionOuterBoxDims.h/scaleRatio">
        </EditorInfoPanel>

      <div class="hs-flyouts" v-for="hsDims in calculatedHotspotDims" :key="'hf-'+hsDims.hotspotId">
        <EditorHotspotFlyout
                v-if="!edMxStateEd.textEditHotspotId && !resizeAction.scaleX && !preSelectAction.boxX && !moveAction.amountX &&
                      ( (preSelectAction.hotspotIds.includes(hsDims.hotspotId) && selectedHsIds.length === 0)
                        || (selectedHsIds.includes(hsDims.hotspotId) && selectedHsIds.length === 1)  )"
                :hsHotspot="hotspotsById[hsDims.hotspotId]"
                :hotspotIsSelected="selectedHsIds.includes(hsDims.hotspotId)"
                :scaledPosX="adjPosX(hsDims.x)" :scaledPosY="adjPosY(hsDims.y)" :scaledWidth="hsDims.w">
        </EditorHotspotFlyout>
      </div>

    </div>
    </div>
</template>

<script>

import EditorMixin from './EditorMixin';
import EditorHotspotFlyout from './EditorHotspotFlyout.vue';
import EditorInfoPanel from './EditorInfoPanel.vue';
import HotspotUtils from '../../store/HotspotUtils';

/**
 * Multi Selection of hotspots, moving, resizing.
 *
 * @see webapp/docs/editor-selection.md
 *
 * note, Bootstrap 3+ defines "box-sizing: border-box;" for ALL elements
 *
 */

export default {
  name: "EditorSelection",
  mixins: [EditorMixin],
  components: {EditorHotspotFlyout, EditorInfoPanel},
  props: {
    presOffsetPosX: {type: Number},
    presOffsetPosY: {type: Number}
  },
  data: function() {
    return {
      pointerIsDown: false,
      preSelectAction: {boxX: null, boxY: null, boxW: null, boxH: null, hotspotIds: []},
      backdropDown: {x: null, y: null},
      hotspotDown: {x: null, y: null, hsId: null},
      moveAction: {downX: null, downY: null, amountX: 0, amountY: 0},
      resizeAction: {downX: null, downY: null, downHandle: null, hoverHandle: null, scaleX: null, scaleY: null,
        hsPos: {}},
      panAction: {prevClientX: 0, prevClientY: 0, status: null, downTime: 0},
      ct: {
        LEFT_CLICK_BUTTON: 0,
        MOVE_THRESHOLD: 5,
        LONG_PRESS_MS: 750,
        MAX_ITEMS_SELECTED: 50,
        MIN_HS_DIM: 50,
        PAN_STATUS: {
          INACTIVE: 0,  // not using finger or was long press
          POSSIBLE: 1,  // using finger | ctrl key, short/long press not determined
          ACTIVE: 2 // using finger | crtl key and was short press
        }
      }
    }
  },

  mounted() {
    window.addEventListener('pointerup', this.windowPointerUp, {passive: true});
  },

  beforeUnmount() {
    window.removeEventListener('pointerup', this.windowPointerUp, {passive: true});
  },

  methods: {
    adjPosX(xPos) {
      return xPos + this.presOffsetPosX;
    },
    adjPosY(yPos) {
      return yPos + this.presOffsetPosY;
    },

    backdropPointerDown(e) {
      if (!e.isPrimary || e.button !== this.ct.LEFT_CLICK_BUTTON) return;
      this.pointerIsDown = true;
      //console.log('backdropPointerDown');

      this.deselectAll();
      //console.log('backdropPointerDownXX', this.selectedHsIds.join(','));

      let rect = this.$el.getBoundingClientRect();  // box that represents .editorSelection
      this.backdropDown.x = e.pageX - rect.left - this.presOffsetPosX;  // x position within the element.
      this.backdropDown.y = e.pageY - rect.top - this.presOffsetPosY;

      this.panAction.status = this.ct.PAN_STATUS.INACTIVE;
      if (e.pointerType === 'touch' || e.shiftKey) this.panAction.status = this.ct.PAN_STATUS.POSSIBLE;
      this.panAction.downTime = Date.now();
      this.panAction.prevClientX = null;
      this.panAction.prevClientY = null;

      //console.log('down at', this.backdropDown.x, this.backdropDown.y);

      // note, we don't use `setPointerCapture` to follow the mouse or finger even outside this div
      // because that may lead to placing a hotspot outside the canvas
    },

    resizeHandlePointerDown(handle, e) {
      if (!e.isPrimary || e.button !== this.ct.LEFT_CLICK_BUTTON) return;
      //console.log('resizeHandlePointerDown', handle)
      this.pointerIsDown = true;
      let rect = this.$el.getBoundingClientRect();  // box that represents .editorSelection
      this.resizeAction.downX = e.pageX - rect.left;  // x position within the element.
      this.resizeAction.downY = e.pageY - rect.top;
      this.resizeAction.downHandle = handle;
      this.resizeAction.scaleX = 1;
      this.resizeAction.scaleY = 1;
    },

    hotspotPointerDown(e, hotspotId) {
      //console.log('hotspotPointerDown button', e.button);
      if (!e.isPrimary || e.button !== this.ct.LEFT_CLICK_BUTTON) return;
      //console.log('hotspotPointerDown');
      this.pointerIsDown = true;
      let rect = this.$el.getBoundingClientRect();  // box that represents .editorSelection
      this.hotspotDown.x = e.pageX - rect.left;  // x position within the element.
      this.hotspotDown.y = e.pageY - rect.top;
      this.hotspotDown.hsId = hotspotId;
      //console.log('hotspotPointerDownXX', e);
    },

    hotspotDblClick(e, hotspotId) {
      //console.log('hotspotDblClick button', e.button, hotspotId);
      if (this.selectedHsIds.includes(hotspotId) && this.selectedHsIds.length > 1) {
        // ignore
      } else {
        e.preventDefault();
        this.deselectAll();  // deselect all hotspots
        this.selectHs(hotspotId);
        if (this.edMxStateEd.textEditHotspotId !== hotspotId) {
          this.$store.commit('edSetTextEditHotspotId', hotspotId);
        }
      }
    },

    backdropPointerMove(e) {
      //console.log('backdropPointerMove', this.pointerIsDown, e.isPrimary, e.button, e.pressure, e.buttons);
      if (!e.isPrimary) return;  // note, e.button is -1 during move

      //console.log('backdropPointerMove', this.moveAction.downX)
      let rect = this.$el.getBoundingClientRect();  // box that represents .editorSelection
      let xPosRelParent = e.pageX - rect.left;  // x position within the element.
      let yPosRelParent = e.pageY - rect.top;

      // when the user is using a finger (not mouse or pen) on screen then pan by default otherwise a long press means draw select box
      if (this.panAction.status === this.ct.PAN_STATUS.POSSIBLE) {
        let msSinceDown = Date.now() - this.panAction.downTime;
        this.panAction.status = msSinceDown < this.ct.LONG_PRESS_MS ? this.ct.PAN_STATUS.ACTIVE : this.ct.PAN_STATUS.INACTIVE;
      }
      if (this.panAction.status === this.ct.PAN_STATUS.ACTIVE) {
        this.panTouchMove(e);
        return;
      }

      if (!this.pointerIsDown) {
        this.calcHotspotHoverOverlap(xPosRelParent  - this.presOffsetPosX, yPosRelParent  - this.presOffsetPosY);
      }

      if (this.backdropDown.x && this.pointerIsDown) {
        this.calcPreSelectBox(e.pageX, e.pageY);
        this.calcPreSelectOverlap(true);
      }
      if (this.resizeAction.downHandle && this.resizeAction.downX && this.pointerIsDown) {
        //console.log('active resize handle', this.resizeAction.downX, xPosRelParent);
        let subtractDownPointX = ['tr', 'br'].includes(this.resizeAction.downHandle) ? -1 : 1;
        let subtractDownPointY = ['br', 'bl'].includes(this.resizeAction.downHandle) ? -1 : 1;
        let relDownPointX = subtractDownPointX * (this.resizeAction.downX - xPosRelParent);
        let relDownPointY = subtractDownPointY * (this.resizeAction.downY - yPosRelParent);
        this.resizeAction.scaleX = (this.selectionOuterBoxDims.w + relDownPointX) / this.selectionOuterBoxDims.w;
        this.resizeAction.scaleY = (this.selectionOuterBoxDims.h + relDownPointY) / this.selectionOuterBoxDims.h;
      }
      if (this.hotspotDown.x && this.pointerIsDown) {
        let amountMovedX = xPosRelParent - this.hotspotDown.x;
        let amountMovedY = yPosRelParent - this.hotspotDown.y;
        if ((Math.abs(amountMovedX) + Math.abs(amountMovedY)) > this.ct.MOVE_THRESHOLD) {  // not trying to select, trying to move
          // deselect all and select this hs if not selected
          if (!this.selectedHsIds.includes(this.hotspotDown.hsId) &&
              !this.edMxLoneSelectedHotspotLocked) {
            this.deselectAll();
            this.selectHs(this.hotspotDown.hsId);
          }
          if (!this.edMxLoneSelectedHotspotLocked) {
            // convert hotspot select to hotspot move
            this.moveAction.downX = this.hotspotDown.x;
            this.moveAction.downY = this.hotspotDown.y;
            this.hotspotDown.x = null;
            this.hotspotDown.y = null;
            this.hotspotDown.hsId = null;
          }
        }
      }
      if (this.moveAction.downX && this.pointerIsDown) {
        this.moveAction.amountX = xPosRelParent - this.moveAction.downX;
        this.moveAction.amountY = yPosRelParent - this.moveAction.downY;
        //console.log('pointerMove moving', this.moveAction.amountX)
      }
    },

    windowPointerUp(e) {    // handled against the window as it's the only thing that will 'catch' the pointer outside the div
      if (!e.isPrimary || e.button !== this.ct.LEFT_CLICK_BUTTON) return;
      //console.log('windowPointerUp');

      if (this.backdropDown.x) {
        // select the things that were pre-selected
        this.$store.commit('edSelectedHotspotIdsReset');
        this.$store.commit('edSelectedHotspotIdsAdd', [...this.preSelectAction.hotspotIds]);
        this.preSelectAction.hotspotIds.splice(0);
      }

      // deselect all if shift key not held

      if (this.hotspotDown.x) {
        // if hotspot was clicked a second time and user did nothing else, then select it

        let hsAlreadySelected = this.selectedHsIds.includes(this.hotspotDown.hsId);
        let hsIsLocked = this.hotspotsById[this.hotspotDown.hsId].is_locked;
        if (hsAlreadySelected && this.selectedHsIds.length === 1 && !e.shiftKey) {  // enter text mode
          this.$store.commit('edSetTextEditHotspotId', this.hotspotDown.hsId);
        }
        if (hsAlreadySelected && e.shiftKey) {  // remove hs from selection
            this.deselectHs(this.hotspotDown.hsId);
        }
        if (!hsAlreadySelected && !e.shiftKey) {
            this.deselectAll();  // clear selection first (user holds shift to add to selection)
        }
        if (!hsAlreadySelected  // not already selected
            && (!hsIsLocked || this.selectedHsIds.length === 0 )  // unlocked or it will be only thing selected
            && !this.edMxLoneSelectedHotspotLocked) {  // we don't alrteady have a locked hs selected
          this.selectHs(this.hotspotDown.hsId);
        }
      }

      if (this.moveAction.downX) {
        this.updateHotspotsAfterMove();
      }

      if (this.resizeAction.downX) {
        this.updateHotspotsAfterResize();
      }

      this.resetAllPoints();
    },

    backdropPointerCancel(e) {
      if (!e.isPrimary) return;
      //console.log('backdropPointerCancel');
      this.resetAllPoints();
    },

    backdropPointerLeave() {  // pointer has left the canvas (pointer might be down or up)
      // no need to implement
    },

    resetAllPoints() {
      //console.log('resetAllPoints');
      this.pointerIsDown = false;
      this.preSelectAction.boxX = null;
      this.backdropDown.x = null;
      this.hotspotDown.x = null;
      this.moveAction.downX = null;
      this.moveAction.amountX = 0;
      this.moveAction.amountY = 0;
      this.resizeAction.downX = null;
      this.resizeAction.downHandle = null;
      this.resizeAction.scaleX = null;
      this.resizeAction.scaleY = null;
      this.panAction.status = null;
    },

    backdropContextMenu(e) {
      //console.log('backdropContextMenu', this.panAction.status);
      if ([this.ct.PAN_STATUS.POSSIBLE, this.ct.PAN_STATUS.ACTIVE].includes(this.panAction.status)) e.preventDefault();
    },

    calcHotspotHoverOverlap(xPos, yPos) {
      //console.log('calcHotspotHoverOverlap', {xPos, yPos});
      let hotspotsHovered = [];
      this.calculatedHotspotDims.forEach((hsDims) => {
        let hsDimsRight = hsDims.x + hsDims.w;
        let hsDimsBottom = hsDims.y + hsDims.h;
        let isOverlap = (xPos > hsDims.x && xPos < hsDimsRight && yPos > hsDims.y && yPos < hsDimsBottom);
        if (isOverlap) {  // note, hotspot can overlap each other, that is ok
          hotspotsHovered.push(hsDims);
        }
      });
      //console.log('hotspotsHovered', hotspotsHovered.map(hs => hs.hotspotId).join(','))
      if (hotspotsHovered.length === 0 || this.resizeAction.hoverHandle) {
        if (this.preSelectAction.hotspotIds.length > 0) this.preSelectAction.hotspotIds.splice(0);
      }
      if (hotspotsHovered.length > 0 && !this.resizeAction.hoverHandle) {
        let hotspotHovered = hotspotsHovered[hotspotsHovered.length-1];  // take the hs with highest z-index
        let alreadySelected = this.selectedHsIds.includes(hotspotsHovered.hotspotId);
        let alreadyPreSelected = this.preSelectAction.hotspotIds.includes(hotspotHovered.hotspotId);
        //console.log('hotspotHovered', hotspotHovered.hotspotId, alreadyPreSelected);
        if (!alreadyPreSelected) {
          if (this.preSelectAction.hotspotIds.length > 0) this.preSelectAction.hotspotIds.splice(0);
          if (!alreadySelected) this.preSelectAction.hotspotIds.push(hotspotHovered.hotspotId);
        }
      }
      //console.log('pre sel', this.preSelectAction.hotspotIds.join(','));
    },

    calcPreSelectBox(pageX, pageY) {
      let rect = this.$el.getBoundingClientRect();  // box that represents .editorSelection
      let xPosRelParent = pageX - rect.left - this.presOffsetPosX;  // x position within the element.
      let yPosRelParent = pageY - rect.top - this.presOffsetPosY;
      // note, we don't use e.offsetX -- as it's relative to the thing you are hovering on

      //console.log('esPointerMove', xPosRelParent, yPosRelParent);
      let drawnBoxWidth = xPosRelParent - this.backdropDown.x;
      let drawnBoxHeight = yPosRelParent - this.backdropDown.y;
      this.preSelectAction.boxX = this.backdropDown.x - Math.abs(Math.max(0, -1*drawnBoxWidth));
      this.preSelectAction.boxY = this.backdropDown.y - Math.abs(Math.max(0, -1*drawnBoxHeight));
      this.preSelectAction.boxW = Math.abs(drawnBoxWidth);
      this.preSelectAction.boxH = Math.abs(drawnBoxHeight);
    },

    calcPreSelectOverlap(isDraggingPreSelect) {
      // detect if the drawn box intersects with any hotspot(s) if so highlight them
      let preSelectBoxRight = this.preSelectAction.boxX + this.preSelectAction.boxW;
      let preSelectBoxBottom = this.preSelectAction.boxY + this.preSelectAction.boxH;
      this.calculatedHotspotDims.forEach((hsDims) => {
        let hsDimsRight = hsDims.x + hsDims.w;
        let hsDimsBottom = hsDims.y + hsDims.h;
        //console.log('consider', this.preSelectAction.boxX, '<', hsDimsRight, '&&', preSelectBoxRight, '>', hsDims.x)
        // console.log('consider', this.preSelectAction.boxY, '>', hsDimsBottom, '&&', preSelectBoxBottom, '<', hsDims.y)
        let isSelectable = isDraggingPreSelect ? hsDims.selectableViaPreSelect : true;
        let isOverlap = !(
          preSelectBoxRight < hsDims.x || this.preSelectAction.boxX > hsDimsRight ||
          preSelectBoxBottom < hsDims.y || this.preSelectAction.boxY > hsDimsBottom);
        let shouldBeSelected = isSelectable && isOverlap;
        let isMax = this.preSelectAction.hotspotIds.length >= this.ct.MAX_ITEMS_SELECTED;
        let hsIdArrIdx = this.preSelectAction.hotspotIds.indexOf(hsDims.hotspotId);
        if (shouldBeSelected && hsIdArrIdx === -1 && !isMax) this.preSelectAction.hotspotIds.push(hsDims.hotspotId);
        if (!shouldBeSelected && hsIdArrIdx !== -1) this.preSelectAction.hotspotIds.splice(hsIdArrIdx, 1);
      });
    },

    selectHs(hsId) {
      let isMax = this.selectedHsIds.length >= this.ct.MAX_ITEMS_SELECTED;
      if (!this.selectedHsIds.includes(hsId) && !isMax) this.$store.commit('edSelectedHotspotIdsAdd', [hsId]);
    },

    deselectHs(hsId) {
      this.$store.commit('edSelectedHotspotIdsRemove', hsId);
    },

    deselectAll() {
      if (this.selectedHsIds.length > 0) this.$store.commit('edSelectedHotspotIdsReset');
      if (this.edMxStateEd.textEditHotspotId) this.$store.commit('edSetTextEditHotspotId', null);
    },

    hotspotResizeSaveStartPos(hotspotId, vNode) {
      let rect = vNode.el.getBoundingClientRect();
      //console.log('hotspotResizeSaveStartPos', rect.x);
      this.resizeAction.hsPos[hotspotId] = {startX: rect.x, startY: rect.y, startW: rect.width, startH: rect.height,
        endX: rect.x, endY: rect.y, endW: rect.width, endH: rect.height};
    },

    hotspotResizeSavePos(hotspotId, vNode) {
      // record how much hotspot has moved from it's original position, here instead of implementing an algoright to
      // calculate each hotspots changes x,y,w,h we rely on css transform to do this for us
      if (this.resizeAction.downX) {
        let rect = vNode.el.getBoundingClientRect();
        let pos = this.resizeAction.hsPos[hotspotId];
        pos.endX = rect.x;
        pos.endY = rect.y;
        pos.endW = rect.width;
        pos.endH = rect.height;
      } else {
        this.hotspotResizeSaveStartPos(hotspotId, vNode);
      }
    },

    updateHotspotsAfterMove() {
      let unscale = (i) => i / this.scaleRatio;
      this.updateHotspots((oldBounds) => {
        //console.log('old', oldBounds.hsLeft, 'amt', this.moveAction.amountY);
        return {
          hsLeft: oldBounds.hsLeft + Math.round(unscale(this.moveAction.amountX)),
          hsTop: oldBounds.hsTop + Math.round(unscale(this.moveAction.amountY)),
          hsWidth: oldBounds.hsWidth, hsHeight: oldBounds.hsHeight }
      });
    },

    updateHotspotsAfterResize() {
      //console.log('updateHotspotsAfterResize')
      let unscale = (i) => i / this.scaleRatio;
      this.updateHotspots((oldBounds, hsId) => {
        let pos = this.resizeAction.hsPos[hsId];
        if (!pos) return oldBounds;
        //console.log('hsDims.resizedAmt', pos, pos.startW, pos.endW, pos.endW - pos.startW);
        let hsLeft = oldBounds.hsLeft + Math.round(unscale(pos.endX - pos.startX));
        let hsTop = oldBounds.hsTop + Math.round(unscale(pos.endY - pos.startY));
        let hsWidth = oldBounds.hsWidth + Math.round(unscale(pos.endW - pos.startW));
        let hsHeight = oldBounds.hsHeight + Math.round(unscale(pos.endH - pos.startH));
        if (hsWidth < this.ct.MIN_HS_DIM) hsWidth = this.ct.MIN_HS_DIM;
        if (hsHeight < this.ct.MIN_HS_DIM) hsHeight = this.ct.MIN_HS_DIM;
        if (hsWidth > this.edMxStateShowcase.presentation.width) hsWidth = this.edMxStateShowcase.presentation.width;
        if (hsHeight > this.edMxStateShowcase.presentation.height) hsHeight = this.edMxStateShowcase.presentation.height;
        return {hsLeft: hsLeft, hsTop: hsTop, hsWidth: hsWidth, hsHeight: hsHeight};
      });

    },

    updateHotspots(boundsUpdateFn) {
      let changedHs = [];
      this.selectedHsIds.forEach((hsId) => {
        let hotspot = this.currentPageHotspots.find(h => h.id === hsId);
        let oldBounds = HotspotUtils.boundsStrToObj(hotspot.bounds);
        let newBounds = HotspotUtils.boundsObjToStr(boundsUpdateFn(oldBounds, hsId));
        if (hotspot.bounds !== newBounds) {
          //console.log('bounds change for', hsId, hotspot.bounds, newBounds);
          let hs = Object.assign({}, hotspot);
          hs.bounds = newBounds;
          changedHs.push(hs);
        }
      });
      if (changedHs.length > 0) this.$store.dispatch('presUndoableSaveHotspotBatch', changedHs);
    },

    panTouchMove(e) {
      if (!e.isPrimary) return;
      if (this.panAction.prevClientX) {
        let chgX = this.panAction.prevClientX - e.clientX;
        let chgY = this.panAction.prevClientY - e.clientY;
        let canvasScroller = document.getElementsByClassName('canvas-scroller')[0]
        //console.log('scrollTo', canvasScroller.scrollLeft + chgX);
        canvasScroller.scrollTo(canvasScroller.scrollLeft + chgX, canvasScroller.scrollTop + chgY);
      }
      this.panAction.prevClientX = e.clientX;
      this.panAction.prevClientY = e.clientY;
    },

  },

  computed: {
    selectedHsIds() {
      return this.edMxStateEd.selectedHotspotIds;
    },
    scaleRatio() {
      return this.edMxStateEd.scaleRatio;
    },
    currentPageHotspots() {
      if (!this.edMxStateEd.currentPageId) return [];
      return this.$store.getters.presHotspotsForPageId(this.edMxStateEd.currentPageId);
    },
    hotspotsById() {
      let byId = {};
      this.currentPageHotspots.forEach((hs) => byId[hs.id] = hs);
      return byId;
    },
    calculatedHotspotDims() {
      let scale = (i) => i * this.scaleRatio;
      let hsDims = [];
      this.currentPageHotspots.forEach((hs) => {
        let bounds = HotspotUtils.boundsStrToObj(hs.bounds);
          hsDims.push({hotspotId: hs.id, selectableViaPreSelect: !hs.is_locked,
            w: scale(bounds.hsWidth), h: scale(bounds.hsHeight), x: scale(bounds.hsLeft), y: scale(bounds.hsTop)})
      });
      return hsDims;
    },
    selectionOuterBoxDims() {
      // calculate the size of the box drawn to represent multiple selected hotspots
      // tlx: top left x, tly: top left y, brx: bottom right x, bry: bottom right y
      let outer = {tlx: null, tly: null, brx: null, bry: null};
      this.calculatedHotspotDims.forEach((hsDims) => {
        if (this.selectedHsIds.includes(hsDims.hotspotId)) {
          //console.log('consider outerBoxDims', hsDims.y, hsDims.h);
          let hsBottomRight = {x: hsDims.x + hsDims.w, y: hsDims.y + hsDims.h };
          outer.tlx = outer.tlx === null || hsDims.x < outer.tlx ? hsDims.x : outer.tlx;
          outer.tly = outer.tly === null || hsDims.y < outer.tly ? hsDims.y : outer.tly;
          outer.brx = outer.brx === null || hsBottomRight.x > outer.brx ? hsBottomRight.x : outer.brx;
          outer.bry = outer.bry === null || hsBottomRight.y > outer.bry ? hsBottomRight.y : outer.bry;
        }
      });
      //console.log('consider outerBoxDims 222', outer.tly, 'bry', outer.bry, 'tly', outer.tly);
      return {x: outer.tlx, y: outer.tly, w: outer.brx - outer.tlx, h: outer.bry - outer.tly}
    },
    resizeScaleOriginPoint() {
      let addSelBoxW = ['bl', 'tl'].includes(this.resizeAction.downHandle);
      let addSelBoxH = ['tr', 'tl'].includes(this.resizeAction.downHandle);
      let originXInt = this.selectionOuterBoxDims.x + (addSelBoxW ? this.selectionOuterBoxDims.w : 0);
      let originYInt = this.selectionOuterBoxDims.y + (addSelBoxH ? this.selectionOuterBoxDims.h : 0);
      return {x: originXInt, y: originYInt};
    },

  },
}

</script>

<style scoped>

.selection-backdrop {
    touch-action: none;
    z-index: 60;  /* see main/ui/README.md for all z-index values */
    position: absolute;
    width: 100%;
    height: 100%;
}

.selection-backdrop-rz-move {
  z-index: 115;  /* see main/ui/README.md for all z-index values */
}

.selection-backdrop.pan-active {
    cursor: grab;
}

.selection-wrap {
  touch-action: none;
  position: absolute;
  width: 100%;
  height: 100%;
}

.selection-outer-box {
  position: absolute;
  z-index: 120;  /* see main/ui/README.md for all z-index values */
  pointer-events: none;  /* block pointer events so other layers can get them */
}
.selection-outer-box.selection-outer-active {
  border: 2px dashed #00EEFF;
}

.pre-select-box {
  z-index: 160;  /* see main/ui/README.md for all z-index values */
  position: absolute;
  border: 1px solid #00EEFF;
}

.pre-select-box:before {
    display: block;
    content: "";
    opacity: .15;
    height: 100%;
    width: 100%;
    background-color: #00EEFF;
}

.resizing-selection-outer {
  position: absolute;
  background-color: #00EEFF;
  opacity: 0.15;
  z-index: 130;  /* see main/ui/README.md for all z-index values */
  pointer-events: none;  /* block pointer events so other layers can get them */
}

.resizing-selection-wrap {
  z-index: 130;  /* see main/ui/README.md for all z-index values */
  pointer-events: none;  /* block pointer events so other layers can get them */

}

.hs-dims-wrap {
  z-index: 90;  /* see main/ui/README.md for all z-index values */
  pointer-events: none;  /* block pointer events so other layers can get them */
}

.hs-dims {
    cursor: pointer;
    position: absolute;
    border: 1px dotted rgba(193, 230, 239, .69);
    pointer-events: auto;  /* allow pointer */
    z-index: 90;  /* see main/ui/README.md for all z-index values */
}

.hs-dims.hs-dims-resizing {
  opacity: 0.2;
  background: silver;
}

.hs-dims-moving {
  cursor: move;
}

.hs-dims.hs-dims-pre-sel {
  border: 1px solid #00EEFF;
}

.hs-dims.hs-dims-sel {
  cursor: move;
  border: 2px solid #00EEFF;
}

.rz-handle {
  position: absolute;
  width: 31px;
  height: 31px;
  font-size: 1px;
  z-index: 120;  /* see main/ui/README.md for all z-index values */
  pointer-events: auto;  /* allow pointer */
}
.rz-handle-tl { top: -16px; left: -16px; }
.rz-handle-tr { top: -16px; right: -16px; }
.rz-handle-bl { bottom: -16px; left: -16px; }
.rz-handle-br { bottom: -16px; right: -16px; }

.rz-cursor-tl { cursor: nwse-resize; }
.rz-cursor-tr { cursor: nesw-resize; }
.rz-cursor-bl { cursor: nesw-resize; }
.rz-cursor-br { cursor: nwse-resize; }

.rz-origin-tl { transform-origin: bottom right; }
.rz-origin-tr { transform-origin: bottom left; }
.rz-origin-bl { transform-origin: top right; }
.rz-origin-br { transform-origin: top left; }

.rz-handle-box {
  margin: 11px;
  width: 9px;
  height: 9px;
  background: white;
  border-radius: 2px;
  border: 2px solid #c0c0c0;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 3px 1px;
  opacity: 1;
}

.resizing-selection-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ed-hs-resizing {
  position: absolute;
}

.resizing-selection-hs {
  position: absolute;
  background-color: #00EEFF;
  opacity: 0.3;
}

.hs-flyouts {
  z-index: 150;  /* see main/ui/README.md for all z-index values */
  pointer-events: auto;  /* allow pointer */
}



</style>