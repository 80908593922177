



let HotspotUtils = {

    newHotspotPosition: function(hotspot, presentation) {
        let boundsObj = HotspotUtils.boundsStrToObj(hotspot.bounds);
        boundsObj.hsWidth = Math.round(presentation.width / 4);
        boundsObj.hsHeight = Math.round(presentation.height / 4);
        boundsObj.hsLeft = Math.round((presentation.width / 2) - boundsObj.hsWidth/2);
        boundsObj.hsTop = Math.round((presentation.height / 2) - boundsObj.hsHeight/2);
        hotspot.bounds = HotspotUtils.boundsObjToStr(boundsObj);
    },

    ensureNotOffCanvas: function (hotspot, presentation, hotspotsOnPage) {  // check we are not off the canvas
        //console.log('ensureNotOffCanvas hsBounds', hotspot.bounds);
        let checkAgain = false;
        let boundsObj = HotspotUtils.boundsStrToObj(hotspot.bounds);

        if (boundsObj.hsLeft > 0 && (boundsObj.hsLeft + boundsObj.hsWidth >= presentation.width)) {
            boundsObj.hsLeft = 0;
            checkAgain = true;
        }
        else if (boundsObj.hsLeft < 0 && (boundsObj.hsLeft + boundsObj.hsWidth < presentation.width)) {
            boundsObj.hsLeft = 0;
            checkAgain = true;
        }

        if (boundsObj.hsTop > 0 && (boundsObj.hsTop + boundsObj.hsHeight >= presentation.height)) {
                boundsObj.hsTop = 0;
                checkAgain = true;
        }
        else if (boundsObj.hsTop < 0 && (boundsObj.hsTop + boundsObj.hsHeight < presentation.height)) {
                boundsObj.hsTop = 0;
                checkAgain = true;
        }
        hotspot.bounds = HotspotUtils.boundsObjToStr(boundsObj);

        HotspotUtils.ensureNotOnTop(hotspot, hotspotsOnPage);
        if (checkAgain) HotspotUtils.ensureNotOffCanvas(hotspot, presentation, hotspotsOnPage);
    },

    ensureNotOnTop: function (hotspot, hotspots) {  // check we are not on top of another hotspot
        //console.log('ensureNotOnTop hsBounds', hsBounds);
        let boundsObj = HotspotUtils.boundsStrToObj(hotspot.bounds);
        let hsTop = boundsObj.hsTop;
        let hsLeft = boundsObj.hsLeft;
        let i = 0;
        while (!HotspotUtils.isProposedPositionFree(hotspots, hsLeft, hsTop) && i < 100) {
            hsLeft += 40;
            hsTop += 40;
            i += 1;
        }
        boundsObj.hsTop = hsTop;
        boundsObj.hsLeft = hsLeft;
        hotspot.bounds = HotspotUtils.boundsObjToStr(boundsObj);
    },

    isProposedPositionFree: function(hotspots, hsLeft, hsTop) {
        let positionFree = true;
        hotspots.forEach((iterHotspot) => {
            //console.log('ensureNotOnTop iterBounds', iterHotspot.get('bounds'));
            let iterBoundsObj = HotspotUtils.boundsStrToObj(iterHotspot.bounds);
            if (iterBoundsObj.hsLeft === hsLeft && iterBoundsObj.hsTop === hsTop) {
                positionFree = false;
            }
        });
        return positionFree;
    },

    boundsStrToObj(bounds) {
        //console.log('EditorMixin/methods/mxBoundsToObj', bounds);
        let boundsArr = bounds.split(',');
        let boundsObj = { hsLeft: parseFloat(boundsArr[0]), hsTop: parseFloat(boundsArr[1]),
            hsWidth: parseFloat(boundsArr[2]), hsHeight: parseFloat(boundsArr[3])}
        if (isNaN(boundsObj.hsLeft+boundsObj.hsTop+boundsObj.hsWidth+boundsObj.hsHeight)) {
            boundsObj = { hsLeft: 100, hsTop: 100, hsWidth: 200, hsHeight: 200}  // something has gone horribly wrong, fix
        }
        return boundsObj;
    },


    boundsObjToStr(dims) {
        if (isNaN(dims.hsLeft+dims.hsTop+dims.hsWidth+dims.hsHeight)) throw Error('invalid dims');
        return [dims.hsLeft, dims.hsTop, dims.hsWidth, dims.hsHeight].join(',');
    },

    defaultHotspotImgZoomRatio: function(hotspot, imageWidth, imageHeight) {
        //console.log('defaultHotspotImgZoomRatio', hotspot, imageWidth, imageHeight);
        let hsBoundsObj = HotspotUtils.boundsStrToObj(hotspot.bounds);
        let minWidth = hsBoundsObj.hsWidth;  // fit width, the image width should be exactly the width of the button
        let minHeight = imageHeight * (minWidth / imageWidth); // assign height according to scaling factor of width

        if (imageWidth <= hsBoundsObj.hsWidth || imageHeight <= hsBoundsObj.hsHeight) {
            return 1;  // if the image is smaller than the hotspot don't allow zooming in
        }
        if (minHeight < hsBoundsObj.hsHeight) {  // what if the image is not high enough now?
            minHeight = hsBoundsObj.hsHeight;  // in that case, the image height should match the the button's height
            minWidth = imageWidth * (minHeight / imageHeight);  // and we scale the image width accordingly
        }
        // the smaller zoom factor is the one that fills the whole hotspot (is that really the case?)
        return Math.min(minHeight / imageHeight, minWidth / imageWidth);
    },

    applyLastEditedHotspotStyle: function(context, parentPageId, hs, hsb, templateHotspotBranding) {
        let lastEditedHs = context.getters.presGetLastEditedHotspot(parentPageId);
        let lastEditedHsb = null;
        //console.log('applyLastEditedHotspotStyle', lastEditedHs);
        if (lastEditedHs) {
            hs.bounds = lastEditedHs.bounds;
            lastEditedHsb = context.getters.presHotspotBrandingByHsId(lastEditedHs.id);
            if (!lastEditedHsb) {
                console.warn('applyLastEditedHotspotStyle template hs with no hsb');
                lastEditedHsb = Object.assign({}, templateHotspotBranding);
            }
        }
        if (!lastEditedHsb) {
            lastEditedHsb = Object.assign({}, templateHotspotBranding);
            HotspotUtils.newHotspotPosition(hs, context.state.showcase.presentation);

        }
        hsb.fill_colour = lastEditedHsb.fill_colour;
        hsb.background_colour = lastEditedHsb.background_colour;
        hsb.font_colour = lastEditedHsb.font_colour;
        hsb.font_resource_id = lastEditedHsb.font_resource_id;
        hsb.font_size = lastEditedHsb.font_size;
        hsb.text_alignment = lastEditedHsb.text_alignment;
        hsb.vertical_alignment = lastEditedHsb.vertical_alignment;
        hsb.additional_style_obj = lastEditedHsb.additional_style_obj;
    },

    prepareHotspotPosition: function(context, newHs) {
        let hotspotsOnPage = context.getters.presHotspotsForPageId(newHs.parent_page_id);
        HotspotUtils.ensureNotOnTop(newHs, hotspotsOnPage);
        HotspotUtils.ensureNotOffCanvas(newHs, context.state.showcase.presentation, hotspotsOnPage);
    },

    getNextHsZindex: function(context, parentPageId) {
        let highestZindexHs = context.getters.presGetHotspotWithHighestZindexForPage(parentPageId);
        return highestZindexHs ? highestZindexHs.zindex + 1 : 1;
    }

};

export default HotspotUtils;