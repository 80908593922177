<template>
    <div class="container-fluid" v-if="isShown">

        <div class="row">
            <div class="col-1 px-0 pt-2">
                <ScToggleOnOff :active="isTemplate" :loading="showLoading"
                               @sc-toggle="toggleIsTemplate()"></ScToggleOnOff>
            </div>
            <div class="col-11">
                <h4>Set as Template</h4>
                <small class="d-block">
                  Allow Admin and Editor users to create a new presentation using this presentation as a Template.
                  <br/>When enabled, this presentation will appear on the 'New Presentation' screen as a
                  'Workshop Template'.
                  <br/>Editors with access can edit this presentation template from the Templates filter on Home, but
                  it won't appear in the default Home view, or to Viewer users.
                  <br><br> This setting is activated <strong> immediately</strong> — no republish required!
                </small>
            </div>
        </div>

        <hr class="my-4"/>

    </div>
</template>


<script>

import ScNotification from '../../../shared/common/ScNotification.vue';
import ScToggleOnOff from '../../../shared/common/ScToggleOnOff.vue';
import $ from 'jquery';


export default {
  name: 'PresSettingsTemplate',
  components: { ScToggleOnOff },
  props: {
    workspaceId: {type: Number, default: null},
  },
  data () {
    return {
      isShown: false,
      isTemplate: true,
      presentationId: null,
      showLoading: false
    };
  },
  mounted () {
    if (this.$store.state.pres) {
      this.presentationId = this.$store.state.pres.showcase.presentation.id;
      this.isTemplate = this.$store.state.pres.showcase.presentation.template;
      this.isShown = true;
    }
  },

  methods: {
    toggleIsTemplate() {
        this.showLoading = true;
        $.ajax({
          type: 'POST', url: "/main/editor/ajax_pres_settings_template",
          data: {
            workspace_id: this.workspaceId, presentation_id: this.presentationId, is_template: !this.isTemplate
          }
        }).done((data) => {
          ScNotification.growlSuccessMsg('Saved.');
          this.$store.commit('presIsTemplateEnabled', data.is_template);
          this.isTemplate = data.is_template;
        }).fail((jqXhr) => {
          ScNotification.growlXhrError(jqXhr, 'trying to update template settings');
        }).always(() => {
          this.showLoading = false;
        });
    },
  },
};


</script>