<template>
    <div v-if="edMxCurrentPage && edMxSelectedHotspots.length === 0" class="d-flex justify-content-center align-items-center">
        <div>
            <button class="btn" role="button" @click.prevent="newHotspotEmpty(true)"
                    v-sc-tooltip="'New blank hotspot with text (Alt+W)'">
                <ScIcon name="plusSquareFW"/> Add Text
            </button>
        </div>
        <div class="dropdown navbar-light">
            <button type="button" class="btn dropdown-toggle sc-highlight-expanded" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                <ScIcon name="externalLinkFW" class="me-1"/>
                <strong>Add Hotspot</strong>
            </button>
            <div class="dropdown-menu dropdown-menu-right" @click.prevent.stop>
                <div class="my-2 mx-3" style="width: 320px; margin-left:-170px">
                    <div class="my-1 text-center">
                        <button class="btn btn-outline-secondary w-100 mb-2" role="button"
                                v-sc-tooltip="'(Alt+B)'"
                                @click.prevent="newHotspotEmpty(false)">New Blank Hotspot</button>
                    </div>

                    <div class="mt-3 text-muted">Add Hotspot that links to a:</div>
                    <div class="my-2 text-center row g-0">
                        <div class="col-6 pe-1">
                            <button class="btn btn-outline-secondary w-100 mb-2 px-0" role="button"
                                    @click.prevent="newHotspotThatTargetsNewPagelistSelectBgs()">New Image Slide(s)</button>
                            <button class="btn btn-outline-secondary w-100 mb-2" role="button"
                                    @click.prevent="newHotspotThatTargetsExistingPage()">Existing Slide</button>
                            <button class="btn btn-outline-secondary w-100 mb-2" role="button"
                                    @click.prevent="newHotspotThatTargetsWeburl()">URL</button>
                        </div>
                        <div class="col-6 ps-1">
                            <button class="btn btn-outline-secondary w-100 mb-2" role="button"
                                    @click.prevent="newHotspotThatTargetsNewSlide()">New Blank Slide</button>
                            <button class="btn btn-outline-secondary w-100 mb-2" role="button"
                                    @click.prevent="newHotspotThatTargetsVideo()">Video</button>
                            <button class="btn btn-outline-secondary w-100 mb-2" role="button"
                                    v-sc-tooltip="'(Alt+P)'"
                                    @click.prevent="edMnNewHotspotThatTargetsDocument()">Document</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>
    </div>
</template>



<script>

import LibraryMainModal from '../../library/LibraryMainModal.vue';
import EditorMixin from './../EditorMixin';
import EditorWeburlModal from './../EditorWeburlModal.vue';
import EditorFindPageModal from './../EditorFindPageModal.vue';
import ScBootstrapUtils from '../../global/ScBootstrapUtils';
import ScIcon from '../../../shared/common/ScIcon.vue';

export default {
    name: "EditorToolbarAddHotspot",
    mixins: [EditorMixin],
    inject: ['edMnNewHotspotThatTargetsDocument'],
    components: {ScIcon, LibraryMainModal},
    methods: {

        hotspotLinkShowDetails() {
            if (!this.edMxLoneSelectedHotspot) return;
            ScBootstrapUtils.ensureDropdownShown(this.$refs.hotspotLinkToggle)
        },

        chooseHotspotTargetResource(type) {
            this.$refs.libraryMainModal.toggleBsModal({
                selectionMode: 'single', dialogType: 'modal',
                filterType: type
            }, this.assignHotspotTargetResource);
        },

        chooseHotspotWeburl(targetWeburlObj) {
            EditorWeburlModal.modal(targetWeburlObj, (updatedTargetWeburlObj) => {
                this.$store.dispatch('presNewWeburlResource', updatedTargetWeburlObj).then((resId) => {
                    let hs = Object.assign({}, this.edMxLoneSelectedHotspot);
                    hs.target_resource_id = resId;
                    this.$store.dispatch('presUndoableSaveHotspotBatch', [hs]);
                }, () => {});
                if (!targetWeburlObj) ScBootstrapUtils.ensureDropdownHidden(this.$refs.hotspotLinkToggle);
            });
        },

        replaceHotspotTargetResource () {
            if (this.currentHotspotTargetResource) {
                this.chooseHotspotTargetResource(this.currentHotspotTargetResource.content_type);
            }
        },

        assignHotspotTargetResource(resources) {
            // remove current file from sharing if enabled
            if (this.currentHotspotTargetResource) {
                this.edMxRemoveFromSharingForCurrentPage(this.currentHotspotTargetResource.id);
                this.edMxRemoveFromSharingForShowcase(this.currentHotspotTargetResource.id);
            }

            let resId = null;
            if (resources && resources.length > 0) {
                this.$store.dispatch('presResourceAddedFromLibrary', resources[0]);
                resId = resources[0].id;

                if (this.$store.getters.userCurrentWorkspaceObj.shareable_on_slide_by_default) {
                    // add to shareable on this slide
                    this.$store.dispatch('presToggleShareableResource', {
                        resource_id: resId, page_id: this.edMxCurrentPage.id, enabled: true
                    });
                }
            }
            let hs = Object.assign({}, this.edMxLoneSelectedHotspot);
            hs.target_resource_id = resId;
            this.$store.dispatch('presUndoableSaveHotspotBatch', [hs]);
            if (resources && resources.length > 0) this.hotspotLinkShowDetails();
        },

        assignHotspotTargetPage(pageId) {
            let hs = Object.assign({}, this.edMxLoneSelectedHotspot);
            hs.target_page_id = pageId;
            this.$store.dispatch('presUndoableSaveHotspotBatch', [hs]);
            if (pageId) this.hotspotLinkShowDetails();
        },

        chooseHotspotTargetPage() {
            EditorFindPageModal.modal({  // modal will close itself automatically
                pageChooseMode: true, linkToHotspotId: this.edMxLoneSelectedHotspot.id,
                success: this.assignHotspotTargetPage
            });
        },

        newHotspotThatTargetsExistingPage() {
            EditorFindPageModal.modal({  // modal will close itself automatically
                pageChooseMode: true,
                linkToHotspotId: null,  // we don't know until created
                success: (targetPageId) => {
                    let targetPage = this.$store.getters.presPage(targetPageId);
                    this.edMxNewHotspot(null, null, targetPage.id, false);
                }
            });
        },

        newHotspotEmpty(focusText) {
            this.edMxNewHotspot(null, null, null, focusText);
        },

        newHotspotThatTargetsWeburl() {
            EditorWeburlModal.modal(null, (updatedTargetWeburlObj) => {
                this.$store.dispatch('presNewWeburlResource', updatedTargetWeburlObj).then((resourceId) => {
                    this.edMxNewHotspot(null, resourceId, null, false);
                }, () => {});
            });
        },

        newHotspotThatTargetsVideo() {
            this.$refs.libraryMainModal.toggleBsModal({
                selectionMode: 'single', dialogType: 'modal',
                filterType: 'movie'
            }, (resources) => {
                if (!resources || resources.length === 0) return;
                this.$store.dispatch('presResourceAddedFromLibrary', resources[0]);
                this.edMxNewHotspot(null, resources[0].id, null, false);

                if (this.$store.getters.userCurrentWorkspaceObj.shareable_on_slide_by_default) {
                    this.$store.dispatch('presToggleShareableResource', {
                        resource_id: resources[0].id, page_id: this.edMxCurrentPage.id, enabled: true
                    });
                }
            });
        },

        newPagelistForHotspot() {
            this.$store.dispatch('presNewPagelist', {
                existing_hotspot_id: this.edMxLoneSelectedHotspot.id,
                new_hotspot_page_id: null,
                page_background_res_ids: null
            }).then(() => {
                this.hotspotLinkShowDetails();
            });
        },

        assignBgResourcesToNewPagelistForHotspot(slideBgResources) {
            let bgResIds = [];
            slideBgResources.forEach((res) => {
                this.$store.dispatch('presResourceAddedFromLibrary', res);
                bgResIds.push(res.id);
            });
            this.$store.dispatch('presNewPagelist', {
                existing_hotspot_id: this.edMxLoneSelectedHotspot.id,
                new_hotspot_page_id: null,
                page_background_res_ids: bgResIds
            }).then(() => {
                this.hotspotLinkShowDetails();
            });
        },

        newPagelistWithImagesForHotspot() {
            this.$refs.libraryMainModal.toggleBsModal({
                selectionMode: 'multiple', dialogType: 'modal',
                filterType: 'image'
            }, (resources) => {
                if (!resources || resources.length === 0) return;
                this.assignBgResourcesToNewPagelistForHotspot(resources);
            });
        },

        newHotspotThatTargetsNewSlide() {
            this.$store.dispatch('presNewPagelist', {
                existing_hotspot_id: null,
                new_hotspot_page_id: this.edMxStateEd.currentPageId,
                page_background_res_ids: null
            }).then((n) => { this.edMxSelectHotspot(n, false) });
        },

        assignBgResourcesToNewPagelist(slideBgResources) {
            let bgResIds = [];
            slideBgResources.forEach((res) => {
                this.$store.dispatch('presResourceAddedFromLibrary', res);
                bgResIds.push(res.id);
            });
            this.$store.dispatch('presNewPagelist', {
                existing_hotspot_id: null,
                new_hotspot_page_id: this.edMxStateEd.currentPageId,
                page_background_res_ids: bgResIds
            }).then((n) => { this.edMxSelectHotspot(n, false) });
        },

        newHotspotThatTargetsNewPagelistSelectBgs() {
            this.$refs.libraryMainModal.toggleBsModal({
                selectionMode: 'multiple', dialogType: 'modal',
                filterType: 'image'
            }, (resources) => {
                if (!resources || resources.length === 0) return;
                this.assignBgResourcesToNewPagelist(resources);
            });
        },


    },
    computed: {

        currentHotspotTargetWeburl: function() {
            let targetWeburl = null;
            if (this.currentHotspotTargetResource && this.currentHotspotTargetResource.content_type === 'weburl') {
                targetWeburl = {weburl: null, openExternally: null};
                let urlRmd = this.$store.getters.presResourcemetadataFirstWithName(
                        this.currentHotspotTargetResource.id, 'weburl');
                if (urlRmd) targetWeburl.weburl = urlRmd.value;
                let openRmd = this.$store.getters.presResourcemetadataFirstWithName(
                        this.currentHotspotTargetResource.id, 'open_externally');
                if (openRmd) targetWeburl.openExternally = openRmd.value;
                let brwIntRmd = this.$store.getters.presResourcemetadataFirstWithName(
                        this.currentHotspotTargetResource.id, 'browser_opens_inline');
                if (brwIntRmd) targetWeburl.browserOpensInline = brwIntRmd.value;
            }
            return targetWeburl;
        },
        currentHotspotTargetResource: function() {
            let res = null;
            if (this.edMxLoneSelectedHotspot && this.edMxLoneSelectedHotspot.target_resource_id) {
                res = this.$store.getters.presResource(this.edMxLoneSelectedHotspot.target_resource_id);
            }
            return res;
        },
        currentHotspotTargetVideoLoops: function() {
            return this.edMxLoneSelectedHotspot && this.edMxLoneSelectedHotspot.is_movie_looping;
        },
        currentHotspotTargetPage: function() {
            let res = null;
            if (this.edMxLoneSelectedHotspot && this.edMxLoneSelectedHotspot.target_page_id) {
                res = this.$store.getters.presPage(this.edMxLoneSelectedHotspot.target_page_id);
            }
            return res;
        },
    },


}


</script>
