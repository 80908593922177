<template>
  <div v-if="edMxSelectedHotspots.length > 0 && edMxSelectedHotspotsBranding.length > 0" class="d-inline-block">
    <div class="dropdown">
      <button type="button" class="btn px-md-3 sc-highlight-expanded" data-bs-toggle="dropdown" ref="toolbarTextColorDdBtn"
              :disabled="edMxLoneSelectedHotspotLocked" role="button" aria-expanded="false"
              v-sc-tooltip="'Text Colors'" style="max-height:44px">
        <ScIcon name="fontFW"/>
        <div class="d-flex border">
          <span :style="{'background-color': fontColor, width: '10px', height: '4px'}"
                class="d-inline-block"></span>
          <span :style="{'background-color': bgColor, width: '10px', height: '4px'}"
                class="d-inline-block"></span>
        </div>
      </button>
      <div ref="ddMenuTextColor" class="dropdown-menu" @click.prevent.stop style=" width: 220px">

        <div class="row justify-content-between mx-0" >
          <span class="col dropdown-header border-0 mt-2 mb-0">Text Color</span>
          <div class="col mt-1 text-end">

            <ScColorPicker id="colPickerFont" :color="fontColor"
                           :hasTransparent="false"
                           :isDisabled="edMxLoneSelectedHotspotLocked"
                           @change="updateHotspotBrandingFontColor($event)" />
          </div>
        </div>

        <hr class="my-2 mx-3"/>

        <div class="row justify-content-between mx-0" >
          <span class="col dropdown-header border-0 mt-2 pe-0">Highlight Color</span>
          <div class="col mt-1 text-end">
            <ScColorPicker id="colPickerBg" :color="bgColor"
                           :hasTransparent="true"
                           :isDisabled="edMxLoneSelectedHotspotLocked"
                           @change="updateHotspotBrandingBackgroundColor($event)"/>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>


<script>

import EditorMixin from './../EditorMixin';
import ScColorPicker from '../../global/ScColorPicker.vue';
import ScIcon from '../../../shared/common/ScIcon.vue';
import ScBootstrapUtils from "../../global/ScBootstrapUtils";


export default {
  name: "EditorToolbarTextColor",
  mixins: [EditorMixin],
  components: {ScColorPicker, ScIcon},
  methods: {

    updateHotspotBrandingFontColor(color) {
      this.edMxUpdateSelectedHotspotsBranding('font_colour', color);
      ScBootstrapUtils.ensureDropdownShown(this.$refs.toolbarTextColorDdBtn);
    },

    updateHotspotBrandingBackgroundColor(color) {
      this.edMxUpdateSelectedHotspotsBranding('background_colour', color);
      ScBootstrapUtils.ensureDropdownShown(this.$refs.toolbarTextColorDdBtn);
    },

  },
  computed: {
    fontColor () {
      return this.edMxSelectedMultipleHotspotsBrandingDisplayValueForAttribute('font_colour');
    },
    bgColor () {
      return this.edMxSelectedMultipleHotspotsBrandingDisplayValueForAttribute('background_colour');
    },
  }

}


</script>
