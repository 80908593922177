<template>
  <div v-if="edMxSelectedHotspots.length > 0 && edMxSelectedHotspotsBranding.length > 0" class="d-inline-block">
    <ScFonts @selected-font-changed="fontIdUpdate($event)"
             :currentFontId="fontResourceId"
             :isDisabled="edMxLoneSelectedHotspotLocked"
             :showShowcaseFonts="true"
             :shortenFontName="shortenFontName"
             class="mx-0 mx-xl-1 d-inline-block"/>

    <div class="customSelectArrow d-inline-block" :style="{'opacity': edMxLoneSelectedHotspotLocked ? '.65' : '1'}">
      <select class="form-control bg-white" @change="fontSizeUpdate($event.target.value)"
              :value="fontSize" style="width: 70px; border: none;" :disabled="edMxLoneSelectedHotspotLocked">
        <option :key="fontSize" v-for="fontSize in fontSizesArray" :value="fontSize">{{fontSize}}</option>
      </select>
    </div>
  </div>
</template>


<script>

import EditorMixin from './../EditorMixin';
import ScFonts from '../../global/ScFonts.vue';

export default {
    name: "EditorToolbarTextFont",
    mixins: [EditorMixin],
    components: {ScFonts},
    props: {
      shortenFontName: {type: Boolean, default: false} // needed for toolbar responsive behavior
    },
    data () {
      return {
        fontSizesArray: [10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 44, 48, 54, 60, 66, 72, 80, 96, 128]
      }
    },
    methods: {
      fontIdUpdate (font) {
        //console.log('fontUpdate', font);
        this.edMxUpdateSelectedHotspotsBranding('font_resource_id', font.id);
      },

      fontSizeUpdate (size) {
        // console.log('fontSizeUpdate', size);
        this.edMxUpdateSelectedHotspotsBranding('font_size', size);
      }
    },
    computed: {
      fontSize () {
        let fS = this.edMxSelectedMultipleHotspotsBrandingDisplayValueForAttribute('font_size');
        return (!fS) ? null : this.$store.getters.fontsNormalizeFontSize(fS);
      },

      fontResourceId () {
        return this.edMxSelectedMultipleHotspotsBrandingDisplayValueForAttribute('font_resource_id');
      },

    },

}


</script>



<style>

.edit-toolbar .dropdown-toggle::after {
  color: #bbbbbb;
}
.customSelectArrow select:disabled {
  opacity: 0.65;
  background: white !important;
}

</style>