<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" ref="scModal" v-vb-is:modal.show
         @vb-hidden-bs-modal="isModalShown=false"
         class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header text-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title w-100">Assign Labels</h4>
                </div>
                <div class="modal-body pb-3" style="min-height: 150px">
                    <div class="manage-labels mb-4">
                        <div class="labels-list ">
                            <div v-for="label in labels" :key="label.id">
                                <div class="mb-2 form-check">
                                    <input type="checkbox" :checked="assignedToPresLabelIds.indexOf(label.id) !== -1"
                                           :id="'labelChk_' + label.id" :disabled="assigningLabelId===label.id"
                                           @click.prevent="assignToPres(label.id)" class="me-2 form-check-input">
                                    <label class="form-check-label" :for="'labelChk_' + label.id" >
                                        <span
                                            class="badge fw-bold fs-md label-sc-pres mb-0 text-truncate"
                                            v-sc-tooltip="label.name" style="max-width: 15rem;vertical-align: bottom;"
                                            :style="{'background-color': label.bg_color}">{{ label.name }}</span></label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="!showAddLabelOption">
                        <button @click.prevent="showAddLabelOption=true" :disabled="addingLabel || tooManyLabels"
                                class="btn btn-outline-secondary">New label</button>
                        <div v-if="tooManyLabels" class="mt-3">
                            <ScIcon name="exclamationTriangleFW" class="text-danger"/> You can only add up
                            to {{ maxNumLabels }} labels</div>
                    </div>

                    <ManageLabelsEdit v-if="showAddLabelOption"
                                      :tooManyLabels="tooManyLabels"
                                      :maxNumLabels="maxNumLabels"
                                      :addingLabel="addingLabel"
                                      @save-label="assignLabelNew"
                                      @label-edit-close="showAddLabelOption=false" />

                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import $ from 'jquery';
    import _ from 'underscore'; // sortBy
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import constants from '../../../constants';
    import MainAppMixin from '../../MainAppMixin';
    import ManageLabelsEdit from './ManageLabelsEdit.vue';

    let _ins = null;

    export default {
        name: 'AssignLabelsModal',
        mixins: [ MainAppMixin ],
        components: { ManageLabelsEdit, ScIcon },
        data () {
            return {
                isModalShown: false,
                presId: null,
                addingLabel: false,
                assigningLabelId: null,
                showAddLabelOption: false,
            }
        },
        mounted () {
            _ins = this;
        },
        beforeUnmount() {
            _ins = null;
        },
        methods: {
            assignToPres (labelId) {
                //console.log('assign', labelId);
                ScNotification.growlClear();

                let assignedToPresLabelIdsDraft = [...this.assignedToPresLabelIds];
                let idx = assignedToPresLabelIdsDraft.indexOf(labelId);
                if (idx === -1) assignedToPresLabelIdsDraft.push(labelId);
                else assignedToPresLabelIdsDraft.splice(idx, 1);

                this.assigningLabelId = labelId;
                $.ajax({
                    type: 'POST', url: '/main/presentations/ajax_assign_labels',
                    data: {label_ids: assignedToPresLabelIdsDraft.join(','),
                        workspace_id: this.mainMxCurrentWorkshopId, presentation_id: this.presId}
                }).done(() => {
                    this.assigningLabelId = null;
                    ScNotification.growlSuccessMsg('Labels updated');
                    this.$store.commit('plistLabelsAssigned', {
                        presentationId: this.presId, labelIds: assignedToPresLabelIdsDraft});

                }).fail((jqXhr) => {
                    this.assigningLabelId = null;
                    ScNotification.growlXhrError(jqXhr, 'when applying labels');
                });
            },

            assignLabelNew (val) {

                if (this.tooManyLabels) return;
                if (!val.name || val.name.length < 2) return;
                if (this.willNameDuplicate(val.name, val.bg_color)) {
                    ScNotification.growlErrMsg('Duplicate label, just tick it below.');
                    return;
                }

                this.addingLabel = true;
                let newLabelJson = {name: val.name, bg_color: val.bg_color,
                    presentation_ids: [], workspace_id: this.mainMxCurrentWorkshopId};

                $.ajax({
                    type: 'POST', url: '/main/presentations/ajax_new_label',
                    data: newLabelJson
                }).done((data) => {
                    newLabelJson.id = data.label_id;
                    this.$store.commit('plistNewLabel', newLabelJson);
                    this.assignToPres(newLabelJson.id);

                    this.showAddLabelOption = false;

                }).always(() => {
                    this.addingLabel = false;

                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'adding label');
                });

            },

            willNameDuplicate (name, bgColor) {
                let numMatchingNames = 0;
                this.labels.forEach(function(label) {
                    if (label.name.toLowerCase() === name.toLowerCase() && label.bg_color === bgColor) {
                        numMatchingNames++;
                    }
                });
                return numMatchingNames > 0;
            },
            toggleBsModal(presId) {
                //console.log('toggleBsModal', this.$refs, _ins.$el);
                this.presId = presId;
                this.isModalShown = true;
            }
        },
        computed: {
            labels () {
                return this.$store.state.plist.labels;
            },
            tooManyLabels () {
                return this.assignedLabels.length >= this.maxNumLabels || this.$store.state.plist.labels.length >= this.maxNumLabels;
            },
            maxNumLabels () {
                return constants.MAX_NUM_LABELS;
            },
            assignedLabels () {
                let assignedLabels = [];
                this.labels.forEach((label) => {
                    if (label.presentation_ids.length > 0) {
                        assignedLabels.push(label);
                    }
                });
                return _.sortBy(assignedLabels, 'name');
            },
            assignedToPresLabelIds() {
                if (!this.labels) return [];
                let presLabelIds = [];
                this.labels.forEach((label) => {
                    if (label.presentation_ids && label.presentation_ids.indexOf(this.presId) !== -1) {
                        presLabelIds.push(label.id);
                    }
                });
                return presLabelIds;
            },
        },


        //
        // public functions
        //
        modal(presId) {
            _ins.toggleBsModal(presId)
        }

    }

</script>

