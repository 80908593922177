<template>
    <teleport to=".sc-modals-anchor">
        <div v-vb-is:modal.show ref="scModal" @vb-hidden-bs-modal="$emit('modal-was-hidden')"
                class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-bottom">
                        <button class="btn-close" @click.prevent="hideModal"></button>
                        <h4 class="modal-title w-100 text-center">Manage Settings</h4>
                    </div>

                    <div class="modal-body mb-3 rounded-bottom">

                        <EditorToolbarIntroVideo></EditorToolbarIntroVideo>

                        <EditorToolbarScreensaver></EditorToolbarScreensaver>

                        <ManageViewerArrows :workspaceId="mainMxCurrentWorkshopId"></ManageViewerArrows>

                        <ManageCustomTransitions :workspaceId="mainMxCurrentWorkshopId"></ManageCustomTransitions>

                        <PresSettingsTemplate :workspaceId="mainMxCurrentWorkshopId"></PresSettingsTemplate>

                        <PresResize></PresResize>

                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>


<script>

    import MainAppMixin from '../../MainAppMixin';
    import ManageViewerArrows from './ManageViewerArrows.vue';
    import ManageCustomTransitions from './ManageCustomTransitions.vue';
    import EditorToolbarScreensaver from './EditorToolbarScreensaver.vue';
    import EditorToolbarIntroVideo from './EditorToolbarIntroVideo.vue';
    import PresSettingsTemplate from './PresSettingsTemplate.vue';
    import PresResize from './PresResize.vue';

    export default {
        name: 'PresentationSettingsModal',
        emits: ['modal-was-hidden'],
        mixins: [MainAppMixin],
        components: {ManageViewerArrows, ManageCustomTransitions, EditorToolbarIntroVideo, EditorToolbarScreensaver,
          PresSettingsTemplate, PresResize},

        methods: {
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            }
        }
    };


</script>