
import $ from 'jquery';
import _ from 'underscore'; // debounce, filter, sortBy, findWhere, where
import ScNotification from '../shared/common/ScNotification.vue';
import HotspotUtils from './HotspotUtils';
import PresentationUtils from './PresentationUtils';
import {isAfter} from 'date-fns';
import StoreUtils from './StoreUtils';


const WAIT_MS_BEFORE_AJAX = 5000;
let _savePendingTimeout = null;

let templateResource = {
    id: null,
    url: null,
    name: null,
    width: 0,
    height: 0,
    thumb_url: null
};

let templateResourcemetadata = {
    id: null,
    resource_id: null,
    name: null,
    value: null,
};

let templateHotspot = {
    id: null,
    parent_page_id: null,
    bounds: [100, 100, 100, 100].join(','),
    crop_x: null, crop_y: null,

    html_content: "",
    is_movie_looping: false,
    is_locked: false,
    original_resource_id: null,
    passcode: null,
    target_page_id: null,
    target_pagelist_id: null,
    target_resource_id: null,
    zindex: 1,
    zoom_width: null, zoom_height: null,
    deleted: false,
    updated_date: null

    //  only used at server and viewers
    // client_resource_id: null,
    // hotspot_uid: "d8a0e800-8cdf-496d-b2f1-630f57ae384b",
    // presentationmetadata_id: null,
    // parent_pagelist_id: null,
    // target_content_type:  'none',

    // legacy
    // position: "floating",
    // title_1: null,
    // title_2: null,
    // title_enabled: true,
};

let templateHotspotBranding = {
    id: null,
    hotspot_id: null,
    fill_colour: "transparent",
    background_colour: "transparent",
    font_colour: "#FFFFFF",
    font_resource_id: null,
    font_size: "24",
    text_alignment: "center",
    vertical_alignment: "middle",
    additional_style_obj: null
    // legacy text_bold: false,
    // legacy text_italic: false,
};

let templatePage = {
    id: null,
    pagelist_id: null,
    sort_order: 0,
    alpha_num_name: null,
    title: 'None',
    page_bg_color: "#000000",
    page_animated_bg_id: null,
    background_src_resource_id: null,
    resource_pagethumb_id: null,
    deleted: false,
};

let templatePagelist = {
    id: null,
    sequence_num: 0,
    deleted: false,
};

let templateSharableResource = {
    resource_id: null,
    presentation_id: null,
};

let templateSharablePageResource = {
    resource_id: null,
    page_id: null,
    presentation_id: null,
};


let _state = {

    showcaseLoaded: false,
    showcaseLoading: true,
    showcaseSavePending: false,
    showcaseLastLoadedDate: null,
    draftCompileTokens: [],

    showcase: {
        presentation: {
            title: null,
            id: null,
            width: 2048,
            height: 1535,
            workspace_id: 0,
            publish_status: 'unpublished',
            permissions: null,
            viewer_arrows_enabled: null,
            template: false,
        },
        presentationmetadata: {
            root_pagelist_id: null,
            opening_scene_resource_id: null,
            screensaver_video_resource_id: null,
            screensaver_timeout: null,
            cid: 0  // 0 = changes since last publish, 1 = no changes since last publish
        },
        primary_layout: {
            code: 'hd-landscape'
        },
        pagelist: {},
        page: {},
        hotspot: {},
        hotspot_branding: {},
        resource: {},
        resourcemetadata: {},
        shareable_resource: {},
        shareable_page_resource: {}
    }
};


const dataManager = {
    newPagelistImmediate(newPagelistOpts, context) {
        return new Promise(function(resolve, reject) {
            $.ajax({
                method: "POST", url: '/main/editor/ajax_new_pagelist_v2',
                data: {
                    workspace_id: context.rootState.pres.showcase.presentation.workspace_id,
                    presentation_id: context.rootState.pres.showcase.presentation.id,
                }
            }).then((data) => {
                let newPagelist = Object.assign({}, templatePagelist);
                newPagelist.id = data.pagelist_id;
                newPagelist.sequence_num = data.sequence_num;
                context.commit('presAddedPagelist', newPagelist);
                context.dispatch('presDraftCompileStarted', data.draft_compile_token);
                resolve(newPagelist);

            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'adding slideshow');
                reject();
            });
        });
    },
    newPageImmediate(newPageOpts, context) {
        return new Promise(function(resolve,reject) {
            $.ajax({
                method: "POST", url: '/main/editor/ajax_new_page_v2',
                data: {
                    workspace_id: context.rootState.pres.showcase.presentation.workspace_id,
                    presentation_id: context.rootState.pres.showcase.presentation.id,
                    pagelist_id: newPageOpts.pagelist_id,
                    sort_order: newPageOpts.sort_order,
                    alpha_num_name: newPageOpts.alpha_num_name
                }
            }).then((data) => {
                let newPage = Object.assign({}, templatePage);
                newPage.id = data.page_id;
                newPage.deleted = true;  // will be flagged as undeleted by the first operation
                newPage.pagelist_id = data.pagelist_id;
                newPage.sort_order = data.sort_order;
                newPage.alpha_num_name = data.alpha_num_name;
                context.commit('presAddedPage', newPage);
                context.dispatch('presDraftCompileStarted', data.draft_compile_token);
                resolve(newPage);

            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'adding slide');
                reject();
            });
        });
    },
    newHotspotsImmediate(newHsOpts, context) {
        return new Promise(function(resolve,reject) {
            $.ajax({
                method: "POST", url: '/main/editor/ajax_new_hotspots',
                data: {
                    workspace_id: context.rootState.pres.showcase.presentation.workspace_id,
                    page_id: newHsOpts.parent_page_id, num_hotspots: newHsOpts.num_hotspots
                }
            }).then((data) => {
                let createdHotspotsWithBranding = [];
                if (data.hotspot_ids) {
                    data.hotspot_ids.forEach((hsId) => {
                        let newHs = Object.assign({}, templateHotspot);
                        newHs.id = hsId;
                        newHs.parent_page_id =  newHsOpts.parent_page_id;
                        newHs.deleted = true;  // will be flagged as undeleted by the first operation
                        let newHsb = Object.assign({}, templateHotspotBranding);
                        newHsb.hotspot_id = hsId;
                        let hotspotWithBranding = {hotspot: newHs, hotspot_branding: newHsb};
                        context.commit('presAddedHotspotWithBranding', hotspotWithBranding);
                        createdHotspotsWithBranding.push(hotspotWithBranding);
                    });
                }
                context.dispatch('presDraftCompileStarted', data.draft_compile_token);
                //console.log('newHotspotsImmediate resolve', createdHotspotsWithBranding);
                resolve(createdHotspotsWithBranding);

            }).fail(function (jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'creating hotspot');
                reject();
            });
        });
    },
    hotspotsWithBrandingSaveImmediate(hotspotWithBrandingArr, context) {
        //console.log('hotspotsWithBrandingSaveImmediate', JSON.stringify(hotspotWithBrandingArr));
        let hsArr = [];
        let parentPageId = null;
        hotspotWithBrandingArr.forEach((hotspotWithBranding) => {
            let hs = hotspotWithBranding.hotspot;
            let hsb = hotspotWithBranding.hotspot_branding;
            parentPageId = hs.parent_page_id;
            if (!hsb) {
                console.warn('hotspotsWithBrandingSaveImmediate called but no branding', hs.id);
                hsb = Object.assign({}, templateHotspotBranding);
            }
            let hsbAdditionalStyleStr = JSON.stringify(hsb.additional_style_obj);

            hsArr.push({
                id: hs.id,
                deleted: hs.deleted,
                parent_page_id: hs.parent_page_id,
                target_page_id: hs.target_page_id,
                target_resource_id: hs.target_resource_id,
                original_resource_id: hs.original_resource_id,
                bounds: hs.bounds,
                crop_x: hs.crop_x, crop_y: hs.crop_y,
                zoom_width: hs.zoom_width, zoom_height: hs.zoom_height,
                zindex: hs.zindex,
                html_content: hs.html_content,
                passcode: hs.passcode,
                is_movie_looping: hs.is_movie_looping,
                is_locked: hs.is_locked,

                branding_fill_colour: hsb.fill_colour,
                branding_background_colour: hsb.background_colour,
                branding_font_colour: hsb.font_colour,
                branding_font_resource_id: hsb.font_resource_id,
                branding_font_size: hsb.font_size,
                branding_text_alignment: hsb.text_alignment,
                branding_vertical_alignment: hsb.vertical_alignment,

                additional_style_obj: hsbAdditionalStyleStr
            });
        });
        let hsArrStr = JSON.stringify(hsArr);

        return new Promise((resolve, reject) => {
            //console.log('save??', hsbAdditionalStyleStr, hsb)
            //console.log('save??',  hsArrStr, hsArr)
            $.ajax({
                method: "POST", url: '/main/editor/ajax_update_hotspot_with_branding_v2',
                data: {
                    workspace_id: context.rootState.pres.showcase.presentation.workspace_id,
                    presentation_id: context.rootState.pres.showcase.presentation.id,
                    parent_page_id: parentPageId, selected_hs_arr: hsArrStr
                }
            }).then(function(response){
                if (response.status === 'ok') {
                    context.dispatch('presDraftCompileStarted', response.draft_compile_token);
                }
                resolve();
            }).fail(function(jqXhr){
                ScNotification.growlXhrError(jqXhr, 'saving hotspot');
                reject();
            });
        });
    },
    pageSaveImmediate(changedPage, context) {
        let pg = changedPage;
        return new Promise((resolve, reject) => {
            $.ajax({
                method: "POST", url: '/main/editor/ajax_update_page_v2',
                data: {
                    workspace_id: context.rootState.pres.showcase.presentation.workspace_id,
                    id: pg.id,
                    page_bg_color: pg.page_bg_color,
                    page_animated_bg_id: pg.page_animated_bg_id,
                    background_src_resource_id: pg.background_src_resource_id,
                    page_title: pg.title,
                    alpha_num_name: pg.alpha_num_name,
                    sort_order: pg.sort_order,
                    deleted: pg.deleted,
                }
            }).then(function (data) {
                context.dispatch('presDraftCompileStarted', data.draft_compile_token);
                resolve();
            }).fail(function (jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'saving slide');
                reject();
            });
        });
    },
    introVideoSaveImmediate(introVideoId, context) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST', url: '/main/editor/ajax_intro_video',
                data: {
                    workspace_id: context.rootState.pres.showcase.presentation.workspace_id,
                    presentation_id: context.rootState.pres.showcase.presentation.id,
                    intro_video_id: introVideoId
                }
            }).then(function(data){
                context.dispatch('presDraftCompileStarted', data.draft_compile_token);
                resolve();
            }).fail(function(jqXhr){
                ScNotification.growlXhrError(jqXhr, 'saving intro video');
                reject();
            });
        });
    },
    screensaverVideoSaveImmediate(screensaverVideoId, context) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST', url: '/main/editor/ajax_screensaver_video',
                data: {
                    workspace_id: context.rootState.pres.showcase.presentation.workspace_id,
                    presentation_id: context.rootState.pres.showcase.presentation.id,
                    screensaver_video_id: screensaverVideoId
                }
            }).then(function(data){
                //console.log(data)
                context.dispatch('presDraftCompileStarted', data.draft_compile_token);
                resolve();
            }).fail(function(jqXhr){
                ScNotification.growlXhrError(jqXhr, 'saving screensaver video');
                reject();
            });
        });
    },
    screensaverTimeoutSaveImmediate(screensaverTimeout, context) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST', url: '/main/editor/ajax_screensaver_timeout',
                data: {
                    workspace_id: context.rootState.pres.showcase.presentation.workspace_id,
                    presentation_id: context.rootState.pres.showcase.presentation.id,
                    screensaver_timeout: screensaverTimeout
                }
            }).then(function(data){
                //console.log(data)
                context.dispatch('presDraftCompileStarted', data.draft_compile_token);
                resolve();
            }).fail(function(jqXhr){
                ScNotification.growlXhrError(jqXhr, 'saving screensaver video');
                reject();
            });
        });
    }
};
dataManager.pageSaveDelayed = StoreUtils.debounceBasedOnArgId(dataManager.pageSaveImmediate,
    WAIT_MS_BEFORE_AJAX);
dataManager.hotspotsWithBrandingSaveDelayed = StoreUtils.collectAndDelayHotspotChanges(dataManager.hotspotsWithBrandingSaveImmediate,
    WAIT_MS_BEFORE_AJAX);
dataManager.introVideoSaveDelayed = _.debounce(dataManager.introVideoSaveImmediate,
    WAIT_MS_BEFORE_AJAX);
dataManager.screensaverVideoSaveDelayed = _.debounce(dataManager.screensaverVideoSaveImmediate,
    WAIT_MS_BEFORE_AJAX);
dataManager.screensaverTimeoutSaveDelayed = _.debounce(dataManager.screensaverTimeoutSaveImmediate,
    WAIT_MS_BEFORE_AJAX);



let _getters = {
    // should always start with "pres" to avoid collisions with other modules

    presCanPublish: (state) => {
        return !state.showcaseSavePending && state.draftCompileTokens.length === 0;
    },

    presFontScaleRatio: (state) => {
        let pres = state.showcase.presentation;
        let highDensity = pres.width > 2000 || pres.height > 2000;
        return highDensity ? 2 : 1;
    },

    presHotspotsForPageId: (state) => (pageId) => {
        return PresentationUtils.scDataHotspotsForPageId(state.showcase, pageId);
    },

    presGetHotspotWithHighestZindexForPage: (state) => (pageId) => {
        let hs = null;
        let hsSortedByZindex = PresentationUtils.scDataHotspotsForPageId(state.showcase, pageId);
        if ( hsSortedByZindex && hsSortedByZindex.length > 0 ) {
            hs = hsSortedByZindex[hsSortedByZindex.length -1];
        }
        return hs;
    },

    presGetLastEditedHotspot: (state) => (pageId) => {
        // get the last edited hotspot for the current page, if no for the whole presentation
        let hotspotsForPage = PresentationUtils.scDataHotspotsForPageId(state.showcase, pageId);
        let lastEditedHotspot = null;
        let highestHsUpdatedDate = new Date(0);
        hotspotsForPage.forEach((hs) => {
            if (isAfter(new Date(hs.updated_date), highestHsUpdatedDate)) {
                highestHsUpdatedDate = new Date(hs.updated_date);
                lastEditedHotspot = hs;
            }
        });
        if (!lastEditedHotspot) {
            Object.values(state.showcase.hotspot).forEach((hs) => {
                if (!hs.deleted && isAfter(new Date(hs.updated_date), highestHsUpdatedDate)) {
                    highestHsUpdatedDate = new Date(hs.updated_date);
                    lastEditedHotspot = hs;
                }
            });
        }
        //console.log('presGetLastEditedHotspot', lastEditedHotspot.html_content);
        return lastEditedHotspot;
    },

    presHotspotBrandingByHsId: (state) => (id) => {
        return PresentationUtils.scDataHotspotBrandingByHsId(state.showcase, id);
    },

    presHotspotBrandingAdditionalStylesObjByHsId: (state) => (id) => {
        let hsb = Object.values(state.showcase.hotspot_branding).find(hsb => hsb.hotspot_id === id);
        //console.log(hsb, typeof hsb.additional_style_obj)
        return hsb && hsb.additional_style_obj ? hsb.additional_style_obj : {};
    },
    presHotspotImageResourceByHsId: (state, getters) => (id) => {
        //console.log('store/getters/presHotspotImageResourceByHsId', id);
        let res = null;
        let hs = PresentationUtils.scDataHotspot(state.showcase, id);
        if (hs && hs.original_resource_id) {
            res = getters.presResource(hs.original_resource_id);
        }
        return res;
    },
    presResource: (state) => (id) => {
        //console.log('store/getters/presResource', id);
        if (id === null) return null;
        return state.showcase.resource[String(id)];
    },
    presResourcemetadataFirstWithName: (state) => (id, name) => {
        if (id === null) return null;
        let rmd = Object.values(state.showcase.resourcemetadata).find(rmd => {
            return rmd.resource_id === id && rmd.name === name
        });
        return rmd ? rmd : null;
    },
    presPage: (state) => (id) => {
        return PresentationUtils.scDataPage(state.showcase, id);
    },
    presHotspotsThatTargetPageId: (state) => (id) => {
        let hotspots = _.filter(state.showcase.hotspot, {target_page_id: id, deleted: false});
        //console.log('presHotspotsThatTargetPageId', id, hotspots);
        hotspots = _.sortBy(hotspots, 'pagelist_id');
        //console.log('presHotspotsThatTargetPageId', id, hotspots);
        return hotspots;
    },
    presPagesThatTargetPageId: (state, getters) => (id) => {
        let hotspots = getters.presHotspotsThatTargetPageId(id);
        let pages = {};
        hotspots.forEach((hs) => {
            pages[String(hs.parent_page_id)] = PresentationUtils.scDataPage(state.showcase, hs.parent_page_id);
        });
        return Object.values(pages);
    },

    presPagesForPagelist: (state) => (pagelistId) => {
        return PresentationUtils.scDataPagesForPagelist(state.showcase, pagelistId)
    },

    presGetPagelistById: (state) => (pagelistId) => {
        return Object.values(state.showcase.pagelist).find(pagelist => pagelist.id === pagelistId);
    },

    presIsResourceShareable: (state) => (opts) => {
        let sr = null;
        if (opts.page_id) {
            sr = _.findWhere(state.showcase.shareable_page_resource, {
                page_id: opts.page_id, resource_id: opts.resource_id});
        }   else {
            sr = _.findWhere(state.showcase.shareable_resource, {resource_id: opts.resource_id});
        }
        return !!sr;
    },

    presIsLastInstanceOfShareableResource: (state) => (opts) => {
        return _.where(state.showcase.shareable_page_resource, {resource_id: opts.resource_id});
    },

    presGetFirstPageWithPagelistId: (state) => (pagelistId) => {
        //console.log('presGetFirstPageWithPagelistId', pagelistId, state.showcase.page);
        let page = null;
        let pagesSortedById = PresentationUtils.scDataPagesForPagelist(state.showcase, pagelistId);
        if ( pagesSortedById && pagesSortedById.length > 0 ) {
            page = pagesSortedById[0];
        }
        return page;
    },

    presRootPage: (state, getters) => {
        let rootPageListId = state.showcase.presentationmetadata.root_pagelist_id;
        return getters.presGetFirstPageWithPagelistId(rootPageListId);
    },
};

function prepareHotspotBatchOperation(context, changedHsWithBrandingArr) {
    let hotspotOldArr = [];
    let hotspotNewArr = [];
    changedHsWithBrandingArr.forEach((changedHsWithBranding) => {
        let changedHs = changedHsWithBranding.hotspot;
        let changedHsb = changedHsWithBranding.hotspot_branding;
        let currentHs = PresentationUtils.scDataHotspot(context.state.showcase, changedHs.id);
        let currentHsb = PresentationUtils.scDataHotspotBrandingByHsId(context.state.showcase, changedHs.id);
        changedHs.updated_date = (new Date()).toISOString();  // update locally immediately
        //console.log('prepareHotspotBatchOperation changedHs', changedHs);
        hotspotOldArr.push({id: changedHs.id, hotspot: Object.assign({}, currentHs),
            hotspot_branding: Object.assign({}, currentHsb)});
        hotspotNewArr.push({id: changedHs.id, hotspot: Object.assign({}, changedHs),
            hotspot_branding: Object.assign({}, changedHsb)});
    });
    return {
        dataManagerCmd: 'hotspotsWithBrandingSaveDelayed', mutationCmd: 'presHotspotsWithBrandingSave',
        oldValue: hotspotOldArr, newValue: hotspotNewArr
    };
}


let _actions = {

    /* should always start with pres */

    presSetTitle: (context, newTitle) => {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST', url: '/main/editor/ajax_change_title', data: {
                workspace_id: context.state.showcase.presentation.workspace_id,
                presentation_id: context.state.showcase.presentation.id,
                title: newTitle
            }}).done((response) => {
                if (response.status === 'ok') {
                    context.commit('presComTitle', newTitle);
                    context.dispatch('presDraftCompileStarted', response.draft_compile_token);
                    resolve();
                }
                if (response.status === 'error' && response.code === 'title-taken') {
                    ScNotification.growlErrMsg('Dang! That title\'s taken. Try another?');
                }
                if (response.status === 'error' && response.msg){
                    ScNotification.growlErrMsg(response.msg);
                }
                reject();

            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'saving title');
                reject();
            });
        });
    },

    presReload: (context) => {
        return context.dispatch('presFetch', {
            workspace_id: Number(context.state.showcase.presentation.workspace_id),
            presentation_id: Number(context.state.showcase.presentation.id)
        });
    },

    presFetch: (context, sc) => {
        //console.log('$store/actions/presFetch', context, sc);
        // sc.workshopId, sc.showcaseId)
        return new Promise((resolve) => {
            if (!sc.presentation_id || !sc.workspace_id) {
                resolve();
                return;
            }
            context.commit('presSavePresLoading', true);
            $.ajax({
                method: "GET", url: '/main/editor/ajax_draft_showcase',
                data: {workspace_id: sc.workspace_id, presentation_id: sc.presentation_id}
            }).then((data) => {
                context.commit('updatePres', data.export);
                resolve();
            }).catch((jqXhr) => {
                //console.log(error);
                context.commit('updatePres', null);
                ScNotification.growlXhrError(jqXhr, 'fetching presentation');
                resolve();
            });
        });
    },

    presSetIntroVideo: (context, changedIntroVideoId) => {
        //console.log('$store/actions/presSetIntroVideo', changedIntroVideoId);
        let currentIntroVideoId = context.state.showcase.presentationmetadata.opening_scene_resource_id;
        let undoStep = {operations: [{
            dataManagerCmd: 'introVideoSaveDelayed', mutationCmd: 'presUpdateIntroVideo',
            oldValue: currentIntroVideoId, newValue: changedIntroVideoId
        }]};
        context.dispatch('undoExecStep', {
            undoStep: undoStep, valueKey: 'newValue', dataManager: dataManager, addStep: true});
    },

    presSetScreensaverVideo: (context, changedScreensaverVideoId) => {
        //console.log('$store/actions/presSetScreensaverVideo', changedScreensaverVideoId);
        let currentScreensaverVideoId = context.state.showcase.presentationmetadata.screensaver_video_resource_id;
        let undoStep = {operations: [{
            dataManagerCmd: 'screensaverVideoSaveDelayed', mutationCmd: 'presUpdateScreensaverVideo',
            oldValue: currentScreensaverVideoId, newValue: changedScreensaverVideoId
        }]};
        context.dispatch('undoExecStep', {
            undoStep: undoStep, valueKey: 'newValue', dataManager: dataManager, addStep: true});
    },

    presSetScreensaverTimeout: (context, changedScreensaverTimeout) => {
        //console.log('presSetScreensaverTimeout', context.state.showcase.presentationmetadata.screensaver_timeout, changedScreensaverTimeout);
        let currentScreensaverTimeout = context.state.showcase.presentationmetadata.screensaver_timeout;
        let undoStep = {operations: [{
            dataManagerCmd: 'screensaverTimeoutSaveDelayed', mutationCmd: 'presUpdateScreensaverTimeout',
            oldValue: currentScreensaverTimeout, newValue: changedScreensaverTimeout
        }]};
        context.dispatch('undoExecStep', {
            undoStep: undoStep, valueKey: 'newValue', dataManager: dataManager, addStep: true});

    },

    presResourceAddedFromLibrary: (context, libraryResource) => {
        //console.log('presResourceAddedFromLibrary', context, libraryResource);
        let res = {
            id: libraryResource.id,
            name: libraryResource.name,
            url: libraryResource.url,
            thumb_url: libraryResource.thumb_url,
            bytesize: libraryResource.bytesize,
            content_type: libraryResource.content_type,
            suffix: libraryResource.suffix,
            thumb_date: libraryResource.thumb_date,
            poster_date: libraryResource.poster_date,
            width: libraryResource.width,
            height: libraryResource.height,
        };
        if (libraryResource.video_1024_url) {
            context.commit('presAddedResourcemetadata', {
                id: Math.round(Math.random() * 10000000),  // doesn't matter so long as unique
                resource_id: libraryResource.id, name: 'video_1024_url', value: libraryResource.video_1024_url});
        }
        if (libraryResource.video_2048_url) {
            context.commit('presAddedResourcemetadata', {
                id: Math.round(Math.random() * 10000000),  // doesn't matter so long as unique
                resource_id: libraryResource.id, name: 'video_2048_url', value: libraryResource.video_2048_url});
        }
        context.commit('presAddedResource', res);
    },

    presNewWeburlResource: function(context, targetWeburlObj) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: "POST", url: '/main/editor/ajax_save_weburl_resource_v2',
                data: {
                    workspace_id: context.state.showcase.presentation.workspace_id,
                    web_url: targetWeburlObj.weburl, open_externally: targetWeburlObj.openExternally,
                    browser_opens_inline: targetWeburlObj.browserOpensInline
                }
            }).then((data) => {
                let newRes = Object.assign({}, templateResource);
                newRes.id = data.resource_id;
                newRes.content_type = 'weburl';
                newRes.name = targetWeburlObj.weburl.substring(0, 255);
                context.commit('presAddedResource', newRes);
                context.commit('presAddedResourcemetadata', {
                    id: Math.round(Math.random() * 10000000),  // doesn't matter so long as unique
                    resource_id: data.resource_id, name: 'weburl', value: targetWeburlObj.weburl
                });
                if (targetWeburlObj.openExternally) {
                    context.commit('presAddedResourcemetadata', {
                        id: Math.round(Math.random() * 10000000),  // doesn't matter so long as unique
                        resource_id: data.resource_id, name: 'open_externally', value: true
                    });
                }
                if (targetWeburlObj.browserOpensInline) {
                    context.commit('presAddedResourcemetadata', {
                        id: Math.round(Math.random() * 10000000),  // doesn't matter so long as unique
                        resource_id: data.resource_id, name: 'browser_opens_inline', value: true
                    });
                }
                resolve(data.resource_id);

            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'creating hotspot');
                reject();
            });
        });
    },

    presNewHotspots: function(context, newHsOpts) {
        //console.log('presNewHotspots', newHsOpts);
        return new Promise((resolve) => {
            // fetch the new id first then proceed with everything else
            dataManager.newHotspotsImmediate({
                parent_page_id: newHsOpts.parent_page_id,
                num_hotspots: newHsOpts.template_hotspot_ids.length
            }, context).then((newHsWithBrandings) => {
                //console.log('presNewHotspots after newHotspotsImmediate', newHsWithBrandings);
                let changedHsWithBrandingArr = [];
                let createdHotspots = [];
                let nextZindex = HotspotUtils.getNextHsZindex(context, newHsOpts.parent_page_id);

                let sortedTemplateHotspots = [];
                if (newHsOpts.template_hotspot_ids) {
                    let hotspots = newHsOpts.template_hotspot_ids.map((templateHotspotId) => {
                        return PresentationUtils.scDataHotspot(context.state.showcase, templateHotspotId);
                    });
                    sortedTemplateHotspots = PresentationUtils.scDataHotspotsSort(hotspots);
                }

                newHsWithBrandings.forEach((newHsWithBranding, idx) => {
                    let newHs = Object.assign({}, newHsWithBranding.hotspot);
                    let newHsb = Object.assign({}, newHsWithBranding.hotspot_branding);

                    if (sortedTemplateHotspots && sortedTemplateHotspots[idx]) {
                        let templateHsOrig = sortedTemplateHotspots[idx];
                        let templateHotspotId = templateHsOrig.id;

                        let templateHs = Object.assign({}, templateHsOrig);
                        if (templateHs.target_page_id && templateHs.target_page_id === newHsOpts.parent_page_id) {
                            templateHs.target_page_id = null;  // reset target page when hotspot links to own page
                        }
                        templateHs.id = newHs.id;  // don't clobber the id
                        templateHs.parent_page_id = newHsOpts.parent_page_id;
                        let templateHsb = Object.assign({}, PresentationUtils.scDataHotspotBrandingByHsId(context.state.showcase, templateHotspotId));
                        if (!templateHsb) {
                            console.warn('presNewHotspots template hs with no hsb');
                            templateHsb = Object.assign({}, templateHotspotBranding);
                        }
                        templateHsb.id = newHs.id; // this can be anything so long as it's unique
                        templateHsb.hotspot_id = newHs.id;
                        Object.assign(newHs, templateHs);
                        Object.assign(newHsb, templateHsb);

                    } else {
                        HotspotUtils.applyLastEditedHotspotStyle(context, newHsOpts.parent_page_id, newHs, newHsb,
                          templateHotspotBranding);
                    }

                    if (newHsOpts.target_resource_id) newHs.target_resource_id = newHsOpts.target_resource_id;
                    if (newHsOpts.target_page_id) newHs.target_page_id = newHsOpts.target_page_id;
                    if (newHsOpts.html_content) newHs.html_content = newHsOpts.html_content;
                    newHs.deleted = false;
                    newHs.zindex = nextZindex;
                    HotspotUtils.prepareHotspotPosition(context, newHs);
                    changedHsWithBrandingArr.push({hotspot: newHs, hotspot_branding: newHsb});
                    createdHotspots.push(newHs);
                    nextZindex ++;  // we have to do this locally as new hotspots in this batch are not commited yet
                });

                let undoStep = {operations: [prepareHotspotBatchOperation(context, changedHsWithBrandingArr)]};
                context.dispatch('undoExecStep', {
                    undoStep: undoStep, valueKey: 'newValue', dataManager: dataManager, addStep: true});

                resolve(createdHotspots);
            });
        });
    },

    presUndoableSaveHotspotBatch: function(context, changedHsBatch) {
        let changedHsWithBrandingArr = [];
        changedHsBatch.forEach((changedHs) => {
            let currentHsb = PresentationUtils.scDataHotspotBrandingByHsId(context.state.showcase, changedHs.id);
            //console.log('presUndoableSaveHotspotBatch item', changedHs, currentHsb);
            changedHsWithBrandingArr.push({hotspot: changedHs, hotspot_branding: currentHsb})
        });
        let undoStep = {operations: [prepareHotspotBatchOperation(context, changedHsWithBrandingArr)]};
        context.dispatch('undoExecStep', {
            undoStep: undoStep, valueKey: 'newValue', dataManager: dataManager, addStep: true});
    },

    presUndoableSaveHotspotBrandingBatch: function(context, changedHsbBatch) {
        //console.log('$store/actions/presUndoableSaveHotspotBranding', context, changedHsb);
        let changedHsWithBrandingArr = [];
        changedHsbBatch.forEach((changedHsb) => {
          let currentHs = Object.assign({}, PresentationUtils.scDataHotspot(context.state.showcase, changedHsb.hotspot_id));
          changedHsWithBrandingArr.push({hotspot: currentHs, hotspot_branding: changedHsb})
        });
        let undoStep = {operations: [prepareHotspotBatchOperation(context, changedHsWithBrandingArr)]};
        context.dispatch('undoExecStep', {
            undoStep: undoStep, valueKey: 'newValue', dataManager: dataManager, addStep: true});
    },

    presNewPagelist: function(context, newPagelistOpts) {
        return new Promise(function(resolve) {
            //console.log('presNewPagelist');
            let slideBgResourceIds = [null];
            if (newPagelistOpts.page_background_res_ids) {
                slideBgResourceIds = newPagelistOpts.page_background_res_ids;
            }
            let newPages = null;
            let newPagelist = null;
            context.dispatch('presSavePending');

            dataManager.newPagelistImmediate(newPagelistOpts, context).then((newPagelistArg) => {
                newPagelist = newPagelistArg;
                let newPagePromises = [];
                slideBgResourceIds.forEach(() => {
                    let idx = 0;
                    let newPagePromise = dataManager.newPageImmediate({
                        pagelist_id: newPagelist.id, sort_order: idx,
                        alpha_num_name: PresentationUtils.getAlphaNumName(newPagelistArg.sequence_num, idx)
                    }, context);
                    newPagePromises.push(newPagePromise);
                    idx++;
                });
                return Promise.all(newPagePromises);

            }).then(function(argNewPages) {
                newPages = argNewPages;
                let idx = 0;
                let pageChangePromises = [];
                newPages.forEach((newPage) => {
                    let changedPage = Object.assign({}, newPage);
                    changedPage.deleted = false;
                    let bgResId = slideBgResourceIds[idx];
                    changedPage.background_src_resource_id = bgResId;
                    if (bgResId) {
                        let bgRes = context.state.showcase.resource[String(bgResId)];
                        changedPage.title = bgRes ? bgRes.name : null;
                    }
                    changedPage.sort_order = idx;
                    changedPage.alpha_num_name = PresentationUtils.getAlphaNumName(newPagelist.sequence_num,
                        changedPage.sort_order);

                    pageChangePromises.push(
                        dataManager.pageSaveImmediate(changedPage, context).then(() => {
                            context.commit('presPageSave', changedPage, { root: true });
                        }, () => {
                            ScNotification.growlErrMsg('Could not cave changes to ' + changedPage.alpha_num_name);
                        })
                    );
                    idx++;
                });
                return Promise.all(pageChangePromises)

            }).then(function() {
                // make a hotspot if needed
                if (newPagelistOpts.existing_hotspot_id) {
                    return new Promise((reso) => {
                        let hs = PresentationUtils.scDataHotspot(context.state.showcase, newPagelistOpts.existing_hotspot_id);
                        let hsb = PresentationUtils.scDataHotspotBrandingByHsId(context.state.showcase, newPagelistOpts.existing_hotspot_id);
                        reso([{hotspot: hs, hotspot_branding: hsb}]);
                    });
                }   else {
                    return dataManager.newHotspotsImmediate({
                        parent_page_id: newPagelistOpts.new_hotspot_page_id, num_hotspots: 1}, context);
                }

            }).then(function(newHotspotWithBrandings) {
                // make all operations in one undo step
                let newHotspotWithBranding = newHotspotWithBrandings[0];
                let changedHs = Object.assign({}, newHotspotWithBranding.hotspot);
                let changedHsb = Object.assign({}, newHotspotWithBranding.hotspot_branding);

                if (!newPagelistOpts.existing_hotspot_id) {
                    HotspotUtils.applyLastEditedHotspotStyle(context,
                        newHotspotWithBranding.parent_page_id, changedHs, changedHsb, templateHotspotBranding);
                    changedHs.zindex = HotspotUtils.getNextHsZindex(context, changedHs.parent_page_id);
                    HotspotUtils.prepareHotspotPosition(context, changedHs);
                }
                changedHs.deleted = false;
                changedHs.target_page_id = newPages[0].id;

                let undoStep = {operations: [prepareHotspotBatchOperation(context, [{hotspot: changedHs, hotspot_branding: changedHsb}])]};
                context.dispatch('undoExecStep', {
                    undoStep: undoStep, valueKey: 'newValue', dataManager: dataManager, addStep: true});
                resolve(changedHs);

            }).catch((e) => {
                console.error('newpl err', e);
            });
        });
    },

    presNewPage: function(context, newPageOpts) {
        //console.log('presNewPage', newPageOpts);
        // fetch the new id first then proceed with everything else
        return new Promise(function(resolve) {
            dataManager.newPageImmediate(newPageOpts, context).then((newPage) => {
                let changedPage = Object.assign({}, newPage);
                changedPage.deleted = false;
                changedPage.background_src_resource_id = newPageOpts.background_src_resource_id;
                changedPage.page_animated_bg_id = newPageOpts.page_animated_bg_id;
                changedPage.page_bg_color = newPageOpts.page_bg_color;
                changedPage.sort_order = newPageOpts.sort_order;
                changedPage.title = newPageOpts.title;
                changedPage.alpha_num_name = newPageOpts.alpha_num_name;

                // add changedPage into pageArray on right position and update sort orders
                context.dispatch('presAddPageWithSortOrder', changedPage);

                context.dispatch('presUndoableSavePage', changedPage);
                //console.log('pres.js got new page', changedPage);
                resolve(changedPage);
            });

        });
    },

    presAddPageWithSortOrder (context, newPageAdded) {
        let pl = context.getters.presGetPagelistById(newPageAdded.pagelist_id);

        let pagesArr = [...PresentationUtils.scDataPagesForPagelist(context.state.showcase, pl.id)];
        pagesArr.splice(newPageAdded.sort_order, 0, newPageAdded);

        let sortedPages = [];
        PresentationUtils.resequencePages(pl.sequence_num, pagesArr, sortedPages);
        // call update on all pages that need new sort_order
        sortedPages.forEach((page) => {
            context.dispatch('presUndoableSavePage', page);
        });
    },

    presDeletePagelist: function(context, pagelistId) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: "POST", url: '/main/editor/ajax_delete_pagelist_v2',
                data: {
                    workspace_id: context.state.showcase.presentation.workspace_id,
                    presentation_id: context.rootState.pres.showcase.presentation.id,
                    pagelist_id: pagelistId
                }
            }).then((data) => {
                context.commit('presDeletedPagelist', pagelistId);
                context.dispatch('presDraftCompileStarted', data.draft_compile_token);
                resolve();

            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'deleting pagelist');
                reject();
            });
        });
    },

    presSortPagesResequenceSortOrderAlphaNumName: function(context, opts) {
        let pages = [];
        opts.pageIdsArray.forEach((pageId) => {
            pages.push(PresentationUtils.scDataPage(context.state.showcase, pageId));
        });
        let pagelist = context.getters.presGetPagelistById(opts.pagelistId);
        let changedPages = [];
        PresentationUtils.resequencePages(pagelist.sequence_num, pages, changedPages);
        let undoStep = {operations: []};
        changedPages.forEach((changedPage) => {
            let currentPage = PresentationUtils.scDataPage(context.state.showcase, changedPage.id);
            undoStep.operations.push({
                dataManagerCmd: 'pageSaveDelayed', mutationCmd: 'presPageSave',
                oldValue: Object.assign({}, currentPage), newValue: Object.assign({}, changedPage)
            });
        });
        context.dispatch('undoExecStep', {
            undoStep: undoStep, valueKey: 'newValue', dataManager: dataManager, addStep: true});
    },

    presDeletePageAndResequenceSortOrderAlphaNumName: function(context, pageId) {
        let pageToDelete = PresentationUtils.scDataPage(context.state.showcase, pageId);
        let pages = [...PresentationUtils.scDataPagesForPagelist(context.state.showcase, pageToDelete.pagelist_id)];  // shallow copy
        let pagelist = context.getters.presGetPagelistById(pageToDelete.pagelist_id);
        let changedPages = [];

        let changedPage = Object.assign({}, pageToDelete);  // the page to be deleted
        changedPage.deleted = true;
        changedPages.push(changedPage);
        pages.splice(pages.indexOf(pageToDelete), 1);  // remove page from list

        PresentationUtils.resequencePages(pagelist.sequence_num, pages, changedPages);

        let undoStep = {operations: []};
        let changedHsWithBrandingArr = [];
        let hotspots = context.getters.presHotspotsThatTargetPageId(pageId);
        hotspots.forEach((hs) => {
            let changedHs = Object.assign({}, hs);
            changedHs.target_page_id = null;
            let currentHsb = PresentationUtils.scDataHotspotBrandingByHsId(context.state.showcase, changedHs.id);
            changedHsWithBrandingArr.push({hotspot: changedHs, hotspot_branding: currentHsb});
        });
        if (changedHsWithBrandingArr.length > 0) {
            undoStep.operations.push(prepareHotspotBatchOperation(context, changedHsWithBrandingArr));
        }

        changedPages.forEach((changedPage) => {
            let currentPage = PresentationUtils.scDataPage(context.state.showcase, changedPage.id);
            undoStep.operations.push({
                dataManagerCmd: 'pageSaveDelayed', mutationCmd: 'presPageSave',
                oldValue: Object.assign({}, currentPage), newValue: Object.assign({}, changedPage)
            });
        });
        context.dispatch('undoExecStep', {
            undoStep: undoStep, valueKey: 'newValue', dataManager: dataManager, addStep: true});
    },

    presUndoableSavePageBatch: function(context, changedPages) {
        let undoStep = {operations: []};
        changedPages.forEach((changedPage) => {
            let currentPage = PresentationUtils.scDataPage(context.state.showcase, changedPage.id);
            undoStep.operations.push({
                dataManagerCmd: 'pageSaveDelayed', mutationCmd: 'presPageSave',
                oldValue: Object.assign({}, currentPage), newValue: Object.assign({}, changedPage)
            });
        });
        context.dispatch('undoExecStep', {
            undoStep: undoStep, valueKey: 'newValue', dataManager: dataManager, addStep: true});
    },

    presUndoableSavePage: function(context, changedPage) {
        //console.log('$store/actions/presUndoableSavePage', context, changedPage);
        let currentPage = PresentationUtils.scDataPage(context.state.showcase, changedPage.id);
        let undoStep = {operations: [{
            dataManagerCmd: 'pageSaveDelayed', mutationCmd: 'presPageSave',
            oldValue: Object.assign({}, currentPage), newValue: Object.assign({}, changedPage)
        }]};
        context.dispatch('undoExecStep', {
            undoStep: undoStep, valueKey: 'newValue', dataManager: dataManager, addStep: true});
    },

    presSavePending: function(context) {
        if (_savePendingTimeout) clearTimeout(_savePendingTimeout);
        context.commit('presSavePendingSet', true);
        _savePendingTimeout = setTimeout(() => {
            context.commit('presSavePendingSet', false);
        }, WAIT_MS_BEFORE_AJAX + 1000);  // add a little more so ajax call can be enqueued
    },

    presToggleShareableResource: (context, opts) => {
        //if (shareableItem.action_pending) return;
        //shareableItem.action_pending = true;
        let args = {
            workspace_id: context.state.showcase.presentation.workspace_id,
            presentation_id: context.state.showcase.presentation.id,
            resource_id: opts.resource_id,
            page_id: opts.page_id, enabled: opts.enabled
        };
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                url: '/main/presentations/ajax_toggle_shareable_content',
                data: args
            }).then(function (data) {
                context.commit('presSaveShareableResource', {data: data, args: args});
                context.dispatch('presDraftCompileStarted', data.draft_compile_token);
                resolve();

            }).fail(function (jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'changing shareable item');
                reject();
            });
        });
    },

    presShareSettings: (context, opts) => {
        return new Promise((resolve) => {
            $.ajax({
                type: 'POST', url: '/main/presentations/ajax_allows_sharing', data: {
                    workspace_id: context.state.showcase.presentation.workspace_id,
                    presentation_id: context.state.showcase.presentation.id,
                    allows_showcase_sharing: opts.fullShowcaseSharingEnabled
                }
            }).then((data) => {
                ScNotification.growlSuccessMsg('Saved');
                context.commit('presShareSettingsSave', opts);
                context.dispatch('presDraftCompileStarted', data.draft_compile_token);
                resolve();

            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'saving');
                resolve();
            });
        });

    },

    presDraftCompileStarted: (context, draftCompileToken) => {
        context.commit('presMarkTainted', draftCompileToken);
        setTimeout(function() {
            if (context && context.commit) context.commit('presDraftCompiled', draftCompileToken);
        }, 60 * 1000);  // remove token after 60s just in case
    }

};



let _mutations = {

    presSavePendingSet(state, saveIsPending) {
        state.showcaseSavePending = saveIsPending;
    },

    presSavePresLoading(state, isLoading) {
        state.showcaseLoading = isLoading;
    },

    presComTitle(state, newTitle) {
        state.showcase.presentation.title = newTitle;
    },

    presUpdateIntroVideo(state, intro_video_res_id) {
        state.showcase.presentationmetadata.opening_scene_resource_id = intro_video_res_id;
    },

    presUpdateScreensaverVideo(state, screensaver_video_res_id) {
        state.showcase.presentationmetadata.screensaver_video_resource_id = screensaver_video_res_id;
    },

    presUpdateScreensaverTimeout(state, screensaver_timeout) {
        state.showcase.presentationmetadata.screensaver_timeout = screensaver_timeout;
    },

    presSetDefaultFont(state, font_resource_id) {
        //console.log('presSetDefaultFont', font_resource_id);
        templateHotspotBranding.font_resource_id = font_resource_id;
    },

    updatePres(state, pres) {
        //console.log('$store/mutations/updatePres', pres);
        let statePres = state.showcase;

        // reset back to nothing
        StoreUtils.vueDeleteAllKeys(statePres.pagelist);
        StoreUtils.vueDeleteAllKeys(statePres.page);
        StoreUtils.vueDeleteAllKeys(statePres.hotspot);
        StoreUtils.vueDeleteAllKeys(statePres.hotspot_branding);
        StoreUtils.vueDeleteAllKeys(statePres.resource);
        StoreUtils.vueDeleteAllKeys(statePres.resourcemetadata);
        StoreUtils.vueDeleteAllKeys(statePres.shareable_resource);
        StoreUtils.vueDeleteAllKeys(statePres.shareable_page_resource);

        if (pres) {
            // now populate from response
            StoreUtils.vueSetAllKeys(statePres.pagelist, pres.pagelist, templatePagelist);
            StoreUtils.vueSetAllKeys(statePres.page, pres.page, templatePage);
            StoreUtils.vueSetAllKeys(statePres.hotspot, pres.hotspot, templateHotspot);
            StoreUtils.vueSetAllKeys(statePres.hotspot_branding, pres.hotspot_branding, templateHotspotBranding);
            StoreUtils.vueSetAllKeys(statePres.resource, pres.resource, templateResource);
            StoreUtils.vueSetAllKeys(statePres.resourcemetadata, pres.resourcemetadata, templateResourcemetadata);
            StoreUtils.vueSetAllKeys(statePres.shareable_resource, pres.shareable_resource, templateSharableResource);
            StoreUtils.vueSetAllKeys(statePres.shareable_page_resource, pres.shareable_page_resource, templateSharablePageResource);

            // use assign with these as they are not "deep"
            statePres.presentationmetadata = Object.assign({}, statePres.presentationmetadata, pres.presentationmetadata);
            statePres.primary_layout = Object.assign({}, statePres.primary_layout, pres.primary_layout);
            statePres.presentation = Object.assign({}, statePres.presentation, pres.presentation);  // should be set last

            // check all hotspots have a branding row, add one if not
            Object.values(statePres.hotspot).forEach((hs) => {
                if (!statePres.hotspot_branding[String(hs.id)]) {
                    let newHsb = Object.assign({}, templateHotspotBranding);
                    newHsb.id = hs.id;
                    newHsb.hotspot_id = hs.id;
                    statePres.hotspot_branding[String(hs.id)] = newHsb;
                }
            });

            state.showcaseLoaded = true;
        }
        state.showcaseLoading = false;
        state.showcaseLastLoadedDate = new Date();
        state.draftCompileTokens.splice(0);

    },

    presPageSave(state, changedPage) {
        //console.log('$store/mutations/presPageSave', JSON.stringify(changedPage));
        if (!changedPage) return;
        let currentPage = PresentationUtils.scDataPage(state.showcase, changedPage.id);
        if (!currentPage){
            let newPage = Object.assign({}, changedPage);
            //newPage.id = changedPage.id;
            state.showcase.page[String(changedPage.id)] = newPage;
        }   else {
            Object.assign(currentPage, changedPage);
        }
    },

    presHotspotsWithBrandingSave(state, changedHotspotsWithBrandingArr) {
        //console.log('$store/mutations/presHotspotsWithBrandingSave'); //, JSON.stringify(changedHotspotsWithBrandingArr));
        if (!changedHotspotsWithBrandingArr) return;
        changedHotspotsWithBrandingArr.forEach((changedHotspotWithBranding) => {
            //console.log('changedHotspotWithBranding', JSON.stringify(changedHotspotWithBranding));
            let changedHs = changedHotspotWithBranding.hotspot;
            let currentHs = PresentationUtils.scDataHotspot(state.showcase, changedHs.id);
            if (!currentHs){
                state.showcase.hotspot[String(changedHs.id)] = changedHs;
            }   else {
                Object.assign(currentHs, changedHs);
            }
            let changedHsb = changedHotspotWithBranding.hotspot_branding;
            if (!changedHsb) {
                console.warn('presHotspotsWithBrandingSave called but no branding', changedHs.id);
                changedHsb = Object.assign({}, templateHotspotBranding);
                changedHsb.hotspot_id = changedHs.id;
            }
            let currentHsb = PresentationUtils.scDataHotspotBrandingByHsId(state.showcase, changedHs.id);
            if (!currentHsb) {
                state.showcase.hotspot_branding[String(changedHs.id)] = changedHsb;
            } else {
                Object.assign(currentHsb, changedHsb);
            }
        })
    },

    presAddedResource(state, res) {
        //console.log('presAddedResource', state, res);
        if (!state.showcase.resource[String(res.id)]) {
            state.showcase.resource[String(res.id)] = Object.assign({}, templateResource, res);
        }
    },

    presResourceRenamed(state, renamedResource) {
        //console.log('presResourceRenamed', state, renamedResource);
        let res = state.showcase.resource[String(renamedResource.id)];
        if (res) {
            res.name = renamedResource.name;
        }
    },

    presAddedResourcemetadata(state, rmd) {
        //console.log('presAddedResourcemetadata', state, res);
        if (!state.showcase.resourcemetadata[String(rmd.id)]) {
            state.showcase.resourcemetadata[String(rmd.id)] = Object.assign({}, templateResourcemetadata, rmd);
        }
    },

    presAddedHotspotWithBranding(state, hotspotWithBranding) {
        if (!state.showcase.hotspot[String(hotspotWithBranding.hotspot.id)]) {
            state.showcase.hotspot[String(hotspotWithBranding.hotspot.id)] = hotspotWithBranding.hotspot;
        }
        if (!state.showcase.hotspot_branding[String(hotspotWithBranding.hotspot_branding.hotspot_id)]) {
            state.showcase.hotspot_branding[String(hotspotWithBranding.hotspot_branding.hotspot_id)] =
                hotspotWithBranding.hotspot_branding;
        }
    },

    presAddedPage(state, page) {
        if (!state.showcase.page[String(page.id)]) {
            state.showcase.page[String(page.id)] = page;
        }
    },

    presAddedPagelist(state, pl) {
        if (!state.showcase.pagelist[String(pl.id)]) {
            state.showcase.pagelist[String(pl.id)] = pl;
        }
    },

    presDeletedPagelist(state, pagelistId) {
        let pagesInList = _.filter(state.showcase.page, {pagelist_id: pagelistId, deleted: false});
        pagesInList.forEach((p) => {  // delete pages that belong to list
            delete state.showcase.page[String(p.id)];
            Object.values(state.showcase.hotspot).forEach((hs) => {  // delete hotspots
                if (hs.page_id === p.id) {
                    delete state.showcase.hotspot[String(hs.id)];
                }
            });
        });
        if (state.showcase.pagelist[String(pagelistId)]) {  // now the list itself
            delete state.showcase.pagelist[String(pagelistId)];
        }
    },

    presMarkTainted(state, draftCompileToken) {
        state.showcase.presentationmetadata.cid = 0;
        if (draftCompileToken) {
            state.draftCompileTokens.push(draftCompileToken);
        }
    },

    presDraftCompiled(state, draftCompileToken) {
        if (!state || !state.draftCompileTokens || !draftCompileToken) return;
        let tokenIdx = state.draftCompileTokens.indexOf(draftCompileToken);
        if (tokenIdx !== -1) state.draftCompileTokens.splice(tokenIdx, 1);
    },

    presResourceThumbReady(state, data) {
        //console.log('presResourceThumbReady', data);
        let resToUpdate = state.showcase.resource[String(data.resource_id)];
        if (!resToUpdate) return;
        resToUpdate.thumb_url = data.thumb_url;
        resToUpdate.bytesize = data.bytesize;
        if (data.res_width) resToUpdate.width = data.res_width;
        if (data.res_height) resToUpdate.height = data.res_height;
    },

    presPageThumbReady(state, data) {
        //console.log('presPageThumbReady', data);
        let pageToUpdate = state.showcase.page[String(data.page_id)];
        if (!pageToUpdate) return;

        let thumbRes = state.showcase.resource[String(data.resource_id)];
        if (thumbRes) {
            thumbRes.thumb_url = data.thumb_url;
        } else {
            state.showcase.resource[String(data.resource_id)] = {
                id: data.resource_id,
                thumb_url: data.thumb_url
            };
        }
        pageToUpdate.resource_pagethumb_id = data.resource_id;

        // todo: need retry logic??
        // if (!args.attempt) args.attempt = 0;
        // args.attempt++;
        // if (args.attempt > 5) return;
        //
        // //console.log('addThumbToPage', args.attempt);
        //
        // let page = getPage(args.page_id);
        // if (!page) {
        //     setTimeout(function () {  // maybe we dont' have page yet, retry in 2s
        //         addThumbToPage(args);
        //     }, 5000);
        //     return;
        // }

    },

    presSaveShareableResource: (state, payload) => {
        if (payload.args.page_id) {
            if (payload.args.enabled) {
                state.showcase.shareable_page_resource[String(payload.data.shareable_resource_id)] = {
                    id: payload.data.shareable_resource_id,
                    resource_id: payload.args.resource_id,
                    page_id: payload.args.page_id,
                };
            } else {
                delete state.showcase.shareable_page_resource[String(payload.data.shareable_resource_id)];
            }
        } else {
            if (payload.args.enabled) {
                state.showcase.shareable_resource[String(payload.data.shareable_resource_id)] = {
                    id: payload.data.shareable_resource_id,
                    resource_id: payload.args.resource_id
                };
            } else {
                delete state.showcase.shareable_resource[String(payload.data.shareable_resource_id)];
            }
        }
    },

    presShareSettingsSave: (state, opts) => {
        let showcaseShareableItem = null;
        let scShareRes = _.findWhere(state.showcase.resource, {content_type: 'showcase_share'});
        if (scShareRes) {
            let sr = _.findWhere(state.showcase.shareable_resource, {resource_id: scShareRes.id});
            if (sr) showcaseShareableItem = sr;
        }

        if (opts.fullShowcaseSharingEnabled && !showcaseShareableItem) {
            let fakeId = Math.floor(Math.random() * 100000000);
            state.showcase.resource[String(fakeId)] = {
                id: fakeId, content_type: 'showcase_share', name: 'Showcase Share' };
            state.showcase.shareable_resource[String(fakeId)] = {
                id: fakeId, resource_id: fakeId };
        }
        if (!opts.fullShowcaseSharingEnabled && showcaseShareableItem) {
            delete state.showcase.shareable_resource[String(showcaseShareableItem.id)];
        }
    },

    presViewerArrowsEnabled: (state, enabled) => {
        state.showcase.presentation.viewer_arrows_enabled = enabled;
    },

    presIsTemplateEnabled: (state, enabled) => {
        state.showcase.presentation.template = enabled;
    },

    presPermissionsUpdate: (state, permissions) => {
        state.showcase.presentation.permissions = permissions;
    },


};


export default {
    state: _state,
    getters: _getters,
    actions: _actions,
    mutations: _mutations
};
