<template>
  <div v-if="edMxSelectedHotspots.length > 0 && edMxSelectedHotspotsBranding.length > 0" class="d-inline-block" >
    <div class="dropdown">
      <button type="button" class="btn px-md-3 sc-highlight-expanded" data-bs-toggle="dropdown" :disabled="edMxLoneSelectedHotspotLocked"
              role="button" aria-expanded="false" v-sc-tooltip="'Spacing'">
        <ScIcon name="lineHeightFW"/>
      </button>
      <div class="dropdown-menu dropdown-menu-right pb-3" @click.prevent.stop style="width: 330px;">

        <span class="dropdown-header border-0 mb-0">Letter Spacing</span>
        <div class="btn-group align-items-center w-100 mb-2 px-3">
          <button class="btn"
                  :class="[(hsbLetterSpacing === 'xs') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingLetterSpacing('xs')">XS</button>
          <button class="btn"
                  :class="[(hsbLetterSpacing === 'sm') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingLetterSpacing('sm')">S</button>
          <button class="btn"
                  :class="[(hsbLetterSpacing === 'md') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingLetterSpacing('md')">M</button>
          <button class="btn"
                  :class="[(hsbLetterSpacing === 'lg') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingLetterSpacing('lg')">L</button>
          <button class="btn"
                  :class="[(hsbLetterSpacing === 'xl') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingLetterSpacing('xl')">XL</button>
        </div>

        <hr class="my-2 mx-3" />

        <span class="dropdown-header border-0 mb-0">Line Height</span>
        <div class="btn-group align-items-center w-100 mb-2 px-3">
          <button class="btn"
                  :class="[(hsbLineHeight === 'xs') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingLineHeight('xs')">XS</button>
          <button class="btn"
                  :class="[(hsbLineHeight === 'sm') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingLineHeight('sm')">S</button>
          <button class="btn"
                  :class="[(hsbLineHeight === 'md') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingLineHeight('md')">M</button>
          <button class="btn"
                  :class="[(hsbLineHeight === 'lg') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingLineHeight('lg')">L</button>
          <button class="btn"
                  :class="[(hsbLineHeight === 'xl') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingLineHeight('xl')">XL</button>
        </div>

        <hr class="my-2 mx-3" />

        <span class="dropdown-header border-0 mb-0">Padding</span>
        <div class="btn-group align-items-center w-100 mb-2 px-3">
          <button class="btn"
                  :class="[(hsbHotspotPadding === 'off') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingHotspotPadding('off')">Off</button>
          <button class="btn"
                  :class="[(hsbHotspotPadding === 'sm') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingHotspotPadding('sm')">S</button>
          <button class="btn"
                  :class="[(hsbHotspotPadding === 'md') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingHotspotPadding('md')">M</button>
          <button class="btn"
                  :class="[(hsbHotspotPadding === 'lg') ? 'active btn-outline-primary' : 'btn-outline-secondary']"
                  @click.prevent.stop="updateHotspotBrandingHotspotPadding('lg')">L</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import EditorMixin from './../EditorMixin';
import ScIcon from '../../../shared/common/ScIcon.vue';


export default {
    name: "EditorToolbarTextSpacing",
    mixins: [EditorMixin],
    components: {ScIcon},
    methods: {
      updateHotspotBrandingLetterSpacing (value) {
        this.edMxUpdateSelectedHotspotsBrandingAdditionalStyles('letter_spacing', value);
      },

      updateHotspotBrandingLineHeight (value) {
        this.edMxUpdateSelectedHotspotsBrandingAdditionalStyles('line_height', value);
      },

      updateHotspotBrandingHotspotPadding (value) {
        this.edMxUpdateSelectedHotspotsBrandingAdditionalStyles('hotspot_padding', value);
      },

    },
    computed: {
      hsbLetterSpacing () {
        return this.edMxSelectedMultipleHotspotsBrandingAdditionalStylesDisplayValueForAttribute('letter_spacing', 'md');
      },
      hsbLineHeight () {
        return this.edMxSelectedMultipleHotspotsBrandingAdditionalStylesDisplayValueForAttribute('line_height', 'sm');
      },
      hsbHotspotPadding () {
        return this.edMxSelectedMultipleHotspotsBrandingAdditionalStylesDisplayValueForAttribute('hotspot_padding', this.getDefaultHsPadding);
      },
      getDefaultHsPadding () {
        let fSarr = [];
        this.edMxSelectedHotspotsBranding.forEach((obj) => {
          let fS = (obj.font_size && obj.font_size > 36) ? 'sm' : 'md';
          if (!fSarr.includes(fS)) fSarr.push(fS);
        });
        return (fSarr.length === 1) ? fSarr[0] : null;
      }
    },

}


</script>
