<template>
    <div class="">
        <div class="shadow-sm rounded bg-white text-center thumbnail-page mx-auto" :style="pageThumbContainerStyle"
             :class="[isPageSelected ? 'thumbnail-page-selected' : '', isPageCurrent ? 'thumbnail-page-current':'']">
            <a href="#" @click.prevent="emitSelectPage" @dblclick="emitSelectPageAndGoto" :title="'Go to slide ' + page.alpha_num_name">
                <img v-if="pageThumb" alt="Thumbnail"
                     :src="pageThumb.thumb_url"
                     class="mt-2 mx-2 mb-0"
                     :style="pageThumbStyle" />
                <span v-if="!pageThumb"
                      class="page-thumb border d-flex align-items-center justify-content-center mt-2 mx-auto mb-0"
                      :style="pageThumbStyle">
                    <ScIcon name="imageMedium" class="text-white" style="opacity: 0.2;"/>
                </span>
            </a>


            <div v-if="isPageSelected" class="text-truncate py-1 mx-auto text-primary" :style="pageCaptionStyle">
                <button class="btn btn-link py-1 float-start" :disabled="!previousPage" v-sc-tooltip="'Move left'"
                        @click.prevent.stop="movePage(false)"><ScIcon name="chevronLeftFW"/></button>

                <div class="py-0" style="position: absolute; margin-left: 69px;">
                   <EditorSlideMoreDropdown :maxSlidesPerSlideshow="200" :page="page"></EditorSlideMoreDropdown>
                </div>

                <button class="btn btn-link py-1 float-end" :disabled="!nextPage" v-sc-tooltip="'Move right'"
                        @click.prevent.stop="movePage(true)"><ScIcon name="chevronRightFW"/></button>
            </div>

            <div v-else class="text-truncate py-2 mx-auto text-primary" :style="pageCaptionStyle">
                <a href="#" @click.prevent="emitSelectPage" @dblclick.prevent="emitSelectPageAndGoto"
                   style="line-height: 1.65;" class="text-break">
                    <span v-if="!isPageUsed && pageIncomingPages && pageIncomingPages.length > 0">
                        <ScIcon name="lowVision" class="me-2 text-muted" style="opacity: 0.5;"
                                v-sc-tooltip="'This slide is linked from another unused slide'"/>
                    </span>
                    <span v-else-if="!isPageUsed">
                        <ScIcon name="lowVision" class="me-2 text-muted" style="opacity: 0.5;"
                                v-sc-tooltip="'No hotspots link to this slide or this slide is linked from another unused slide'"/>
                    </span>
                    <strong>{{page.alpha_num_name}}</strong>
                    <span v-if="pageTitle">&nbsp;{{ pageTitle }}</span>
                </a>
            </div>

        </div>
    </div>
</template>


<script>


    import EdtorMixin from './EditorMixin';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import EditorSlideMoreDropdown from './EditorSlideMoreDropdown.vue';

    export default {
        name: "EditorPageThumb",
        emits: ['select-page', 'current-page-moved'],
        mixins: [EdtorMixin],
        inject: ['edMnUnusedPagelistIds'],
        components: {EditorSlideMoreDropdown, ScIcon},

        props: {
            pageId: {type: Number, default: null},
            isPageSelected: {type: Boolean, default: false},
            isPageCurrent: {type: Boolean, default: false},
            displayPageReadOnly: {type: Boolean, default: false}
        },
        data () {
            return {
                //
            }
        },

        methods: {
            emitSelectPage () {
                this.$emit('select-page', this.page, false);
            },
            emitSelectPageAndGoto() {
                this.$emit('select-page', this.page, true);
            },

            movePage(moveNext) {
                let otherPage = Object.assign({}, moveNext ? this.nextPage : this.previousPage);
                let pg = Object.assign({}, this.page);

                let originalSortOrder = pg.sort_order;  // swap the sort orders and alpha_num_names
                let originalAlphaNumName = pg.alpha_num_name;
                pg.sort_order = otherPage.sort_order;
                pg.alpha_num_name = otherPage.alpha_num_name;
                otherPage.sort_order = originalSortOrder;
                otherPage.alpha_num_name = originalAlphaNumName;

                this.$store.dispatch('presUndoableSavePageBatch', [pg, otherPage]);
                setTimeout(() => {
                    this.$emit('current-page-moved');
                })
            },
        },

        computed: {
            page () {
                return this.$store.getters.presPage(this.pageId);
            },
            pageThumb () {
                if (!this.page) return null;
                if (!this.page.resource_pagethumb_id) return null;
                return this.$store.getters.presResource(this.page.resource_pagethumb_id);
            },
            pageTitle() {
                return (this.page.title && this.page.title !== 'None') ? this.page.title : '';
            },
            isPageUsed() {
                return !this.edMnUnusedPagelistIds.includes(this.page.pagelist_id);
            },
            thumbRatio () {
                return .83
            },
            pageThumbContainerStyle() {
                return {
                    width: (this.edMxStateShowcase.primary_layout.thumb_width * this.thumbRatio) + 20 + 'px',
                    minWidth: '200px' // for portrait thumbs
                }
            },
            pageThumbStyle() {
                return {
                    width: (this.edMxStateShowcase.primary_layout.thumb_width * this.thumbRatio) + 'px',
                    height: (this.edMxStateShowcase.primary_layout.thumb_height * this.thumbRatio) + 'px'
                }
            },
            pageCaptionStyle() {
                return {
                    width: (this.edMxStateShowcase.primary_layout.thumb_width * this.thumbRatio) + 'px',
                    minWidth: '196px' // for portrait thumbs
                }
            },

            pageIncomingPages() {
                //console.log('pageIncomingPages');
                let pages = [];
                if (this.page) {
                    pages = this.$store.getters.presPagesThatTargetPageId(this.page.id);
                }
                return pages;
            },
            previousPage() {
                let prev = null;
                if (this.page) {
                    let pages = this.$store.getters.presPagesForPagelist(this.page.pagelist_id);
                    let currentIdx = pages.indexOf(this.page);
                    if (currentIdx > 0) prev = pages[currentIdx-1];
                }
                return prev;
            },
            nextPage() {
                let next = null;
                if (this.page) {
                    let pages = this.$store.getters.presPagesForPagelist(this.page.pagelist_id);
                    let currentIdx = pages.indexOf(this.page);
                    if (currentIdx+1 < pages.length) {
                        next = pages[currentIdx+1];
                    }
                }
                return next;
            },

        }
    }

</script>


<style>

    .page-thumb {
        background-color: black;
        user-select: none;
        user-drag: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
    }

</style>