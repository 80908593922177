<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
            @vb-hide-bs-modal="canModalHide" @vb-hidden-bs-modal="isModalShown=false"
            class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title w-100 text-center">Copy</h4>
                </div>
                <div class="modal-body clearfix">

                    <p>Create a copy of the published version of this presentation?</p>

                    <p class="text-danger"><strong>Note, custom settings in Manage Access will not be copied.</strong></p>

                    <div v-if="resCheckLoading" class="alert alert-block">
                        <ScIcon name="spinnerSmall" class="text-muted"/>
                         &nbsp; Checking that resources are able to be copied
                    </div>

                    <div class="alert alert-danger"
                         v-if="!resCheckLoading && !resourcesPublishable">
                        Some resources are not able to be copied, please re-publish and try again in a few minutes.
                    </div>

                </div>
                <div class="modal-footer">
                    <button class="btn btn-copy btn-primary-orange fw-bold"
                        @click.prevent="copyNow()"
                        :disabled="copyLoading || resCheckLoading || !resourcesPublishable">
                        <span v-if="!copyLoading">Copy</span>
                        <ScIcon v-else name="spinnerFW" class="mx-2"/>
                    </button>
                </div>

            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';

    let _ins = null;

    export default {
        name: 'PresentationCopy',
        components: {ScIcon},
        data: function() {
            return {
                isModalShown: false,
                presId: null,
                workspaceId: null,
                copyLoading: false,
                resCheckLoading: false,
                resourcesPublishable: false
            }
        },
        mounted: function() {
            _ins = this;
        },
        beforeUnmount() {
            _ins = null;
        },
        methods: {
            canModalHide(e) {
                if (this.copyLoading) e.preventDefault();
            },
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            copyNow: function () {
                this.copyLoading = true;
                $.ajax({method: 'POST', url: '/main/presentations/ajax_copy_v2',
                    data: {presentation_id: this.presId, workspace_id: this.workspaceId}
                }).done((data) => {
                    this.$store.commit('plistPresAdded', data);
                    ScNotification.growlSuccessMsg('Copy process started.  This may take up to 10 minutes.');
                    this.copyLoading = false;
                    this.hideModal();

                    setTimeout(() => {
                        document.dispatchEvent(new CustomEvent('sc_event:workshop_home_show_pres', {detail: data}));
                    }, 250);

                }).fail((jqXhr) => {
                    this.copyLoading = false;
                    ScNotification.growlXhrError(jqXhr, 'copying');
                });
            },
            checkResourcesPublishable: function() {
                this.resCheckLoading = true;
                this.resourcesPublishable = true;
                $.ajax({
                    method: 'POST', url: '/main/presentations/ajax_check_resources_publishable_v2',
                    data: {presentation_id: this.presId, workspace_id: this.workspaceId}
                }).then((data) => {
                    this.resCheckLoading = false;
                    this.resourcesPublishable = data.resources_publishable;

                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'checking resources');
                    this.resCheckLoading = false;
                });
            },
            toggleBsModal(presentationId, workspaceId) {
                this.copyLoading = false;
                this.presId = presentationId;
                this.workspaceId = workspaceId;
                this.checkResourcesPublishable();
                this.isModalShown = true;
            }
        },

        //
        // public functions
        //
        modal: function (presentationId, workspaceId) {
            _ins.toggleBsModal(presentationId, workspaceId);
        }

    };


</script>