<template>
  <div v-if="edMxSelectedHotspots.length > 0 && edMxSelectedHotspotsBranding.length > 0" class="d-inline-block" >

    <div class="d-inline-block">
      <div class="dropdown">
        <button type="button" class="btn px-md-3 sc-highlight-expanded" data-bs-toggle="dropdown"
                :disabled="edMxLoneSelectedHotspotLocked" role="button" aria-expanded="false"
                v-sc-tooltip="'Hotspot Position'">
          <ScIcon name="arrowsUpDownLeftRightFW" />
        </button>
        <div class="dropdown-menu dropdown-menu-right pb-3" @click.prevent.stop style="width: 245px;">

          <div v-if="edMxSelectedHotspots.length > 1">
            <span class="dropdown-header border-0 mb-0">Position {{edMxSelectedHotspots.length}} Hotspots</span>

            <div class="btn-group align-items-center w-100 px-3">
              <button class="btn btn-outline-secondary" title="Top"
                      @click.prevent.stop="hotspotAlignTop"><ScIcon name="objectsAlignTopFW"/></button>
              <button class="btn btn-outline-secondary" title="Center Vertical"
                      @click.prevent.stop="hotspotAlignCenterVertical"><ScIcon name="objectsAlignCenterVerticalFW"/></button>
              <button class="btn btn-outline-secondary" title="Bottom"
                      @click.prevent.stop="hotspotAlignBottom"><ScIcon name="objectsAlignBottomFW"/></button>
            </div>

            <div class="btn-group align-items-center w-100 px-3 my-2">
              <button class="btn btn-outline-secondary" title="Left"
                      @click.prevent.stop="hotspotAlignLeft"><ScIcon name="objectsAlignLeftFW"/></button>
              <button class="btn btn-outline-secondary" title="Center Horizontal"
                      @click.prevent.stop="hotspotAlignCenterHorizontal"><ScIcon name="objectsAlignCenterHorizontalFW"/></button>
              <button class="btn btn-outline-secondary" title="Right"
                      @click.prevent.stop="hotspotAlignRight"><ScIcon name="objectsAlignRightFW"/></button>
            </div>

            <hr class="my-2 mx-4" />
            <span class="dropdown-header border-0 mb-0"
                  :class="[this.edMxSelectedHotspots.length < 3 ? 'text-muted': '']">Space Hotspots Evenly</span>
            <div class="btn-group align-items-center w-100 px-3 mb-2">
              <button class="btn btn-outline-secondary" title="Vertically" :disabled="this.edMxSelectedHotspots.length < 3"
                      @click.prevent.stop="hotspotSpaceOutVertically"><ScIcon name="distributeSpacingVerticalFW"/></button>

              <button class="btn btn-outline-secondary" title="Horizontally" :disabled="this.edMxSelectedHotspots.length < 3"
                      @click.prevent.stop="hotspotSpaceOutHorizontally"><ScIcon name="distributeSpacingHorizontalFW"/></button>
            </div>

          </div>
          <div v-else>
            <span class="dropdown-header border-0">Position Hotspots</span>
            <small class="dropdown-item text-muted pt-0"
                   style="white-space: break-spaces;">Select multiple hotspots to use the positioning tool.</small>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>


<script>

import EditorMixin from './../EditorMixin';
import ScIcon from '../../../shared/common/ScIcon.vue';
import HotspotUtils from '../../../store/HotspotUtils';

export default {
  name: "EditorToolbarHotspotAlign",
  mixins: [EditorMixin],
  components: {ScIcon},
  methods: {
    hotspotAlignTop () {
      let minTopPos = 100000;
      this.edMxSelectedHotspots.forEach((hotspot) => {
         let oldBounds = HotspotUtils.boundsStrToObj(hotspot.bounds);
         if (minTopPos > oldBounds.hsTop) minTopPos = oldBounds.hsTop;
       });
      this.updateHotspots((oldBounds) => {
        return {hsLeft: oldBounds.hsLeft, hsTop: minTopPos, hsWidth: oldBounds.hsWidth, hsHeight: oldBounds.hsHeight};
      });
    },
    hotspotAlignCenterVertical () {
      let minTopPos = 100000;
      let maxTopPos = 0;
      this.edMxSelectedHotspots.forEach((hotspot) => {
        let oldBounds = HotspotUtils.boundsStrToObj(hotspot.bounds);
        let oldMaxTopPos = oldBounds.hsTop + oldBounds.hsHeight;
        if (maxTopPos < oldMaxTopPos) maxTopPos = oldMaxTopPos;
        if (minTopPos > oldBounds.hsTop) minTopPos = oldBounds.hsTop;
      });
      let middlePos = minTopPos + ((maxTopPos - minTopPos) / 2);

      this.updateHotspots((oldBounds) => {
        let topPos = middlePos - (oldBounds.hsHeight/2);
        return {hsLeft: oldBounds.hsLeft, hsTop: topPos, hsWidth: oldBounds.hsWidth, hsHeight: oldBounds.hsHeight};
      });

    },
    hotspotAlignBottom () {
      let maxTopPos = 0;
      this.edMxSelectedHotspots.forEach((hotspot) => {
        let oldBounds = HotspotUtils.boundsStrToObj(hotspot.bounds);
        let oldMaxTopPos = oldBounds.hsTop + oldBounds.hsHeight;
        if (maxTopPos < oldMaxTopPos) maxTopPos = oldMaxTopPos;
      });
      this.updateHotspots((oldBounds) => {
        let topPos = maxTopPos - oldBounds.hsHeight;
        return {hsLeft: oldBounds.hsLeft, hsTop: topPos, hsWidth: oldBounds.hsWidth, hsHeight: oldBounds.hsHeight};
      });
    },
    hotspotAlignLeft () {
      let minLeftPos = 100000;
      this.edMxSelectedHotspots.forEach((hotspot) => {
        let oldBounds = HotspotUtils.boundsStrToObj(hotspot.bounds);
        if (minLeftPos > oldBounds.hsLeft) minLeftPos = oldBounds.hsLeft;
      });
      this.updateHotspots((oldBounds) => {
        return {hsLeft: minLeftPos, hsTop: oldBounds.hsTop, hsWidth: oldBounds.hsWidth, hsHeight: oldBounds.hsHeight};
      });
    },
    hotspotAlignCenterHorizontal () {
      let minLeftPos = 100000;
      let maxLeftPos = 0;
      this.edMxSelectedHotspots.forEach((hotspot) => {
        let oldBounds = HotspotUtils.boundsStrToObj(hotspot.bounds);
        if (minLeftPos > oldBounds.hsLeft) minLeftPos = oldBounds.hsLeft;
        let oldRightPos = oldBounds.hsLeft + oldBounds.hsWidth;
        if (maxLeftPos < oldRightPos) maxLeftPos = oldRightPos;
      });
      let centrePos = minLeftPos + ((maxLeftPos - minLeftPos) / 2);

      this.updateHotspots((oldBounds) => {
        let leftPos = centrePos - (oldBounds.hsWidth/2);
        return {hsLeft: leftPos, hsTop: oldBounds.hsTop, hsWidth: oldBounds.hsWidth, hsHeight: oldBounds.hsHeight};
      });
    },
    hotspotAlignRight () {
      let maxLeftPos = 0;
      this.edMxSelectedHotspots.forEach((hotspot) => {
        let oldBounds = HotspotUtils.boundsStrToObj(hotspot.bounds);
        let oldMaxLeftPos = oldBounds.hsLeft + oldBounds.hsWidth;
        if (maxLeftPos < oldMaxLeftPos) maxLeftPos = oldMaxLeftPos;
      });
      this.updateHotspots((oldBounds) => {
        let leftPos = maxLeftPos - oldBounds.hsWidth;
        return {hsLeft: leftPos, hsTop: oldBounds.hsTop, hsWidth: oldBounds.hsWidth, hsHeight: oldBounds.hsHeight};
      });
    },

    updateHotspots(boundsUpdateFn) {
      let changedHs = [];
      this.edMxSelectedHotspots.forEach((hotspot) => {
        let oldBounds = HotspotUtils.boundsStrToObj(hotspot.bounds);
        let newBounds = HotspotUtils.boundsObjToStr(boundsUpdateFn(oldBounds));
        if (hotspot.bounds !== newBounds) {
          //console.log('bounds change for', hotspot, hotspot.bounds, newBounds);
          let hs = Object.assign({}, hotspot);
          hs.bounds = newBounds;
          changedHs.push(hs);
        }
      });
      if (changedHs.length > 0) this.$store.dispatch('presUndoableSaveHotspotBatch', changedHs);
    },

    hotspotSpaceOutVertically () {
      let sortedByTopAsc = [...this.edMxSelectedHotspots];
      sortedByTopAsc.sort((a,b) => {
        let boundsA = HotspotUtils.boundsStrToObj(a.bounds);
        let boundsB = HotspotUtils.boundsStrToObj(b.bounds);
        return boundsA.hsTop - boundsB.hsTop;
      });
      let minTopBounds = HotspotUtils.boundsStrToObj(sortedByTopAsc[0].bounds);

      let sortedByBottomDesc = [...this.edMxSelectedHotspots];
      sortedByBottomDesc.sort((a,b) => {
        let boundsA = HotspotUtils.boundsStrToObj(a.bounds);
        let boundsB = HotspotUtils.boundsStrToObj(b.bounds);
        return (boundsA.hsTop + boundsA.hsHeight) - (boundsB.hsTop + boundsB.hsHeight);
      }).reverse();
      let maxBottomBounds = HotspotUtils.boundsStrToObj(sortedByBottomDesc[0].bounds);

      if (sortedByTopAsc[0].id === sortedByBottomDesc[0].id) return;  // ignore if same hotspot

      let totalHeights = 0;
      this.edMxSelectedHotspots.forEach((hs) => {
        let bounds = HotspotUtils.boundsStrToObj(hs.bounds);
        totalHeights += bounds.hsHeight;
      });

      let totalSpaceAvail = (maxBottomBounds.hsTop + maxBottomBounds.hsHeight) - minTopBounds.hsTop;
      let totalGap = totalSpaceAvail - totalHeights;
      let spaceBetweenEachHs = Math.round(totalGap / (this.edMxSelectedHotspots.length - 1));

      let changedHs = [];
      let nextHsTop = minTopBounds.hsTop;
      sortedByTopAsc.forEach((hotspot) => {
        let oldBounds = HotspotUtils.boundsStrToObj(hotspot.bounds);
        let newBounds = HotspotUtils.boundsObjToStr({hsLeft: oldBounds.hsLeft, hsTop: nextHsTop,
          hsWidth: oldBounds.hsWidth, hsHeight: oldBounds.hsHeight});
        if (hotspot.bounds !== newBounds) {
          let hs = Object.assign({}, hotspot);
          hs.bounds = newBounds;
          changedHs.push(hs);
        }
        nextHsTop = nextHsTop + oldBounds.hsHeight + spaceBetweenEachHs;
      });
      if (changedHs.length > 0) this.$store.dispatch('presUndoableSaveHotspotBatch', changedHs);
    },

    hotspotSpaceOutHorizontally () {
      let sortedByLeftAsc = [...this.edMxSelectedHotspots];
      sortedByLeftAsc.sort((a,b) => {
        let boundsA = HotspotUtils.boundsStrToObj(a.bounds);
        let boundsB = HotspotUtils.boundsStrToObj(b.bounds);
        return boundsA.hsLeft - boundsB.hsLeft;
      });
      let minLeftBounds = HotspotUtils.boundsStrToObj(sortedByLeftAsc[0].bounds);

      let sortedByRightDesc = [...this.edMxSelectedHotspots];
      sortedByRightDesc.sort((a,b) => {
        let boundsA = HotspotUtils.boundsStrToObj(a.bounds);
        let boundsB = HotspotUtils.boundsStrToObj(b.bounds);
        return (boundsA.hsLeft + boundsA.hsWidth) - (boundsB.hsLeft + boundsB.hsWidth);
      }).reverse();
      let maxRightBounds = HotspotUtils.boundsStrToObj(sortedByRightDesc[0].bounds);

      if (sortedByLeftAsc[0].id === sortedByRightDesc[0].id) return;  // ignore if same hotspot

      let totalWidths = 0;
      this.edMxSelectedHotspots.forEach((hs) => {
        let bounds = HotspotUtils.boundsStrToObj(hs.bounds);
        totalWidths += bounds.hsWidth;
      });

      let totalSpaceAvail = (maxRightBounds.hsLeft + maxRightBounds.hsWidth) - minLeftBounds.hsLeft;
      let totalGap = totalSpaceAvail - totalWidths;
      let spaceBetweenEachHs = Math.round(totalGap / (this.edMxSelectedHotspots.length - 1));

      let changedHs = [];
      let nextHsLeft = minLeftBounds.hsLeft;
      sortedByLeftAsc.forEach((hotspot) => {
        let oldBounds = HotspotUtils.boundsStrToObj(hotspot.bounds);
        let newBounds = HotspotUtils.boundsObjToStr({hsLeft: nextHsLeft, hsTop: oldBounds.hsTop,
          hsWidth: oldBounds.hsWidth, hsHeight: oldBounds.hsHeight});
        if (hotspot.bounds !== newBounds) {
          let hs = Object.assign({}, hotspot);
          hs.bounds = newBounds;
          changedHs.push(hs);
        }
        nextHsLeft = nextHsLeft + oldBounds.hsWidth + spaceBetweenEachHs;
      });
      if (changedHs.length > 0) this.$store.dispatch('presUndoableSaveHotspotBatch', changedHs);
    }

  },

}


</script>
