<template>
    <div class="top-canvas-bar rounded d-inline-block ps-2">
        <ScIcon v-if="!isPageUsed" name="lowVision" class="me-2 text-muted" style="opacity: 0.5;"
                                v-sc-tooltip="'No hotspots link to this slide'"/>
        <span>
            <strong class="me-1">{{page.alpha_num_name}}</strong>
            <span v-if="pageTitle" class="text-truncate" :style="titleWidthStyleObj">&nbsp;{{ pageTitle }}</span>
        </span>
        <EditorSlideMoreDropdown :page="page" :showTitle="false"></EditorSlideMoreDropdown>
    </div>
</template>

<script>

    import EditorSlideMoreDropdown from './EditorSlideMoreDropdown.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
        name: "EditorTopCanvasToolbar",
        components: {EditorSlideMoreDropdown, ScIcon},
        inject: ['edMnUnusedPageIds'],
        props: {
            page: {type: Object, default: null}
        },
        computed: {
            pageTitle() {
                return (this.page.title && this.page.title !== 'None') ? this.page.title : '';
            },
            isPageUsed() {
                return !this.edMnUnusedPageIds.includes(this.page.id);
            },
            titleWidthStyleObj() {
                return {
                    'max-width': (this.$store.state.pres.showcase.presentation.width * this.$store.state.ed.scaleRatio) - 70 + 'px',
                    'display': 'table-cell'
                };
            }

        }
    }
</script>

<style scoped>

  .top-canvas-bar {
    position: absolute;
    z-index: 110;  /* see README.md webapp z-index values */
    background-color: #f5f5f5;
  }

</style>