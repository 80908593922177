<template>
    <div class="editor-page-listing">
        <div v-if="searchResults.length === 0" class="text-center">
            <div style="margin-top: 10vh; height: 25vh;" class="text-muted p-2">
                <em v-if="pageFilter === 'all'">No slides </em>
                <em v-if="pageFilter === 'current'">No slides in current slideshow </em>
                <em v-if="pageFilter === 'unused'">No unused slides </em>
                <em v-if="searchForText"> matching "{{searchForText}}" </em>
            </div>
        </div>
        <div :class="[showPagesFullWidth ? '':'d-flex flex-wrap']" ref="allResultsEl"
             style="margin-bottom: 200px;"><!-- fix to fully show last item more dropdown -->
            <div v-for="(sr, idx) in searchResults" :key="sr.page.id">
                <div :title="sr.page.alpha_num_name + ' ' + pageTitle(sr.page) + (sr.page.id === currentPageId ? ' (Current Slide)' : '')"
                     v-if="showUpToIdx > idx" :ref="'pageThumbWrap'+sr.page.id">

                    <ScSimpleDraggable :isDraggable="true"
                                       :listIdx="idx"
                                       :showDragOverLineOnSide="calcedNumResultDivsPerRow > 1"
                                       :draggableContentType="'text/page-in-pagelist-'+sr.page.pagelist_id"
                                       @has-dropped="hasDropped($event)">
                        <div :class="['p-2', showPagesFullWidth && sr.firstInPagelist ? 'sr-first-in-pagelist':'', showPagesFullWidth ? 'w-100':'']">
                            <EditorPageThumb :pageId="sr.page.id"
                                 :isPageSelected="sr.page.id === selectedPageId"
                                 :isPageCurrent="sr.page.id === currentPageId"
                                 :displayPageReadOnly="displayPagesReadOnly"
                                 @current-page-moved="scrollToCurrentPage"
                                 @select-page="pageSelected"></EditorPageThumb>
                            </div>
                    </ScSimpleDraggable>
                </div>
            </div>
        </div>
        <div v-if="showUpToIdx+1 < searchResultsLength" class="text-muted text-center p-2">
            <button @click.prevent="showUpToIdx+=50" type="button" class="btn btn-link">Show more</button>
        </div>
    </div>
</template>

<script>

import _ from 'underscore'; // debounce
import EditorMixin from './EditorMixin';
import PresentationUtils from "../../store/PresentationUtils";
import EditorPageThumb from "./EditorPageThumb.vue";
import ScSimpleDraggable from "../../shared/common/ScSimpleDraggable.vue";


export default {
    name: "EditorPageListing",
    emits: ['page-selected', 'search-results-length'],
    mixins: [EditorMixin],
    inject: ['edMnUnusedPagelistIds'],
    components: {EditorPageThumb, ScSimpleDraggable},
    props: {
        searchText: {type: String, default: null},
        pageFilter: {type: String, default: 'all'},  // all, current, unused
        selectedPageId: {type: Number, default: null},
        showPagesFullWidth: {type: Boolean, default: false},
        displayPagesReadOnly: {type: Boolean, default: false},
    },
    data: function() {
        return {
            searchForText: '',
            showUpToIdx: 200,
            calcedNumResultDivsPerRow: 0,
        };
    },
    mounted: function() {
        this.scrollToCurrentPage();
        this.calcNumItemsPerRow();
        window.addEventListener('resize', this.calcNumItemsPerRow, false);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.calcNumItemsPerRow, false);
    },
    methods: {
        calcNumItemsPerRow() {
            setTimeout(() => {
                this.calcedNumResultDivsPerRow = 0;
                if (this.$refs.allResultsEl) {
                    // https://stackoverflow.com/questions/49043684/how-to-calculate-the-amount-of-flexbox-items-in-a-row
                    const grid = Array.from(this.$refs.allResultsEl.children);
                    if (grid.length > 0 && grid.findIndex) {
                        const baseOffset = grid[0].offsetTop;
                        const breakIndex = grid.findIndex(item => item.offsetTop > baseOffset);
                        this.calcedNumResultDivsPerRow = (breakIndex === -1 ? grid.length : breakIndex);
                    }
                }
            }, 1);
        },
        pageSelected(page, gotoPage) {
            this.$emit('page-selected', page, gotoPage);
            document.activeElement.blur(); // to fix copy&paste issue (errors/#6150)
        },
        hasDropped(dropData) {
            console.log('page was dropped', dropData);
            let fromSr = this.searchResults[dropData.fromIdx];
            let toSr = this.searchResults[dropData.toIdx];
            console.log(fromSr, toSr);
            if (fromSr && toSr && fromSr.page && toSr.page && fromSr.page.pagelist_id === toSr.page.pagelist_id) {
                let searchResultsCopy = [...this.searchResults] // shallow copy
                searchResultsCopy.splice(dropData.fromIdx, 1);  // remove item being dragged
                searchResultsCopy.splice(dropData.toIdx, 0, fromSr);  // add it where it's supposed to go
                let resortedPageIds = []
                searchResultsCopy.forEach((sr) => {
                    if (sr.page.pagelist_id === fromSr.page.pagelist_id) resortedPageIds.push(sr.page.id)
                })
                this.$store.dispatch('presSortPagesResequenceSortOrderAlphaNumName', {
                    pagelistId: fromSr.page.pagelist_id, pageIdsArray: resortedPageIds});
            }
        },
        pageTitle(page) {
            return (page.title && page.title !== 'None') ? page.title : '';
        },
        scrollToCurrentPage() {
            setTimeout(() => {  // focus the page thumb when we nav to that page
                if (this.edMxCurrentPage && this.searchResults && this.searchResults.findIndex) {
                    // find out where current page is in search results
                    let currentPageSearchResultsIdx = this.searchResults.findIndex((sr) => {
                        return sr.page.id === this.edMxCurrentPage.id;
                    });
                    // increase showUpToIdx if too low
                    if (currentPageSearchResultsIdx > this.showUpToIdx)
                        this.showUpToIdx = currentPageSearchResultsIdx + 10;

                    setTimeout(() => {  // find the comp and scroll to it
                        let pageThumbWrap = this.$refs['pageThumbWrap' + this.edMxCurrentPage.id];
                        if (pageThumbWrap && pageThumbWrap.scrollIntoView) {
                            pageThumbWrap.scrollIntoView({behavior: 'smooth', block: 'center'});
                        }
                    });
                }
            }, 180);
        },
    },

    computed: {
        searchResults: function() {
            let results = [];
            let currentPagelistId = null;
            if (this.currentPageId) {
                let currentPage = this.$store.getters.presPage(this.currentPageId);
                currentPagelistId = currentPage.pagelist_id;
            }
            Object.values(this.pagelists).forEach((pl) => {
                if (this.pageFilter === 'current' && pl.id !== currentPagelistId) return;  // only show current pagelist when not showAll
                if (this.pageFilter === 'unused' && !this.edMnUnusedPagelistIds.includes(pl.id)) return;  // only show unused pages

                let pages = this.$store.getters.presPagesForPagelist(pl.id);

                pages.forEach((page) => {
                    if (!page) return;

                    let displaySlide = false;
                    let searchForText = this.searchForText ? this.searchForText.toLowerCase() : '';
                    if (searchForText === '') displaySlide = true;

                    // match on alphanumName, background name and page title
                    if (!displaySlide && searchForText.length > 0) {
                        let slideName = this.pageTitle(page);
                        if (page.alpha_num_name) slideName = page.alpha_num_name + ' ' + slideName;
                        displaySlide = slideName.toLowerCase().indexOf(searchForText) > -1;
                    }

                    // match on slide target file names
                    if (!displaySlide && searchForText.length > 0) {
                        let hotspotsForPage = this.$store.getters.presHotspotsForPageId(page.id);
                        hotspotsForPage.forEach((hs) => {
                            if (displaySlide) return;
                            let targetResourceId = hs.target_resource_id;
                            if (!targetResourceId) return;
                            let targetRes = this.$store.getters.presResource(targetResourceId);
                            let resName = targetRes && targetRes.name ? targetRes.name.toLowerCase() : '';
                            displaySlide = resName.indexOf(searchForText) > -1;
                        });
                    }
                    if (displaySlide) results.push({page: page, firstInPagelist: null});
                });
            });
            for (let i = 0; i < results.length; i++) {  // see if each page is first in pagelist
                results[i].firstInPagelist = results[i-1] && results[i-1].page.pagelist_id !== results[i].page.pagelist_id;
            }
            results.sort((sr1, sr2) => {
                return PresentationUtils.pageAlphaNumSortComparator(sr1.page, sr2.page);
            });

            this.$emit('search-results-length', results.length);
            return results;
        },
        pagelists() {
            return this.$store.state.pres.showcase.pagelist;
        },
        presentation() {
            return this.$store.state.pres.showcase.presentation;
        },
        currentPageId() {
            return this.edMxCurrentPage.id;
        },
        searchResultsLength() {  // also used by EditorFindPageModal
            return this.searchResults.length;
        },

    },

    watch: {
        searchText: _.debounce(function() {
            this.searchForText = this.searchText; // delay
        }, 2000),

        edMxCurrentPage: function() {
            this.scrollToCurrentPage();
        }
    }


}



</script>


<style scoped>

    .sr-first-in-pagelist {
        margin-top: 15px;
    }

</style>
