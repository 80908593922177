<template>
    <div class="hotspot">

      <div class="vdr" :style="vdrStyleObject">

        <EditorHotspotImage :hsHotspot="hsHotspot"></EditorHotspotImage>

        <div class="vdr-inset">
          <EditorEditableText :hsHotspot="hsHotspot" :height="scaledHeight" :width="scaledWidth"></EditorEditableText>
        </div>

      </div>

    </div>
</template>

<script>

    import EditorMixin from './EditorMixin';
    import HotspotUtils from '../../store/HotspotUtils';
    import EditorHotspotImage from "./EditorHotspotImage.vue";
    import EditorEditableText from "./EditorEditableText.vue";
    import EditorHotspotAdditionalStyleHelper from "./EditorHotspotAdditionalStyleHelper";

    /**
     * hotspot
     * vdr
     * editable text
     *
     * we want this order (top to bottom):
     * HS border
     * Rounded corners
     * text padding applied here
     * high light color
     * bg img
     * bg color
     *
     * note, Bootstrap 3+ defines "box-sizing: border-box;" for ALL elements
     *
     */

    export default {
        name: "EditorHotspot",
        mixins: [EditorMixin],
        components: {EditorHotspotImage, EditorEditableText},
        props: {
            hsHotspot: {type: Object, default: null},
        },
        created: function() {
            //console.log('EditorHotspot/created', this.hsHotspot);
        },
        mounted: function() {
          //console.log('EditorHotspot/mounted');
          this.reviewDimensions()
        },
        beforeUnmount: function () {
          //console.log('beforeUnmount')
        },
        methods: {

          reviewDimensions: function () {
            // todo: put in pres store???
            if (this.minw > this.scaledWidth) this.width = this.minw;
            if (this.minh > this.scaledHeight) this.height = this.minh;
          },
        },

        computed: {

            minw() { return this.scaleRatio*50 },
            minh() { return this.scaleRatio*50 },
            maxw() { return this.scaledPresWidth },
            maxh() { return this.scaledPresHeight },

            boundsObj() {
              return HotspotUtils.boundsStrToObj(this.hsHotspot.bounds);
            },
            scaledPosX() {
                //console.log('EditorHotspot/computed/scaledPosX', this.scaleRatio, this.boundsObj.hsLeft);
                return this.scaleRatio * this.boundsObj.hsLeft;
            },
            scaledPosY() {
                //console.log('EditorHotspot/computed/scaledPosY', this.scaleRatio, this.boundsObj.hsTop);
                return this.scaleRatio * this.boundsObj.hsTop;
            },
            scaledWidth() {
                //console.log('EditorHotspot/computed/scaledWidth', this.scaleRatio, this.boundsObj.hsWidth, this.scaleRatio * this.boundsObj.hsWidth);
                return this.scaleRatio * this.boundsObj.hsWidth;
            },
            scaledHeight() {
                return this.scaleRatio * this.boundsObj.hsHeight;
            },
            scaledPresWidth() {
                return this.scaleRatio * this.edMxStateShowcase.presentation.width
            },
            scaledPresHeight() {
                return this.scaleRatio * this.edMxStateShowcase.presentation.height;
            },
            scaleRatio() {
                return this.edMxStateEd.scaleRatio;
            },

          vdrStyleObject: function () {
            let cssObj = {};
            let additionalBorderWidth = EditorHotspotAdditionalStyleHelper.getBorderWidthValue(
                this.currentHotspotBrandingAdditionalStyles, this.fontScaleRatio * this.scaleRatio);

            let calculatedWidth = this.scaledWidth + (additionalBorderWidth *2); // *2 = add left and right border width
            let calculatedHeight = this.scaledHeight + (additionalBorderWidth *2); // *2 = add top and bottom border width
            let calculatedLeft = this.scaledPosX - additionalBorderWidth; // - left border width
            let calculatedTop = this.scaledPosY - additionalBorderWidth; // - top border width

            //console.log('vdrStyleObject', this.width, calculatedWidth, additionalBorderWidth)
            cssObj.width = calculatedWidth + 'px';  // 2=left+right dotted border for drag handles
            cssObj.height = calculatedHeight + 'px';  // 2=top+bottom dotted border for drag handles
            cssObj.left = calculatedLeft + 'px';
            cssObj.top = calculatedTop + 'px';
            return cssObj;
          },
          currentHotspotBrandingAdditionalStyles() {
            return this.$store.getters.presHotspotBrandingAdditionalStylesObjByHsId(this.hsHotspot.id);
          },
          fontScaleRatio() {
            return this.$store.getters.presFontScaleRatio;
          },

        },

      watch: {
        scaleRatio: function() {
          // when scale changes make sure we get scaled properties from EditorHotspot
          //console.log('EditorDraggableResizable/watch/drScaleRatio', this.grid);
          let vm = this;
          // make sure we run in "next tick" to let scale change the dom
          setTimeout(function() {
            vm.reviewDimensions();
          });
        }
      }

    }

</script>

<style>


.vdr {
  position: absolute;
  touch-action: none;
}

.vdr {
  cursor: pointer;
}


.vdr .vdr-inset {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>