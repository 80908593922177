<template>
    <div class="hotspot-image-wrap" :style="hotspotImageWrapStyleObject">
        <img v-if="hotspotImageRes" class="hotspot-image" alt="Hotspot Image"
             :src="hotspotImageRes.url" :style="hotspotImageStyleObject" />
    </div>
</template>

<script>

    import _ from 'underscore'; // debounce
    import EditorMixin from './EditorMixin';
    import HotspotUtils from '../../store/HotspotUtils';
    import EditorHotspotStyleHelper from './EditorHotspotStyleHelper';
    import EditorHotspotAdditionalStyleHelper from './EditorHotspotAdditionalStyleHelper';


    export default {
        name: "EditorHotspotImage",
        mixins: [EditorMixin],
        props: ['hsHotspot'],
        data () {
            return {
                zoomSliderVal: 100,
                cropXSliderVal: 0,
                cropYSliderVal: 0,
                receivedChangesFromToolbar: false,
                sliderValsSentToToolbar: false,
                immediateSaveTimer: null,
            }
        },

        mounted: function() {
            //console.log('EditorHotspotImage/mounted', this.hotspotImageRes, this.hsHotspot);
            this.initializeSliderVals();
        },

        beforeUnmount: function () {
            this.saveZoomCropImmediate();
        },

        methods: {
            initializeSliderVals: function() {
                //console.log(this.hsHotspot.id, 'EditorHotspotImage/initializeSliderVals');
                this.receivedChangesFromToolbar = false;
                this.zoomSliderVal = this.initialZoomSliderVal;
                this.cropXSliderVal = this.initialCropXSliderVal;
                this.cropYSliderVal = this.initialCropYSliderVal;
            },
            sendValsToToolbar: function() {
                if (!this.hsIsLoneHsSelected) {
                    this.sliderValsSentToToolbar = false;
                    return;
                }
                //console.log(this.hsHotspot.id, 'we are current update the slider toolbar', this);
                this.$store.commit('edSetHotspotImageToolbarSliders', {
                    zoomSliderVal: this.zoomSliderVal,
                    cropXSliderVal: this.cropXSliderVal, cropYSliderVal: this.cropYSliderVal });
                setTimeout(() => {  // make sure the watchers below can't see update to these values until done
                    this.sliderValsSentToToolbar = true;
                }, 1);
            },
            saveZoomCropImmediate: function() {
                // only process update if we actually got something from the toolbar (and hs is ok)
                if (!this.receivedChangesFromToolbar || this.hsHotspot.deleted) return;
                this.receivedChangesFromToolbar = false;

                let hs = Object.assign({}, this.hsHotspot);
                let currentVals = [
                    hs.zoom_width ? hs.zoom_width : 0, hs.zoom_height ? hs.zoom_height : 0,
                    hs.crop_x ? hs.crop_x : 0, hs.crop_y ? hs.crop_y : 0];
                let newVals = [Math.round(this.localZoomX), Math.round(this.localZoomY),
                    Math.round(-this.localCropX), Math.round(-this.localCropY)];

                if (currentVals.join(',') !== newVals.join(',')) {
                    // console.log(this.hsHotspot.id, 'saveZoomCropImmediate different', currentVals.join(','),
                    //     newVals.join(','), this.localZoomY, Math.round(this.localZoomY));
                    hs.zoom_width = newVals[0];
                    hs.zoom_height = newVals[1];
                    hs.crop_x = newVals[2];
                    hs.crop_y = newVals[3];
                    if (isNaN(hs.zoom_width)) throw new Error('hs.zoom_width is NaN invalid');
                    this.$store.dispatch('presUndoableSaveHotspotBatch', [hs]);
                }
            },

            saveZoomCropDelayed: _.debounce(function() {
                // every time we call saveZoomCropImmediate it might add an undo item, to delay a bit
                this.saveZoomCropImmediate();
            }, 5000),

        },

        computed: {
            fontScaleRatio() {
                return this.$store.getters.presFontScaleRatio;
            },

            hotspotImageStyleObject: function() {
                if (!this.hotspotImageRes) return {};
                //console.log('EditorHotspotImage/computed/hotspotImageStyleObject', this.localCropX, this.edState.scaleRatio);
                let scaledZoomX = this.localZoomX * this.edState.scaleRatio;
                let scaledZoomY = this.localZoomY * this.edState.scaleRatio;
                let scaledCropX = this.localCropX * this.edState.scaleRatio;
                let scaledCropY = this.localCropY * this.edState.scaleRatio;
                return {
                    width: scaledZoomX + 'px', height: scaledZoomY + 'px',
                    top: scaledCropY + 'px', left: scaledCropX +'px'
                };
            },


            currentHotspotBrandingAdditionalStyles() {
                return this.$store.getters.presHotspotBrandingAdditionalStylesObjByHsId(this.hsHotspot.id);
            },

            initialZoomSliderVal: function() {
                if (!this.hotspotImageRes) return 100;
                let zoomRatio = 1;
                let zoomWidth = this.hsHotspot.zoom_width;
                if (zoomWidth && this.hotspotImageRes.width) {
                    zoomRatio = zoomWidth / this.hotspotImageRes.width;
                }
                return 100 * zoomRatio;
            },

            zoomLevelDecimal: function() {
                //console.log('zoomLevelDecimal', this.zoomSliderVal, this.zoomSliderVal/100);
                return (this.zoomSliderVal / 100);
            },

            localZoomX: function() {
                //console.log('localZoomX', this.zoomLevelDecimal, this.hotspotImageRes ? this.hotspotImageRes.width : 'emp');
                let zx = this.hotspotImageRes ? this.zoomLevelDecimal * this.hotspotImageRes.width : 0;
                return zx > 0 ? zx : 1;
            },

            localZoomY: function() {
                let zy = this.hotspotImageRes ? this.zoomLevelDecimal * this.hotspotImageRes.height : 0;
                return zy > 0 ? zy : 1;
            },

            initialCropXSliderVal: function() {
                let cropRatio = 0;
                if (this.hsHotspot.crop_x === null) return 0;
                let hsCropX = -1 * this.hsHotspot.crop_x;
                let boundsObj = HotspotUtils.boundsStrToObj(this.hsHotspot.bounds);
                //console.log('initialCropXSliderVal', this.hsHotspot.crop_x, boundsObj);
                if (boundsObj) {
                    if (hsCropX > 0) cropRatio = hsCropX/boundsObj.hsWidth;
                    else cropRatio = hsCropX/this.localZoomX;
                }
                return 100 * cropRatio;
            },

            localCropX: function() {
                let cropX = 0;
                let boundsObj = HotspotUtils.boundsStrToObj(this.hsHotspot.bounds);  // (this.hotspotImageRes.width * this.zoomLevelDecimal);
                if (boundsObj) {
                    if (this.cropXSliderVal > 0) cropX = boundsObj.hsWidth * (this.cropXSliderVal/100);
                    else cropX = this.localZoomX * (this.cropXSliderVal/100);
                }
                return cropX;
            },

            initialCropYSliderVal: function() {
                let cropRatio = 0;
                if (this.hsHotspot.crop_y === null) return 0;
                let hsCropY = -1 * this.hsHotspot.crop_y;
                let boundsObj = HotspotUtils.boundsStrToObj(this.hsHotspot.bounds);
                if (boundsObj) {
                    if (hsCropY > 0) cropRatio = hsCropY/boundsObj.hsHeight;
                    else cropRatio = hsCropY/this.localZoomY;
                }
                //console.log(this.hsHotspot.id, 'initialCropYSliderVal', hsCropY, boundsObj.hsHeight, this.localZoomY, 100 * cropRatio);
                return 100 * cropRatio;
            },

            localCropY: function() {
                let cropY = 0;
                let boundsObj = HotspotUtils.boundsStrToObj(this.hsHotspot.bounds);  // (this.hotspotImageRes.width * this.zoomLevelDecimal);
                if (boundsObj) {
                    if (this.cropYSliderVal > 0) cropY = boundsObj.hsHeight * (this.cropYSliderVal/100);
                    else cropY = this.localZoomY * (this.cropYSliderVal/100);
                }
                //console.log(this.hsHotspot.id, 'localCropY', cropY, boundsObj.hsHeight, this.localZoomY, this.cropYSliderVal);
                return cropY;
            },

            hotspotImageRes: function() {
                //console.log('EditorHotspotImage/computed/hotspotImageRes');
                return this.$store.getters.presHotspotImageResourceByHsId(this.hsHotspot.id);
            },

            edState: function() {
                return this.$store.state.ed;
            },
            hotspotImageToolbarState: function() {
                return this.$store.state.ed.hotspotImageToolbar;
            },
            hsIsLoneHsSelected: function() {
                //console.log('hsIsLoneHsSelected', this.edMxLoneSelectedHotspot);
                return this.edMxLoneSelectedHotspot && this.edMxLoneSelectedHotspot.id === this.hsHotspot.id;
            },
            hotspotImageWrapStyleObject () {
                let cssObj = {};
                EditorHotspotStyleHelper.addHotspotBgColor(cssObj, this.$store.getters.presHotspotBrandingByHsId(this.hsHotspot.id));
                EditorHotspotAdditionalStyleHelper.addAdditionalHotspotStyles(cssObj,
                    this.currentHotspotBrandingAdditionalStyles, this.fontScaleRatio * this.edState.scaleRatio);
                EditorHotspotAdditionalStyleHelper.addAdditionalHotspotBgImgStyles(cssObj,
                    this.currentHotspotBrandingAdditionalStyles);
                return cssObj;
            }

        },
        watch: {
            'hsIsLoneHsSelected': function() {
                if (this.hsIsLoneHsSelected) this.sendValsToToolbar();
            },
            'hotspotImageToolbarState.zoomSliderVal': function(val) {  // val is number from 1-100 = 200=max zoom (2000%), 1=min zoom (1%)
                if (!this.hsIsLoneHsSelected || !this.sliderValsSentToToolbar) return;
                //console.log('EditorHotspotImage/watch/zoomSlider', val);
                this.zoomSliderVal = val;
                this.receivedChangesFromToolbar = true;
                this.saveZoomCropDelayed();
            },
            'hotspotImageToolbarState.cropXSliderVal': function(val) {  // val is number from 1-100 = 100=all the way to the right, 1 all way to left
                if (!this.hsIsLoneHsSelected || !this.sliderValsSentToToolbar) return;
                //console.log('EditorHotspotImage/watch/cropXSlider', val);
                this.cropXSliderVal = val;
                this.receivedChangesFromToolbar = true;
                this.saveZoomCropDelayed();
            },
            'hotspotImageToolbarState.cropYSliderVal': function(val) {  // val is number from 1-100 = 100=all the way to the top, 1 all way to bottom
                if (!this.hsIsLoneHsSelected || !this.sliderValsSentToToolbar) return;
                this.cropYSliderVal = val;
                this.receivedChangesFromToolbar = true;
                this.saveZoomCropDelayed();
            },
            'hsHotspot.crop_x': function() {
                this.initializeSliderVals();
            },
            'hsHotspot.crop_y': function() {
                this.initializeSliderVals();
            },
            'hsHotspot.zoom_x': function() {
                this.initializeSliderVals();
            },
            'hsHotspot.zoom_y': function() {
                this.initializeSliderVals();
            },
            'hsHotspot.original_resource_id': function() {
                this.initializeSliderVals();
                this.sendValsToToolbar();
            },
        }

    }

</script>

<style>

    .hotspot-image {
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: none;
    }

    .hotspot-image-wrap {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

</style>