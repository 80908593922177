<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
         @vb-hide-bs-modal="canModalHide" @vb-hidden-bs-modal="modalWasHidden"
         class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered sc-modal-touch-scroll">
            <div class="modal-content">
                <div class="modal-header">
                    <button v-if="!importStarted || copyError || resourceCheckError" class="btn-close" data-bs-dismiss="modal"></button>
                    <h4 class="modal-title w-100 text-center">Copy slide from another presentation</h4>
                </div>

                <div class="modal-body ">
                    <div v-if="!importStarted">
                        <label>Presentation</label>

                        <div v-if="presentationsLoading"><ScIcon name="spinnerFW" class="text-muted"/></div>

                        <div class="text-danger" v-if="!presentationsLoading && presentations.length === 0">
                            <em>No presentations with the same slide size (in pixels) are available</em>
                        </div>
                        <div v-if="!presentationsLoading && presentations.length > 0" class="clearfix">
                            <small class="text-muted my-2 d-block">
                                <ScIcon name="questionCircle"/> Only presentations with the same slide size (in pixels) are shown.
                            </small>
                            <ScSelectWithThumbnailAndSearch id="scCopyPage1"
                                placeholder="Select a presentation..."
                                :items="sortedPresentations"
                                :disabled="presentationsLoading"
                                @sc-select-item="presChanged($event)"
                                @deselect-item="presDeselected">
                            </ScSelectWithThumbnailAndSearch>
                        </div>

                        <br><label>Slide</label>
                        <div v-if="presentationsLoading || pagesLoading"><ScIcon name="spinnerFW" class="text-muted"/></div>
                        <div v-if="!presentationsLoading && !pagesLoading && sortedPages.length === 0" class="text-muted"><em>No slides available</em></div>
                        <div v-if="!presentationsLoading && !pagesLoading && sortedPages.length > 0" class="d-block clearfix">
                            <ScSelectWithThumbnailAndSearch id="scCopyPage2"
                                    placeholder="Select a slide..."
                                    :items="sortedPages"
                                    :disabled="pagesLoading || !linkSrcPresId"
                                    @sc-select-item="pageChanged($event)">
                            </ScSelectWithThumbnailAndSearch>
                        </div>

                        <br><strong>Include</strong>
                        <div class="form-check mt-2">
                            <input class="form-check-input" type="checkbox" id="importSiblingPages"
                                   v-model="importSiblingPages" :disabled="!linkSrcPageId" >
                            <label class="form-check-label" for="importSiblingPages">Slides to left and right of chosen slide</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="importLinkedPages"
                                   v-model="importLinkedPages" :disabled="!linkSrcPageId" >
                            <label class="form-check-label" for="importLinkedPages">Linked slides</label>
                        </div>


                        <br>
                        <div class="text-center">
                            <strong>{{pageIdsToCopy.length}} slides</strong>
                            will be added to the current presentation.

                            <br><br>
                            <small class="text-muted">
                                <ScIcon name="questionCircle"/> The editor is reloaded after copying so it can load the copied slides and files.
                            </small>
                        </div>
                    </div>

                    <div v-if="copyLoading">
                        <ScIcon name="spinnerFW" class="text-muted"/> Copying...
                    </div>

                    <div v-if="resourceCheckLoading">
                        <ScIcon name="check" class="text-success"/> Copying done
                        <br/><ScIcon name="spinnerFW" class="text-muted"/> Checking files...
                    </div>

                    <div v-if="showCopyDone">
                        <ScIcon name="check" class="text-success"/> Copying done
                        <br/><ScIcon name="check" class="text-success"/> File check done
                        <br/><ScIcon name="spinnerFW" class="text-muted"/> Reloading editor...
                    </div>

                    <div v-if="resourceCheckError">
                        <br/><ScIcon name="exclamationTriangleFW" class="text-danger"/> Error checking files.
                    </div>

                    <div v-if="copyError">
                        <br/><ScIcon name="exclamationTriangleFW" class="text-danger"/> Error copying slides.
                        Copying slides can take a long time and it may still be running on the server.  Reload the
                        editor in 5 minutes.
                    </div>

                </div>
                <div v-if="!showCopyDone && !copyLoading && !resourceCheckLoading && !resourceCheckError && !copyError" class="confirm-buttons modal-footer">
                    <button class="btn btn-primary-orange fw-bold" :disabled="importCount < 1"
                        @click.prevent="beginImport()">Copy</button>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>

<script>

import _ from 'underscore'; // sortBy, findWhere
import $ from 'jquery';
import ScNotification from '../../shared/common/ScNotification.vue';
import ScIcon from '../../shared/common/ScIcon.vue';
import EditorMixin from './EditorMixin';
import ScSelectWithThumbnailAndSearch from '../../shared/common/ScSelectWithThumbnailAndSearch.vue';

let DataManager = {
    refreshPresentations: function(vm) {
        vm.presentations.splice(0);
        vm.presentationsLoading = true;
        $.ajax({
            method: 'GET', url: '/main/presentations/ajax_list',
            data: {workspace_id: vm.$store.state.user.currentWorkshopId}
        }).done((data) => {
            Object.values(data.presentations).forEach((pres) => {
                if (!pres) return;
                //console.log('match', pres, vm.currentLayoutCode, pres.owner_workshop_name);
                if (pres.id === vm.currentPresentationId) return;  // don't allow current to be selected
                if (pres.code !== vm.currentLayoutCode) return;  // don't allow current to be selected
                if (pres.owner_workshop_name !== null) return;  // don't allow partner presentation to be selected
                if (pres.thumbnail) (new Image()).src = pres.thumbnail;  // pre-cache
                vm.presentations.push({id: pres.id, name: pres.title, thumbnail: pres.thumbnail});
            });
            vm.presentationsLoading = false;
        }).fail((xhr) => {
            ScNotification.growlErrMsg('Error ' + xhr.status + ' loading presentations');
        });
    },
    refreshPages: function(vm) {
        vm.pages.splice(0);
        vm.pagesLoading = true;
        $.ajax({
            method: 'GET', url: '/main/editor/ajax_draft_showcase',
            data: {workspace_id: vm.$store.state.user.currentWorkshopId, presentation_id: vm.linkSrcPresId}
        }).done(function(data) {
            vm.lastPresData = data.export;
            let sortedPages = _.sortBy(Object.values(data.export.page), (page) => {
                let match = /([A-Z]+)([0-9]+)/.exec(page.alpha_num_name);
                if (match) return match[1] + '-' + (1000000 + Number(match[2]));
                return page.alpha_num_name;
            });
            sortedPages.forEach((page) => {
                let thumbRes = null;
                if (page.resource_pagethumb_id) {
                    thumbRes = _.findWhere(data.export.resource, {id: page.resource_pagethumb_id});
                    if (thumbRes) (new Image()).src = thumbRes.thumb_url;  // pre-cache
                }
                let pgTitle = (!page.title || page.title === 'None') ? '' : page.title;
                vm.pages.push({id: page.id, name: page.alpha_num_name + ' ' + pgTitle,
                    thumbnail: thumbRes ? thumbRes.thumb_url : null});
            });
        }).always(function() {
            vm.pagesLoading = false;
        }).fail(function (xhr) {
            ScNotification.growlErrMsg('Error ' + xhr.status + ' loading presentation');
        });
    },
    resourcesCheck: function(vm) {
        vm.resourceCheckLoading = true;
        vm.resourceCheckError = false;
        $.ajax({
            type: 'POST',
            url: "/main/presentations/ajax_check_resources_publishable_v2",
            data: {attempt: vm.resourcesChecks+1, workspace_id: vm.$store.state.user.currentWorkshopId,
                presentation_id: vm.currentPresentationId}
        }).then(function(data) {
            if ( data.resources_publishable ) {
                vm.resourceCheckLoading = false;
                vm.showCopyDone = true;
                vm.reloadEditorOnHidden = true;
                vm.hideModal();

            } else if (vm.resourcesChecks > 10) {
                ScNotification.growlErrMsg('File checking taking too long.  Any issues should be resolved on publish.  Reloading editor.');
                vm.reloadEditorOnHidden = true;
                vm.hideModal();

            }   else {
                vm.resourcesChecks = vm.resourcesChecks +1;
                setTimeout(() => {
                    DataManager.resourcesCheck(vm)
                },  vm.resourcesChecks * 5 * 1000);  // back off each time
            }
        }).fail(function (jqXhr) {
            ScNotification.growlXhrError(jqXhr, 'when checking files');
            vm.resourceCheckError = true;
            vm.resourceCheckLoading = false;
            vm.reloadEditorOnHidden = true;
            vm.hideModal();
        });
    },
    copyPages: function(vm) {
        vm.copyError = false;
        vm.copyLoading = true;
        vm.importStarted = true;
        vm.reloadEditorOnHidden = false;
        $.ajax({
            method: 'POST', url: '/main/editor/ajax_copy_pages',
            data: {
                workspace_id: vm.$store.state.user.currentWorkshopId,
                src_page_ids: vm.pageIdsToCopy.join(','),
                link_src_page_id: vm.linkSrcPageId,
                link_to_hotspot_id: vm.linkToHotspotId,
                dest_presentation_id: vm.currentPresentationId,
                copy_links_to_pages: vm.importLinkedPages
            }
        }).done(function() {
            DataManager.resourcesCheck(vm);

        }).always(function() {
            vm.copyLoading = false;

        }).fail(function (xhr) {
            ScNotification.growlErrMsg('Error ' + xhr.status + ' copying slides');
            vm.copyLoading = false;
            vm.copyError = true;
            vm.reloadEditorOnHidden = true;
            setTimeout(() => {
                vm.hideModal();
            }, 5000);
        });
    }
};

let _ins = null;

export default {
    name: 'EditorCopyPagelists',
    mixins: [EditorMixin],
    components: {ScSelectWithThumbnailAndSearch, ScIcon},
    data: function() {
        return {
            isModalShown: false,
            linkToHotspotId: null,
            currentPresentationId: null,
            workspaceId: null,
            currentLayoutCode: null,

            lastPresData: null,
            presentations: [],
            presentationsLoading: false,
            pages: [],
            pagesLoading: false,
            linkSrcPresId: null,
            linkSrcPageId: null,
            importSiblingPages: true,
            importLinkedPages: false,
            resourceCheckLoading: false,
            resourcesChecks: 0,
            importStarted: false,
            showCopyDone: false,
            copyLoading: false,
            copyError: false,
            resourceCheckError: false,
            reloadEditorOnHidden: false,
        }
    },
    mounted: function() {
        //console.log('mounted');
        _ins = this;
    },
    beforeUnmount() {
        _ins = null;
    },
    methods: {
        hideModal() {
            this.$refs.scModal.$vb.modal.hide();
        },
        canModalHide(e) {
            if (this.copyLoading || this.resourceCheckLoading) e.preventDefault();
        },
        modalWasHidden() {
            this.isModalShown = false;
            if (this.reloadEditorOnHidden) {
                this.edMxReloadEditorKeepPage();
            }
        },
        toggleBsModal(options) {
            this.linkToHotspotId = options.linkToHotspotId;
            this.currentPresentationId = options.currentPresentationId;
            this.currentLayoutCode = options.currentLayoutCode;
            this.workspaceId = options.workspaceId;
            DataManager.refreshPresentations(this);
            this.isModalShown = true;
        },
        presChanged: function(selectedPresId) {
            //console.log('preschanged', selectedPresId)
            this.linkSrcPresId = selectedPresId;
            if (!this.linkSrcPresId) {
                ScNotification.growlErrMsg('Please select a presentation');
                return;
            }
            DataManager.refreshPages(this);
        },
        presDeselected () {
            this.linkSrcPresId = null;
            this.pages.splice(0);
            this.linkSrcPageId = null;
        },
        pageChanged: function(selectedPageId) {
            this.linkSrcPageId = selectedPageId;
            if (!this.linkSrcPageId) {
                ScNotification.growlErrMsg('Please select a slide');
            }
        },
        beginImport: function() {
            if (!this.linkSrcPageId || this.pageIdsToCopy.length === 0) return;
            DataManager.copyPages(this);
        },
    },
    computed: {
        sortedPresentations: function() {
            return _.sortBy(this.presentations, 'text');
        },
        sortedPages: function() {
            return _.sortBy(this.pages, 'text');
        },
        importCount: function() {
            return this.pageIdsToCopy.length;
        },
        pageIdsToCopy: function() {
            let pageIdsToCopy = [];
            let pagelistIdsToCopy = [];

            if (this.linkSrcPageId && this.lastPresData) {

                let calculatePagelistRecurs = (pagelistId) => {
                    if (pagelistIdsToCopy.indexOf(pagelistId) > -1) return;  // we already did this one
                    pagelistIdsToCopy.push(pagelistId);
                    let siblings = _.where(this.lastPresData.page, {pagelist_id: pagelistId});
                    siblings.forEach((siblingPage) => {
                        calculatePageRecurs(siblingPage.id);
                    });
                };

                let calculatePageRecurs = (pageId) => {
                    if (pageIdsToCopy.indexOf(pageId) > -1) return;
                    pageIdsToCopy.push(pageId);
                    let hotspots = _.where(this.lastPresData.hotspot, {parent_page_id: pageId});
                    hotspots.forEach((hs) => {
                        if (hs.target_pagelist_id && this.importLinkedPages) {
                            calculatePagelistRecurs(hs.target_pagelist_id);
                        }
                    });
                };

                let selectedPage = _.findWhere(this.lastPresData.page, {id: this.linkSrcPageId});
                if (selectedPage) {
                    if (this.importSiblingPages) calculatePagelistRecurs(selectedPage.pagelist_id);
                    else calculatePageRecurs(selectedPage.id);
                }
            }
            return pageIdsToCopy;
        }
    },

    modal: function(options) {
        //console.log('EditorCopyPagelists.open', options);
        _ins.toggleBsModal(options);
    }
};





</script>
