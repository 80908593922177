<template>
    <div class="container-fluid">

        <div class="row">
            <div class="col-1 px-0 pt-2">
                <ScToggleOnOff :active="isEnabled"
                               :loading="showLoading"
                               @sc-toggle="toggleEnableTransitions()"></ScToggleOnOff>
            </div>
            <div class="col-11">
                <h4>Enable transitions between slides</h4>
                <small class="d-block">
                    When enabled, the default 'grow' animation from a hotspot to a slide will play. Disable to remove
                    this transition and have slides instantly appear instead.
                  <br/><br/>Requires a republish to take effect.

                </small>
            </div>
        </div>

        <hr class="my-4"/>

    </div>
</template>

<script>
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import ScToggleOnOff from '../../../shared/common/ScToggleOnOff.vue';
    import MainAppMixin from '../../MainAppMixin';
    import $ from 'jquery';


    let DataManager = {
        loadData (vm) {
            vm.showLoading = true;
            vm.isEnabled = true;
            $.ajax({
                type: 'GET', url: "/main/presentations/ajax_load_transitions_enabled",
                data: { workspace_id: vm.workspaceId, presentation_id: vm.presentationId }
            }).done(function(data){
                if (data.transitions_enabled === null)
                    vm.isEnabled = true;
                else
                    vm.isEnabled = !!data.transitions_enabled;
            }).fail(function(jqXhr) {
                ScNotification.growlXhrError(jqXhr, "loading data");
            }).always(function() {
                vm.showLoading = false;
            });
        },
        updateData (vm) {
            vm.showLoading = true;
            $.ajax({
                type: 'POST', url: "/main/presentations/ajax_save_transitions_enabled",
                data: {
                    workspace_id: vm.workspaceId,
                    presentation_id: vm.presentationId,
                    transitions_enabled: vm.isEnabled
                }
            }).done(function(data) {
                if (data.status === 'error') {
                    ScNotification.growlErrMsg('Change NOT saved. Please reload this page and try again. ' +
                        'If problem continues please contact support.');
                } else {
                    ScNotification.growlSuccessMsg('Saved.');
                    vm.markPresHasChanges();
                }
            }).fail(function() {
                ScNotification.growlErrMsg('Change NOT saved. Please reload this page and try again. ' +
                    'If problem continues please contact support.');
            }).always(function() {
                vm.showLoading = false;
            });
        }
    };

    export default {
        name: "ManageCustomTransitions",
        components: { ScToggleOnOff },
        mixins: [ MainAppMixin ],
        props: {
            workspaceId: {type: Number, default: null},
        },
        data () {
            return {
                isEnabled: true,
                showLoading: false
            };
        },
        mounted () {
            DataManager.loadData(this);
        },
        methods: {
            toggleEnableTransitions () {
                this.isEnabled = !this.isEnabled;
                DataManager.updateData(this);
            },
            markPresHasChanges() {
                if (this.$store.state.pres && this.$store.state.pres.showcase.presentation.id === this.presentationId) {
                    this.$store.dispatch('presDraftCompileStarted');
                }
                if (this.$store.state.plist) {
                    this.$store.commit('plistPresHasChanges', this.presentationId);
                }
            },
        },
        computed: {
            presentationId () {
                return (this.$store.state.pres) ? this.$store.state.pres.showcase.presentation.id: null;
            }
        }
    }
</script>
