<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
            @vb-hide-bs-modal="canModalHide" @vb-hidden-bs-modal="modalWasHidden"
            class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title w-100 text-center ms-2">Manage Sharing</h4>
                </div>
                <div v-if="presentationId" class="modal-body" style="min-height: 150px;">
                    <div v-if="fetchPending" class="col-md-12 text-center pt-5">
                        <ScIcon name="spinnerFW" class="text-muted"/>
                    </div>
                    <div v-if="!fetchPending" class="col-md-12">

                    <div v-if="currentResourceId" class="my-2">
                        <EditorResourceThumb :resourceId="currentResource.id">
                            <div class="col-12 my-auto ms-auto text-start px-0">
                            <div class="form-check  mb-1 mt-3">
                                <input class="form-check-input" type="checkbox" id="slide-bg-shared-page"
                                       :checked="currentResourceShareableOnPage"
                                       @click.prevent.stop="toggleCurrentResourceShareableOnPage(!currentResourceShareableOnPage)">
                                <label class="form-check-label" for="slide-bg-shared-page"
                                       @click.prevent.stop="toggleCurrentResourceShareableOnPage(!currentResourceShareableOnPage)"> Shareable on this slide</label>
                            </div>

                            <div class="form-check mb-3">
                                <input class="form-check-input" type="checkbox" id="slide-bg-shared"
                                       :checked="currentResourceShareable"
                                       @click.prevent.stop="toggleCurrentResourceShareable(!currentResourceShareable)">
                                <label class="form-check-label" for="slide-bg-shared"
                                       @click.prevent.stop="toggleCurrentResourceShareable(!currentResourceShareable)"> Shareable everywhere</label>
                            </div>
                            </div>
                        </EditorResourceThumb>
                    </div>

                    <div v-if="!currentPageId">
                        <p><small><ScIcon name="questionCircle" class="text-muted"/> Use 'Manage Sharing' via the
                        editor to configure sharing for individual slides.</small></p>
                    </div>
                    <div v-if="currentPageId">
                        <div class="d-flex justify-content-between">
                            <strong>Shareable on this slide ({{ slideAlphaNumName }})</strong>
                            <button v-if="shareableItemsCurrentSlide.length > 1" @click="deleteAllShareableItemsForCurrentSlide"
                                    v-sc-tooltip="'Remove all files from the \'Shareable on this slide\' list'"
                                    :disabled="isDeleting" class="btn btn-link text-muted p-0">
                                <ScIcon v-if="isDeleting" name="spinnerFW" class="text-muted"/>
                                <ScIcon name="trashAltFW"/>
                            </button>
                        </div>


                        <div class="list-group my-2">
                            <PresentationShareableItem v-for="shareableItem in shareableItemsCurrentSlide"
                                :res="shareableItem" :page_id="currentPageId" :resource_id="shareableItem.id"
                                :key="'sc'+shareableItem.id"></PresentationShareableItem>
                        </div>

                        <button class="btn btn-outline-secondary me-2 my-2"
                            v-sc-tooltip="'Add file to share from the current slide'" @click="addShareableItemCurrentSlide">Add File</button>

                        <p><small><ScIcon name="questionCircle" class="text-muted"/>
                            Add files that your users can share when they are on this specific slide.</small></p>
                    </div>



                    <br/><strong>Allow full presentation to be shared</strong>
                    <br/>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="full-share" id="full-share"
                               :checked="fullShowcaseSharingEnabled" @click="toggleFullShowcaseSharingEnabled($event.target.checked)"
                               :disabled="shareSettingPending">
                        <label class="form-check-label" for="full-share">Enabled</label>
                    </div>

                    <br/><br/>
                    <div class="d-flex justify-content-between">
                        <strong>Shareable on all slides</strong>
                        <button v-if="shareableItemsAllSlides.length > 1" @click="deleteAllShareableItemsForAllSlides"
                                v-sc-tooltip="'Remove all files from the \'Shareable on all slides\' list'"
                                :disabled="isDeleting" class="btn btn-link text-muted p-0">
                            <ScIcon v-if="isDeleting" name="spinnerFW" class="text-muted"/>
                            <ScIcon v-else name="trashAltFW"/>
                        </button>
                        </div>
                    <div class="no-results text-center" style="display: none;">
                        <div style="margin-top: 10vh; height: 25vh;" class="text-muted"><em>No results</em></div>
                    </div>
                    <div class="my-2">
                        <transition-group name="" tag="ul" class="list-group">
                        <PresentationShareableItem v-for="shareableItem in shareableItemsAllSlides"
                           :res="shareableItem" :page_id="null" :resource_id="shareableItem.id"
                           :key="'sa'+shareableItem.id"
                           @remove-shared-file="markPresHasChanges()"></PresentationShareableItem>
                        </transition-group>
                    </div>
                    <div>
                        <button class="btn btn-outline-secondary me-2 mt-2"
                            v-sc-tooltip="'Add file to share from all slides'" @click="addShareableItemAllSlides">Add File</button>
                    </div>

                    <br/>
                    <p><small><ScIcon name="questionCircle" class="text-muted"/>
                        Add files that your users can share from anywhere in your presentation.
                        Shared files and presentation access
                        <span v-if="currentWorkshopObj.shared_expiry_days"> expires {{currentWorkshopObj.shared_expiry_days}} days </span>
                        <span v-else> does not expire </span>
                        after being sent (can be changed in
                        <a v-if="mainMxUwCanPerform('a:list_workspace_settings')"
                           href="#" @click.prevent="showWorkspaceSettings">workshop settings</a>
                        <span v-else>workshop settings</span>)
                    </small></p>
                    </div>
                </div>
            </div>
        </div>
        <LibraryMainModal ref="libraryMainModal"></LibraryMainModal>
    </div>
    </teleport>
</template>

<script>

import _ from 'underscore'; // sortBy
import ScNotification from '../../shared/common/ScNotification.vue';
import ScIcon from '../../shared/common/ScIcon.vue';
import LibraryMainModal from '../library/LibraryMainModal.vue';
import PresentationShareableItem from './PresentationShareableItem.vue';
import store from '../../store';
import PresentationStore from '../../store/PresentationStore';
import MainAppMixin from '../MainAppMixin';
import EditorResourceThumb from '../editor/EditorResourceThumb.vue';
import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';


if (!store.state.pres) store.registerModule('pres', PresentationStore);

let _ins = null;

export default {
    name: 'PresentationShareable',
    mixins: [MainAppMixin],
    components: {PresentationShareableItem, EditorResourceThumb, ScIcon, LibraryMainModal},
    data() {
        return {
            isModalShown: false,
            showWorkspaceSettingsWhenHidden: false,
            fetchPending: false,
            shareSettingPending: false,

            // passed from editor or home page
            presentationId: null,
            slideAlphaNumName: '',
            currentPageId: null,
            currentResourceId: null,

            isDeleting: false
        }
    },
    mounted() {
        _ins = this;
    },
    beforeUnmount() {
        _ins = null;
    },
    methods: {
        hideModal() {
            this.$refs.scModal.$vb.modal.hide();
        },
        modalWasHidden() {
            if (this.showWorkspaceSettingsWhenHidden) {
                this.$router.push({ name: 'settings-workshop', params: { workshopId: this.mainMxCurrentWorkshopId }});
                this.showWorkspaceSettingsWhenHidden = false;
            }
            this.isModalShown = false;
        },
        canModalHide(e) {
            if (this.shareSettingPending || this.isDeleting) e.preventDefault();
        },
        toggleBsModal(presentationId, currentPageId, currentSlideAlphaNumName, currentResourceId) {
            this.presentationId = presentationId;
            this.currentPageId = currentPageId;
            this.slideAlphaNumName = currentSlideAlphaNumName;
            this.currentResourceId = currentResourceId;
            this.isModalShown = true;

            if (!this.currentPageId) {  // launched standalone, load the presentation
                this.fetchPending = true;
                this.$store.dispatch('presFetch', {
                    workspace_id: this.mainMxCurrentWorkshopId,
                    presentation_id: this.presentationId
                }).then(() => {
                    this.fetchPending = false;
                });
            }
        },
        showWorkspaceSettings() {
            this.showWorkspaceSettingsWhenHidden = true;
            this.hideModal();
        },
        toggleCurrentResourceShareableOnPage: function(on) {
            this.$store.dispatch('presToggleShareableResource', {
                resource_id: this.currentResource.id, page_id: this.currentPageId, enabled: on});
            this.markPresHasChanges();
        },
        toggleCurrentResourceShareable: function(on) {
            this.$store.dispatch('presToggleShareableResource', {
                resource_id: this.currentResource.id, page_id: null, enabled: on});
            this.markPresHasChanges();
        },

        chooseResAndAddShareableItem(page_id) {
            this.$refs.libraryMainModal.toggleBsModal({
                selectionMode: 'multiple', dialogType: 'modal',
                filterType: 'all'
            }, (resourcesAdded) => {
                resourcesAdded.forEach((r) => {
                    let alreadyInList = this.$store.getters.presIsResourceShareable({resource_id: r.id, page_id: page_id});
                    if (alreadyInList) {
                        ScNotification.growlErrMsg(r.name + ' is already in the list.');
                        return;
                    }
                    this.$store.dispatch('presResourceAddedFromLibrary', r);
                    this.$store.dispatch('presToggleShareableResource', {
                        resource_id: r.id, page_id: page_id, enabled: true
                    });
                    this.markPresHasChanges();
                });
            });
        },

        markPresHasChanges() {
            if (this.$store.state.plist) this.$store.commit('plistPresHasChanges', this.presentationId);
        },
        addShareableItemAllSlides() {
            this.chooseResAndAddShareableItem(null);
        },
        addShareableItemCurrentSlide() {
            this.chooseResAndAddShareableItem(this.currentPageId);
        },
        toggleFullShowcaseSharingEnabled(enabled) {
            this.shareSettingPending = true;
            this.$store.dispatch('presShareSettings', {
                fullShowcaseSharingEnabled: enabled
            }).then(() => {
                this.shareSettingPending = false;
                this.markPresHasChanges();
            });
        },
        deleteAllShareableItemsForCurrentSlide() {
            ScConfirmModal2.modal('This will remove all files from the "Shareable on this slide" list, for this slide only. Are you sure?').then(() => {
                ScConfirmModal2.modal('Are you really sure?').then(() => {
                    this.isDeleting = true;
                    for (let slide of this.shareableItemsCurrentSlide) {
                        this.$store.dispatch('presToggleShareableResource', {
                            resource_id: slide.id, page_id: this.currentPageId, enabled: false
                        });
                    }
                    this.isDeleting = false;
                }, () => {});
            }, () => {});

        },
        deleteAllShareableItemsForAllSlides() {
            ScConfirmModal2.modal('This will remove all files from the "Shareable from all slides" list. Are you sure?').then(() => {
                ScConfirmModal2.modal('Are you really sure?').then(() => {
                    this.isDeleting = true;
                    for (let slide of this.shareableItemsAllSlides) {
                        this.$store.dispatch('presToggleShareableResource', {
                            resource_id: slide.id, page_id: null, enabled: false
                        });
                    }
                    this.markPresHasChanges();
                    this.isDeleting = false;
                }, () => {});
            }, () => {});
        },
    },
    computed: {
        currentWorkshopObj() {
            return this.$store.getters.userCurrentWorkspaceObj;
        },
        currentResource() {
            if (!this.currentResourceId) return null;
            if (!this.$store.getters.presResource) return null;  // editor not loaded
            return this.$store.getters.presResource(this.currentResourceId);  // editor loaded
        },
        currentResourceShareable: function() {
            return this.$store.getters.presIsResourceShareable({
                page_id: null, resource_id: this.currentResource.id})
        },
        currentResourceShareableOnPage: function() {
            return this.$store.getters.presIsResourceShareable({
                page_id: this.currentPageId, resource_id: this.currentResource.id})
        },
        fullShowcaseSharingEnabled() {
            let enabled = false;
            Object.values(this.$store.state.pres.showcase.shareable_resource).forEach((sr) => {
                let res = this.$store.getters.presResource(sr.resource_id);
                if (res && res.content_type === 'showcase_share') {
                    enabled = true;
                }
            });
            return enabled;
        },
        shareableItemsAllSlides() {
            let shareableItemsAllSlides = [];
            Object.values(this.$store.state.pres.showcase.shareable_resource).forEach((sr) => {
                let res = this.$store.getters.presResource(sr.resource_id);
                if (res && res.content_type !== 'showcase_share' && res.content_type !== 'weburl') {
                    shareableItemsAllSlides.push(res);
                }
            });
            return _.sortBy(shareableItemsAllSlides, (r) => {
                return r.name.toLocaleLowerCase()
            });
        },
        shareableItemsCurrentSlide() {
            let shareableItemsCurrentSlide = [];
            Object.values(this.$store.state.pres.showcase.shareable_page_resource).forEach((srp) => {
                if (srp.page_id === this.currentPageId) {
                    shareableItemsCurrentSlide.push(this.$store.getters.presResource(srp.resource_id));
                }
            });
            return _.sortBy(shareableItemsCurrentSlide, (r) => {
                return r.name.toLocaleLowerCase()
            });
        }
    },

    modal: function (presentationId, currentPageId, currentSlideAlphaNumName, currentResourceId) {
        //console.log('ScManageShareable.open', presentationId, currentPageId, currentSlideAlphaNumName, $root);
        _ins.toggleBsModal(presentationId, currentPageId, currentSlideAlphaNumName, currentResourceId);
    }
};

</script>
