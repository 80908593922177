

import constants from '../../constants'
import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue'
import PresentationUtils from "../../store/PresentationUtils";

export default {
    methods: {
        // should always start with edMx
        edMxNavToPage: function (pageId) {
            let page = null;
            if (pageId) {
                page = this.$store.getters.presPage(pageId);
            }
            if (!page) {
                page = this.$store.getters.presRootPage;
            }
            if (page) {
                this.$store.commit('edSetPage', page);
            }
        },
        edMxResourceIconCode(res) {
            return constants.RES_CONTENT_TYPE_TO_ICON[res.content_type];
        },
        edMxReloadEditorKeepPage: function () {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    let currentPageId = this.$store.state.ed.currentPageId;
                    this.$store.dispatch('presReload').then(() => {
                        setTimeout(() => {
                            this.edMxNavToPage(currentPageId);
                            resolve();
                        }, 500);
                    }, reject);
                }, 2000);
            });
        },
        edMxDeleteSelectedHotspots() {
            let n = this.edMxSelectedHotspots.length;
            ScConfirmModal2.modal(`Are you sure you want to delete ${n} hotspot${n===1?'':'s'}?`).then(() => {
                let hsBatch = [];
                this.edMxSelectedHotspots.forEach((hotspot) => {
                    let hs = Object.assign({}, hotspot);
                    hs.deleted = true;
                    hsBatch.push(hs);
                });
                this.$store.commit('edSelectedHotspotIdsReset');
                this.$store.dispatch('presUndoableSaveHotspotBatch', hsBatch);
                hsBatch.forEach((hs) => {
                    if (hs.target_resource_id) {
                        this.edMxRemoveFromSharingForCurrentPage(hs.target_resource_id);
                        this.edMxRemoveFromSharingForShowcase(hs.target_resource_id);
                    }
                });
            }, () => {});
        },
        edMxDuplicateHostposts(hsIds) {
            return new Promise((resolve) => {
                if (hsIds.length > 0) {
                    //console.log('edMxDuplicateHostposts', JSON.stringify(hsIds));
                    this.$store.dispatch('presNewHotspots', {
                        template_hotspot_ids: hsIds, parent_page_id: this.edMxStateEd.currentPageId
                    }).then((newHotspots) => {
                        let newHsIds = newHotspots.map((hs) => hs.id);
                        this.$store.commit('edSelectedHotspotIdsReset');
                        this.$store.commit('edSelectedHotspotIdsAdd', newHsIds);
                        resolve();
                    });
                } else {
                    resolve();
                }

            });
        },
        edMxRemoveFromSharingForCurrentPage(resourceId) {
            let isShareable = this.$store.getters.presIsResourceShareable({
                page_id: this.$store.state.ed.currentPageId, resource_id: resourceId
            });
            if (isShareable) {
                this.$store.dispatch('presToggleShareableResource', {
                    resource_id: resourceId, page_id: this.$store.state.ed.currentPageId,
                    enabled: false
                });
            }
        },
        edMxRemoveFromSharingForShowcase (resourceId, deleteSlide) {
            if (!resourceId)
                return;

            let isShareableOnAll = false;
            let allInstances = null;
            isShareableOnAll = this.$store.getters.presIsResourceShareable({
                page_id: null, resource_id: resourceId
            });

            if (isShareableOnAll) {
                allInstances = this.$store.getters.presIsLastInstanceOfShareableResource({
                    resource_id: resourceId
                });
            }

            if (!allInstances)
                return;

            let count = 0;
            for (let i of allInstances) {
                if (i.page_id !== this.$store.state.ed.currentPageId)
                    count++;
            }

            let confirmMsg = this.$store.getters.presResource(resourceId).name +
                ' was also set to be shareable from all slides. Did you want ' +
                'to remove it from sharing as well?';

            if (deleteSlide) {
                confirmMsg = 'This slide had ' + deleteSlide + ' file(s) linked and set to be shareable from all slides. ' +
                    'They aren\'t linked anywhere else. Did you want to remove those files from sharing as well?';
            }

            if (count  === 0) {
                ScConfirmModal2.modal(confirmMsg).then(() => {
                    this.$store.dispatch('presToggleShareableResource', {
                        resource_id: resourceId, page_id: null, enabled: false
                    });
                }, () => {});
            }
        },

        edMxSelectHotspot(hotspot, focusText) {
            this.$store.commit('edSelectedHotspotIdsAdd', [hotspot.id]);
            if (focusText) {
                setTimeout(() => {  // set hotspot into text mode
                    this.$store.commit('edSetTextEditHotspotId', hotspot.id);
                    setTimeout(() => {  // select all text in hotspot
                        document.dispatchEvent(new CustomEvent('sc_event:hotspot_created_select_text', {detail: {}}));
                    }, 100);  // bit of a hac but it's a 'nice to have' anyway.  Hard to co-ordinate across components!
                });
            }
        },

        edMxNewHotspot(templateHotspotId, targetResourceId, targetPageId, focusText) {
            this.$store.dispatch('presNewHotspots', {
                parent_page_id: this.edMxCurrentPage.id,
                template_hotspot_ids: [templateHotspotId],
                target_resource_id: targetResourceId,
                target_page_id: targetPageId,
                html_content: focusText ? 'Add some text' : null
            }).then((newHotspots) => {
                //console.log('edMxNewHotspot callback');
                this.edMxSelectHotspot(newHotspots[0], focusText);
            });
        },

        edMxUpdateSelectedHotspotsBrandingAdditionalStyles (key, value) {
          let hsbBatch = [];
          this.edMxSelectedHotspotsBranding.forEach((hsb) => {
            let hsbas = Object.assign({}, hsb.additional_style_obj);
            hsbas[key] = value;
            hsb.additional_style_obj = hsbas;
            hsbBatch.push(hsb);
          });
          this.$store.dispatch('presUndoableSaveHotspotBrandingBatch', hsbBatch);
        },

        edMxUpdateSelectedHotspotsBranding (key, value) {
          let hsbBatch = [];
          this.edMxSelectedHotspotsBranding.forEach((hs) => {
            let hsb = Object.assign({}, hs);
            hsb[key] = value;
            hsbBatch.push(hsb);
          });
          this.$store.dispatch('presUndoableSaveHotspotBrandingBatch', hsbBatch);
        },

        edMxSelectedMultipleHotspotsBrandingDisplayValueForAttribute(attr) {
          let itemValue = null;
          for (let hs of this.edMxStateEd.selectedHotspotIds) {
            let hsb = this.$store.getters.presHotspotBrandingByHsId(hs);
            if (!itemValue) // only happening for first element
              itemValue = hsb[attr];
            else {
              // check if eg font_size is same, return after it differs for the first time
              if (itemValue !== hsb[attr])
                return null;
            }
          }
          return itemValue;
        },

        edMxSelectedMultipleHotspotsBrandingAdditionalStylesDisplayValueForAttribute(attr, default_value=null) {
          let itemValue = null;
          for (let hs of this.edMxStateEd.selectedHotspotIds) {
            let hsb = this.$store.getters.presHotspotBrandingAdditionalStylesObjByHsId(hs);
            if (!itemValue) { // only happening for first element
              itemValue = (!hsb[attr]) ? 'null' : hsb[attr];
            } else {
              // check if eg border_width is same, return after it differs for the first time
              let compare =  (!hsb[attr]) ? 'null' : hsb[attr];
              if (itemValue !== compare)
                return null;
            }
          }
          if (this.edMxStateEd.selectedHotspotIds.length === 1)
            return (itemValue === 'null') ? ((default_value) ? default_value : 'null') : itemValue;

          if (itemValue === 'null' && default_value) return default_value;
          return (itemValue) ? itemValue : default_value;
        },


    },
    computed: {
        // should always start with edMx

        edMxStateEd: function() {
            return this.$store.state.ed;
        },
        edMxStateShowcase: function() {
            return this.$store.state.pres.showcase;
        },
        edMxSelectedHotspots() {
            let shs = [];
            this.edMxStateEd.selectedHotspotIds.forEach((hsId) => {
                shs.push(PresentationUtils.scDataHotspot(this.edMxStateShowcase, hsId));
            });
            return shs;
        },
        edMxLoneSelectedHotspot: function() {  // one and only selected hotspot or null
            return this.edMxSelectedHotspots.length === 1 ? this.edMxSelectedHotspots[0] : null;
        },
        edMxSelectedHotspotsBranding() {
            let hbs = [];
            this.edMxStateEd.selectedHotspotIds.forEach((hsId) => {
                hbs.push(this.$store.getters.presHotspotBrandingByHsId(hsId));
            });
            return hbs;
        },

        edMxLoneSelectedHotspotLocked() {  // note, a locked hotspot cannot be multi-selected
            //console.log('edMxLoneSelectedHotspotLocked', this.edMxSelectedHotspots);
            return this.edMxSelectedHotspots.length > 0 ? this.edMxSelectedHotspots[0].is_locked : false;
        },

        edMxCurrentPage: function() {
            let page = null;
            if (this.edMxStateEd.currentPageId) {
                page = this.$store.getters.presPage(this.edMxStateEd.currentPageId);
            }
            return page;
        },
        edMxRootPage: function() {
            return this.$store.getters.presRootPage;
        }
    }
}