<template>
    <div class="slide-more-dropdown btn-group dropdown">
        <button class="btn btn-link py-1" data-bs-toggle="dropdown" type="button" v-sc-tooltip="'More'"
                aria-expanded="false"><ScIcon name="ellipsisHFW"/></button>
        <div class="dropdown-menu" aria-labelledby="dropdown">
            <div v-if="showTitle" class="dropdown-header text-break" style="max-width: 230px;white-space: break-spaces;">
                <ScIcon v-if="!isPageUsed" name="lowVision" class="me-2 text-muted" style="opacity: 0.5;"
                                v-sc-tooltip="'No hotspots link to this slide'"/>
                <span class="fw-bold" v-if="page">{{page.alpha_num_name}}</span> <span class="fw-normal">{{plPageTitle(page)}}</span>
            </div>

            <div class="dropdown-item d-block" v-if="currentPageIncomingPages && currentPageIncomingPages.length > 0">
                <ScIcon name="replyFW" v-sc-tooltip="'Linked from'" class="float-start me-2"/>
                <span v-for="(linkPage, lpIdx) in currentPageIncomingPages" :key="'lp'+lpIdx" class="float-start">
                    <a href="#" @click.prevent="edMxNavToPage(linkPage.id)" class="float-start">{{linkPage.alpha_num_name}}</a>
                    <span v-if="(lpIdx+1) < currentPageIncomingPages.length" class="float-start me-1">,</span>
                </span>
            </div>

            <button class="btn btn-link dropdown-item" @click.prevent.stop="deleteCurrentPage()"
                    :disabled="page.id === edMxRootPage.id">
                <ScIcon name="trashAltFW" class="pe-2"/>Delete</button>
            <button class="btn btn-link dropdown-item" @click.prevent.stop="renameCurrentPage(page)"
                ><ScIcon name="pencilFW" class="pe-2"/>Rename</button>

            <template v-if="newPageEnabled">
                <div v-if="numberSiblingSlides < maxSlidesPerSlideshow">
                    <button class="btn btn-link dropdown-item" @click.prevent="openNewSlideModal"><ScIcon name="plusFW"/> Add slide</button>
                </div>
                <div v-else>
                    <small class="text-muted dropdown-item">Max slides reached ({{maxSlidesPerSlideshow}})</small>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

    import EdtorMixin from './EditorMixin';
    import MainAppMixin from '../MainAppMixin';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScRenameModal2 from '../../shared/common/ScRenameModal2.vue';
    import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';
    import EditorNewSlideModal from './EditorNewSlideModal.vue';

    export default {
        name: "EditorSlideMoreDropdown",
        mixins: [MainAppMixin, EdtorMixin],
        components: {ScIcon},
        inject: ['edMnUnusedPageIds'],
        props: {
            maxSlidesPerSlideshow: {type: Number, default: 200},
            page: {type: Object, default: null},
            newPageEnabled: {type: Boolean, default: true},
            showTitle: {type: Boolean, default: true}
        },

        methods: {
            plPageTitle(page) {
                if (!page) return null;
                return (page.title && page.title !== 'None') ? page.title : '';
            },

            deleteCurrentPage() {
                let delMsg = 'Are you sure you want to delete slide ' + this.page.alpha_num_name + '?';
                let linksNum = this.currentPageIncomingPages.length;
                if (linksNum === 1) {
                    delMsg = delMsg + ' 1 slide links here.';
                } else if (linksNum > 1) {
                    delMsg = delMsg + ' ' + linksNum + ' slides link here.';
                }
                ScConfirmModal2.modal(delMsg).then(() => {

                    let pageIdToDelete = this.page.id;

                    // check if files in hotspots on deleted slide are included in sharing
                    let hotspotsForPage = this.$store.getters.presHotspotsForPageId(pageIdToDelete);
                    for (let i of hotspotsForPage) {
                        this.edMxRemoveFromSharingForCurrentPage(i.target_resource_id);
                        this.edMxRemoveFromSharingForShowcase(i.target_resource_id, hotspotsForPage.length);
                    }

                    let pageToSelect = this.nextPage;
                    if (!pageToSelect) pageToSelect = this.previousPage;
                    //console.log('pageToSelect', pageToSelect, pageToSelect.alpha_num_name);
                    this.edMxNavToPage(pageToSelect ? pageToSelect.id : null);  // it will nav to root if null

                    this.$store.dispatch('presDeletePageAndResequenceSortOrderAlphaNumName', pageIdToDelete);
                }, () => {});
            },

            renameCurrentPage: function(pg) {
                ScRenameModal2.modal({ name: (pg.title !=='None') ? pg.title : '',
                                            allowEmpty: true, maxLength: 128}, (newName) => {
                    let changedPage = Object.assign({}, pg);
                    changedPage.title = newName;
                    return this.$store.dispatch('presUndoableSavePage', changedPage);
                });
            },
            openNewSlideModal () {
                EditorNewSlideModal.modal(this.page.pagelist_id);
            }
        },
        computed: {

            currentPageIncomingPages() {
                //console.log('currentPageIncomingPages');
                let pages = [];
                if (this.page) {
                    pages = this.$store.getters.presPagesThatTargetPageId(this.page.id);
                }
                return pages;
            },
            numberSiblingSlides () {
                let pages = this.$store.getters.presPagesForPagelist(this.page.pagelist_id);
                return pages ? pages.length : 0;
            },
            isPageUsed() {
                return !this.edMnUnusedPageIds.includes(this.page.id);
            },
            previousPage() {
                let prev = null;
                if (this.page) {
                    let pages = this.$store.getters.presPagesForPagelist(this.page.pagelist_id);
                    let currentIdx = pages.indexOf(this.page);
                    if (currentIdx > 0) prev = pages[currentIdx-1];
                }
                return prev;
            },
            nextPage() {
                let next = null;
                if (this.page) {
                    let pages = this.$store.getters.presPagesForPagelist(this.page.pagelist_id);
                    let currentIdx = pages.indexOf(this.page);
                    if (currentIdx+1 < pages.length) {
                        next = pages[currentIdx+1];
                    }
                }
                return next;
            },
        }
    }
</script>

<style scoped>
    .slide-more-dropdown {
        z-index: 100 /* see README.md webapp z-index values */
    }
</style>