


let BrowserNativeExts = {

    /** files and folders **/

    getStaticRootUrl() {
        return 'https://cdn.showcaseworkshop.com';
    },

    getThemedAssetUrl: function() {
        let scLang = window.SC_LANG ? window.SC_LANG : 'en-en';
        return 'https://cdn.showcaseworkshop.com/showcase-custom-themes/1.0.0/' + scLang;
    },


    /** URL handling **/

    openExternally: async (urlOrPath) => {
        //console.log('bn oe');
        window.open(urlOrPath, '_blank');  // open in a new tab
    },

    isOnline() {
        return navigator.onLine;
    },


}


export default BrowserNativeExts;
