<template>
    <div class="drawer container-fluid p-0">
        <div class="row g-0" style="position: relative; bottom: 0;">
            <div class="col-12 p-0">
                <div class="pagelist-thumbs">
                    <div class="row g-0 p-3 sticky-top mx-0" style="background-color: #f5f5f5;">
                        <div class="col-4">
                            <button type="button" class="btn btn-outline-secondary dropdown-toggle sc-highlight-expanded"
                                    data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                <ScIcon v-if="pageFilter === 'all'" name="asterisk" />
                                <ScIcon v-else-if="pageFilter === 'current'" name="clone" />
                                <ScIcon v-else-if="pageFilter === 'unused'" name="lowVision" />
                            </button>
                            <div class="dropdown-menu dropdown-menu-right pe-3">
                                <a href="#" @click.prevent="pageFilter='all'"
                                   :class="['dropdown-item text-start', pageFilter === 'all' ? 'active' : '']">
                                    <ScIcon name="asteriskFW" class="me-2"/> All Slides
                                </a>
                                <a href="#" @click.prevent="pageFilter='current'"
                                   :class="['dropdown-item text-start', pageFilter === 'current' ? 'active' : '']">
                                    <ScIcon name="cloneFW" class="me-2"/> Current Slideshow
                                </a>
                                <a href="#" @click.prevent="pageFilter='unused'"
                                   :class="['dropdown-item text-start', pageFilter === 'unused' ? 'active' : '']">
                                    <ScIcon name="lowVisionFW" class="me-2"/> Unused Slides
                                </a>
                            </div>
                        </div>
                        <div class="col-8">
                            <input type="search" placeholder="Search" class="form-control search-query form-contXXrol-sm" maxlength="128"
                                   v-model.trim="searchForText" @keyup.enter.prevent />
                        </div>
                    </div>

                    <EditorPageListing v-if="displayResults" :searchText="searchForText"
                                       :selectedPageId="(edMxCurrentPage) ? edMxCurrentPage.id : null"
                                       :pageFilter="pageFilter"
                                       :showPagesFullWidth="true"
                                       :displayPageReadOnly="false"
                                       @page-selected="pageSelected"></EditorPageListing>

                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import EdtorMixin from './EditorMixin'
    import EditorPageListing from './EditorPageListing.vue'
    import MainAppMixin from '../MainAppMixin'
    import ScIcon from '../../shared/common/ScIcon.vue';


    export default {
        name: "EditorSidePanel",
        mixins: [MainAppMixin, EdtorMixin],
        components: {ScIcon, EditorPageListing},
        props: [],
        data() {
            return {
                disableAddingSlides: false,
                maxSlidesPerSlideshow: 200,
                searchForText: null,
                pageFilter: 'all',
                displayResults: false
            }
        },

        mounted() {
            setTimeout(() => {
                this.displayResults = true;
            }, 50);  // delay slightly
        },

        methods: {
            pageSelected(page) {
                this.edMxNavToPage(page.id);
            }
        },

        computed: {
            edState() {
                return this.$store.state.ed;
            },
        },

        watch: {
        }

    }

</script>

<style>

    .pl-active {
        background-color: #f5f5f5;
    }


</style>